import React, { Component } from 'react';
import GetPortfolioProfilesAction from './action/GetPortfolioProfilesAction';
import GetPortfolioProfilesStore from './store/GetPortfolioProfilesStore';
import AssignProfilesToPortfolioAction from './action/AssignProfilesToPortfolioAction';
import AssignProfilesToPortfolioStore from './store/AssignProfilesToPortfolioStore';
import GetProfilesAction from '../AuthorisePage/action/GetProfilesAction';
import GetProfilesStore from '../AuthorisePage/store/GetProfilesStore';
import DeletePortfolioProfilesAction from './action/DeletePortfolioProfilesAction';
import DeletePortfolioProfilesStore from './store/DeletePortfolioProfilesStore';
import ChangeProfileAction from '../Dashboard/action/ChangeProfileAction';
import GetIntegrationAction from '../IntegrationControl/action/GetIntegrationAction';
import GetUserAction from '../AuthorisePage/action/GetUserAction';
import GetUserStore from '../AuthorisePage/store/GetUserStore';
import GetPortfolioProfileListAction from './action/GetPortfolioProfileListAction';
import GetPortfolioProfileListStore from './store/GetPortfolioProfileListStore';
import './style.scss';
import { Row, Col } from 'react-bootstrap';
import {
	LocaleProvider,
	Table,
	notification,
	Dropdown,
	Menu,
	message,
	Button,
	Modal,
	Input,
} from 'antd';
import enUS from 'antd/lib/locale-provider/en_US';
import Select from 'react-select';
import _ from 'lodash';
import urlConfig from '../../config/index';
var languageFile = require("../../../public/languages/Translation.js")
var analytics = window.analytics;
var cookie = require('../../utils/cookie');

class PortfolioManageProfiles extends Component {
	constructor(props) {
		super(props);
		this.state = {
			roleValue: 'READ_ONLY',
			loading: true,
			UsersForProfile: [],
			selectedRowKeys: [],
			newProfile: '',
			profiles: [],
			selectedOption: null,
			showRemoveProfileConfirm: false,
			profileLandingPage: '/dashboard',
		};
		this.addProfile = this.addProfile.bind(this);
		this.handleCancel = this.handleCancel.bind(this);
		this.newProfileChange = this.newProfileChange.bind(this);
		this.profilesResponse = this.profilesResponse.bind(this);
		this.profilesResponse = this.profilesResponse.bind(this);

		this.onAddProfile = this.onAddProfile.bind(this);
		this.getPortfolioProfile = this.getPortfolioProfile.bind(this);
		this.showRemovePortfolioProfileConfirm = this.showRemovePortfolioProfileConfirm.bind(this);
		this.deletePortfolioProfile = this.deletePortfolioProfile.bind(this);
		this.viewProfile = this.viewProfile.bind(this);
	}

	componentWillMount() {
		GetPortfolioProfilesStore.bind(this.getPortfolioProfilesResponse);
		GetProfilesStore.bind(this.profilesResponse);
		AssignProfilesToPortfolioStore.bind(this.assignProfilesResponse);
		DeletePortfolioProfilesStore.bind(this.deleteProfilesResponse);
		GetUserStore.bind(this.getUserImage);
		GetPortfolioProfileListStore.bind(this.getPortfolioProfile);
		GetUserAction.get();

	}

	componentWillUnmount() {
		GetPortfolioProfilesStore.unbind(this.getPortfolioProfilesResponse);
		GetProfilesStore.unbind(this.profilesResponse);
		AssignProfilesToPortfolioStore.unbind(this.assignProfilesResponse);
		DeletePortfolioProfilesStore.unbind(this.deleteProfilesResponse);
		GetPortfolioProfileListStore.bind(this.getPortfolioProfile);
		GetUserStore.unbind(this.getUserImage);
	}

	componentDidMount() {
		GetPortfolioProfilesAction.getPortfolioProfiles(localStorage.getItem('portfolioId'));
		GetProfilesAction.getProfiles(cookie.readCookie('lctoken'));
		GetPortfolioProfileListAction.getPortfolioProfileList(cookie.readCookie('lctoken'))
	}
	profilesResponse() {
		// var profiles = []
		// console.log("xxxxxxxxxxx",GetPortfolioProfilesStore.getResponse())
		// profiles = _.map(GetProfilesStore.getResponse(), (item, key) => {
		// 	_.map(GetPortfolioProfilesStore.getResponse().list, (content, key) => {
		// 	return item;
		// });
		// });
		// this.setState({
		// 	profiles: GetProfilesStore.getResponse(),
		// });
	}
	getPortfolioProfile(){
		this.setState({
			profiles: GetPortfolioProfileListStore.getResponse(),
		});
	}
	getUserImage = () => {
		if (GetUserStore.getResponse().userImage) {
			localStorage.setItem('userImage', GetUserStore.getResponse().userImage);
		} else {
			localStorage.setItem('userImage', '/images/googleavatar.png');
		}
		if (GetUserStore.getResponse().landingPage) {
			this.setState({
				profileLandingPage: GetUserStore.getResponse().landingPage
				// profileLandingPage:
				// 	tabRedirectionMapping[GetUserStore.getResponse().landingPage],
			});
		}
	};
	deleteProfilesResponse = () => {
		if (DeletePortfolioProfilesStore.getResponse()) {
			if (DeletePortfolioProfilesStore.getResponse().status === 403) {
				message.warning("User dosen't have sufficient permission!");
			} else {
				message.success("Profile successfully deleted!");
				GetPortfolioProfileListAction.getPortfolioProfileList(cookie.readCookie('lctoken'))
				GetPortfolioProfilesAction.getPortfolioProfiles(localStorage.getItem('portfolioId'));
			}
		}
	}
	deletePortfolioProfile() {
		this.setState({
			showRemoveProfileConfirm: false,
		});
		DeletePortfolioProfilesAction.deletePortfolioProfile(this.state.profileId, localStorage.getItem('portfolioId'))
		//	DeletePortfolioAction.deletePortfolio(this.state.portfolioId);
	}
	showRemovePortfolioProfileConfirm(profileId) {
		this.setState({
			profileId: profileId,
			showRemoveProfileConfirm: true,
		});
	}
	chooseOptionProfileRemove = (item, key) => {
		if (key.key == 'deleteProfile') {
			this.showRemovePortfolioProfileConfirm(item.profileId);
		}
	};
	viewProfile = (
		profileId,
		profileName,
		//paymentExpired,
		masterLocationFilter,
		//profileData
	) => {
		var urlName = urlConfig.urlName;
		// console.log("changeProfile ===> ", profileId, profileName, paymentExpired, masterLocationFilter)
		// if (paymentExpired) {
		// 	localStorage.setItem('profileId', 'expired');
		// 	localStorage.setItem('profileName', profileName);
		// 	this.setState({
		// 		currentProfileId: profileId,
		// 	});
		// 	this.props.history.push('/authorize/paymentExpired');
		// } else {
		localStorage.setItem('profileId', profileId);
		localStorage.setItem('profileName', profileName);
		localStorage.setItem('masterLocationFilter', masterLocationFilter);
		//localStorage.setItem('favicon', profileData.favicon);
		// localStorage.setItem(
		// 	'logo',
		// 	profileData.logo || `/images/${urlName}/logo-icon.png`
		// );
		// localStorage.setItem(
		// 	'logoText',
		// 	profileData.logoWithText || `/images/${urlName}/logo-text.png`
		// );
		// if (profileData.favicon) {
		// 	var link =
		// 		document.querySelector("link[rel*='icon']") ||
		// 		document.createElement('link');
		// 	link.type = 'image/x-icon';
		// 	link.rel = 'shortcut icon';
		// 	link.href = profileData.favicon;
		// 	document.getElementsByTagName('head')[0].appendChild(link);
		// }
		ChangeProfileAction.changeProfile({
			profileId: profileId,
			profileName: profileName,
			masterLocationFilter: masterLocationFilter,
		});
		GetIntegrationAction.getintegration(urlConfig.urlName);
		this.props.history.push(this.state.profileLandingPage);
		//}
	}
	getPortfolioProfilesResponse = () => {
		var profileData = [];
		var data1 = [];
		if (
			GetPortfolioProfilesStore.getResponse() &&
			GetPortfolioProfilesStore.getResponse().total > 0
		) {
			let unsortData = GetPortfolioProfilesStore.getResponse().list;
			data1 = _.sortBy(unsortData, [
				function (o) {
					return o.profileName && o.profileName.toLowerCase();
				},
			]);
			profileData = data1.map(function (item, index) {
				return (
					<div key={index} className="profile-block">
						<div className="profile-block__header">
							<div
								onClick={this.viewProfile.bind(
									this,
									item.profileId,
									item.profileName,
									item.masterLocationFilter,

								)}
								className="portfolio-profile-brand">
									<span>{item.profileName.substring(0, 2)}</span>
									<h3>{item.profileName}</h3>
								</div>
								<div className="portfolio-profile-delete">
									<div className="chat-filter">
										<Dropdown
											overlay={
												<Menu onClick={this.chooseOptionProfileRemove.bind(this, item)}>
													<Menu.Item key="deleteProfile">
														<i
															className="fa fa-trash-o"
															style={{ fontSize: 15, marginRight: 6 }}
														></i>
																{languageFile[localStorage.getItem('language')]["27861"]}
															</Menu.Item>
											</Menu>
										}
										trigger={['click']}
										onClick={this.test}
									>
										<a>
											<span></span>
										</a>
									</Dropdown>
								</div>
							</div>
						</div>
						<div className="profile-block__content"> 
							{(item.role === 'OWNER' ||
							  item.role === 'ADMIN') && <ul className="content-list">
								<li>
									<div className='list-top'>
										<span><i class="fa fa-building" aria-hidden="true"></i></span>
										<span>{
												languageFile[localStorage.getItem('language')][
												'12941'
												]
											}</span>
									</div>

									<span className='list-bottom'>{item.activeOrganizations}</span>
								</li>
								<li>
									<div className='list-top'>
										<span><i class="fa fa-map-marker" aria-hidden="true"></i></span>
										<span>{
												languageFile[localStorage.getItem('language')][
												'12374'
												]
											}</span>
									</div>
									<span className='list-bottom'>{(item.masterLocationFilter && item.totalMasterLocationCount || item.locationsCount)}</span></li>								<li>
									<div className='list-top'>
										<span><i class="fa fa-check-circle" aria-hidden="true"></i></span>
										<span>{
												languageFile[localStorage.getItem('language')][
												'20531'
												]
											}</span>
									</div>
									<span className='list-bottom'>{(item.masterLocationFilter && item.totalMasterLocationCount || item.totalActiveLocationCount || "0")}</span></li>							
								</ul>}
						</div>
					</div>
				);
			}, this);
		}
		this.setState({
			profileData: profileData,
			//loading: false
		});
	};

	handleCancel(e) {
		// console.log(e);
		this.setState({
			showAddProfileOptions: false,
			showRemoveProfileConfirm: false,
			selectedOption: null
		});
	}

	newProfileChange(selectedOption) {
		this.setState({ selectedOption: selectedOption });
		this.setState({
			newProfile: selectedOption,
		});
	}
	onAddProfile() {
		let profileIds = [];
		profileIds = _.map(this.state.newProfile, (item, key) => {
			return item.value;
		});
		this.setState({
			showAddProfileOptions: false,
		});
		AssignProfilesToPortfolioAction.assignProfilesToPortfolio({ "portfolioId": localStorage.getItem('portfolioId'), "profileIds": profileIds })
	}
	assignProfilesResponse = () => {
		if (AssignProfilesToPortfolioStore.getResponse()) {
			if (AssignProfilesToPortfolioStore.getResponse().status == 403) {
				message.warning("User dosen't have sufficient permission!");
			} else if (AssignProfilesToPortfolioStore.getResponse().status == 200) {
				message.success(AssignProfilesToPortfolioStore.getResponse().message);
				GetPortfolioProfileListAction.getPortfolioProfileList(cookie.readCookie('lctoken'))
				GetPortfolioProfilesAction.getPortfolioProfiles(
					localStorage.getItem('portfolioId')
				);
			}else{
				message.warning(AssignProfilesToPortfolioStore.getResponse().data.message);
			}
		}
		this.setState({
			selectedOption: null,
		});
	}
	addProfile() {
		this.setState(
			{
				showAddProfileOptions: true,
			},
			function () {
				analytics.track('Portfolio Creation Started');
			}
		);
	}
	render() {
		var ownersProfileOptions = [];
		ownersProfileOptions = _.map(this.state.profiles.list, (item, key) => {
			return {
					label: item.profileName,
					value: item.profileId,
			};		
		});
		// var ownersProfileOptions = this.state.profiles
		// 	.filter(function (item, index) {
		// 		return item.role == 'OWNER';
		// 	})
		// 	.map(function (item) {
		// 		return {
		// 			label: item.profile.profileName,
		// 			value: item.profile.profileId,
		// 		};
		// 	});
		return (
			<LocaleProvider>
				<>
					<div className="page-header">
						<h3> {languageFile[localStorage.getItem('language')]["27851"]}</h3>
					</div>
					<div className="authorize-block">
						<div className="container-fluid">
							<div className="portfolio-profile">
								<div className="profile-block">
									<div onClick={this.addProfile} className="new-card">
										<span className="new-card-icon">
											<svg viewBox="0 0 45 45">
												<path data-name="Path 2" d="M19715,19877v-19h-19v-7h19v-19h7v19h19v7h-19v19Z" transform="translate(-19696 -19832)" />
											</svg>
										</span>
										<h3>{
												languageFile[localStorage.getItem('language')][
												'10171'
												]
											}</h3>
									</div>
								</div>
								{this.state.profileData}
							</div>
						</div>
					</div>
					<Modal
						visible={this.state.showAddProfileOptions}
						title={languageFile[localStorage.getItem('language')]["27891"]}
						width={450}
						onOk={this.handleOk}
						onCancel={this.handleCancel}
						footer={
							<div key="0">
								<Button
									key="submit"
									type="primary"
									//loading={this.state.loading}
									onClick={this.onAddProfile}
								>
									Add
								</Button>
								<Button
									key="cancel"
									type="default"
									//loading={this.state.loading}
									onClick={this.handleCancel}
								>
									{languageFile[localStorage.getItem('language')]["10523"]}
								</Button>
							</div>
						}
					>
						<div className="invite-options">
							{/* <div className="hint-text">
								<span>Select Profile</span>
							</div> */}
							<div style={{ textAlign: 'center' }}>
								<Select
									isClearable
									isMulti
									name="form-field-name"
									placeholder={languageFile[localStorage.getItem('language')]["27901"]}
									value={this.state.selectedOption}
									options={ownersProfileOptions}
									onChange={this.newProfileChange}
								/>
							</div>
						</div>
					</Modal>
					<Modal
						visible={this.state.showRemoveProfileConfirm}
						wrapClassName="conflict-message"
						title={languageFile[localStorage.getItem('language')]['10222']}
						width={450}
						onOk={this.handleOk}
						onCancel={this.handleCancel}
						footer={
							<div>
								<Button
									style={{ backgroundColor: '#F58258', borderColor: '#F58258' }}
									key="submit"
									type="primary"
									onClick={this.deletePortfolioProfile}
								>
									{languageFile[localStorage.getItem('language')]["22971"]}
								</Button>
								<Button key="cancel" type="default" onClick={this.handleCancel}>
									{languageFile[localStorage.getItem('language')]["22821"]}
								</Button>
							</div>
						}
					>
						<div>{languageFile[localStorage.getItem('language')]["27871"]}</div>
					</Modal>
				</>
			</LocaleProvider>
		);
	}
}

export default PortfolioManageProfiles;
