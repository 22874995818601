import React from 'react';
import { ResponsiveContainer, Area, AreaChart, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import StackedBarChartStore from './store/StackedBarStore';
import StackedBarChartAction from './action/StackedBarChartAction';
var moment = require("moment");
var languageFile = require("../../../../public/languages/Translation");

class StackedBarChart extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true
		}
		this.getData = this.getData.bind(this);
		this.onGraphResponse = this.onGraphResponse.bind(this);
	}

	getData() {
		var hashMap = { 'QUERIES_DIRECT': languageFile[localStorage.getItem('language')]["10765"], 'QUERIES_INDIRECT': languageFile[localStorage.getItem('language')]["10771"], "QUERIES_CHAIN": languageFile[localStorage.getItem('language')]["21241"], 'date': 'date' }
		if (StackedBarChartStore.getResponse()) {
			// console.log("StackedBarChartStore ===> ",StackedBarChartStore.getResponse())
			var data = StackedBarChartStore.getResponse().histogram.map(function (item, index) {
				var singleItem = {};
				for (var i in item) {
					var value = item[i];
					if (i === 'date') {
						value = moment(value).format('ll')
					}
					singleItem[hashMap[i]] = value;
				}
				return singleItem;
			})
			this.setState({
				graphData: data,
				loading: false
			})
		}
	}

	componentWillMount() {
		StackedBarChartStore.bind(this.onGraphResponse);
		this.getData();
	}

	componentWillUnmount() {
		StackedBarChartStore.unbind(this.onGraphResponse);
		// StackedBarChartAction.clearFilters();
	}

	onGraphResponse() {
		this.getData();
	}

	formatNumbers(n) {
		return n.toLocaleString();
	}

	render() {
		if (this.state.loading) {
			return (
				<div className="loader-main">
					<div className="loader"></div>
				</div>
			)
		}

		if (this.state.graphData.length === 0) {
			return <div className="loader-main nightmare-check-stacked-bar">{languageFile[localStorage.getItem('language')]["23191"]}</div>
		}

		return (
			<span className='nightmare-check-stacked-bar'><ResponsiveContainer width="100%" height="100%">
				<AreaChart data={this.state.graphData}
					margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
					<XAxis dataKey="date" />
					<YAxis tickFormatter={n => n.toLocaleString()}/>
					<Tooltip formatter={n => n.toLocaleString()} />
					<Legend align='left' iconType="rect" wrapperStyle={{ border: "0px" }} />
					<Area dataKey={languageFile[localStorage.getItem('language')]["10765"]} stackId={1} stroke="#08589e" fill="#08589e" fillOpacity={1}/>
					<Area dataKey={languageFile[localStorage.getItem('language')]["10771"]} stackId={1} stroke="#2B86B9" fill="#2B86B9" fillOpacity={1}/>
					<Area dataKey={languageFile[localStorage.getItem('language')]["21241"]} stackId={1} stroke="#4eb4d3" fill="#4eb4d3" fillOpacity={1}/>
				</AreaChart>
			</ResponsiveContainer></span>
		)
	}
}

export default StackedBarChart;