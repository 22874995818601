import React from "react";
import { SideBarRouteItem } from './SideBarRouteItem'

export const SideBarRouteWrapper = ({ sidebarRoutesList, onClickSideBarRouteItem, pathname}) => {
    return <ul className="settings__sidebar--menu">
        {
            sidebarRoutesList
                .map(
                    (item, key) => {
                            return (
                                <SideBarRouteItem
                                    onLinkClick={onClickSideBarRouteItem}
                                    key={key}
                                    index={key}
                                    config={item}
                                    routePath={pathname}
                                />
                            )
                    }
                )
        }
    </ul>
}