var languageFile = require("../../public/languages/Translation");

module.exports = {
    missingMap: {
        'storeCode': languageFile[localStorage.getItem('language')]["27481"],
        'regularHour':'Store Hours',
        'phone': languageFile[localStorage.getItem('language')]["13138"],
        'website': 'Website Link',
        'description': languageFile[localStorage.getItem('language')]["10759"],
        'additionalCategories': languageFile[localStorage.getItem('language')]["27491"],
        'attributes': languageFile[localStorage.getItem('language')]["24011"],
		'brandImages': 'Brand Images',
		'brandLogo': 'Brand Logo',
		'latlng': 'Latitude & Longitude'
 
    },

    statusMap: {
        'isVerified': languageFile[localStorage.getItem('language')]["20461"],
        'unVerified': 'Un-verified',
        'hasPendingVerification': 'Pending Verification',
        'needsReverification': 'Re-Verification',
        'open': languageFile[localStorage.getItem('language')]["27451"],
        'isGoogleUpdated': languageFile[localStorage.getItem('language')]["27461"],
        'isPendingReview': 'Pending Review',
        // 'isDisabled': 'Disabled',
        'isDuplicate': 'Duplicate',
        'isDisconnected': 'Disconnected',
        'close': 'Closed Permanently',
        'closed_temporarily': 'Closed Temporarily',        
    },

    mapOptions:{
        'storeCode': languageFile[localStorage.getItem('language')]["27481"],
        'regularHour':'Store Hours',
        'phone': languageFile[localStorage.getItem('language')]["13138"],
        'website': 'Website Link',
        'description': languageFile[localStorage.getItem('language')]["10759"],
        'additionalCategories': languageFile[localStorage.getItem('language')]["27491"],
        'attributes': languageFile[localStorage.getItem('language')]["24011"],
        'isVerified': languageFile[localStorage.getItem('language')]["20461"],
        'unVerified': 'Un-verified',
        'hasPendingVerification': 'Pending Verification',
        'needsReverification': 'Re-Verification',
        'open': languageFile[localStorage.getItem('language')]["27451"],
        'isGoogleUpdated': languageFile[localStorage.getItem('language')]["27461"],
        'isPendingReview': 'Pending Review',
        'isDisabled': 'Disabled',
        'isDuplicate': 'Duplicate',
        'isDisconnected': 'Disconnected',
        'close': 'Closed Permanently',
		'closed_temporarily': 'Closed Temporarily',  
		'brandImages': 'Brand Images',
		'brandLogo': 'Brand Logo',
		'latlng': 'Latitude & Longitude'

    },
    locationFields:{
        'primaryPhone': 'Primary phone',
        'websiteUrl':'Website url',
        'additionalPhones': 'Additional phones',
        'attributes': 'Attributes',
        'storeCode': 'Store code',
        'labels': 'Labels',
        'locationName': 'Location Name',
        'primaryCategory': 'Primary Category',
        'regularHours': 'Regular Hours',
        'specialHours': 'Special Hours',
        'address': 'Address',
        'profile': 'Profile',
        'openInfo' : 'Open Info',
        'postalAddress':'Postal Address',
        'relationshipData':'Relationship Data',
        'additionalCategories':'Additional Categories'
    },
    sourceOptions :  [
	{
		value: 'google',
		label: 'Google',
	},
	{
		value: 'facebook',
		label: 'Facebook',
	},
	{
		value: 'tripadvisor',
		label: 'Tripadvisor',
	},
	{
		value: 'yelp',
		label: 'Yelp',
	},
	{
		value: 'booking',
		label: 'Booking',
	},
	{
		value: 'reviewshakebooking',
		label: 'Booking Index',
	},
	{
		value: 'cellarpass',
		label: 'Cellerpass',
	},
	{
		value: 'lawyers',
		label: 'Lawyers',
	},
	{
		value: 'ratemds',
		label: 'Ratemds',
	},
	{
		value: 'vitals',
		label: 'vitals',
	},
	{
		value: 'healthgrades',
		label: 'Healthgrades',
	},
	{
		value: 'productreview',
		label: 'Productreview',
	},
	{
		value: 'apartments',
		label: 'Apartments',
	},
	{
		value: 'zomato',
		label: 'Zomato',
	},
	{
		value: 'zocdoc',
		label: 'Zocdoc',
	},
	{
		value: 'trustpilot',
		label: 'Trustpilot',
	},
	{
		value: 'glassdoor',
		label: 'Glassdoor',
	},
	{
		value: 'hostellingscotland',
		label: 'Hostelling Scotland',
	},
	{
		value: 'hostelworld',
		label: 'Hostel world',
	},
	{
		value: 'expedia',
		label: 'Expedia',
	},
	{
		value: 'indeed',
		label: 'Indeed',
	},
	{
		value: 'opentable',
		label: 'OpenTable',
	},
	{
		value: 'zillow',
		label: 'Zillow',
	},
	{
		value: 'hotels',
		label: 'Hotels.com',
	},
	{
		value: 'foursquare',
		label: 'Foursquare',
	},
	{
		value: 'reviewshakegmb',
		label: 'GBP Public',
	},
	{
		value: 'googleplaystore',
		label: 'Google Play Index',
	},
	{
		value: 'appstore',
		label: 'App Store Index',
	},
	{
		value: 'appleappstore',
		label: 'App Store',
	},
	// {
	// 	value: 'applemaps',
	// 	label: 'Apple Places',
	// },
	{
		value: 'applebusinessconnect',
		label: 'Apple Business Connect',
	},
	{
		value: 'cars',
		label: 'Cars.com',
	},
	{
		value: 'cargurus',
		label: 'Carguru',
	},
	{
		value: 'dealerrater',
		label: 'Dealerrater',
	},
	{
		value: 'edmunds',
		label: 'Edmunds',
	},
	{
		value: 'talabat',
		label: 'Talabat',
	},
	{
		value: 'reserveout',
		label: 'Reserveout',
	},
	{
		value: 'homeadvisor',
		label: 'HomeAdvisor',
	},
	{
		value: 'trustradius',
		label: 'TrustRadius',
	},
	{
		value: 'capterra',
		label: 'Capterra',
	},
	{
		value: 'gtwo',
		label: 'G2 Crowd',
	},
	{
		value: 'airbnb',
		label: 'Airbnb',
	},
	{
		value: 'ubereats',
		label: 'Uber Eats',
	},
	{
		value: 'doordash',
		label: 'Doordash',
	},
	{
		value: 'grubhub',
		label: 'Grubhub',
	},
	{
		value: 'reviewshaketrustpilot',
		label: 'Trustpilot Index  ',
	},
	{
		value: 'cardealer',
		label: 'Car Dealer Reviews',
	},
	{
		value: 'trustedshops',
		label: 'TrustedShops',
		
	},
	{
		value: 'playstore',
		label: 'Google Play',
	},
	{
		value: 'reviewshakefacebook',
		label: 'Facebook Index',
	},
]

}