import AppDispatcher from '../../../flux/dispatcher/dispatcher';
import Constant from '../constant/ConversionRateConstant';
import restClient from '../../MainFrame/action/APIClient';
import LogoutConstant from '../../MainFrame/constants/Constants';

var GetCityChartAction = function () {

}

GetCityChartAction.prototype = {

    getCity: function (data) {
	restClient.getConversionRates(data, function (error, response) {
		if (error) {
			if (error.status === 401) {
				AppDispatcher.dispatch({
					actionType: LogoutConstant.UNAUTHORIZED_EXCEPTION,
					data: error
				});
			} else if (error.status === 404) {
				AppDispatcher.dispatch({
					actionType: Constant.CITY_RATES,
					data: error
				});
			}
		} else {
			AppDispatcher.dispatch({
				actionType: Constant.CITY_RATES,
				data: response
			});
		}
	})
}
}

export default new GetCityChartAction();