import AppDispatcher from '../../../flux/dispatcher/dispatcher';
import Constant from '../constant/Constant';
import restClient from '../../../routers/MainFrame/action/APIClient';

var GetLocationEditsAction = function () {

}

GetLocationEditsAction.prototype = {

	getLocationEdits: function (data) {
		restClient.getLocationEdits(data, function (error, response) {
			if (error) {
				// console.log("error", error);
				AppDispatcher.dispatch({
					actionType: Constant.EDIT_LOCATION,
					data: error
				});
			} else if(response != null) {
				response['status'] = 200;
				AppDispatcher.dispatch({
					actionType: Constant.EDIT_LOCATION,
					data: response
				});
			}
		})
	}
}

export default new GetLocationEditsAction();