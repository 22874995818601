import React from 'react';
import { LCModal, LCButton ,LCInput} from '../../../frameWork';
var languageFile = require('../../../../public/languages/Translation.js');

const CHANGE_PAYER_BUTTON='Change payer';
const VALID_EMAIL_TEXT='Please enter a valid email!';
const NEW_BILLING_EMAIL_TEXT='Enter new billing email';
const CANCEL='Cancel';
export const ChangeBillingEmailModalWindow = ({
    changePayerDetailsModal,
    handleCancel,
    handleOk,
    changeOwner,
    invalidEmail,
    payerEmail,
    updatePayerEmail
}) => {
	return (
		<LCModal
			title='Update Payer details'
			width={400}
			visible={changePayerDetailsModal}
			onOk={handleOk}
			onCancel={handleCancel}
			footer={
				<div>
					<LCButton type='primary' onClick={changeOwner}
					buttonlabel={CHANGE_PAYER_BUTTON}
					/>
					<LCButton onClick={handleCancel}
					buttonlabel={CANCEL}
					/>
				</div>
			}
			modalContent={<div>
				{NEW_BILLING_EMAIL_TEXT}
				<div style={{margin: 10}}>
					{invalidEmail && (
						<span className='error-message'>
							{VALID_EMAIL_TEXT}
						</span>
					)}
					<LCInput
						placeholder={languageFile[localStorage.getItem('language')]['28161']}
						value={payerEmail}
						onChange={updatePayerEmail}
					/>
				</div>
			</div>}
		/>
	);
};
