import Dispatcher from '../../../flux/dispatcher/dispatcher';
import Constant from '../constants/LoginConstant';

var EventEmitter = require('events').EventEmitter;
var assign = require('object-assign');
var RESPONSE_CHANGE_EVENT = 'globalResponse';

var response={}
function parseResponse(resp){
    response = resp;
    response.status = 200;
}
function parseError(err) {
    response = {};
    response.status = err.status;
    response.message = err.data.message;
}

var GetWorkSpaceStore = assign({},EventEmitter.prototype,{
    emitChangeEvent: function(event) {
        this.emit(event);
    },
    bind: function(callback) {
        this.on(RESPONSE_CHANGE_EVENT, callback);
    },
    unbind: function(callback) {
        this.removeListener(RESPONSE_CHANGE_EVENT,callback);
    },
    getResponse:function(){
        return response
    }
});

Dispatcher.register(function(action){
    switch (action.actionType) {
        case Constant.GET_WORKSPACE_RESPONSE:
            var resp = action.data;
            parseResponse(resp)
            GetWorkSpaceStore.emitChangeEvent(RESPONSE_CHANGE_EVENT);
            break;
        case Constant.GET_WORKSPACE_ERROR:
            var error = action.data;
            parseError(error)
            GetWorkSpaceStore.emitChangeEvent(RESPONSE_CHANGE_EVENT)
            break;
        default:
    }
});

export default  GetWorkSpaceStore;