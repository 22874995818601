import React from 'react'
import './style.scss'
import { urlName } from '../../config';
import { ButtonElement } from '../../components/ButtonElement';

function ResetPasswordExpired() {

  const toForgotPassword = ()=>{
    window.location.href ="/forgotPassword";
  }
  
  return (
    <div className='main-container'>
        <div className='reset-wrapper'>
        <div className='logo'>
             <span>
                <img src={`/images/${urlName}/logo-icon.png`} />
                {/* <img src={require("../../../public/images/logo-icon.png")} /> */}
            </span>
        </div>
        <h3 className='title'>Time Out!</h3>
        <p  className='description'>The link to reset your password has been expired!</p>
        <ButtonElement
                            onClick={()=>toForgotPassword()}
                            children={
                                'Reset Password'
                            }
                            type='primary'
                        />
       
        </div>
    </div>
  )
}
export default ResetPasswordExpired