import React from 'react';
var languageFile = require('../../../../public/languages/Translation');
const PER_LOCALTIONS=languageFile[localStorage.getItem('language')]["24141"];
const LOCATIONS = languageFile[localStorage.getItem('language')]["24161"];

export const PlusPlanTitle = () => {
	return (
		<div className='payment-price'>
			<h3 style={{margin: 0}}>
				<span className='price-line'>
					<span className='price-symbol'>$</span>
					<span className='price-amount'>10 </span>
					PER LOCATION
				</span>{' '}
			</h3>
			<h5
				style={{
					margin: '25px 0 10px 0',
					fontWeight: 300
				}}>
			</h5>
		</div>
	);
};
