import Dispatcher from '../../../../flux/dispatcher/dispatcher';
import Constants from '../constant/Constant'
var EventEmitter = require('events').EventEmitter;
var RESPONSE_CHANGE_EVENT = 'globalResponse';
var assign = require('object-assign');

var response = null;

function parseResponse(resp){
  response = resp;
}

var InsightGroupBarchartCustomerStore = assign({},EventEmitter.prototype,{
   emitChangeEvent: function(event) {
       this.emit(event);
   },
   bind: function(callback) {
       this.on(RESPONSE_CHANGE_EVENT, callback);
   },
   unbind: function(callback) {
       this.removeListener(RESPONSE_CHANGE_EVENT, callback);
   },
   getResponse:function(){
        return response;
   }
});

Dispatcher.register(function(action){

   switch (action.actionType) {
       case Constants.INSIGHTS_BAR_CUSTOMER:
           var resp = action.data;
           parseResponse(resp)
           InsightGroupBarchartCustomerStore.emitChangeEvent(RESPONSE_CHANGE_EVENT)
       default:
   }


});
export default InsightGroupBarchartCustomerStore;
