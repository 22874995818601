import React from 'react'
import WorkspaceHome from './children/WorkspaceHome'
import WorkSpaceCreate from './children/WorkSpaceCreate'
import WorkSpaceSearch from './children/WorkSpaceSearch'
import WorkSpacePlan from './children/WorkSpacePlan'
import style from './children/style.module.scss';

export default function RightSection(props) {
  return (
    <section className={style.rightSection}>
      {(props.step == 2 && props.subPage == 1) ? <WorkspaceHome {...props} /> :
       (props.step == 2 && props.subPage == 2) ? <WorkSpaceSearch {...props}/> :props.step == 3 ? <WorkSpaceCreate {...props}/>: <WorkSpacePlan {...props}/>}
    </section>
  )
}
