import React from 'react';
import {Table, Pagination, Icon, Tooltip,Checkbox} from 'antd';
import {LCTable, LCPagination} from '../../../frameWork';
//import getUsersForProfileStore from '../store/getUsersForProfileStore';
import GetPortfolioProfilesStore from '../../PortfolioManageProfiles/store/GetPortfolioProfilesStore';

//import DeleteUserStore from '../store/DeleteUserStore';
import moment from 'moment';
import Select from 'react-select';

var languageFile = require('../../../../public/languages/Translation.js');

export const RenderProfileListWrapper = ({
    tableLoading,
    tableData,
    onPageChange,
    showTotal,
    changeSize,
    profileOptions,
    rolesMenu,
    roleChange,
    getPageSizeOptions,
    editUser,
    DeleteInvitedUser,
    reinviteUser,
   // assignProfileChange,
    ischecked,
    allChecked,
    ischeckedAll,
    roleChanges
}) => {
    const cols = [
       
        {
            title:
            <Checkbox checked={ischeckedAll} onChange={allChecked}/>,
            dataIndex: 'userId',
            key: 'userId',
            render: (text, record) => {
                return (
                    <div>
                        <Checkbox checked={record.ischecked} onChange={ischecked.bind({}, record)} />
                    </div>
                );
            }
        },
        {
            title:languageFile[localStorage.getItem('language')]["28081"],
            dataIndex:"label",
            key:"label",
            render: (text, record) => {
                return (
                    // <div>{text || " - "}</div>
                    <div>{text || " - "}</div>
                )
            }
        },
        {
            title: languageFile[localStorage.getItem('language')]['13598'],
            dataIndex: 'roleName',
            key: 'roleName',
            render: (text,record) => (
               
            <span className="role-actions" onClick={roleChanges.bind({}, record)}>
            <Select
				name='form-field-name'
                placeholder='Select Role'
                isClearable='true'
                isDisabled={record.roleVisible}
				// isLoading={drodpownLoading}
				value={record.selectedRole}
				options={rolesMenu}
				// onInputChange={searchForRole}
				 onChange={roleChange.bind({}, record)}
			/>
            </span>
            )
        },
    ];

    return (
        <div className='portfolio-settings'>
            <section className='card'>
                <article className='card-body'>
                    <LCTable
                        loading={tableLoading}
                        pagination={{ defaultPageSize: 5}}
                        columns={cols}
                        dataSource={profileOptions}
                        total={
                            GetPortfolioProfilesStore.getResponse()
                                ? GetPortfolioProfilesStore.getResponse()
                                    .total
                                : 0
                        }
                        //onChange={onPageChange}
                        //footer={`Total 8 items`}
                        //showTotal={`Total 8 items`}
                       // onChange={assignProfileChange}
                    />
                    {/* <footer className='card-footer'>
                        <LCPagination
                            total={
                                GetPortfolioProfilesStore.getResponse()
                                    ? GetPortfolioProfilesStore.getResponse()
                                        .total
                                    : 0
                            }
                            //onChange={onPageChange}
                            showTotal={showTotal}
                            pagination={{ defaultPageSize: 5}}
                            pageSizeOptions={["5"]}
                            //showSizeChanger
                            onShowSizeChange={changeSize}
                            defaultPageSize={5}
                        />
                    </footer> */}
                </article>
            </section>
        </div>
    );
};
const inviteDateFormat = (text,record) => {
    if(text){
        return moment(text || record.inviteDate).format('ll')
    }else if(record.inviteDate){
        return moment(text || record.inviteDate).format('ll')
    }else {
        return "-";
    }
}