import React from 'react';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import LineChartStore from './store/LineChartStore';
// import LineChartAction from 'components/LineChartComponent/action/LineChartAction';
var moment = require('moment');
var languageFile = require("../../../../public/languages/Translation");

class LineChartComponent extends React.Component{

	constructor(props) {
		super(props)
		this.state = {
			loading: true
		}
		this.getData = this.getData.bind(this)
		this.onGraphResponse = this.onGraphResponse.bind(this)
	}

	componentWillMount () {
		LineChartStore.bind(this.onGraphResponse);
		this.getData();
	}
	getData () {
		var hashMap = { 'ACTIONS_DRIVING_DIRECTIONS': languageFile[localStorage.getItem('language')]["10792"], 'ACTIONS_PHONE': languageFile[localStorage.getItem('language')]["13132"], 'ACTIONS_WEBSITE': languageFile[localStorage.getItem('language')]["14523"], 'date': 'date' };
		if (LineChartStore.getResponse()) {
			var data = LineChartStore.getResponse().histogram.map(function (item, index) {
				var singleItem = {};
				for (var i in item) {
					var value = item[i];
					if (i === 'date') {
						value = moment(value).format('ll')
					} else {
						if (value === 0) {
							value = null;
						}
					}
					singleItem[hashMap[i]] = value;
				}
				return singleItem;
			})
			// console.log("xxxdata", data)
			this.setState({
				graphData: data,
				loading: false
			})
		}
	}
	componentWillUnmount () {
		LineChartStore.unbind(this.onGraphResponse);
		// LineChartAction.clearFilters();
	}

	onGraphResponse () {
		this.getData();
	}

	formatterFunction (val) {
		// console.log("val is", val)
	}

	render () {

		if (this.state.loading) {
			return (
				<div className="loader-main">
					<div className="loader"></div>
				</div>
			)
		}

		if (this.state.graphData.length === 0) {
			return <div className="loader-main nightmare-check-stacked-line">{languageFile[localStorage.getItem('language')]["23191"]}</div>
		}

		return (
			<span className='nightmare-check-stacked-line'><ResponsiveContainer width="100%" height="100%">
				<LineChart data={this.state.graphData}
					margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
					<CartesianGrid vertical={false} />
					<XAxis dataKey="date" />
					<YAxis tickFormatter={n => n.toLocaleString()} tickLine={false} axisLine={false} />
					<Tooltip formatter={n => n.toLocaleString()} />
					<Legend iconType='rect' align='left' wrapperStyle={{ border: "0px" }} />
					<Line type="monotone" dataKey={languageFile[localStorage.getItem('language')]["10792"]} connectNulls={true} stroke="#08589e" strokeWidth={2} />
					<Line type="monotone" dataKey={languageFile[localStorage.getItem('language')]["13132"]} stroke="#F67428" connectNulls={true} strokeWidth={2} />
					<Line type="monotone" dataKey={languageFile[localStorage.getItem('language')]["14523"]} stroke="#37942D" connectNulls={true} strokeWidth={2} />
				</LineChart>
			</ResponsiveContainer></span>
		)
	}
}

export default LineChartComponent;