var keyMirror = require('keymirror');

module.exports = keyMirror({
    SAVE_PROFILE_SETTINGS: null,
    PROFILE_SETTINGS_RECEIVED: null,
    PROFILE_SETTINGS_ABSENT: null,
    GET_AR_SETTINGS: null,
    ADD_AR_SETTINGS:null,
    EDIT_AR_SETTINGS:null,
    DELETE_AR_SETTINGS:null,
    VALIDATE_AR_SETTINGS:null,
    GET_TEMPLATES : null,
    ADD_NEW_AI_AGENT : null,
    UPLOAD_AI_AGENT_ICON: null,
    GET_AI_AGENT_LIST: null,
    UPDATE_AI_AGENT: null,
    DELETE_AI_AGENT: null,
    GET_ENTITY_TAG: null,
    VALIDATE_AI_AGENT: null,
});