import React from 'react';
import style from './style.module.scss';
import { Button } from 'antd';
import getSVG from '../../../../../utils/commonSVG';

export default function WorkSpacePlan(props) {
  return (
    <div className={style.planWrapper}>
        <div className={style.headSection}>
          <h1> 
              How do you plan to use Localclarity?
          </h1>
          <p className={style.label}>
              Select all the apply now and in the near future.
          </p>
        </div>
        <div className={style.planItemsWrapper}>
          <div onClick={()=> props.changePlan('localListings')} className={style.items +' '+ (props.localListings?style.active:'')}>
              <div className={style.content}>
                    <h4 >Local Listings</h4>
                    <span className={style.sub}>Optimize local content to elevate search & map rankings.</span>
              </div>
              {getSVG('check-box')}
          </div>
          <div onClick={()=> props.changePlan('reputationManagement')} className={style.items +' '+ (props.reputationManagement? style.active:'')}>
              <div className={style.content}>
                    <h4 >Reputation Management</h4>
                    <span className={style.sub}>Leverage generative AI to enhance customer experience.</span>
              </div>
              {getSVG('check-box')}
          </div>
          <div onClick={()=> props.changePlan('localSocial')} className={style.items +' '+ (props.localSocial? style.active:'')}>
              <div className={style.content}>
                    <h4 >Local Social</h4>
                    <span className={style.sub}>Shedule and publish posts to locations.</span>
              </div>
              {getSVG('check-box')}
          </div>
          <div onClick={()=> props.changePlan('localKeywords')} className={style.items +' '+ (props.localKeywords? style.active:'')}>
              <div className={style.content}>
                    <h4 >Local Keywords</h4>
                    <span className={style.sub}>Track local search keywords and map rankings.</span>
              </div>
              {getSVG('check-box')}
          </div>
          <div onClick={()=> props.changePlan('analyticReporting')} className={style.items +' '+ (props.analyticReporting?style.active:'')}>
              <div className={style.content}>
                    <h4 >Analytics & Reporting</h4>
                    <span className={style.sub}>Deliver actionable insights in all brand stakeholders.</span>
              </div>
              {getSVG('check-box')}
          </div>

        </div>
        <div className={style.footer}>
          <Button loading={props.createWorkspaceLoader} onClick={props.onCreateWorkspace} className='primary-btn btn-padding-lg larger-button'>Create Workspace</Button>

        </div>
    </div>
  )
}
