import React from 'react';
var languageFile = require('../../../../public/languages/Translation');
const PER_LOCATIONS = languageFile[localStorage.getItem('language')]["24141"];
const LOCATIONS = languageFile[localStorage.getItem('language')]["24261"];
export const ProPlanTitle = () => {
	return (
		<div className='payment-price'>
			<h3 style={{ margin: 0 }}>
				<span className='price-line'>
					<span className='price-symbol'>$</span>
					<span className='price-amount'>12 </span>
					PER LOCATION
				</span>{' '}
			</h3>

			<h5
				style={{
					margin: '20px 0 10px 0',
					fontWeight: 300
				}}>
			</h5>
		</div>
	);
};
