import AppDispatcher from '../../../flux/dispatcher/dispatcher';
import Constant from '../constant/SignupConstant';
import restClient from '../../MainFrame/action/APIClient';



var SignUpAction = function () {

}

SignUpAction.prototype = {

	signup: function (userObject) {
		restClient.signup(userObject, function (error, response) {
			if (error) {
				AppDispatcher.dispatch({
					actionType: Constant.SIGNUP_ERROR,
					data: error
				});
			} else {
				AppDispatcher.dispatch({
					actionType: Constant.SIGNUP_RESPONSE_RECEIVED,
					data: response
				});
			}
		});
	}
}

export default new SignUpAction();