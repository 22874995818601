import React from 'react';
import StackedAreaStore from './store/StackedAreaStore';
import { ResponsiveContainer, AreaChart, Area, XAxis, YAxis, Tooltip, Legend } from 'recharts';
// import GetOrganisationsStore from 'components/ReviewDesk/store/GetOrganisationsStore';
// import StackedAreaAction from 'components/StackedAreaChart/action/StackedAreaChartAction';
import LoadingStatusStore from '../../../routers/Dashboard/store/LoadingStatusStore';
var moment = require("moment");
var languageFile = require("../../../../public/languages/Translation");

class StackedAreaChart extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true
		}
		this.getData = this.getData.bind(this);
		this.onLoadChange = this.onLoadChange.bind(this);
		this.onGraphResponse = this.onGraphResponse.bind(this);
	}

	getData() {
		var hashMap = { 
			'BUSINESS_IMPRESSIONS_DESKTOP_MAPS': languageFile[localStorage.getItem('language')]["2074"],
			'BUSINESS_IMPRESSIONS_MOBILE_MAPS': languageFile[localStorage.getItem('language')]["2073"],
			'BUSINESS_IMPRESSIONS_MOBILE_SEARCH':languageFile[localStorage.getItem('language')]["2075"],
			'BUSINESS_IMPRESSIONS_DESKTOP_SEARCH': languageFile[localStorage.getItem('language')]["2075"],
			'date': 'date' 
		}
		if (StackedAreaStore.getResponse()) {
			var data = StackedAreaStore
						.getResponse()
						.histogram
						.map(function (item, index) {
							var singleItem = {};
							for (var i in item) {
								singleItem[hashMap[i]] = item[i]; 
								var value = item[i];
								if (i === 'date') {
									value = moment(value).format('ll')
								}
								singleItem[hashMap[i]] = value;
							}
							return singleItem;
						})
			this.setState({
				graphData: data,
				loading: false
			})
		}
	}

	componentWillMount() {
		StackedAreaStore.bind(this.onGraphResponse);
		LoadingStatusStore.bind(this.onLoadChange);
		//GetOrganisationsStore.bind(this.onOrgsResponse);
		this.getData();
	}

	componentWillUnmount() {
		StackedAreaStore.unbind(this.onGraphResponse);
		LoadingStatusStore.unbind(this.onLoadChange);
		//GetOrganisationsStore.unbind(this.onOrgsResponse);
		// StackedAreaAction.clearFilters();
	}

	// onOrgsResponse : function(){alert("triggered")
	// 	StackedAreaChartAction.changeOrganisation(GetOrganisationsStore.getResponse().hits[0]);
	// },

	onLoadChange() {
		this.setState({
			loading: true
		})
	}

	onGraphResponse() {
		this.getData();
	}

	render() {
		if (this.state.loading) {
			return (
				<div className="loader-main">
					<div className="loader"></div>
				</div>
			)
		}
		if (this.state.graphData.length === 0) {
			return <div className="loader-main nightmare-check-stacked-area">{languageFile[localStorage.getItem('language')]["23191"]}</div>
		}
		return (
			<span className='nightmare-check-stacked-area'><ResponsiveContainer width="100%" height="100%">
				<AreaChart data={this.state.graphData}
					margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
					<XAxis dataKey="date" />
					<YAxis tickFormatter={n => n.toLocaleString()} />
					<Tooltip formatter={n => n.toLocaleString()} />
					<Legend align='left' iconType="rect" wrapperStyle={{ border: "0px" }} />
					{/* <Area dataKey={languageFile[localStorage.getItem('language')]["12544"]} stackId={1} stroke="#08589e" fill="#08589e" fillOpacity={1}/>
					<Area dataKey={languageFile[localStorage.getItem('language')]["13750"]} stackId={1} stroke="#4eb4d3" fill="#4eb4d3"  fillOpacity={1}/> */}

					<Area dataKey={languageFile[localStorage.getItem('language')]["2073"]} stackId={1} stroke="#08589e" fill="#08589e" fillOpacity={1}/>
					<Area dataKey={languageFile[localStorage.getItem('language')]["2074"]} stackId={1} stroke="#4eb4d3" fill="#4eb4d3"  fillOpacity={1}/>
					<Area dataKey={languageFile[localStorage.getItem('language')]["2075"]} stackId={1} stroke="#d27042" fill="#d27042" fillOpacity={1}/>
					<Area dataKey={languageFile[localStorage.getItem('language')]["2076"]} stackId={1} stroke="#d7986e" fill="#d7986e"  fillOpacity={1}/>
					
				</AreaChart>
			</ResponsiveContainer></span>
		)
	}
}

export default StackedAreaChart;