import React from 'react';
import { SubscriberPaymentPlanTable } from './SubscriberPaymentPlanTable';
import { RenderPlanTableRemarkText } from './RenderPlanTableRemarkText';
import { RenderCancelSubscriptionButton } from './RenderCancelSubscriptionButton';

export const RenderPlanDetailsTableWrapper=({
    paymentDetails,
    getAddonDetails,
    cancelConfirmation,
    reActiveConfirmation
})=>{
    return (
        <div className='payment-ledger table-responsive'>
					<SubscriberPaymentPlanTable
                     paymentDetails={paymentDetails}
                     getAddonDetails={getAddonDetails}
                    />
					<RenderPlanTableRemarkText/>
					<RenderCancelSubscriptionButton
                      paymentDetails={paymentDetails}
                      cancelConfirmation={cancelConfirmation}
                      reActiveConfirmation={reActiveConfirmation}
                    />
				</div>
    )
}