var languageFile = require("../../public/languages/Translation");
module.exports={
    reviewTranslationMap :{
        "1 star": languageFile[localStorage.getItem('language')]["10010"],
        "2 star": languageFile[localStorage.getItem('language')]["10013"],
        "3 star": languageFile[localStorage.getItem('language')]["10016"],
        "4 star": languageFile[localStorage.getItem('language')]["10019"],
        "5 star": languageFile[localStorage.getItem('language')]["10022"],
        "Total" : languageFile[localStorage.getItem('language')]["13935"],
      }
}