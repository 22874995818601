import AppDispatcher from '../../../flux/dispatcher/dispatcher';
import Constant from '../constant/PaymentsConstant';
import restClient from '../../MainFrame/action/APIClient';
import LogoutConstant from '../../MainFrame/constants/Constants';

var ReactiveSubscriptionAction = function () {

}

ReactiveSubscriptionAction.prototype = {

	reactive: function () {
		restClient.reactiveSubscription(localStorage.getItem('profileId'), function (error, response) {
			if (error) {
				if (error.status === 401) {
					AppDispatcher.dispatch({
						actionType: LogoutConstant.UNAUTHORIZED_EXCEPTION,
						data: error
					});
				} else {
					AppDispatcher.dispatch({
						actionType: Constant.REACTIVE_SUBSCRIPTION,
						data: error
					});
				}
			} else {
				response["status"] = 200;
				AppDispatcher.dispatch({
					actionType: Constant.REACTIVE_SUBSCRIPTION,
					data: response
				});
			}
		})
	}
}

export default new ReactiveSubscriptionAction();