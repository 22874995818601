import React, { useState } from "react";
import { Button, Input, Select, Switch } from "antd";
import { INVITE_USERS } from "../../../../../components/ProgressiveModalWindow/Constants";
import TextArea from "antd/lib/input/TextArea";
import './style.scss'
import { Invite_Role } from "../Constants";

export const InviteUsers = (props) => {
    return (
        <div className='body-section'>
            <div className='profile-details'>
                <ul>
                    <li>
                        <div className="invite-user-block">
                            <div className="head">
                                <h3>{INVITE_USERS.EMAIL}</h3>
                                <h3>{INVITE_USERS.ROLE}</h3>
                            </div>
                            <div className="content">
                                <Input className={props.props.inviterUser[0].error ? 'invalid' : ''} value={props.props.inviterUser[0].invitees} onChange={(e) => props.onChangeCreteProfileDetails('inviteEmail', e.target.value, 0)} prefix={
                                    <svg viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                                        <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                                        <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                                    </svg>

                                } placeholder="you@example.com"></Input>
                                <Select placeholder='Select'
                                    onChange={(e) => props.changeInvities(0, e)}
                                    value={props.props.inviterUser[0].role}
                                    style={{
                                        width: 120,
                                    }}
                                >
                                    {Invite_Role.map((item) => {
                                        return <Select.Option key={item.value} value={item.value} >
                                            {item.label}</Select.Option>

                                    })}
                                </Select>
                            </div>
                            <div className="content">
                                <Input className={props.props.inviterUser[1].error ? 'invalid' : ''} value={props.props.inviterUser[1].invitees} onChange={(e) => props.onChangeCreteProfileDetails('inviteEmail', e.target.value, 1)} prefix={
                                    <svg viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                                        <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                                        <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                                    </svg>

                                } placeholder="you@example.com"></Input>
                                <Select onChange={(e) => props.changeInvities(1, e)} value={props.props.inviterUser[1].role} placeholder='Select'
                                    style={{
                                        width: 120,
                                    }}
                                >
                                    {Invite_Role.map((item) => {
                                        return <Select.Option key={item.value} value={item.value} >
                                            {item.label}</Select.Option>

                                    })}
                                </Select>
                            </div>
                            <div className="content">
                                <Input className={props.props.inviterUser[2].error ? 'invalid' : ''} value={props.props.inviterUser[2].invitees} onChange={(e) => props.onChangeCreteProfileDetails('inviteEmail', e.target.value, 2)} prefix={
                                    <svg viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                                        <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                                        <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                                    </svg>

                                } placeholder="you@example.com"></Input>
                                <Select onChange={(e) => props.changeInvities(2, e)} value={props.props.inviterUser[2].role} placeholder='Select'
                                    style={{
                                        width: 120,
                                    }}
                                >
                                    {Invite_Role.map((item) => {
                                        return <Select.Option key={item.value} value={item.value} >
                                            {item.label}</Select.Option>

                                    })}
                                </Select>
                            </div>
                            <p>Enter multiple emails by commas for each role. You will have the ability to craft custom roles in platform.</p>
                        </div>
                    </li>
                    <li>
                        <div className="footer-invite">
                            <div className="head">
                                Personal Message
                            </div>
                            <TextArea className="custom-text-area" placeholder="Share a short message introducing your team to #{{Domain}} " onChange={(e) => props.onChangeCreteProfileDetails('inviteMessage', e.target.value)} value={props.props.inviteMessage} rows={5} />
                        </div>
                    </li>
                </ul>
            </div>

        </div>
    )
}