import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { Amchartmenu } from '../../routers/Dashboard/children/ChartDownload';
import _ from 'lodash';
import languageFile from '../../../public/languages/Translation';
import {reviewTranslationMap} from '../../../src/utils/reviewStarTranslation';
const starColor = {
    1: "#E22F30",
    2: "#FEBF00",
    3: "#DADFE4",
    4: "#91CF50",
    5: "#01B0F1",
    total: "#00ff00"
}
am4core.useTheme(am4themes_animated);

class CommentLengthChart extends Component {

    formatGraphData(chartData) {
        let formattedData = [];
        _.map(chartData, function (item, key) {
            formattedData.push({
                "starRating": item.starRating,
                "key": item.starRating == "total" ? 0 : item.starRating,
                "starRatingType": item.starRating == "total" ? reviewTranslationMap["Total"] : reviewTranslationMap[item.starRating + " star"],
                "avgWordCount": _.round(item.avgWordCount),
                "color": am4core.color(starColor[item.starRating])
            })
        })
        let orderedData = formattedData.sort((a, b) => a.key - b.key)
        return orderedData
    }
    componentDidMount() {

        var commentLengthBarChart = am4core.create("responsetimegraph", am4charts.XYChart);
        commentLengthBarChart.data = this.formatGraphData(this.props.chartData);
        var categoryAxis = commentLengthBarChart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "starRatingType";
        // categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 20;
        categoryAxis.tooltip.disabled = true;
        categoryAxis.renderer.grid.template.disabled = true;
        categoryAxis.renderer.line.strokeOpacity = 1;
        categoryAxis.renderer.line.strokeWidth = 1;
        categoryAxis.renderer.line.stroke = "#8c8c8c";
        categoryAxis.renderer.labels.template.enabled = true;
        // let label = categoryAxis.renderer.labels.template;
        // label.tooltipText = "{category}";
        // categoryAxis.renderer.labels.template.adapter.add("text", function (text) {
        //     return text == "total" ? text : text + "-Star";
        // })

        //axis tick
        categoryAxis.renderer.ticks.template.disabled = false;
        categoryAxis.renderer.ticks.template.strokeOpacity = 1;
        categoryAxis.renderer.ticks.template.stroke = am4core.color("#495C43");
        categoryAxis.renderer.ticks.template.strokeWidth = 2;
        categoryAxis.renderer.ticks.template.length = 7;


        var valueAxis = commentLengthBarChart.xAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.grid.template.disabled = true;
        valueAxis.renderer.minWidth = 50;
        valueAxis.min = 0;
        valueAxis.cursorTooltipEnabled = false;
        valueAxis.renderer.line.strokeOpacity = 1;
        valueAxis.renderer.line.strokeWidth = 2;
        valueAxis.renderer.line.stroke = "#8c8c8c";
        valueAxis.renderer.opposite = false;
        valueAxis.renderer.minGridDistance = 40;
        // valueAxis.max=100;


        //axis tick
        valueAxis.renderer.ticks.template.disabled = false;
        valueAxis.renderer.ticks.template.strokeOpacity = 1;
        valueAxis.renderer.ticks.template.stroke = am4core.color("#495C43");
        valueAxis.renderer.ticks.template.strokeWidth = 2;
        valueAxis.renderer.ticks.template.length = 7;



        // Create series

        var series = commentLengthBarChart.series.push(new am4charts.ColumnSeries());
        series.dataFields.categoryY = "starRatingType";
        series.dataFields.valueX = "avgWordCount";
        // series.dataFields.color = "color"
        series.name = "avgWordCount";
        series.tooltipText = `{categoryY}: {valueX.value.formatNumber("##")} ${languageFile[localStorage.getItem('language')]["22701"]}[/]`;
        // series.columns.template.adapter.add("fill", function (fill, target) {
        //     return am4core.color(target.dataItem.color);;
        // });
        // series.columns.template.adapter.add("stroke", function (fill, target) {
        //     return am4core.color(target.dataItem.color);;
        // });



        //download
        if (this.props.showDownLoad) {
            commentLengthBarChart.exporting.menu = new am4core.ExportMenu();
            commentLengthBarChart.exporting.menu.items = _.cloneDeep(Amchartmenu);
            commentLengthBarChart.exporting.timeoutDelay = 8000;
            commentLengthBarChart.exporting.dataFields = { "starRating": "starRating", "avgWordCount": "avg WordCount" };
            commentLengthBarChart.exporting.filePrefix = "commentLength";
        }
        commentLengthBarChart.cursor = new am4charts.XYCursor();
        // commentLengthBarChart.logo.disabled = true; 
        this.commentLengthBarChart = commentLengthBarChart;
    }
    componentDidUpdate(oldProps) {
        if (oldProps.chartData !== this.props.chartData) {
            this.commentLengthBarChart.data = this.formatGraphData(this.props.chartData);
        }
    }
    render() {
        return (
            <div id="responsetimegraph" style={{ width: "100%", height: "100%" }}></div>
        )
    }
}
export default CommentLengthChart