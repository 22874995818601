import AppDispatcher from '../../../flux/dispatcher/dispatcher';
import Constant from '../constant/PaymentsConstant';
import restClient from '../../MainFrame/action/APIClient';
import LogoutConstant from '../../MainFrame/constants/Constants';

var GetSubscriptionDetailsAction = function () {

}

GetSubscriptionDetailsAction.prototype = {

	getDetails: function () {
		restClient.getPaymentDetails(localStorage.getItem('profileId'), function (error, response) {
			if (error) {
				if (error.status === 401) {
					AppDispatcher.dispatch({
						actionType: LogoutConstant.UNAUTHORIZED_EXCEPTION,
						data: error
					});
				} else if (error.status === 404) {
					AppDispatcher.dispatch({
						actionType: Constant.SUBSCRIPTION_DETAILS,
						data: error
					});
				}
			} else {
				AppDispatcher.dispatch({
					actionType: Constant.SUBSCRIPTION_DETAILS,
					data: response
				});
			}
		})
	}
}

export default new GetSubscriptionDetailsAction();