import React from 'react';
import { Button } from 'antd';
import {LCButton} from '../../../frameWork';
var languageFile = require('../../../../public/languages/Translation');

export const RenderInviteUserButton = ({inviteUserModal,onDownloadData,isDownloading}) => {
    return ( 
        <div className='page-header '>
            <h1>
                {
                    languageFile[localStorage.getItem('language')][
                        '12541'
                    ]
                }
            </h1>
            <div className='permissions-button-block'>
                <Button onClick={inviteUserModal}>
                    <i
                        style={{color: '#7A98BF'}}
                        className='fa fa-user-plus'
                        aria-hidden='true'
                    />{' '}
                    {
                        languageFile[
                            localStorage.getItem('language')
                        ]['11750']
                    }
                </Button>
            </div>
        </div>
    );
};
