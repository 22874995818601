import AppDispatcher from '../../../flux/dispatcher/dispatcher';
import Constant from '../constants/constant';
import LogoutConstant from '../../MainFrame/constants/Constants';
import restClient from '../../MainFrame/action/APIClient';

var AssignUsersToProfilesAction = function () {

}


AssignUsersToProfilesAction.prototype = {

	assignUsersToProfiles: function (data) {

		restClient.assignUsersToProfiles(data, function (error, response) {
			if (error) {
				if (error.status === 401) {
					AppDispatcher.dispatch({
						actionType: LogoutConstant.UNAUTHORIZED_EXCEPTION,
						data: error
					});
				} else{
					AppDispatcher.dispatch({
						actionType: Constant.ASSIGN_USER_TO_PROFILES,
						data: error
					});
				}
			} else {
				response['status']= 200;
				AppDispatcher.dispatch({
					actionType: Constant.ASSIGN_USER_TO_PROFILES,
					data: response
				});
			}
		})
	}
}

export default new AssignUsersToProfilesAction();