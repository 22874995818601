import React from 'react';
import GetPortfolioUsersStore from '../store/GetPortfolioUsersStore';
export const getPageSizeOptions = () => {
    if (GetPortfolioUsersStore.getResponse()) {
        var pageSizeOptions = [];
        var maxSize =
            GetPortfolioUsersStore.getResponse().total % 10 === 0
                ? GetPortfolioUsersStore.getResponse().total / 10
                : GetPortfolioUsersStore.getResponse().total /
                        10 +
                  1;
        maxSize = maxSize > 5 ? 5 : maxSize;
        for (var i = 1; i <= maxSize; i++) {
            pageSizeOptions.push((i * 10).toString());
        }
        return pageSizeOptions;
    } else {
        return [];
    }
};
