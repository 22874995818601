import AppDispatcher from '../../../flux/dispatcher/dispatcher';
import Constant from '../constant/PaymentsConstant';
import restClient from '../../MainFrame/action/APIClient';
import LogoutConstant from '../../MainFrame/constants/Constants';

var CancelSubscriptionAction = function () {

}

CancelSubscriptionAction.prototype = {

	deactivate: function () {
		restClient.cancelSubscription(localStorage.getItem('profileId'), function (error, response) {
			if (error) {
				// console.log("error", error);
				if (error.status === 401) {
					AppDispatcher.dispatch({
						actionType: LogoutConstant.UNAUTHORIZED_EXCEPTION,
						data: error
					});
				}else{
					AppDispatcher.dispatch({
						actionType: Constant.SUBSCRIPTION_DEACTIVATE,
						data: error
					});
				}
			} else {
				AppDispatcher.dispatch({
					actionType: Constant.SUBSCRIPTION_DEACTIVATE,
					data: response
				});
			}
		})
	},

	delete: function () {
		restClient.deleteSubscription(localStorage.getItem('profileId'), function (error, response) {
			if (error) {
				// console.log("error", error);
				if (error.status === 401) {
					AppDispatcher.dispatch({
						actionType: LogoutConstant.UNAUTHORIZED_EXCEPTION,
						data: error
					});
				}

			} else {
				AppDispatcher.dispatch({
					actionType: Constant.SUBSCRIPTION_DELETED,
					data: response
				});
			}
		})
	}
}

export default new CancelSubscriptionAction();