import React from 'react';
import GetProfileDetailsAction from '../AuthorisePage/action/GetProfileDetailsAction';
import GetProfileDetailStore from '../AuthorisePage/store/GetProfileDetailStore';
import GetIntegrationAction from '../IntegrationControl/action/GetIntegrationAction';
import GetIntegrationStore from '../IntegrationControl/store/GetIntegrationStore';
import GetProfileSettingsStore from "../AutoReviewSettings/store/GetProfileSettingsStore";
import languageFile from '../../../public/languages/Translation';
import { SideBarRouteWrapper } from './childrens/SideBarRouteWrapper'
import { SideBarRoutesList } from './childrens/SideBarRoutesList';
import { RouteList } from './childrens/RouteList';
import { RouterListWrapper } from './childrens/RouterListWrapper';
import { deepClone } from '../../factoryFunctions';
import urlConfig from '../../config/index';
import { urlName } from '../../config/index'
import _ from 'lodash';
var cookie = require("../../utils/cookie");

class PortfolioSettings extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			rule: [],
			isSubscribed: false,
			sidebarRoutesList: deepClone(SideBarRoutesList),
			activeIntegrations: {},
			subTabsVisibility: {},
			subTabControl:{},
			tierSubTabVisibility: {},
			initialLoading:true
		}
		this.onClickSideBarRouteItem = this.onClickSideBarRouteItem.bind(this);
	}

	componentWillMount() {
	}

	componentWillUnmount() {
	}

	componentDidMount() {
	}
	

	onClickSideBarRouteItem(index) {
		let sidebarRoutesList = this.state.sidebarRoutesList;
		sidebarRoutesList[index].shouldShowChildrens = !sidebarRoutesList[index].shouldShowChildrens;
		this.setState({
			sidebarRoutesList
		})
	}



	render() {
		return (
			<div className="settings">
				<div className="settings__sidebar">
					<div className="settings__sidebar--header">{languageFile[localStorage.getItem('language')]["13825"]}</div>
					<SideBarRouteWrapper
						sidebarRoutesList={this.state.sidebarRoutesList}
						onClickSideBarRouteItem={this.onClickSideBarRouteItem}
						pathname={this.props.history.location.pathname}
					/>
				</div>
				<div className="settings__content_portfolio" >
					<RouterListWrapper
						routeList={RouteList} />
				</div>
			</div>
		)
	}
}

export default PortfolioSettings;			