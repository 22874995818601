import AppDispatcher from '../../../../flux/dispatcher/dispatcher';
import Constant from '../constant/LocalHistoConstant'
import restClient from '../../../../routers/MainFrame/action/APIClient';
import LogoutConstant from '../../../../routers/MainFrame/constants/Constants';
import urlConfig from '../../../../config/index';
var moment = require('moment');
var _ = require("lodash");
var organization, country, locality, area, profileId, granularity = 'weekly';
var dateRange = {}, filters = {};

var LocalHistogramAction = function () {

}


LocalHistogramAction.prototype = {

	clearFilters: function () {
		filters = {};
		organization = "";
		country = undefined;
		locality = undefined;
		area = undefined;
		granularity = 'weekly';
	},

	addDownloadFilters: function (data) {
		if (data.hasOwnProperty('insightFilters')) {
			delete data.insightFilters;
		}
		filters = _.cloneDeep(data);
		if (filters.profileId) {
			profileId = filters.profileId;
			delete filters.profileId
		}
		if (data.granularity) {
			granularity = data.granularity;
			// delete data.granularity;
		}
		this.loadData();
	},

	addFilters: function (data, newGranularity) {
		if (data.hasOwnProperty('insightFilters')) {
			delete data.insightFilters;
		}
		filters = data;
		granularity = newGranularity;
		this.loadData();
	},

	changeProfile: function (value) {
		profileId = value;
		this.loadData();
	},

	// clearFilters: function () {
	// 	organization = "";
	// 	country = undefined;
	// 	locality = undefined;
	// 	area = undefined;
	// 	granularity = 'weekly';
	// },

	changeOrganisation: function (value) {
		organization = value;
		this.loadData();
	},

	addCountry: function (value) {
		country = value;
		locality = undefined;
		area = undefined;
		this.loadData()
	},

	addArea: function (value) {
		area = value;
		locality = undefined;
		this.loadData();
	},

	addLocality: function (value) {
		locality = value;
		this.loadData();
	},

	addDates: function (fromDate, toDate) {
		dateRange.from = fromDate;
		dateRange.to = toDate;
		this.loadData();
	},

	changeGranularity: function (value) {
		granularity = value;
		this.loadData();
	},

	loadData: function () {
		if (!(filters.hasOwnProperty('dateRange'))) {
			dateRange.from = moment().subtract(16, 'week').format('YYYY-MM-DD');
			dateRange.to = moment().format('YYYY-MM-DD');
			filters['dateRange'] = dateRange;
		}

		var data = {
			profileId: localStorage.getItem('profileId'),
			granularity: granularity,
			filters: filters
		};
		if (data.filters.hasOwnProperty('insightFilters')) {
			delete data.filters.insightFilters;
		}

		restClient.getLocalActionConversionHistogram(data, function (error, response) {
			if (error) {
				// console.log("error", error);
				if (error.status === 401) {
					AppDispatcher.dispatch({
						actionType: LogoutConstant.UNAUTHORIZED_EXCEPTION,
						data: error
					});
				}
			} else {
				AppDispatcher.dispatch({
					actionType: Constant.LOCAL_ACTION_CONVERSION_HISTOGRAM,
					data: response
				});
			}
		})
	},

	downloadLocalHistCsv() {
		var urlName = urlConfig.urlName;
		var data = {
			profileId: localStorage.getItem('profileId'),
			granularity: granularity,
			domainName: urlName,
			filters: filters
		};
		if (data.filters.hasOwnProperty('insightFilters')) {
			delete data.filters.insightFilters;
		}
		restClient.getLocalActionHistogramCsv(data, function (error, response) {
			if (error) {
				// console.log("error", error);
				if (error.status === 401) {
					AppDispatcher.dispatch({
						actionType: LogoutConstant.UNAUTHORIZED_EXCEPTION,
						data: error
					});
				}
			} else {
				AppDispatcher.dispatch({
					actionType: Constant.LOCAL_ACTION_HISTO_CSV,
					data: response
				});
			}
		})
	},
}

export default new LocalHistogramAction();
