import AppDispatcher from '../../../flux/dispatcher/dispatcher';
import Constant from '../constant/AuthoriseAccountConstant';
import LogoutConstant from '../../MainFrame/constants/Constants';
import restClient from '../../MainFrame/action/APIClient';
// var cookie = require("public/js/cookie")

var GetProfilesAction = function () {

}

GetProfilesAction.prototype = {

	getProfiles: function (token) {
		restClient.getProfiles(token, function (error, response) {
			if (error) {
				if (error.status === 401) {
					AppDispatcher.dispatch({
						actionType: LogoutConstant.UNAUTHORIZED_EXCEPTION,
						data: error
					});
				}
			} else {
				AppDispatcher.dispatch({
					actionType: Constant.GET_PROFILE,
					data: response
				});
			}
		})
	}
}

export default new GetProfilesAction();