import React, { lazy } from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { Route, Switch, Router } from 'react-router-dom';
import SignUp from './routers/SignUp';
import ForgotPassword from './routers/ForgotPassword';
import ResetPassword from './routers/ResetPassword';
import PortfolioFrame from './routers/PortfolioFrame/index';
import DownloadPage from './routers/DownloadPage';
import DownloadPageDashboard from './routers/DownloadPageDashboard';
import DownloadPageInsights from './routers/DownloadPageInsights';
import Payment from './routers/Payment';
import Feedback from './routers/Feedback';
import LinkRedirection from './routers/LinkRedirection';
import MicrosoftLogin from './routers/MicrosoftLogin';
import PrivacyPolicy from './routers/PrivacyPolicy';
import AcceptAuth from './routers/AcceptAuth';
import TermsAndPolicy from './routers/TermsAndPolicy';
import ProtectedRoute from './components/ProtectedRoute';
import history from './history';
import 'antd/dist/antd.css';
import 'font-awesome/scss/font-awesome.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../public/css/style.css';
import './index.scss';
import { Suspense } from 'react';
import ResetPasswordExpired from './routers/ResetPasswordExpired';
import CreateWorkFlow from './routers/CreateWorkFlow';
import BuildProfile from './routers/BuildNewProfile';
import  LoginVerification  from './routers/LoginVerification';
function lazyWithPreload(factory) {
	const Component = lazy(factory);
	Component.preload = factory;
	return Component;
}
const Login = lazy(() => import('./routers/Login'));
const AuthorisePage = lazy(() => import('./routers/AuthorisePage'));
const AuthorisePageDemo = lazy(() => import('./routers/AuthorizePage'));
const AccountOverView = lazy(() => import('./routers/AccountOverView'));
document.body.classList.add('light');
export const MainFrame = lazyWithPreload(() => import('./routers/MainFrame'));

ReactDOM.render(
	<Router history={history}>
		<Suspense fallback>
			<Switch>
				<Route exact path='/login' component={Login} onEnter />
				<Route path='/login/verification' component={LoginVerification} />
				<Route path='/signup' component={SignUp} />
				<Route path='/forgotPassword' component={ResetPassword} />
				<Route path='/paymentexpired' component={Payment} />
				<Route path='/feedback' component={Feedback} />
				<Route path='/routegate' component={LinkRedirection} />
				<Route path='/redirect/:query' component={SignUp} />
				<Route path='/acceptAuth' component={AcceptAuth} />
				<Route
					path='/passwordReset/:query'
					component={ForgotPassword}
				/>
				<Route path='/downloadPdf/:query' component={DownloadPage} />
				<Route
					path='/reviewPdf/:query'
					component={DownloadPageDashboard}
				/>
				<Route
					path='/insightPdf/:query'
					component={DownloadPageInsights}
				/>
				<Route
					path='/microsoftRedirect/:query'
					component={MicrosoftLogin}
				/>
				<Route path='/privacy' component={PrivacyPolicy} />
				<Route path='/terms' component={TermsAndPolicy} />
				<Route path='/policy' component={PrivacyPolicy} />
				{/* <Route path='/resetPassword' component={ForgotPassword} /> */}
				<Route path='/resetPasswordLinkExpired' component={ResetPasswordExpired}/>
				{/* <ProtectedRoute path='/authorize' component={AuthorisePage} /> */}
				<ProtectedRoute path='/new-workspace' component={CreateWorkFlow}/>
				<ProtectedRoute path='/build-profile' component={BuildProfile}/>
				<ProtectedRoute path='/account-overview' component={AccountOverView} />
				<ProtectedRoute path='/authorize' component={AuthorisePageDemo}/>
				<ProtectedRoute path='/helpdesk' component={MainFrame} />
				<ProtectedRoute path='/dashboard' component={MainFrame} />
				<ProtectedRoute
					path='/localperformance'
					component={MainFrame}
				/>
				<ProtectedRoute path='/insights' component={MainFrame} />
				<ProtectedRoute path='/reviews' component={MainFrame} />
				<ProtectedRoute path='/posts' component={MainFrame} />
				<ProtectedRoute exact path='/imagemanagement' component={MainFrame} />
				<ProtectedRoute path='/widgetshowcase' component={MainFrame} />
				<ProtectedRoute path='/autoresponse' component={MainFrame} />
				<ProtectedRoute path='/qareviews' component={MainFrame} />
				<ProtectedRoute path='/settings' component={MainFrame} />
				<ProtectedRoute path='/localpost' component={MainFrame} />
				<ProtectedRoute path='/competitor' component={MainFrame} />
				<ProtectedRoute path='/iframe' component={MainFrame} />
				<ProtectedRoute path='/managelocations' component={MainFrame} />
				<ProtectedRoute
					path='/facebookdashboard'
					component={MainFrame}
				/>
				<ProtectedRoute
					path='/sentimentanalysis'
					component={MainFrame}
				/>
				<ProtectedRoute
					path='/locationbulkupdate'
					component={MainFrame}
				/>
				<ProtectedRoute
					path='/manageusers'
					component={PortfolioFrame}
				/>
				<ProtectedRoute
					path='/manageprofiles'
					component={PortfolioFrame}
				/>
				<ProtectedRoute
					path='/portfoliosettings'
					component={PortfolioFrame}
				/>
				<ProtectedRoute component={AuthorisePageDemo} />
			</Switch>
		</Suspense>
	</Router>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
