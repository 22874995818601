import React from 'react';
import {LCMessage} from '../../frameWork';
import {Modal,message} from 'antd';
import PushNotification from '../../components/sharedComponets/ReactAlert/action/PushNotification';
import {InviteUserPageWrapper} from './childrens/inviteUserPageWrapper';
import { GetLocalStorage } from '../../utils/localStorageOperations';
import { getPageSizeOptions } from './helpers/getPageSizeOptions';
import { generateTableData } from './helpers/generateTableData';
import GetPortfolioUsersAction from './action/GetPortfolioUsersAction';
import GetPortfolioUsersStore from './store/GetPortfolioUsersStore';
import GetPortfolioProfilesAction from '../PortfolioManageProfiles/action/GetPortfolioProfilesAction';
import GetPortfolioProfilesStore from '../PortfolioManageProfiles/store/GetPortfolioProfilesStore';
import GetAllUserCreatedRolesAction from './action/GetAllUserCreatedRolesAction';
import GetAllUserCreatedRolesStore from './store/GetAllUserCreatedRolesStore';
import InvitePortfolioUserAction from './action/InvitePortfolioUserAction';
import InvitePortfolioUserStore from './store/InvitePortfolioUserStore';
import RemovePortfolioUserAction from './action/RemovePortfolioUserAction';
import RemovePortfolioUserStore from './store/RemovePortfolioUserStore';
import GetPortfolioUserDetailsAction from './action/GetPortfolioUserDetailsAction';
import GetPortfolioUserDetailsStore from './store/GetPortfolioUserDetailsStore';
import AssignUsersToProfilesAction from './action/AssignUsersToProfilesAction';
import AssignUsersToProfilesStore from './store/AssignUsersToProfilesStore';
import _ from 'lodash';


import { urlName } from '../../config/index';

var languageFile = require("../../../public/languages/Translation");
const enumRole =['OWNER',
	'ADMIN',
	'EDITOR',
	'CUSTOM',
	'READ_ONLY']
var analytics = window.analytics;
const EMAIL='EMAIL';
const INVITE_SUCCESS='Invite has been sent';
const PERMISSION_UPDATED='Permission updated';
const SUCCESSFULLY_DELETED='Successfully deleted';
const INVITE_USER_COMPLETED='Invite User Completed';
const SUCCESS='SUCCESS';
const INVITE_USER_STARTED='Invite User Started'
const confirm=Modal.confirm;
const roleMapping ={'READ_ONLY':languageFile[localStorage.getItem('language')]["25451"]}
class PortfolioManageUsers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            roleValue: 'READ_ONLY',
            loading: true,
            UsersForProfile: [],
            selectedRowKeys: [],
            notificationMessage: null,
            userAlreadyExitsError: false,
			rolesData: [],
			profileOptions:[],
			selectedRole: {label: languageFile[localStorage.getItem('language')]["25451"], value: 'READ_ONLY'},
			//selectedProfile:{},
			currentPage:1,
			pageSize:20,
			isDownloading:false,
			profiles: [],
			ischeckedAll: false,
			showDeleteConfirm: false,
			assignUsersToProfilesEdit: false,
			reInvite: false
        };
		this.getPortfolioProfilesResponse = this.getPortfolioProfilesResponse.bind(this);
    }

    componentWillMount() {
		GetPortfolioUsersStore.bind(this.getPortfolioUsersStore);
		GetPortfolioProfilesStore.bind(this.getPortfolioProfilesResponse);
		GetAllUserCreatedRolesStore.bind(this.getRolesResponse);
		InvitePortfolioUserStore.bind(this.getInvitePortfolioUser);
		RemovePortfolioUserStore.bind(this.getRemovePortfolioUser);
		GetPortfolioUserDetailsStore.bind(this.getPortfolioUserDetailsResponse);
		AssignUsersToProfilesStore.bind(this.assignUsersToProfilesRespone);
    }

    componentWillUnmount() {
		GetPortfolioUsersStore.unbind(this.getPortfolioUsersStore);
		GetPortfolioProfilesStore.unbind(this.getPortfolioProfilesResponse);
		GetAllUserCreatedRolesStore.unbind(this.getRolesResponse);
		InvitePortfolioUserStore.unbind(this.getInvitePortfolioUser);
		RemovePortfolioUserStore.unbind(this.getRemovePortfolioUser);
		GetPortfolioUserDetailsStore.unbind(this.getPortfolioUserDetailsResponse);
		AssignUsersToProfilesStore.unbind(this.assignUsersToProfilesRespone);
    }

    componentDidMount() {
		GetPortfolioUsersAction.getPortfolioUsers(
            localStorage.getItem('portfolioId'),
            (this.state.currentPage - 1)* this.state.pageSize,
            this.state.pageSize
		);
		GetPortfolioProfilesAction.getPortfolioProfiles(localStorage.getItem('portfolioId'));
		GetAllUserCreatedRolesAction.getAllUserCreatedRoles();
	}


	getRolesResponse=()=>{
		this.setState({
	        rolesData:
			GetAllUserCreatedRolesStore.getResponse() &&
			GetAllUserCreatedRolesStore.getResponse().roles.map(function(
				    item,
				    index
				) {
				     return {label: item.roleName, value: item.roleId ? item.roleId : item.roleName};
				}),
	        drodpownLoading: false
		});
	}
	
	getPortfolioProfilesResponse = () => {
		
		var profileOptions=[]
		profileOptions = _.map(GetPortfolioProfilesStore.getResponse().list, (item,index) => {
			return {
				key: index,
				label: item.profileName,
				value: item.profileId,
				//roleName:"",
				//roleId:"",
				ischecked: false,
				roleVisible: true,
				selectedRole:null
			};
		});
		this.setState({
			profiles: GetPortfolioProfilesStore.getResponse().list || null,
			profileOptions: profileOptions
		});
	}


	getPortfolioUsersStore=()=> {
	    if (GetPortfolioUsersStore.getResponse().status === 403) {
	        this.setState({
	           // noPermission: true,
	            loading: false
	        });
	    } else {
	        this.setState({
	            UsersForProfile: GetPortfolioUsersStore.getResponse().usersList
	                .hits,
	            tableData: generateTableData(),
	            tableLoading: false,
				loading: false,
				isOwner: GetPortfolioUsersStore.getResponse().isOwner
	        });
		}
	}


	addEmail=(event)=> {
	    this.setState({
	        userEmail: event.target.value
	    });
	}

	getRemovePortfolioUser=()=>{
		if(RemovePortfolioUserStore.getResponse){
			if(RemovePortfolioUserStore.getResponse().status == 200){
				message.success("User has been successfully deleted!")
				GetPortfolioUsersAction.getPortfolioUsers(
					localStorage.getItem('portfolioId'),
					(this.state.currentPage - 1)* this.state.pageSize,
					this.state.pageSize
				);
			}
			else{
				message.success(RemovePortfolioUserStore.getResponse().message)
			}

		}
	}
	getInvitePortfolioUser=()=>{
		if(InvitePortfolioUserStore.getResponse){
			if(InvitePortfolioUserStore.getResponse().status == 200){
				message.success("Invite User Email has sent")
				GetPortfolioUsersAction.getPortfolioUsers(
					localStorage.getItem('portfolioId'),
					(this.state.currentPage - 1)* this.state.pageSize,
					this.state.pageSize
				);
			}
			else{
				message.warning(InvitePortfolioUserStore.getResponse().data.message)
			}

		}
		this.handleCancel()
	}
	inviteUser=() =>{
		var selectedProfles=[];
		var roles = ['OWNER','ADMIN','READ_ONLY','EDITOR']
		 _.map(this.state.profileOptions, (item,index) => {
			 if(item.ischecked){
			selectedProfles.push({profileId: item.value ,profileName:item.label,role: item.selectedRole ? roles.includes(item.selectedRole.label) ? item.selectedRole.label : 'CUSTOM' : 'READ_ONLY', roleId: item.selectedRole ? item.selectedRole.value : item.selectedRole})
			 }
		})
		if(this.state.assignUsersToProfilesEdit){
			var dataEdit = {
				//portfolioName:localStorage.getItem('portfolioName'),
				//invitee: this.state.userEmail,
				portfolioId:localStorage.getItem('portfolioId'),
				userId:this.state.userEmail,
				//domainName: urlName,
				//isReinvited: false,
				profileList: selectedProfles,
	            
			};
			AssignUsersToProfilesAction.assignUsersToProfiles(dataEdit)
		}else{
	    if (this.validateEmail(this.state.userEmail)) {
	        var dataObject = {
				portfolioName:localStorage.getItem('portfolioName'),
				invitee: this.state.userEmail,
				portfolioId:localStorage.getItem('portfolioId'),
				domainName: urlName,
				isReinvited: false,
				profiles: selectedProfles,
	            
			};
			InvitePortfolioUserAction.invitePortfolioUser(dataObject)
	    } else {
	        this.setState({
	            emailValidationError: true
	        });
		}
	}
	}

	validateEmail=(email) =>{
	    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	    return re.test(email);
	}
	inviteUserModal=()=> {
	    this.setState(
	        {
	            inviteModal: true
	        },
	        function() {
	            analytics.track(INVITE_USER_STARTED);
	        }
	    );
	}
	handleCancel = () => {
		let profileControls= this.state.profileOptions
		_.map(this.state.profileOptions, (item,index) => {
				profileControls[index].ischecked= false
				profileControls[index].selectedRole= null	
				profileControls[index].roleVisible= true		
		})
	    this.setState({
	        permissionsModal: false,
	        deleteModal: false,
	        inviteModal: false,
			userAlreadyExitsError: false,
			emailValidationError:false,
			userEmail:'',
			selectedProfile:'',
			selectedRole:'',
			profileOptions: profileControls,
			ischeckedAll: false,
			showDeleteConfirm:false,
			emailVisible:  false,
			assignUsersToProfilesEdit: false,
			inviteModal: false
	    });
	};



	showTotal=(total)=> {
	    return `Total ${total} items`;
	}

	changeSize = (current, size) => {
	    this.setState({
			tableLoading: true,
			pageSize:size
	    });
	    GetPortfolioUsersAction.getPortfolioUsers(
	        GetLocalStorage('portfolioId'),
	        (current - 1) * 10,
	        size
	    );
	};

	onPageChange = (page, pageSize) => {
	    this.setState({
			tableLoading: true,
			currentPage:page
	    });
	    GetPortfolioUsersAction.getPortfolioUsers(
	        GetLocalStorage('portfolioId'),
	        (page - 1) * this.state.pageSize,
	        this.state.pageSize
	    );
	};
	deletePortfolioUser=()=>{
	    RemovePortfolioUserAction.removePortfolioUser(GetLocalStorage('portfolioId'),this.state.userId)
		this.setState({
			showDeleteConfirm:false
	    });
	}
	assignUsersToProfilesRespone=()=>{
		if(AssignUsersToProfilesStore.getResponse){
			if(AssignUsersToProfilesStore.getResponse().status == 200){
				message.success(AssignUsersToProfilesStore.getResponse().message,2)
			}
			else{
				message.warning(AssignUsersToProfilesStore.getResponse().data.message,2)
			}

		}
		this.handleCancel()
	}
	getPortfolioUserDetailsResponse=()=>{

	let profileControls= this.state.profileOptions
	 _.map(GetPortfolioUserDetailsStore.getResponse().list, (item,index) => {
		_.map(GetPortfolioProfilesStore.getResponse().list, (content,key) => {
			if(item.profileId == content.profileId){

				profileControls[key].ischecked= true	
				profileControls[key].roleVisible = false
				profileControls[key].selectedRole =  {label: item.roleName ? item.roleName : item.role, value: item.roleId ? item.roleId : null} 
		}
		})
		});
		this.setState({
			profiles: GetPortfolioProfilesStore.getResponse().list || null,
			profileOptions: profileControls,
			emailVisible: true,
			assignUsersToProfilesEdit: true
			
		});
		let allChecked = true
    _.map(profileControls, (item,index) => {
	if(!item.ischecked){
		allChecked = false
	}
})
		this.setState({
			ischeckedAll: allChecked,
			inviteModal: this.state.reInvite? false : true
		});
		if(this.state.reInvite){
		var selectedProfles=[];
		var roles = ['OWNER','ADMIN','READ_ONLY','EDITOR']
		 _.map(this.state.profileOptions, (item,index) => {
			 if(item.ischecked){
			selectedProfles.push({profileId: item.value ,profileName:item.label,role: item.selectedRole ? roles.includes(item.selectedRole.label) ? item.selectedRole.label : 'CUSTOM' : 'READ_ONLY', roleId: item.selectedRole ? item.selectedRole.value : item.selectedRole})
			 }
		})
			var dataObject = {
				portfolioName:localStorage.getItem('portfolioName'),
				invitee: this.state.userEmail,
				portfolioId:localStorage.getItem('portfolioId'),
				domainName: urlName,
				isReinvited: true,
				profiles: selectedProfles,
	            
			};
			InvitePortfolioUserAction.invitePortfolioUser(dataObject)
		}
	}
	userEdit=(e)=>{
	
		GetPortfolioUserDetailsAction.getPortfolioUserDetails(e.userId,GetLocalStorage('portfolioId'))
		
		this.setState(
	        {
	            userEmail: e.userId
	        },
	        // function() {
	        //     analytics.track(INVITE_USER_STARTED);
	        // }
	    );

	}
	userDelete = (e) =>{
		this.setState({
			userId:e.userId,
			showDeleteConfirm:true
		});
	}


	reinviteUser = (e) => {
		confirm({
            title: 'Are you sure?',
            content: `Would you like to re-invite the user?`,
            okText: languageFile[localStorage.getItem('language')]["22971"],
            cancelText: languageFile[localStorage.getItem('language')]["22821"],
            onOk: () => {
				this.setState({
					userEmail: e.userId,
					reInvite: true
				});
				GetPortfolioUserDetailsAction.getPortfolioUserDetails(e.userId,GetLocalStorage('portfolioId'))
            },
            onCancel() {
            },
        });
	}
	allChecked=(e)=>{
	let profileControls= this.state.profileOptions
	if(e){
	_.map(this.state.profileOptions, (item,index) => {
			profileControls[index].ischecked= e.target.checked	
			profileControls[index].roleVisible = !e.target.checked
			profileControls[index].selectedRole = e.target.checked ? {label:"READ_ONLY",value:null} : null
	})
	this.setState({
		profileOptions:profileControls,
		ischeckedAll: e.target.checked
	});
}
	}
ischecked=(checked,e)=>{
	GetAllUserCreatedRolesAction.getAllUserCreatedRoles(checked.value);
	let profileControls= this.state.profileOptions
	if(checked){
	_.map(this.state.profileOptions, (item,index) => {
		if(index == checked.key){
			profileControls[index].ischecked= e.target.checked
			profileControls[index].roleVisible = !e.target.checked
			profileControls[index].selectedRole = e.target.checked ? {label:"READ_ONLY",value:null} : null

		}
		
	})
	this.setState({
		profileOptions:profileControls
	});
}
let allChecked = true
_.map(this.state.profileOptions, (item,index) => {
	if(!item.ischecked){
		allChecked = false
	}
})
this.setState({
	ischeckedAll:allChecked
});
}
roleChanges=(e,roleChange)=>{	

	GetAllUserCreatedRolesAction.getAllUserCreatedRoles(e.value);


}
	roleChange=(e,roleChange)=> {
		let profileControls= this.state.profileOptions
		if(roleChange){
		_.map(this.state.profileOptions, (item,index) => {
			if(index == e.key){
				profileControls[index].roleName= roleChange.label
				profileControls[index].roleId= roleChange.value
				profileControls[index].selectedRole = {label:roleChange.label,value:roleChange.value}
			}
			
		})
		this.setState({
	        selectedRole: {
	            label: e.label,
	            value: e.value ? e.value : e.label
			},
			profileOptions:profileControls
		});
	}

	if(roleChange == null){
         _.map(this.state.profileOptions, (item,index) => {
			if(index == e.key){
				profileControls[index].roleName= ""
				profileControls[index].roleId= ""
				profileControls[index].selectedRole = null
				profileControls[index].ischecked = false
				profileControls[index].roleVisible = true
			}
			
		})
		let allChecked = true
      _.map(this.state.profileOptions, (item,index) => {
	if(!item.ischecked){
		allChecked = false
	}
})
		this.setState({
			profileOptions:profileControls,
			ischeckedAll:allChecked
		});
	}
	   
	}
	profileChange=(e)=> {
	    this.setState({
	        selectedProfile: {
	            label: e.label,
	            value: e.value ? e.value : e.label
	        }
	    });
	}
	

	render() {
	    return (
		
	        <InviteUserPageWrapper
	            loading={this.state.loading}
	            // noPermission={this.state.noPermission}
	            // UsersForProfile={this.state.UsersForProfile}
	            // drodpownLoading={this.state.drodpownLoading}
				// selectedRole={this.state.selectedRole}
				 selectedProfile={this.state.selectedProfile}
	            // searchForRole={this.searchForRole}
				 roleChange={this.roleChange}
				 profileChange={this.profileChange}
	             userEmail={this.state.userEmail}
	             addEmail={this.addEmail}
	             emailValidationError={this.state.emailValidationError}
	             inviteUser={this.inviteUser}
				 inviteModal={this.state.inviteModal}
				// assignProfileChange={this.assignProfileChange}
	            // handleCancel={this.handleCancel}
	            // handleOk={this.handleOk}
	             inviteUserModal={this.inviteUserModal}
				// editUser={this.editUser}
				// DeleteInvitedUser={this.deleteInvitedUser}
				reinviteUser={this.reinviteUser}
	            tableLoading={this.state.tableLoading}
	            tableData={this.state.tableData}
	            onPageChange={this.onPageChange}
	            showTotal={this.showTotal}
	             changeSize={this.changeSize}
				getPageSizeOptions={getPageSizeOptions()}
				profileOptions={this.state.profileOptions}
	            // userAlreadyExitsError={
	            //     this.state.userAlreadyExitsError
	            // }
	             rolesMenu={this.state.rolesData}
				 handleCancel={this.handleCancel}
				 ischecked={this.ischecked}
				 allChecked={this.allChecked}
				 ischeckedAll={this.state.ischeckedAll}
				 userDelete={this.userDelete}
				 showDeleteConfirm={this.state.showDeleteConfirm}
				 deletePortfolioUser={this.deletePortfolioUser}
				 userEdit={this.userEdit}
				 emailVisible={this.state.emailVisible}
				 isOwner={this.state.isOwner}
				 roleChanges={this.roleChanges}
	            // handleOk={this.handleOk}
	            // onDownloadData={this.onDownloadData}
	            // isDownloading={this.state.isDownloading}
	        />
	    );
	}
}
export default PortfolioManageUsers;
