import React from 'react';
import _ from "lodash";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Legend } from 'recharts';
import {sourceList} from "../../../routers/ReviewDownload/childrens/ReviewSourceList";
import {SOURCE_COLOR_MAP,SYSTEM_COLOR_MAP} from '../PieChartAm/Children/souceData';
var laguageFile = require("../../../../public/languages/Translation");

class ReviewSourceChart extends React.Component {

	renderActiveShape(props) {
		const RADIAN = Math.PI / 180;
		const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
			fill, payload, percent, value, name } = props;
		const sin = Math.sin(-RADIAN * midAngle);
		const cos = Math.cos(-RADIAN * midAngle);
		const sx = cx + (outerRadius + 10) * cos;
		const sy = cy + (outerRadius + 10) * sin;
		const mx = cx + (outerRadius + 22) * cos;
		const my = cy + (outerRadius + 22) * sin;
		const ex = mx + (cos >= 0 ? 1 : -1) * 1;
		const ey = my;
		const textAnchor = cos >= 0 ? 'start' : 'end';
		if (this.props.source == 'print') {
			return (
				<g>
					<text x={ex + (cos >= 0 ? 1 : -1) * 1} style={{ fontSize: 28, marginBottom: 25 }} y={ey} textAnchor={textAnchor} fill="#333">{`${value} reviews`}</text>
					<text x={ex + (cos >= 0 ? 1 : -1) * 1} style={{ fontSize: 28 }} y={ey} dy={24} textAnchor={textAnchor} fill="#999">
						{`(${name})`}
					</text>
				</g>
			);
		}
		return (
			<g>
				<text x={ex + (cos >= 0 ? 1 : -1) * 1} style={{ fontSize: 12 }} y={ey} textAnchor={textAnchor} fill="#333">{`${value} reviews`}</text>
				<text x={ex + (cos >= 0 ? 1 : -1) * 1} style={{ fontSize: 12 }} y={ey} dy={11} textAnchor={textAnchor} fill="#999">
					{`(${name})`}
				</text>
			</g>
		);
	}
	render() {
		// console.log("dxy", this.props.data)
		if (!_.keys(this.props.data).length) {
			return <section className="card">
				<div className="card-header"><h3>{laguageFile[localStorage.getItem('language')]["20291"]}</h3></div>
				<article className="card-body reviews-stats">
					<div className="reviews-values" style={{ margin: 20 }}>
						-
							</div>
				</article>
			</section>
		}

		let pieData = [];
		for (var item in this.props.data) {

			if (_.findIndex(SYSTEM_COLOR_MAP, (object) => object.source == item) > -1) {
				pieData.push({ name: item, value: this.props.data[item] });
			}
		}

		let pieDataLabel = [];
		for (var item in this.props.data) {
			if (_.findIndex(SYSTEM_COLOR_MAP, (object) => object.source == item) > -1) {
				pieDataLabel.push({ name: item, count: this.props.data[item], percent: this.props.data[(item + "Percent")], color: SOURCE_COLOR_MAP[item] });
			}
		}
		let sortedpieDataLabel = [];
		// sortedpieDataLabel = _.reverse( _.sortBy(pieDataLabel, ['count']).slice(0, 6));
		// sortedpieDataLabel =  _.sortBy(pieDataLabel, ['count']).slice(0, 6);
		sortedpieDataLabel = pieDataLabel.sort((a, b) => b.count - a.count).slice(0, 6);
		return (
			<section className="card">
				<div className="card-header"><h3>{laguageFile[localStorage.getItem('language')]["20291"]}</h3></div>
				<article className="card-body reviews-stats" style={{ padding: 0 }}>
					{this.props.source == 'print' &&
						<div className="donut-printchart">
							<div className="pie-chart">
								<ResponsiveContainer width="100%" height="100%" >
									<PieChart onMouseEnter={this.onPieEnter}>
										<Pie
											data={pieData}
											isAnimationActive={false}
											cx="40%"
											cy="40%"
											// label={this.renderActiveShape}
											innerRadius={22}
											outerRadius={50}
										>
											{
												pieData.map((entry, index) => {
													let idx = _.findIndex(SYSTEM_COLOR_MAP, (object) => object.source == entry.name)
													return <Cell fill={SYSTEM_COLOR_MAP[idx] && SYSTEM_COLOR_MAP[idx].color} />
												})
											}
										</Pie>
									</PieChart>
								</ResponsiveContainer>
							</div>
							<ul className="pie-labels">
								{
									_.map(sortedpieDataLabel, function (item, key) {
										return <li key={key}>
											<span style={{ backgroundColor: item.color + '!important' }}></span>
											<span>{(_.round(item.percent, 1)) + '%'}</span>
											<span>{_.truncate(sourceList[item.name], { length: 11 })}</span>
											<span>{item.count}</span>
										</li>

									})
								}
							</ul>
						</div>
						||
						<div className="donut-chart">
							<div className="pie-chart">
								<ResponsiveContainer width="100%" height="100%" >
									<PieChart onMouseEnter={this.onPieEnter}>
										<Pie
											dataKey={"value"}
											data={pieData}
											isAnimationActive={false}
											cx="50%"
											cy="50%"
											// label={this.renderActiveShape}
											innerRadius={45}
											outerRadius={80}
										>
											{
												pieData.map((entry, index) => {
													let idx = _.findIndex(
														SYSTEM_COLOR_MAP, 
														(object) => object.source == entry.name
													)
													return <Cell 
																key={index} 
																fill={
																	SYSTEM_COLOR_MAP[idx] && 
																	SYSTEM_COLOR_MAP[idx].color
															} />
												})
											}
										</Pie>
									</PieChart>
								</ResponsiveContainer>
							</div>
							<ul className="pie-labels">
								{
									_.map(sortedpieDataLabel, function (item, key) {
									return <li key={key}>
											<span style={{ backgroundColor: item.color }}></span>
											<span>{(_.round(item.percent, 1)) + '%'}</span>
											<span>{_.truncate(sourceList[item.name], { length: 17 })}</span>
											<span>{item.count}</span>
										</li>
									})
								}
							</ul>
						</div>}
				</article>
			</section>
		)
	}
}

export default ReviewSourceChart;