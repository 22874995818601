import React from 'react';
import {Table, Pagination, Icon, Tooltip} from 'antd';
import {LCTable, LCPagination} from '../../../frameWork';
//import getUsersForProfileStore from '../store/getUsersForProfileStore';
import GetPortfolioUsersStore from '../store/GetPortfolioUsersStore';

//import DeleteUserStore from '../store/DeleteUserStore';
import moment from 'moment';
var languageFile = require('../../../../public/languages/Translation');

export const RenderUSerListWrapper = ({
    tableLoading,
    tableData,
    onPageChange,
    showTotal,
    changeSize,
    userDelete,
    userEdit,
    getPageSizeOptions,
    editUser,
    DeleteInvitedUser,
    reinviteUser,
    isOwner
}) => {
    const cols = [
       
        {
            title:
				languageFile[localStorage.getItem('language')][
				    '14144'
				],
            dataIndex: 'userId',
            key: 'userId',
            render: (text, record) => {
                return (
                    <div>
                        {(record.isPaymentOwner && (
                            <span>
                                {`${text} `}{' '}
                                <Tooltip title='Payment owner'>
                                <i className="fa fa-credit-card"  style={{paddingLeft: 5 }}></i>
                                </Tooltip>
                            </span>
                        )) || <span>{text}</span>}
                    </div>
                );
            }
        },
        {
            title:languageFile[localStorage.getItem('language')][
                '12720'
            ],
            dataIndex:"name",
            key:"name",
            render: (text, record) => {
                return (
                    <div>{text || " - "}</div>
                )
            }
        },
        // {
        //     title:
		// 		languageFile[localStorage.getItem('language')][
		// 		    '13598'
		// 		],
        //     dataIndex: 'role',
        //     key: 'role'
        // },
        {
            title:
				languageFile[localStorage.getItem('language')][
				    '11756'
				],
            dataIndex: 'inviter',
            key: 'inviter'
        },
        {
            title:languageFile[localStorage.getItem('language')][
                '21731'
            ],
            dataIndex:"inviteDate",
            key:"inviteDate",
            render: (text, record) => {
                return (
                    <div>{inviteDateFormat(text,record)}</div>
                )
            }
        },
        {
            title:languageFile[localStorage.getItem('language')][
                '24351'
            ],
            dataIndex:"inviteDate",
            key:"inviteDate",
            render: (text, record) => {
                return (
                    <div>{text && <Tooltip title={moment(text).format('ll')}>{moment(text, "YYYY-MM-DD h:mm:ss").fromNow()}</Tooltip> || " - "}</div>
                )
            }
        },
        {
            title:
				languageFile[localStorage.getItem('language')][
				    '13867'
				],
            dataIndex: 'portfolioUserStatus',
            key: 'portfolioUserStatus'
        },
        {
            title:languageFile[localStorage.getItem('language')][
                '21751'
            ],
            dataIndex: '',
            key: 'x',
            render: (text,record) => (
                isOwner && <span className="role-actions">
                    {record.portfolioUserStatus == 'ACTIVE' && record.inviter != 'SELF' &&
                    <Tooltip title="Edit">
                    <a>
                        <Icon onClick={userEdit.bind({}, record)} type='edit' />
                    </a>
                    </Tooltip>}
                    {record.portfolioUserStatus == 'INVITED' &&
                    <Tooltip title={languageFile[localStorage.getItem('language')]["25671"]}>
                        <a><Icon onClick={reinviteUser.bind({}, record)} type='mail' /></a>
                    </Tooltip>}
                    { record.inviter != 'SELF' &&<Tooltip title={languageFile[localStorage.getItem('language')]["10750"]}>
                         <a><Icon onClick={userDelete.bind({}, record)} type='delete' /></a>
                    </Tooltip>}
                </span>
            )
        },
    ];

    return (
        <div className='review-settings'>
            <section className='card'>
                <article className='card-body'>
                    <LCTable
                        loading={tableLoading}
                        pagination={false}
                        columns={cols}
                        dataSource={tableData}
                    />
                    <footer className='card-footer'>
                        <LCPagination
                            total={
                                GetPortfolioUsersStore.getResponse()
                                    ? GetPortfolioUsersStore.getResponse()
                                        .total
                                    : 0
                            }
                            onChange={onPageChange}
                            showTotal={showTotal}
                            pageSizeOptions={["20","50","100"]}
                            showSizeChanger
                            onShowSizeChange={changeSize}
                            defaultPageSize={20}
                        />
                    </footer>
                </article>
            </section>
        </div>
    );
};
const inviteDateFormat = (text,record) => {
    if(text){
        return moment(text || record.inviteDate).format('ll')
    }else if(record.inviteDate){
        return moment(text || record.inviteDate).format('ll')
    }else {
        return "-";
    }
}