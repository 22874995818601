import AppDispatcher from '../../../../flux/dispatcher/dispatcher';
import Constant from '../constant/StackedAreaCopyConstant';
import restClient from '../../../../routers/MainFrame/action/APIClient';
import LogoutConstant from '../../../../routers/MainFrame/constants/Constants';
import urlConfig from '../../../../config/index';
var moment = require('moment');
var _ = require("lodash");
var organization, country, locality, area, profileId, granularity = 'weekly';
var dateRange = {}, filters = {};

var StackedAreaChartCopyAction = function () {

}

StackedAreaChartCopyAction.prototype = {

	clearFilters: function () {
		filters = {};
		organization = "";
		country = undefined;
		locality = undefined;
		area = undefined;
		granularity = 'weekly';
	},

	addDownloadFilters: function (data) {
		filters = _.cloneDeep(data);
		if (data.profileId) {
			profileId = filters.profileId;
			delete filters.profileId
		}
		if (data.granularity) {
			granularity = data.granularity;
			// delete data.granularity;
		}
		this.loadData();
	},

	addFilters: function (data, newGranularity) {
		granularity = newGranularity;
		filters = data;
		this.loadData();
	},

	changeProfile: function (value) {
		profileId = value;
		this.loadData();
	},

	// clearFilters: function () {
	// 	organization = "";
	// 	country = undefined;
	// 	locality = undefined;
	// 	area = undefined;
	// 	granularity = 'weekly';
	// },

	changeOrganisation: function (value) {
		organization = value;
		this.loadData();
	},

	addCountry: function (value) {
		country = value;
		locality = undefined;
		area = undefined;
		this.loadData()
	},

	addArea: function (value) {
		area = value;
		locality = undefined;
		this.loadData();
	},

	addLocality: function (value) {
		locality = value;
		this.loadData();
	},

	addDates: function (fromDate, toDate) {
		dateRange.from = fromDate;
		dateRange.to = toDate;
		this.loadData();
	},

	changeGranularity: function (value) {
		granularity = value;
		this.loadData();
	},

	loadData: function () {

		if (!(filters.hasOwnProperty('dateRange'))) {
			dateRange.from = moment().subtract(16, 'week').format('YYYY-MM-DD');
			dateRange.to = moment().format('YYYY-MM-DD');
			filters['dateRange'] = dateRange;
		}

		filters['insightFilters'] = {
			"metrics": [
				"VIEWS_MAPS",
				"VIEWS_SEARCH"
				// 'BUSINESS_IMPRESSIONS_DESKTOP_MAPS',
				// 'BUSINESS_IMPRESSIONS_MOBILE_MAPS',
				// 'BUSINESS_IMPRESSIONS_MOBILE_SEARCH',
				// 'BUSINESS_IMPRESSIONS_DESKTOP_SEARCH',
			]
		}

		var data = {
			profileId: localStorage.getItem('profileId'),
			granularity: granularity,
			"metrics": [
				"VIEWS_MAPS",
				"VIEWS_SEARCH"
				// 'BUSINESS_IMPRESSIONS_DESKTOP_MAPS',
				// 'BUSINESS_IMPRESSIONS_MOBILE_MAPS',
				// 'BUSINESS_IMPRESSIONS_MOBILE_SEARCH',
				// 'BUSINESS_IMPRESSIONS_DESKTOP_SEARCH',
			],
			filters: filters
		};


		restClient.getLocationInsightHistogram(data, function (error, response) {
			if (error) {
				if (error.status === 401) {
					AppDispatcher.dispatch({
						actionType: LogoutConstant.UNAUTHORIZED_EXCEPTION,
						data: error
					});
				}
			} else {
				AppDispatcher.dispatch({
					actionType: Constant.STACKED_AREA_COPY,
					data: response
				});
			}

		})
	},
	downloadReviewLocation() {
		var urlName = urlConfig.urlName
		var data = {
			profileId: localStorage.getItem('profileId'),
			granularity: granularity,
			domainName: urlName,
			filters: filters
		};
		filters['insightFilters'] = {
			"metrics": [
				"VIEWS_MAPS",
				"VIEWS_SEARCH"
				// 'BUSINESS_IMPRESSIONS_DESKTOP_MAPS',
				// 'BUSINESS_IMPRESSIONS_MOBILE_MAPS',
				// 'BUSINESS_IMPRESSIONS_MOBILE_SEARCH',
				// 'BUSINESS_IMPRESSIONS_DESKTOP_SEARCH',
			]
		}
		restClient.getLocationInsightHistogramCsv(data, function (error, response) {
			if (error) {
				if (error.status === 401) {
					AppDispatcher.dispatch({
						actionType: LogoutConstant.UNAUTHORIZED_EXCEPTION,
						data: error
					});
				}
			} else {
				AppDispatcher.dispatch({
					actionType: Constant.STACKED_CSV_COPY,
					data: response
				});
			}
		})
	}
}

export default new StackedAreaChartCopyAction();
