import React from 'react';
import { SubscribedUserPaymentWrapper } from './SubscribedUserPaymentWrapper';
import { UnSubscribedUserPaymentWrapper } from './UnSubscribedUserPaymentWrapper';

export const SelectPaymentPage=({
    notSubscribed,
    paymentDetails,
    getHostPage,                                                                                                                                                                                                                                                                                                                                                    
    cancelSubscription,
    handleCancel,
    handleOk,
    cancelConfirmationModal,
    cancelConfirmation,
    getAddonDetails,
    invoicesData,
    getInvoicesList,
    ownerUpdatedMessage,
    openPaymentModal,
    invalidEmail,
    changeOwner,
    cancelSubscriptionResponseMessage,
    isSubscriptionCancelled,
    ownerUpdateResponseMessage,
    changePayerDetailsModal,
    changeEmailModal,
    updatePayerEmail,
    payerEmail,
    openPaymentPortal,
    reActiveConfirmation
})=>{
    if (notSubscribed) {
        return <UnSubscribedUserPaymentWrapper openPaymentPortal={openPaymentPortal}/>
    }else {
        return <SubscribedUserPaymentWrapper
        paymentDetails={paymentDetails}
        getHostPage={getHostPage}
        cancelSubscription={cancelSubscription}
        handleCancel={handleCancel}
        handleOk={handleOk}
        isSubscriptionCancelled={isSubscriptionCancelled}
        cancelSubscriptionResponseMessage={cancelSubscriptionResponseMessage}
        cancelConfirmationModal={cancelConfirmationModal}
        cancelConfirmation={cancelConfirmation}
        getAddonDetails={getAddonDetails}
        invoicesData={invoicesData}
        getInvoicesList={getInvoicesList}
        ownerUpdateResponseMessage={ownerUpdateResponseMessage}
        ownerUpdatedMessage={ownerUpdatedMessage}
        openPaymentModal={openPaymentModal}
        invalidEmail={invalidEmail}
        changeOwner={changeOwner}
        changePayerDetailsModal={changePayerDetailsModal}
        changeEmailModal={changeEmailModal}
        updatePayerEmail={updatePayerEmail}
        payerEmail={payerEmail}
        reActiveConfirmation={reActiveConfirmation}
        />
    
    }
}