import React from 'react';
import {InviteUserPageOnLoading} from './inviteUserOnLoading';
import {LCLocaleProvider} from '../../../frameWork';
import enUs from 'antd/lib/locale-provider/en_US';
import Aux from '../../../utils/_aux';
import {RenderInviteUserButton} from './renderInviteUserButton';
import {RenderUSerListWrapper} from './renderUserListWrapper';
import {InviteUSerModalWindowWrapper} from './inviteUserModalWindowWrapper';
import {UserDeleteFromPortfolio} from './userDeleteFromPortfolio';
import GetPortfolioUserDetailsAction from '../action/GetPortfolioUserDetailsAction';

import {Modal,message} from 'antd';

export const InviteUserPageWrapper = ({
    loading,
    // noPermission,
    // UsersForProfile,
     drodpownLoading,
     selectedRole,
    searchForRole,
    roleChange,
    userEmail,
     addEmail,
     emailValidationError,
     inviteUser,
     inviteModal,
     handleCancel,
     handleOk,
     inviteUserModal,
    // editUser,
    tableLoading,
    tableData,
    onPageChange,
     showTotal,
     changeSize,
     getPageSizeOptions,
     selectedProfile,
     //assignProfileChange,
    // userAlreadyExitsError,
    rolesMenu,
    profileOptions,
    profileChange,
    ischecked,
    allChecked,
    ischeckedAll,
    userDelete,
    showDeleteConfirm,
    deletePortfolioUser,
    userEdit,
    emailVisible,
    // DeleteInvitedUser,
    reinviteUser,
    isOwner,
    roleChanges
   // onDownloadData,
    // isDownloading
}) => {
    if (loading) return <InviteUserPageOnLoading />;
    // if (noPermission) return <InsufficientPermissionPage />;
    return (
        <LCLocaleProvider locale={enUs}>
            <Aux>
            <InviteUSerModalWindowWrapper
				rolesMenu={rolesMenu}
				inviteModal={inviteModal}
			    handleCancel={handleCancel}
				 handleOk={handleOk}
				 inviteUser={inviteUser}
				 userEmail={userEmail}
				 addEmail={addEmail}
				 emailValidationError={emailValidationError}
				 drodpownLoading={drodpownLoading}
				 searchForRole={searchForRole}
                selectedRole={selectedRole}
                profileOptions={profileOptions}
                roleChange={roleChange}
                roleChanges={roleChanges}
                selectedProfile={selectedProfile}
                profileChange={profileChange}
                tableLoading={tableLoading}
				tableData={tableData}
				onPageChange={onPageChange}
				showTotal={showTotal}
				changeSize={changeSize}
                getPageSizeOptions={getPageSizeOptions}
              //  assignProfileChange={assignProfileChange}
                ischecked={ischecked}
                allChecked={allChecked}
                ischeckedAll={ischeckedAll}
                emailVisible={emailVisible}
			/>
            <UserDeleteFromPortfolio
             showDeleteConfirm={showDeleteConfirm}
             deletePortfolioUser={deletePortfolioUser}
             handleCancel={handleCancel}
             />
              
            <RenderInviteUserButton
				 inviteUserModal={inviteUserModal}
				// onDownloadData={onDownloadData}
				// isDownloading={isDownloading}
			/>
			<RenderUSerListWrapper
				//editUser={editUser}
				//DeleteInvitedUser={DeleteInvitedUser}
				reinviteUser={reinviteUser}
				tableLoading={tableLoading}
				tableData={tableData}
				onPageChange={onPageChange}
				showTotal={showTotal}
                changeSize={changeSize}
                getPageSizeOptions={getPageSizeOptions}
                userDelete={userDelete}
                userEdit={userEdit}
                isOwner={isOwner}
			/>
            </Aux>
        </LCLocaleProvider>
    );
};
