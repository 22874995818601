import React from 'react';
import { LCModal } from '../../../frameWork';
import { Modal, Button } from 'antd';
var languageFile = require('../../../../public/languages/Translation');

export const UserDeleteFromPortfolio = ({
	showDeleteConfirm,
	deletePortfolioUser,
	handleCancel,
}) => {
	return (
		<Modal
            visible={showDeleteConfirm}
            wrapClassName="conflict-message"
			title="Are you sure?"
			width={450}
			//onOk={this.handleOk}
			onCancel={handleCancel}
			footer={
				<div key="0">
					<Button
						key="submit"
                        type="primary"
                        style={{ backgroundColor: '#F58258', borderColor: '#F58258' }}
						//loading={this.state.loading}
						onClick={deletePortfolioUser}
					>
						{languageFile[localStorage.getItem('language')]["10750"]}
					</Button>
					<Button
						key="cancel"
						type="default"
						//loading={this.state.loading}
						onClick={handleCancel}
					>
						{languageFile[localStorage.getItem('language')]["10523"]}
					</Button>
				</div>
			}
		>
			<div>Are you sure you want to Remove this user ?</div>
		</Modal>
	);
};
