import React, { Component } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import GetRegionChartAction from '../QuadrantAnalysis/action/GetRegionChartAction';
import GetRegionChartStore from '../QuadrantAnalysis/store/GetRegionChartStore';
import _ from 'lodash';
import { colorSpectrumGenerator } from '../../utils/colorSpectrumGenerator';
var languageFile = require('../../../public/languages/Translation');

am4core.useTheme(am4themes_animated);

class QuadrantAnalysicsChart2 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isPaneOpen: false,
			datass: [],
			avgConversion: '',
			avgRate: '',
		};
		this.getRegionChartStore = this.getRegionChartStore.bind(this);
	}

	componentWillMount() {
		GetRegionChartAction.getRegion({
			profileId: localStorage.getItem('profileId'),
			filters: { dateRange: this.props.date },
			requestType: 'region',
			masterLocationFilter:this.props.master
		});
		GetRegionChartStore.bind(this.getRegionChartStore);
	}
	componentDidMount() {
		this.getRegionChartStore();
	}
	componentWillUnmount() {
		GetRegionChartStore.unbind(this.getRegionChartStore);
	}
	getRegionChartStore = () => {
		let data = [];
		let a = 0;
		let b = 0;
		let centerA = 0;
		let centerB = 0;
		let avgCon = 0;
		let avgView = 0;
		let xMax = 0;
		let yMax = 0;
		let dataLenght = 0;
		let len = GetRegionChartStore.getResponse().conversionRateMap;
		if (len != undefined) {
			dataLenght = len.length;
		}
		let extraColours = colorSpectrumGenerator(
			'rgb(0,0,139)',
			'rgb(128,128,128)',
			dataLenght
		);

		_.map(
			GetRegionChartStore.getResponse().conversionRateMap,
			(content, index) => {
				data.push({
					x: (Math.round(content.conversionRate * 100) / 100).toFixed(2),
					y: content.views,
					radius: Math.round(content.views),
					color: am4core.color("#"+Math.floor(Math.random()*16777215).toString(16)),
					region: content.region.toString(16),
				});

				avgCon = GetRegionChartStore.getResponse().avgConversionRate;
				avgView = GetRegionChartStore.getResponse().avgViews;

				if (a < content.conversionRate) {
					a = content.conversionRate;
				}
				if (b < content.views) {
					b = content.views;
				}
			}
		);
		
		this.setState({
			datass: data,
		});

		xMax = a + a / 4;
		yMax = b + b / 4;
		centerA = avgCon;
		centerB = avgView;

		let chart = am4core.create('chart', am4charts.XYChart);
		chart.cursor = new am4charts.XYCursor();
		chart.cursor.behavior = 'zoomXY';

		var DATA = this.state.datass;

		var xAxis = chart.xAxes.push(new am4charts.ValueAxis());
		xAxis.renderer.grid.template.disabled = true;
		xAxis.renderer.labels.template.disabled = true;
		xAxis.strictMinMax = true;
		xAxis.min = 0;
		xAxis.max = xMax;

		let value = 0;
		value = Math.floor(xMax);
		let rangeValue = 0;
		let divideValue = 0;
		let step = 0;
		if (value > 4) {
			divideValue = 7
			value = Math.floor(xMax);
			if (value % 7 != 0) {
				rangeValue = value + (7 - (value % 7));
			} else {
				rangeValue = value;
			}
		} 
		else if(value == 3){
			rangeValue = Math.floor(xMax);
			divideValue = 6
		}
		else if(value == 1 || value == 2){
			rangeValue = Math.floor(xMax);
			divideValue = 4
		}
		else {
			divideValue = 7
			value = xMax;
			value = +value.toFixed(2);
			if (value % 0.07 != 0) {
				rangeValue = value + (0.07 - (value % 0.07));
			} else {
				rangeValue = value;
			}
		}
		var range0 = xAxis.axisRanges.create();
		range0.value = 0;
		range0.label.text = '0%';

		var range1 = xAxis.axisRanges.create();
		var r1 = (rangeValue / divideValue) * 1;
		range1.value = Number.isInteger(r1) ? (rangeValue / divideValue) * 1 : ((rangeValue / divideValue) * 1).toFixed(2)
		range1.label.text = Number.isInteger(r1) ? (rangeValue / divideValue) * 1 + '%' :  (((rangeValue / divideValue) * 1).toFixed(2) + '%');

		var range2 = xAxis.axisRanges.create();
		var r2 = (rangeValue / divideValue) * 2;
		range2.value =Number.isInteger(r2) ? (rangeValue / divideValue) * 2 : ((rangeValue / divideValue) * 2).toFixed(2)
		range2.label.text = Number.isInteger(r2) ? (rangeValue / divideValue) * 2 + '%' :  (((rangeValue / divideValue) * 2).toFixed(2) + '%');

		var range3 = xAxis.axisRanges.create();
		var r3 = (rangeValue / divideValue) * 3;

		range3.value = Number.isInteger(r3) ? (rangeValue / divideValue) * 3 : ((rangeValue / divideValue) * 3).toFixed(2)
		range3.label.text = Number.isInteger(r3) ? (rangeValue / divideValue) * 3 + '%' :  (((rangeValue / divideValue) * 3).toFixed(2) + '%');

		var range4 = xAxis.axisRanges.create();
		var r4 = (rangeValue / divideValue) * 4;
		range4.value = Number.isInteger(r4) ? (rangeValue / divideValue) * 4 : ((rangeValue / divideValue) * 4).toFixed(2)
		range4.label.text = Number.isInteger(r4) ? (rangeValue / divideValue) * 4 + '%' :  (((rangeValue / divideValue) * 4).toFixed(2) + '%');

		var range5 = xAxis.axisRanges.create();
		var r5 = (rangeValue / divideValue) * 5;
		range5.value = Number.isInteger(r5) ? (rangeValue / divideValue) * 5 : ((rangeValue / divideValue) * 5).toFixed(2)
		range5.label.text = Number.isInteger(r5) ? (rangeValue / divideValue) * 5 + '%' :  (((rangeValue / divideValue) * 5).toFixed(2) + '%');

		var range6 = xAxis.axisRanges.create();
		var r6 = (rangeValue / divideValue) * 6;
		range6.value = Number.isInteger(r6) ? (rangeValue / divideValue) * 6 : ((rangeValue / divideValue) * 6).toFixed(2)
		range6.label.text = Number.isInteger(r6) ? (rangeValue / divideValue) * 6 + '%' :  (((rangeValue / divideValue) * 6).toFixed(2) + '%');

		var range7 = xAxis.axisRanges.create();
		var r7 = (rangeValue / divideValue) * 7;
		range7.value = Number.isInteger(r7) ? (rangeValue / divideValue) * 7 : ((rangeValue / divideValue) * 7).toFixed(2)
		range7.label.text = Number.isInteger(r7) ? (rangeValue / divideValue) * 7 + '%' :  (((rangeValue / divideValue) * 7).toFixed(2) + '%');

		var yAxis = chart.yAxes.push(new am4charts.ValueAxis());
		yAxis.min = 0;
		yAxis.max = yMax;
		yAxis.keepSelection = true;
		yAxis.renderer.grid.template.above = true;
		yAxis.strictMinMax = true;

		var range = xAxis.axisRanges.create();
		range.value = avgCon - 1;
		range.endValue = avgCon + 1;
		range.axisFill.fill = am4core.color('white');
		range.axisFill.fillOpacity = 2;
		range.grid.strokeOpacity = 1;

		range.label.disabled = true;
		range.axisFill.tooltip = new am4core.Tooltip();
		//range.axisFill.tooltipText = "Average Conversion Rate: " + avgCon;
		range.axisFill.interactionsEnabled = true;
		range.axisFill.isMeasured = true;

		var range = yAxis.axisRanges.create();
		range.value = avgView - 10000;
		range.endValue = avgView + 10000;
		range.axisFill.fill = am4core.color('white');
		range.axisFill.fillOpacity = 2;
		range.grid.strokeOpacity = 1;
		range.label.disabled = true;
		range.axisFill.tooltip = new am4core.Tooltip();
		//range.axisFill.tooltipText = "Average View: " + avgView;
		range.axisFill.interactionsEnabled = true;
		range.axisFill.isMeasured = true;


		var label = chart.createChild(am4core.Label);
		// label.text = languageFile[localStorage.getItem('language')]["27661"];
		label.text ="Low Views + Low Conversion";
		label.fontSize = 13;
		label.align = 'center';
		label.fill = am4core.color('#545658');
		label.isMeasured = false;
		label.x = 200;
		label.y = 400;
		label.horizontalCenter = 'middle';

		var label = chart.createChild(am4core.Label);
		// label.text = languageFile[localStorage.getItem('language')]["27641"];
		label.text ="High Views + Low Conversion";
		label.fontSize = 13;
		label.align = 'center';
		label.fill = am4core.color('#545658');
		label.isMeasured = false;
		label.x = 200;
		label.y = 40;
		label.horizontalCenter = 'middle';

		var label = chart.createChild(am4core.Label);
		// label.text = languageFile[localStorage.getItem('language')]["27651"];
		label.text ="High Views + High Conversion";
		label.fontSize = 13;
		label.align = 'center';
		label.fill = am4core.color('#545658');
		label.isMeasured = false;
		label.x = am4core.percent(80);
		label.y = am4core.percent(9);
		label.horizontalCenter = 'middle';

		var label = chart.createChild(am4core.Label);
		// label.text = languageFile[localStorage.getItem('language')]["27671"];
		label.text ="Low Views + High Conversion";
		label.fontSize = 13;
		label.align = 'center';
		label.fill = am4core.color('#545658');
		label.isMeasured = false;
		label.x = am4core.percent(80);
		label.y = am4core.percent(85);
		label.horizontalCenter = 'middle';

		var series = chart.series.push(new am4charts.LineSeries());
		series.dataFields.valueX = 'x';
		series.dataFields.valueY = 'y';
		series.dataFields.value = 'radius';
		series.dataFields.color = 'color';
		series.dataFields.region = 'region';

		series.strokeOpacity = 0;
		let i = 0;
		var bullet = series.bullets.push(new am4core.Circle());
		bullet.propertyFields.fill = 'color';
		bullet.strokeOpacity = 0;
		bullet.strokeWidth = 2;
		bullet.fillOpacity = 0.5;
		bullet.stroke = am4core.color('#ffffff');
		bullet.hiddenState.properties.opacity = 0;
		// bullet.tooltipText =
		// 	`${languageFile[localStorage.getItem('language')]["29961"]}: {region} , ${languageFile[localStorage.getItem('language')]["27711"]} {valueX.value} %, ${languageFile[localStorage.getItem('language')]["27721"]} {valueY.value}`;
		bullet.tooltipText =
			`${languageFile[localStorage.getItem('language')]["29961"]}: {region} , ${languageFile[localStorage.getItem('language')]["27711"]} {valueX.value} %, ${languageFile[localStorage.getItem('language')]["14326"]} {valueY.value}`;
		bullet.events.on('over', function (event) {
			var target = event.target;
			chart.cursor.triggerMove({ x: target.pixelX, y: target.pixelY }, 'hard');
			chart.cursor.lineX.y = target.pixelY;
			chart.cursor.lineY.x = target.pixelX - chart.plotContainer.pixelWidth;
			xAxis.tooltip.disabled = false;
			yAxis.tooltip.disabled = false;
		});

		bullet.events.on('out', function (event) {
			chart.cursor.triggerMove(event.pointer.point, 'none');
			chart.cursor.lineX.y = 0;
			chart.cursor.lineY.x = 0;
			xAxis.tooltip.disabled = true;
			yAxis.tooltip.disabled = true;
		});

		series.heatRules.push({
			target: bullet,
			min: 2,
			max: 30,
			property: 'radius',
		});
		series.data = data;

		// chart.logo.disabled = true;
		chart.scrollbarX = new am4core.Scrollbar();
		chart.scrollbarY = new am4core.Scrollbar();
		chart.scrollbarX.disabled = !this.props.showChartZoom;
		chart.scrollbarY.disabled = !this.props.showChartZoom;
		this.chart = chart;

		var label = chart.plotContainer.createChild(am4core.Label);
		label.fontSize = 60;
		label.fillOpacity = 0.4;
		label.align = 'center';
		label.zIndex = 1000;
	};
	componentDidUpdate() {
		this.chart.scrollbarX.disabled = !this.props.showChartZoom;
		this.chart.scrollbarY.disabled = !this.props.showChartZoom;
	}
	render() {
		return <div id="chart" style={{ width: '100%', height: '500px' }}></div>;
	}
}

export default QuadrantAnalysicsChart2;
