import "./style.scss"
import { Link } from 'react-router-dom'
var React = require('react')
var lodashMap = require('lodash.map')
const enhanceWithClickOutside = require('react-click-outside');

class TextSelect extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            searchText: '',
            class: "react-textselect"
        }
        this.handleClick = this.handleClick.bind(this)
        this.handleClickOutside = this.handleClickOutside.bind(this)
        this.handleValueClick = this.handleValueClick.bind(this)
        this.onSearch = this.onSearch.bind(this)
    }

    handleClick(e) {
        if (this.state.class.indexOf("open") > -1) {
            this.setState({
                class: "react-textselect",
            })
        } else {
            this.setState({
                class: "react-textselect open",
            })
        }
    }

    handleClickOutside() {
        this.setState({
            class: "react-textselect"
        })

    }

    handleValueClick(value) {
        if (this.state.class.indexOf("open") > -1) {
            this.setState({
                class: "react-textselect"
            })
        } else {
            this.setState({
                class: "react-textselect open"
            })
        }
        this.props.onChange(value)
    }

    onSearch(event) {
        this.setState({
            searchText: event.target.value
        })
    }

    render() {
        var { options, className } = this.props;
        var self = this;
        var classes = 'textselect-value'
        if (className) classes += ' ' + className
        var width = 0;
        var profilesArray = [];

        if (this.state.searchText && this.props.enableSearch) {
            var filteredArray = profilesArray.filter(function (item) {
                return item.indexOf(self.state.searchText) > -1
            })
            profilesArray = filteredArray;
        }

        var array = lodashMap(options, function mapOptions(value, key) {
            if (value.length > width) {
                width = value.length;
            }
            return (
                <li key={key} onClick={self.handleValueClick.bind(self, value)}><Link to="#">{value}</Link></li>
            )
        })

        var displayStyle = {};
        width = width * 8 + 10;
        if (this.state.class.indexOf("open") > -1) {
            classes = classes + " customAnimation";
            displayStyle.width = width;
            displayStyle.minWidth = 200;
        }
        if (this.props.enableSearch) {
            array.unshift(<li style={{ minWidth: width - 10 }} key={"key"}><input value={this.state.searchText} ref="search" tabIndex="1" onChange={self.onSearch} /></li>)
        }
        return (
            <div className={this.state.class}>
                <span className={classes} key={classes} onClick={this.handleClick} >
                    {this.props.active}
                </span>
                <ul className="react-textselect-input" tabIndex="-1" ref="list">
                    {array}
                </ul>
            </div>
        )
    }
}

export default enhanceWithClickOutside(TextSelect);