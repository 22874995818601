import React, { Component } from "react";
import "./style.scss";
import Axios from "axios";
import config from "../../../globalconfig";
import { message, Spin } from "antd";
import getSVG from "../../utils/commonSVG";
import { urlName } from "../../config";
import { withRouter } from "react-router-dom";
import { red } from "@mui/material/colors";
import cookie from "../../utils/cookie";
import { data } from "iso-3166-2";
import GetWorkspaceAction from "../Login/action/GetWorkspaceAction";
import GetWorkSpaceStore from "../Login/store/GetWorkspaceStore";
import UpdateEmailAction from "../Login/action/UpdateEmailAction";
import GetProfileDetailsAction from "../AuthorisePage/action/GetProfileDetailsAction";
import GetProfileDetailStore from "../AuthorisePage/store/GetProfileDetailStore";
import { defaultDomains } from "../../utils/defaultDomainList";

class LoginVerification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: ["", "", "", "", "", ""],
      integrationResponse: [],
      apiLoader: false,
      error: { status: false, message: "" },
      inputErrors: Array(6).fill(false),
    };
    this.inputs = [];
  }

  componentDidMount() {
    if(localStorage.getItem('userId') == null){
        window.location.href = '/login'
    }
    this.clearCookies();
    this.fetchData();
    GetWorkSpaceStore.bind(this.getWorkSpaceResponse)
    GetProfileDetailStore.bind(this.onProfileDetails);

    if (localStorage.getItem('firstAuthorizeLoad') === null) {
        localStorage.setItem('firstAuthorizeLoad', 1);
      } else {
        window.location.href = '/login'
      }
  }
 
  componentWillUnmount(){
    GetWorkSpaceStore.unbind(this.getWorkSpaceResponse)
    GetProfileDetailStore.unbind(this.onProfileDetails);

  }
  onProfileDetails() {
    if (GetProfileDetailStore.getResponse().status === 200) {
        localStorage.setItem(
            'masterLocationFilter',
            GetProfileDetailStore.getResponse().profile.masterLocationFilter
        );
        localStorage.setItem(
            'profileName',
            GetProfileDetailStore.getResponse().profile.profileName
        );
        localStorage.setItem(
            'favicon',
            GetProfileDetailStore.getResponse().profile.favicon
        );
        localStorage.setItem(
            'logo',
            GetProfileDetailStore.getResponse().profile.logo ||
            `/images/${urlName}/logo-icon.png`
        );
        localStorage.setItem(
            'logoText',
            GetProfileDetailStore.getResponse().profile.logoWithText ||
            `/images/${urlName}/logo-text.png`
        );
        this.redirectToTab();
    } else if (
        GetProfileDetailStore.getResponse().data &&
        GetProfileDetailStore.getResponse().data.status === 404
    ) {
        this.unAuthorisedInfo();
        this.props.history.push('/login');
    } else if (
        GetProfileDetailStore.getResponse().data &&
        GetProfileDetailStore.getResponse().data.message
    ) {
        message.error(GetProfileDetailStore.getResponse().data.message);
        this.props.history.push('/login');
    } else {
        message.error('something went wrong');
    }
}

  clearCookies = () => {
    document.cookie.split(";").forEach(
      (cookie) =>
        (document.cookie = cookie
          .replace(/^ +/, "")
          .replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`))
    );
  };

  getWorkSpaceResponse = () => {
    let response = GetWorkSpaceStore.getResponse();
    if (response.status == 200) {
        if (response.total > 1) {
            cookie.createCookie('workspaceData', JSON.stringify(response.result))
            window.location.href = '/account-overview';
        }
        else if (response.total == 1) {
            cookie.createCookie('workspaceData', JSON.stringify(response.result));
            cookie.createCookie('currentAccountId', response.result[0].accountId)
            window.location.href = '/authorize';
        }
        else {
            cookie.eraseCookie('workspaceData');
            cookie.eraseCookie('currentAccountId')
            window.location.href = '/authorize';
        }
    }
    else {
        cookie.eraseCookie('workspaceData');
        cookie.eraseCookie('currentAccountId')
        window.location.href = '/authorize';
    }
}
  fetchData = async () => {
    try {
      const response = await Axios.get(
        config.server + `/integrations/read?domain=${urlName}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: false,
        }
      );
      this.setState({ integrationResponse: response.data });
    } catch (err) {
      this.setState({ integrationResponse: [] });
    }
  };
  getSuccessResponse = (response) =>{
    if (response.data.token) {
        cookie.createCookie(
            'lctoken',
            response.data.token,
            2
        );
        cookie.createCookie(
            'userName',
            response.data.name,
            2
        );
        cookie.createCookie(
            'userId',
            response.data.userId,
            2
        );

        if (
            cookie.readCookie('inviteeId') &&
            cookie.readCookie('inviteeId') !== cookie.readCookie('userId')
        ) {
            UpdateEmailAction.update({
                profileId: cookie.readCookie('profileId'),
                invitedEmail: cookie.readCookie('inviteeId'),
                loginEmail: cookie.readCookie('userId'),
            });
            cookie.eraseCookie('inviteeId');
            cookie.eraseCookie('profileId');
        }
        //it will work when inbox tab active
        if (
            this.props.location.search.includes('?redirecturl') ||
            this.props.location.search.includes('?reviewurl') ||
            this.props.location.search.includes('?redirecttab') ||
            this.props.location.search.includes('?reviewredirect') ||
            this.props.location.search.includes('?questionredirect') ||
            this.props.location.search.includes('?mediaredirect')
        ) {
            // if (Object.prototype.hasOwnProperty.call(this.props.location.search, 'redirecturl')) {
            localStorage.setItem(
                'profileId',
                this.props.location.search.split('&profileId=')[1]
            );
            GetProfileDetailsAction.getProfileDetails(
                this.props.location.search.split('&profileId=')[1],
                urlName
            );
        } else {
            if (this.props.location.state && this.props.location.state.from)
                window.location.href = `${this.props.location.state.from.pathname}${this.props.location.state.from.search}`;
            else if(defaultDomains.includes(urlName)) {

                GetWorkspaceAction.getWorkspace()
            }
            else{
                window.location.href = '/authorize';
            }
            // window.location.href = '/authorize';

            // else window.location.href = '/authorize';
        }
}

  }
  resendOTP = () =>{
    let userId = localStorage.getItem('userId')
    let data = {};
    data.userId = userId;
    data.domainName = urlName;
    Axios.post(config.server + "/resendOTP", data, {
        headers: {
            'Content-Type': 'application/json',
        },
    }).then((resp) => {
            message.success(resp.data.message)
        })
        .catch((err) => {
           message.error(err.response.data.message)
           setTimeout(() => {
            if (err.response.data.subCode === 2) {
              window.location.href = '/login';
            }
          }, 2000);
        })
  }
  fetchLogin =  (newCode) => {
    let userId = localStorage.getItem('userId')
    let {code} = this.state;
    let data = {};
    data.userId = userId;
    data.domainName = urlName;
    data.otp = newCode ? newCode.join(''):code.join('')
    this.setState({apiLoader: true})
    Axios.post(config.server + "/login", data, {
        headers: {
            'Content-Type': 'application/json',
        },
        withCredentials: false,
    })
        .then((resp) => {
            this.setState({apiLoader:false})
            localStorage.removeItem('userId')
            this.getSuccessResponse(resp)
        })
        .catch((err) => {
           this.setState({
            apiLoader: false,
            inputErrors: [true, true, true, true, true, true],
            code: ["", "", "", "", "", ""],
            error: { status: true, message:err.response.data.message },
           })
        })
    }


  handleChange = (value, index) => {
    const newCode = [...this.state.code];
    newCode[index] = value;
    this.setState({ code: newCode });

    // Reset error state for this input
    const newInputErrors = [...this.state.inputErrors];
    newInputErrors[index] = false;
    this.setState({ inputErrors: newInputErrors });

    // Move to the next input box automatically
    if (value !== "" && index < this.inputs.length - 1) {
      this.inputs[index + 1].focus();
    }
    if (value === "" && index > 0) {
      this.inputs[index - 1].focus();
    }

    if (newCode.every((char) => char !== "")) {
      this.setState({
        apiLoader: true,
      });
      this.fetchLogin(newCode)
    }
  };
  maskUserId = () =>{
    let userId = localStorage.getItem('userId') ? localStorage.getItem('userId') : ''
    const [username, domain] = userId.split('@'); // Split the userId into username and domain

    if (!username || !domain) {
      return userId; // Return as is if format is unexpected
    }
  
    if (username.length>2) {
        const visiblePart = username.slice(0, 2);
        const maskedPart = '*'.repeat(username.length - 2);
        return `${visiblePart}${maskedPart}@${domain}`; 
      } else {
        const visiblePart = username.slice(0, 1);
        const maskedPart = '*'.repeat(username.length - 1);
        return `${visiblePart}${maskedPart}@${domain}`;
      }
  }
  handlePaste = (e) => {
    const pasteData = e.clipboardData.getData("text").slice(0, 6);
    const newCode = pasteData
      .split("")
      .concat(["", "", "", "", "", ""])
      .slice(0, 6);

    this.setState({ inputErrors: Array(6).fill(false), code: newCode });

    if (newCode.every((char) => char !== "")) {
      this.fetchLogin(newCode)
    }
  };

  render() {
    const { code, integrationResponse, apiLoader, error, inputErrors } = this.state;

    return (
      <div className="container">
        <div className="logo">
          {integrationResponse &&
          integrationResponse.whiteLabelImages &&
          integrationResponse.whiteLabelImages.domainIcon ? (
            <img
              src={integrationResponse.whiteLabelImages.domainIcon}
              alt="Domain Icon"
            />
          ) : (
            <img
              src="default-icon.png"
              alt="Default Icon"
            /> // Fallback for when the image isn't available
          )}
        </div>
        <div className="verification-block">
          <h1>Two-Factor Authentication</h1>

          <div className="code-inputs" onPaste={this.handlePaste}>
            {code.map((_, index) => (
              <input
                key={index}
                type="text"
                maxLength="1"
                value={code[index]}
                onChange={(e) => this.handleChange(e.target.value, index)}
                ref={(el) => (this.inputs[index] = el)}
                className={`code-input ${inputErrors[index] ? "error" : ""}`}
              />
            ))}
          </div>
          <div className="error-field">
            {error.status ? (
              <span>
                {getSVG("error-icon")} {error.message}
              </span>
            ) : null}
          </div>
          <p>
           {` A message with a verification code has been sent to email ${this.maskUserId()}. Enter
            the code to continue.`}
          </p>
          <p className="resend">
            {!apiLoader ? (
              <a onClick={()=>this.resendOTP()}>Get a new verification code</a>
            ) : (
              <span className="verify-code">
                <Spin /> Verifying
              </span>
            )}
          </p>
        </div>
      </div>
    );
  }
}

export default withRouter(LoginVerification);
