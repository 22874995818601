import React from 'react';
import { Button } from 'antd';
const CANCEL_SUBSCRIPTION='Cancel Subscription';
const REACTIVE_SUBSCRIPTION='Re-active Subscription';
export const RenderCancelSubscriptionButton=({
    paymentDetails,
    cancelConfirmation,
    reActiveConfirmation
})=>{
    if(paymentDetails.status == 'CANCELLED'){
        return(<div>
            <Button onClick={reActiveConfirmation}>
               {REACTIVE_SUBSCRIPTION}
            </Button>
        </div>)
    }else if(paymentDetails.status !== 'NON_RENEWING')
      return(<div>
            <Button onClick={cancelConfirmation}>
               {CANCEL_SUBSCRIPTION}
            </Button>
        </div>);
        else return [];
    }