import AppDispatcher from '../../../flux/dispatcher/dispatcher';
import Constant from '../constant/ResetPasswordConstant';
import restClient from '../../MainFrame/action/APIClient';

var ResetPasswordAction = function () {

}

ResetPasswordAction.prototype = {

	resetPassword: function (userObject) {
		restClient.resetPassword(userObject, function (error, response) {
			if (error) {
				// console.log("error", error)
				AppDispatcher.dispatch({
					actionType: Constant.PASSWORD_RESET,
					data: error.data
				});
			} else {
				// console.log("response of reset", response);
				response.status = 200;
				AppDispatcher.dispatch({
					actionType: Constant.PASSWORD_RESET,
					data: response
				});
			}
		});
	}
}

export default new ResetPasswordAction();