import React from 'react';
import { ProPlanDetails } from './ProPlanDetails';
import { ProPlanTitle } from './ProPlanTitle';
import languageFile from '../../../../public/languages/Translation';

export const UnsubscribedProPlan = () => {
	return (
		<div className='payment-details'>
			<div className='payment-header'>{languageFile[localStorage.getItem('language')]["24131"]}</div>
			<ProPlanTitle/>
			<ProPlanDetails/>
		</div>
	);
};
