import React from 'react';
import {PaymentPreferenceHeader} from './PaymentPreferenceHeader';
import {ChangeBillingEmailModalWindow} from './ChangeBillingEmailModalWindow';
import {UpdateCardModalWindow} from './UpdateCardModalWinodw';
import {UpdateResponseModalWindow} from './UpdateResponseModalWindow.';
import { RenderPaymentPreferenceTable } from './RenderPaymentPreferenceTable';

export const PaymentPreference = ({
	paymentDetails,
	getHostPage,
	changeEmailModal,
	openPaymentModal,
	updatePayerEmail,
	payerEmail,
	invalidEmail,
	handleCancel,
	changeOwner,
	ownerUpdateResponseMessage,
	handleOk,
	changePayerDetailsModal,
	ownerUpdatedMessage
}) => {
	return (
		<div className='payment-preference'>
			<PaymentPreferenceHeader />
			<RenderPaymentPreferenceTable
			paymentDetails={paymentDetails}
			getHostPage={getHostPage}
			changeEmailModal={changeEmailModal}
			/>
			<ChangeBillingEmailModalWindow
				changePayerDetailsModal={changePayerDetailsModal}
				handleCancel={handleCancel}
				handleOk={handleOk}
				changeOwner={changeOwner}
				invalidEmail={invalidEmail}
				payerEmail={payerEmail}
				updatePayerEmail={updatePayerEmail}
			/>
			<UpdateCardModalWindow
				openPaymentModal={openPaymentModal}
				handleCancel={handleCancel}
				handleOk={handleOk}
			/>
			<UpdateResponseModalWindow
				ownerUpdatedMessage={ownerUpdatedMessage}
				handleCancel={handleCancel}
				ownerUpdateResponseMessage={
					ownerUpdateResponseMessage
				}
				handleOk={handleOk}
			/>
		</div>
	);
};
