import React from 'react';
import { LCModal, LCButton } from '../../../frameWork';
const OK_BUTTON='Ok, got it!';
export const UpdateResponseModalWindow = ({
    ownerUpdatedMessage,
    handleCancel,
	handleOk,
	ownerUpdateResponseMessage
}) => {

	return (
		<LCModal
			key={Math.random()}
			title='Update payment card'
			width={400}
			wrapClassName='payment-card'
			style={{top: 20}}
			visible={ownerUpdatedMessage}
			onOk={handleOk}
			onCancel={handleCancel}
			footer={
				<div>
					<LCButton type='primary' onClick={handleCancel}
					buttonlabel={OK_BUTTON}
					/>
				</div>
			}
			modalContent={ownerUpdateResponseMessage}
		/>
	);
};
