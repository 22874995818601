import AppDispatcher from '../../../../flux/dispatcher/dispatcher';
import Constant from '../constant/StackedBarConstant';
import restClient from '../../../../routers/MainFrame/action/APIClient';
import LogoutConstant from '../../../../routers/MainFrame/constants/Constants';
import urlConfig from '../../../../config/index';

var moment = require('moment');
var _ = require("lodash");
var organization, profileId, country, locality, area, granularity = 'weekly';
var dateRange = {}, filters = {};

var StackedBarChartAction = function () {

}

StackedBarChartAction.prototype = {

	clearFilters: function () {
		filters = {};
		organization = "";
		country = undefined;
		locality = undefined;
		area = undefined;
		granularity = 'weekly';
	},

	addFilters: function (data, newGranularity) {
		filters = data;
		granularity = newGranularity;
		this.loadData();
	},

	changeProfile: function (value) {
		profileId = value;
		this.loadData();
	},

	// clearFilters: function () {
	// 	organization = "";
	// 	country = undefined;
	// 	locality = undefined;
	// 	area = undefined;
	// 	granularity = 'weekly';
	// },

	changeOrganisation: function (value) {
		organization = value;
		this.loadData();
	},

	addDownloadFilters: function (data) {
		filters = _.cloneDeep(data);
		if (filters.profileId) {
			profileId = filters.profileId;
			delete filters.profileId
		}
		if (data.granularity) {
			granularity = data.granularity;
			// delete data.granularity;
		}
		this.loadData();
	},

	addCountry: function (value) {
		country = value;
		locality = undefined;
		area = undefined;
		this.loadData()
	},

	addArea: function (value) {
		area = value;
		locality = undefined;
		this.loadData();
	},

	addLocality: function (value) {
		locality = value;
		this.loadData();
	},

	addDates: function (fromDate, toDate) {
		dateRange.from = fromDate;
		dateRange.to = toDate;
		this.loadData();
	},

	changeGranularity: function (value) {
		granularity = value;
		this.loadData();
	},


	loadData: function () {
		if (!(filters.hasOwnProperty('dateRange'))) {
			dateRange.from = moment().subtract(16, 'week').format('YYYY-MM-DD');
			dateRange.to = moment().format('YYYY-MM-DD');
			filters['dateRange'] = dateRange;
		}

		filters['insightFilters'] = {
			"metrics": [
				"QUERIES_DIRECT",
				"QUERIES_INDIRECT",
				"QUERIES_CHAIN"
			]
		}
		var data = {
			profileId: localStorage.getItem('profileId'),
			granularity: granularity,
			"metrics": [
				"QUERIES_DIRECT",
				"QUERIES_INDIRECT",
				"QUERIES_CHAIN"
			],
			filters: filters
		};


		restClient.getLocationInsightHistogram(data, function (error, response) {
			if (error) {
				// console.log("error", error);
				if (error.status === 401) {
					AppDispatcher.dispatch({
						actionType: LogoutConstant.UNAUTHORIZED_EXCEPTION,
						data: error
					});
				}
			} else {

				AppDispatcher.dispatch({
					actionType: Constant.STACKEDBAR_RESPONSE,
					data: response
				});
			}

		})
	},
	downloadReviewLocation() {
		var urlName = urlConfig.urlName;

		var data = {
			profileId: localStorage.getItem('profileId'),
			granularity: granularity,
			domainName: urlName,
			filters: filters
		};
		filters['insightFilters'] = {
			"metrics": [
				"QUERIES_DIRECT",
				"QUERIES_INDIRECT",
				"QUERIES_CHAIN"
			]
		}

		restClient.getLocationInsightHistogramCsv(data, function (error, response) {
			if (error) {
				// console.log("error", error);
				if (error.status === 401) {
					AppDispatcher.dispatch({
						actionType: LogoutConstant.UNAUTHORIZED_EXCEPTION,
						data: error
					});
				}
			} else {
				AppDispatcher.dispatch({
					actionType: Constant.HISTOGRAM_CSV,
					data: response
				});
			}
		})
	}
}

export default new StackedBarChartAction();
