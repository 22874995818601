import React from 'react';
import {PlanDetailsHeader} from './PlanDetailsHeader';
import {RenderPlanDetailsTableWrapper} from './RenderPlanDetialsTableWrapper';
import {RenderCancelSubscriptionModalWindow} from './RenderCancelSunbscriptionModalWindow';
import {UpdateResponseModalWindow} from './UpdateResponseModalWindow.';

export const PlanDetails = ({
	paymentDetails,
	getAddonDetails,
	cancelConfirmationModal,
	cancelConfirmation,
	cancelSubscription,
	handleCancel,
	handleOk,
	cancelSubscriptionResponseMessage,
	isSubscriptionCancelled,
	reActiveConfirmation
}) => {
	return (
		<div className=''>
			<PlanDetailsHeader paymentDetails={paymentDetails} />
			<RenderPlanDetailsTableWrapper
				paymentDetails={paymentDetails}
				getAddonDetails={getAddonDetails}
				cancelConfirmation={cancelConfirmation}
				reActiveConfirmation={reActiveConfirmation}
			/>
			<RenderCancelSubscriptionModalWindow
				handleCancel={handleCancel}
				cancelConfirmationModal={cancelConfirmationModal}
				handleOk={handleOk}
				cancelSubscription={cancelSubscription}
			/>
			<UpdateResponseModalWindow
				ownerUpdatedMessage={isSubscriptionCancelled}
				handleCancel={handleCancel}
				handleOk={handleOk}
				ownerUpdateResponseMessage={
					cancelSubscriptionResponseMessage
				}
			/>
		</div>
	);
};
