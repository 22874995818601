import AppDispatcher from '../../../flux/dispatcher/dispatcher';
import Constant from '../constants/GetHistogramConstant';
import restClient from '../../MainFrame/action/APIClient';
import LogoutConstant from '../../MainFrame/constants/Constants';
import {urlName} from '../../../config'

// import AppDispatcher from 'dispatcher/dispatcher';
// import Constant from '../constants/GetHistogramConstant';
// import restClient from 'components/MainFrame/action/APIClient';
// import LogoutConstant from 'components/MainFrame/constants/Constants';
var moment = require('moment');
var organization, country, locality, area, profileId;
var dateRange = {}, filters = {};

var PieChartAction = function () {

}

PieChartAction.prototype = {

	addFilters: function (data) {
		filters = data;
		this.loadData();
	},

	changeProfile: function (value) {
		profileId = value;
		this.loadData();
	},

	clearFilters: function () {
		filters = {};
	},

	changeOrganisation: function (value) {
		organization = value;
		this.loadData();
	},

	addDates: function (fromDate, toDate) {
		dateRange.from = fromDate;
		dateRange.to = toDate;
		this.loadData();
	},

	addDownloadFilters: function (data) {
		if (data.profileId) {
			profileId = data.profileId;
			delete data.profileId
		}
		// if(data.granularity){
		// 	granularity = data.granularity;
		// 	// delete data.granularity;
		// }
		filters = data;
		this.loadData();
	},

	addCountry: function (value) {
		country = value;
		locality = undefined;
		area = undefined;
		this.loadData()
	},

	addArea: function (value) {
		area = value;
		locality = undefined;
		this.loadData();
	},

	addLocality: function (value) {
		locality = value;
		this.loadData();
	},

	loadData: function () {

		if (!(filters.hasOwnProperty('dateRange'))) {
			dateRange.from = moment().subtract(16, 'week').format('YYYY-MM-DD');
			dateRange.to = moment().format('YYYY-MM-DD');
			filters['dateRange'] = dateRange;
		}

		var data = {
			profileId: localStorage.getItem('profileId'),
			sources: filters.sources != null ? filters.sources : [],
			filters: filters,
			domainName:urlName
		};
		if (localStorage.getItem('masterLocationFilter') === 'true') {
			data['masterLocationFilter'] = 'true';
		} else {
			data['masterLocationFilter'] = 'false';
		}

		restClient.getAggregatedRatingCount(data, function (error, response) {
			if (error) {
				if (error.status === 401) {
					AppDispatcher.dispatch({
						actionType: LogoutConstant.UNAUTHORIZED_EXCEPTION,
						data: error
					});
				}
			} else {
				AppDispatcher.dispatch({
					actionType: Constant.GET_PIE_CHART,
					data: response
				});
			}
		})
	}
}

export default new PieChartAction();