import Dispatcher from '../../../flux/dispatcher/dispatcher';
import Constants from "../constants/LoginConstant"
var EventEmitter = require('events').EventEmitter;
var assign = require('object-assign');
var RESPONSE_CHANGE_EVENT = 'globalResponse';

var microsoftLink;
function parseResponse(resp) {
    // console.log("mic link", resp);
    microsoftLink = resp;
}

var MicrosoftLinkStore = assign({}, EventEmitter.prototype, {
    emitChangeEvent: function (event) {
        this.emit(event);
    },
    bind: function (callback) {
        this.on(RESPONSE_CHANGE_EVENT, callback);
    },
    unbind: function (callback) {
        this.removeListener(RESPONSE_CHANGE_EVENT, callback);
    },
    getResponse: function () {
        return microsoftLink;
    }
});

Dispatcher.register(function (action) {
    switch (action.actionType) {
        case Constants.MICROSOFT_LINK_RECEIVED:
            var resp = action.data;
            parseResponse(resp)
            MicrosoftLinkStore.emitChangeEvent(RESPONSE_CHANGE_EVENT);
            break;
        default:
    }
});

export default MicrosoftLinkStore;