import React from 'react';
import {LCButton,LCModal} from '../../../frameWork';
const CANCEL_SUBSCRIPTION_CONFIRM='Yes, cancel my subscription';
const CANCEL_SBSCRIPTION_CANCEL='No thanks!';
const CANCEL_SUNBSCRIPTION_CONFIRM_MESSAGE='Are you sure you want to cancel your'+
' subscription for this profile? If you do, you'+
' will be able to use this profile only until'+
' the end of this subscription term.';
export const RenderCancelSubscriptionModalWindow=({
    handleCancel,
    cancelConfirmationModal,
    handleOk,
    cancelSubscription
})=>{
    return (
        <LCModal
					title='Are you sure'
					width={400}
					visible={cancelConfirmationModal}
					onOk={handleOk}
					onCancel={handleCancel}
					footer={
						<div>
							<LCButton
								type='primary'
								onClick={cancelSubscription}
								buttonlabel={CANCEL_SUBSCRIPTION_CONFIRM}
							/>
							<LCButton onClick={handleCancel}
								buttonlabel={CANCEL_SBSCRIPTION_CANCEL}
							/>
						</div>
					}
					modalContent={<div>
						{CANCEL_SUNBSCRIPTION_CONFIRM_MESSAGE}
					</div>}
				/>
    )
}