import React  from "react";
import { Button, Input,Select, Switch } from "antd";
import style from '../../../../../components/ProgressiveModalWindow/style.module.scss';
import { PROFILE_SET_UP, REPORTS_NOTICES } from "../../../../../components/ProgressiveModalWindow/Constants";
import lcICon from '../../../../../../public/images/lcicon.png';
import './style.scss'

export const ReportNotices=(props)=>{
    return(
      <div className='body-section'>
        
        <div className='profile-details'>
            <ul>
                <li className="ai-content">
                    <div className="label-section">
            <h3>{REPORTS_NOTICES.FIRST_HEADER}</h3>
            <p>{REPORTS_NOTICES.FIRST_HEADER_SUB}</p>
            </div>
            <div className="switch-element">
            <Switch onChange={(e)=>props.onChangeCreteProfileDetails('dailyReviewSummary', e)} checked={props.props.dailyReviewSummary} checkedChildren="On" unCheckedChildren="Off"/>
            </div>
                </li >
                <li className="ai-content">
                    <div className="label-section">
            <h3>{REPORTS_NOTICES.SECOND_HEADER}</h3>
            <p>{REPORTS_NOTICES.SECOND_HEADER_SUB}</p>
            </div>
            <div className="switch-element">
            <Switch onChange={(e)=>props.onChangeCreteProfileDetails('weeklyReviewSummary', e)} checked={props.props.weeklyReviewSummary} checkedChildren="On" unCheckedChildren="Off"/>
            </div>                </li>
                <li className="ai-content">
                    <div className="label-section">
            <h3>{REPORTS_NOTICES.THIRD_HEADER}</h3>
            <p>{REPORTS_NOTICES.THIRD_HEADER_SUB} <span>Warning, large accounts can see significant email volume.</span></p>
            </div>
            <div className="switch-element">
            <Switch onChange={(e)=>props.onChangeCreteProfileDetails('realTimeGoogleReviewNotification', e)} checked={props.props.realTimeGoogleReviewNotification} checkedChildren="On" unCheckedChildren="Off"/>
            </div>                </li>
                <li className="ai-content">
                    <div className="label-section">
            <h3>{REPORTS_NOTICES.FOURTH_HEADER}</h3>
            <p>{REPORTS_NOTICES.FOURTH_HEADER_SUB} <span>Warning, large accounts can see significant email volume.</span></p>
            </div>
            <div className="switch-element">
            <Switch onChange={(e)=>props.onChangeCreteProfileDetails('realTimeGoogleMediaNotification', e)} checked={props.props.realTimeGoogleMediaNotification} checkedChildren="On" unCheckedChildren="Off"/>
            </div>                </li>
        </ul>
        </div>
        </div>
    )
}
