import React from 'react';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import './index.scss';
export const CustomScrollbar = (props) => {
	return (
		<SimpleBar forceVisible='y' autoHide={true} {...props}>
			{props.children}
		</SimpleBar>
	);
};
export default CustomScrollbar;
