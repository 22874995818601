import React from 'react';
var languageFile = require('../../../../public/languages/Translation.js');

export const RenderInviteUSerHint = () => {
	return (
		<div className='hint-text-portfolio'>
			<span>
			{languageFile[localStorage.getItem('language')]['28171']}
			</span>
		</div>
	);
};
