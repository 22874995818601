import AppDispatcher from '../../../../flux/dispatcher/dispatcher';
import Constant from '../constants/constant';

var PushNotifiaction = function () {

}

PushNotifiaction.prototype = {

	pushNotification (notificationType, text) {

		var data = {
			notificationType: notificationType,
			text: text
		}
		// console.log('dispatching');
		try {
			setTimeout(function () {
				AppDispatcher.dispatch({
					actionType: Constant.PUSH_NOTIFICATION,
					data: data
				});
			}, 3)
		} catch (error) {
			// console.log('catched error : ', error);
			setTimeout(function () {
				AppDispatcher.dispatch({
					actionType: Constant.PUSH_NOTIFICATION,
					data: data
				});
			}, 10)
		}
	}
}

export default new PushNotifiaction();