var keyMirror = require("keymirror");

module.exports = keyMirror({
    USAGE_INFO_RECEIVED: null,
    USER_TOKEN_RESPONSE: null,
    ADMIN_PASSWORD_RESET: null,
    TSV_UPLOADED: null,
    YELP_TSV_UPLOADED: null,
    PHOTO_URL: null,
    CREATE_PLAN: null,
    CUSTOMER_PHOTO_URL: null,
    PLAN_DETAILS_INFO: null,
    DELETE_PLAN_DETAILS_INFO: null,
    EDIT_PLAN_DETAILS_INFO: null,
    ALL_DOMAIN_LIST: null,
    REFRESH_USAGE_INFO: null,
    VERIFY_USER_EMAIL: null,
    GET_ACTIVE_LOCATION_COUNT: null,
    GET_BILLING_LOCATION_CSV: null,
    CONNECT_DATA_STUDIO: null,
    GET_DATA_STUDIO_STATUS: null,
    ASSIGN_TIER: null,
    BLACK_LIST_USER: null,
    DELETE_USER: null,
    PAYMENT_STATUS: null,
    UPLOAD_BULK_FILES: null,
    GET_FILE_UPLOAD_HISTORY: null,
    GET_FILTER_TAG_CSV: null,
    UPDATE_USER_DOMAIN: null,
    UPDATE_MANAGE_PERMISSION: null,
    UPDATE_USER_NAME: null,
    GET_DYNAMIC_SEARCH:null,
    GET_PROFILES_ADMIN:null,
    ADD_WORKSPACE_PROFILE: null,
    GET_WORKSPACE_TABLE: null,
    ADD_WORKSPACE_OWNER: null,
    DELETE_WORKSPACE_PROFILE: null,
    REFRESH_PROFILE_LOCATION: null,
    UPDATE_2FA_PERMISSION: null
});
