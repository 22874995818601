import React, { Component } from 'react';
import style from './style.module.scss';
import { Button, message, notification } from 'antd';
import { ProgressiveModalWindow } from '../../components/ProgressiveModalWindow';
import { RenderModalForm } from '../AuthorizePage/children/ProfilePage/children/RenderModalForm';
import { urlName } from '../../config';
import UploadProfileIconAction from '../AuthorizePage/action/UploadProfileIconAction';
import cookie from '../../utils/cookie';
import AddProfileAction from '../AuthorizePage/action/AddProfileAction';
import AddProfileStore from '../AuthorizePage/store/AddProfileStore';
import UploadProfileIconStore from '../AuthorizePage/store/UploadProfileIconStore';
import GetProfileDetailsAction from '../AuthorisePage/action/GetProfileDetailsAction';
import { domainNames } from '../../utils/domainNameList';
var analytics = window.analytics;

export default class BuildProfile extends Component {
    constructor() {
        super();
        this.state = {
            showProfileModal: false,
            domainLogo: 'https://lc-whitelabel-images-prod-dev.s3-us-west-2.amazonaws.com/images/localhost%3A3000/domainIcon',
            profileData: [],
            profileLoader: false,
            profileId: '',
            showDeleteConfirm: false,
            showAddOptions: false,
            adminPayment: false,
            newProfile: '',
            profileErrorMessage: '',
            newProfileLoading: false,
            selectedProfile: [],
            isOverlayVisible: false,
            overlayLoader: true,
            total: 0,
            current: 1,
            size: 20,
            nextStep: 1,
            businessIndustry: [],
            profileName: '',
            locationVolume: [{ label: '1 location', value: '1' }, { label: '2 - 5 locations', value: '2-5' }, { label: '6 - 20 locations', value: '6-20' }, { label: '20 - 100 locations', value: '20-100' }, { label: '100 - 500 locations', value: '100-500' }, { label: '500+ locations', value: '500+' }],
            selectedBusiness: undefined,
            selectedLocationVolume: undefined,
            newProfileLanguage: 'english',
            selectedLanguage: 'english',
            selectedDateFormat: "MM/DD/YYYY",
            selectedTimeFormat: 'h:mm',
            listingSheduledDay: [],
            selectedTimeStamp: 'UTC',
            validationInpuClass: '',
            validationSelectClass: '',
            selectedTimeZone: '+00:00',
            fileList: [],
            profileIconURL: 'https://lc-whitelabel-images-prod-dev.s3-us-west-2.amazonaws.com/images/localhost%3A3000/domainIcon',
            uploadIconLoader: false,
            dailyReviewSummary: false,
            weeklyReviewSummary: false,
            realTimeGoogleReviewNotification: false,
            realTimeGoogleMediaNotification: false,
            identifyLanguage: false,
            sentimentAnalysisEnable: false,
            setSentimentLanguage: false,
            defaultSentimentLanguage: 'English',
            reviewGenderTagging: false,
            trackCompetitorReviews: false,
            inviterUser: [{ role: 'READ_ONLY', invitees: '', error: false }, { role: 'READ_ONLY', invitees: '', error: false }, { role: 'READ_ONLY', invitees: '', error: false }],
            inviteMessage: '',
            customGenerativeAIToneVoice: false

        }
    }
    componentDidMount() {
        AddProfileStore.bind(this.onAddingProfile);
        UploadProfileIconStore.bind(this.uploadIconResponse)
    }
    componentWillUnmount() {
        AddProfileStore.unbind(this.onAddingProfile);
        UploadProfileIconStore.unbind(this.uploadIconResponse)
    }
    uploadIconResponse = () => {
        if (UploadProfileIconStore.getResponse().status == 200) {
            let profileIconURL = UploadProfileIconStore.getResponse().url
            this.setState({ profileIconURL })
        }
        this.setState({ uploadIconLoader: false })

    }
    handleCancel = (e) => {
        this.setState({
            userEmail: '',
            newProfile: '',
            showInvite: false,
            showAddOptions: false,
            showAddProtfolioOptions: false,
            showAccounts: false,
            showDeletePortfolioConfirm: false,
            showDeleteConfirm: false,
            noPermissionModal: false,
            noPermissionPortfolioModal: false,
            profileName: '',
            selectedBusiness: undefined,
            selectedLocationVolume: undefined,
            selectedLanguage: undefined,
            selectedDateFormat: "MM/DD/YYYY",
            selectedTimeFormat: 'h:mm',
            listingSheduledDay: [],
            selectedTimeStamp: 'utc',
            validationInpuClass: '',
            validationSelectClass: '',
            selectedTimeZone: '+00:00',
            fileList: [],
            profileIconURL: 'https://lc-whitelabel-images-prod-dev.s3-us-west-2.amazonaws.com/images/localhost%3A3000/domainIcon',
            uploadIconLoader: false,
            dailyReviewSummary: false,
            weeklyReviewSummary: false,
            realTimeGoogleReviewNotification: false,
            realTimeGoogleMediaNotification: false,
            identifyLanguage: false,
            sentimentAnalysisEnable: false,
            setSentimentLanguage: false,
            defaultSentimentLanguage: 'English',
            reviewGenderTagging: false,
            trackCompetitorReviews: false,
            inviterUser: [{ role: 'READ_ONLY', invitees: '' }, { role: 'READ_ONLY', invitees: '' }, { role: 'READ_ONLY', invitees: '' }],
            inviteMessage: '',
            nextStep: 1,
            newProfileLoading: false,


        });
    }
    addAccountMessage = () => {
        notification.open({
            message: 'Great! Your profile is created.',
            description:
                'Now lets connect your Google My Business account to this profile.',
            duration: 5,
        });
    }

    onAddingProfile = () => {
        if (AddProfileStore.getResponse().status === 409) {
            message.warning("You already have a profile with this name!", 2)
        }
        else if (AddProfileStore.getResponse().status === 403) {
            message.error("User doesn't have sufficient permission", 2)
            this.setState({
                showAddOptions: false,
                newProfile: ''
            })
        }
        else if(AddProfileStore.getResponse().status === 200) {
            this.handleCancel();
            analytics.track('Profile Creation Completed', { profile_name: AddProfileStore.getResponse().user.profile.profileName });
            this.addAccountMessage();
            localStorage.setItem(
                'profileId',
                AddProfileStore.getResponse().user.profile.profileId
            );
            localStorage.setItem(
                'profileName',
                AddProfileStore.getResponse().user.profile.profileName
            );
            localStorage.setItem(
                'masterLocationFilter',
                AddProfileStore.getResponse().user.profile.masterLocationFilter || 'false'
            );
            this.props.history.push('/settings/addAccount');
            this.setState({
                showAddOptions: false,
                newProfile: '',
                profileLoader: true
            });
            GetProfileDetailsAction.getProfiles({ from: 0, size: 20, searchText: '' });
        }
        else{
            message.error(AddProfileStore.getResponse().message)
        }
        this.setState({
            newProfileLoading: false, showAddOptions: false
        })
    }
    showModal = () => {
        this.setState({ showProfileModal: true })
    }
    onCancelModal = () => {
        this.setState({ showProfileModal: false })
        this.handleCancel();

    }
    changeInvities = (index, value) => {
        let { inviterUser } = this.state;
        inviterUser.map((item, ind) => {
            if (index == ind) {
                item.role = value;
            }
        })

        this.setState(inviterUser)

    }
    handleProfileIconChange = (fileList) => {
        this.setState({ uploadIconLoader: true })
        const formData = new FormData();
        formData.append('image', fileList.file);
        formData.append('imageType', 'profileIcon')
        formData.append('domainName', urlName)
        UploadProfileIconAction.upload(formData)
    }
    handleProfileIconChange = (fileList) => {
        this.setState({ uploadIconLoader: true })
        const formData = new FormData();
        formData.append('image', fileList.file);
        formData.append('imageType', 'profileIcon')
        formData.append('domainName', urlName)
        UploadProfileIconAction.upload(formData)
    }
    onChangeCreteProfileDetails = (field, value, index) => {
        if (field == 'profileName') {
            if (value.length > 20) {
                message.error('Profile names can only have a maximum of 20 characters');
            }
            else this.setState({ [field]: value, validationInpuClass: '' })
        }
        else if (field == 'selectedLanguage') {
            if (value.length > 0) {
                this.setState({ validationSelectClass: '', [field]: value })
            }
        }
        else if (field == 'inviteEmail') {
            let { inviterUser } = this.state;
            let pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            inviterUser.map((item, ind) => {
                if (ind == index) {
                    if (pattern.test(value) && item.error || item.error && value == '') {
                        item.error = false;
                    }
                    item.invitees = value
                }

            })
            this.setState({ inviterUser })
        }
        else { this.setState({ [field]: value }) }
    }
    addNewProfile = () => {
        this.setState({ newProfileLoading: true })
        let data = {};
        data['profileName'] = this.state.profileName;
        data.creatorId = cookie.readCookie('userId');
        data.domainName = urlName;
        data.locationVolume = this.state.selectedLocationVolume;
        data.businessIndustry = this.state.selectedBusiness;
        data.profileSettings = {
            profileIcon: this.state.profileIconURL,
            defaultLanguage: this.state.selectedLanguage,
            reviewTimeStamp: {

                timeFormat: this.state.selectedTimeStamp
            },
            dateFormat: {
                label: this.state.selectedDateFormat,
                value: this.state.selectedDateFormat
            },
            timeZone: this.state.selectedTimeZone,
            timeFormat: this.state.selectedTimeFormat,
            reportSettings: {
                dailyReviewSummary: this.state.dailyReviewSummary,
                weeklyReviewSummary: this.state.weeklyReviewSummary,
                realTimeGoogleReviewNotification: this.state.realTimeGoogleReviewNotification,
                realTimeGoogleMediaNotification: this.state.realTimeGoogleMediaNotification
            },
            hasLanguageDetection: this.state.identifyLanguage,
            hasSentimentAnalysis: this.state.sentimentAnalysisEnable,
            hasSentimentDetectionLanguage: this.state.sentimentAnalysisEnable ? this.state.setSentimentLanguage : 'false',
            sentimentDetectionLanguage: this.state.setSentimentLanguage ? this.state.defaultSentimentLanguage : '',
            customGenerativeAIToneVoice: this.state.customGenerativeAIToneVoice,
            reviewGenderTagging: this.state.reviewGenderTagging,
            competitorReviews: this.state.trackCompetitorReviews


        }
        data.userInviteList = [];
        this.state.inviterUser.map((items) => {
            if (items.invitees.length > 0) {
                data.userInviteList.push({ role: items.role, invitee: items.invitees })
            }
        })
        data.message = this.state.inviteMessage;
        data.accountId = cookie.readCookie('currentAccountId')

        AddProfileAction.addProfile(data);
    }

    nextStepButtonHandler = () => {
        let validation = false;
        if (this.state.nextStep == 1) {
            if (this.state.profileName.length == 0) {
                validation = true;
                this.setState({ validationInpuClass: 'invalid' })
            }
        }
        if (this.state.nextStep == 2) {
            if (this.state.selectedLanguage == undefined) {
                validation = true;
                this.setState({ validationSelectClass: 'invalid' })
            }
        }
        if (this.state.nextStep == 5) {

            let { inviterUser } = this.state;
            let pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            inviterUser.map((items) => {
                if (items.invitees.length > 0 && !pattern.test(items.invitees)) {
                    items.error = true;
                    validation = true;
                }
            })
            this.setState({ inviterUser })
        }
        if (this.state.nextStep !== 6 && !validation) {
            this.setState({ nextStep: this.state.nextStep + 1 })
        }

    }
    previousStepButtonHandler = () => {
        if (this.state.nextStep !== 1) {
            this.setState({ nextStep: this.state.nextStep - 1 })
        }
    }

    render() {
        return (
            <div className={style.buildPageWrapper}>
                <div className={style.navigationBlock}>
                    <div className={style.homeSection}>
                        <img src='https://lc-whitelabel-images-prod-dev.s3-us-west-2.amazonaws.com/images/localhost%3A3000/domainIcon' />
                    </div>
                </div>
                <div className={style.bodyBlock}>
                    {!this.state.showProfileModal && <> <div className={style.header}>
                        <h1>
                            Great, your Workspace has been created.

                        </h1>
                    </div>
                        <div className={style.bodyContent}>
                            Now that you have created a Workspace, it's time to fill it with the Profiles that will organize all of your locations, users, and integrations.
                        </div>
                        <div className={style.footer}>
                            <Button onClick={this.showModal} className='primary-btn btn-padding-lg larger-button'>Build Profile</Button>
                        </div></>
                    }
                    <ProgressiveModalWindow
                        width={1100}
                        visible={this.state.showProfileModal}
                        onCancel={this.onCancelModal}
                        children=
                        {<RenderModalForm
                            onCancel={this.onCancelModal}
                            domainLogo={this.state.domainLogo}
                            addNewProfile={this.addNewProfile}
                            changeInvities={this.changeInvities}
                            handleProfileIconChange={this.handleProfileIconChange}
                            onChangeCreteProfileDetails={this.onChangeCreteProfileDetails}
                            state={this.state}
                            header={domainNames[urlName]}
                            nextStep={this.state.nextStep}
                            nextStepButtonHandler={this.nextStepButtonHandler}
                            previousStepButtonHandler={this.previousStepButtonHandler}

                        />}
                    />
                </div>
            </div>
        )
    }
}
