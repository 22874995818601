import AppDispatcher from '../../../flux/dispatcher/dispatcher';
import Constant from '../constant/Constant';
import restClient from '../../../routers/MainFrame/action/APIClient';

var UpdateLocationEditAction = function () {

}

UpdateLocationEditAction.prototype = {

	updateLocationEdit: function (data) {
		restClient.updateLocationEdit(data, function (error, response) {
			if (error) {
				// console.log("error", error);
				AppDispatcher.dispatch({
					actionType: Constant.UPDATE_LOCATION_EDIT,
					data: error
				});
			} else if(response != null) {
				response['status'] = 200;
				AppDispatcher.dispatch({
					actionType: Constant.UPDATE_LOCATION_EDIT,
					data: response
				});
			}
		})
	}
}

export default new UpdateLocationEditAction();