import AppDispatcher from '../../../flux/dispatcher/dispatcher';
import Constant from '../constant/AuthoriseAccountConstant';
import restClient from '../../MainFrame/action/APIClient';

var UploadProfileIconAction = function () {

}


UploadProfileIconAction.prototype = {

	upload: function (data) {
		restClient.uploadWhitelabelImage(data, function (error, response) {
			if (error) {
				AppDispatcher.dispatch({
					actionType: Constant.UPLOAD_PROFILE_ICON,
					data: error
				});
			} else {
				response.status = 200;
				AppDispatcher.dispatch({
					actionType: Constant.UPLOAD_PROFILE_ICON,
					data: response
				});
			}
		})
	}
}


export default new UploadProfileIconAction();