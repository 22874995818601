import React from 'react';
import { ResponsiveContainer, Bar, Line, XAxis, YAxis, ComposedChart, Tooltip, Legend } from 'recharts';
import InsightGroupBarchartAction from './action/BarchartAction'
import InsightGroupBarchartStore from './store/BarchartStore'
import InsightGroupBarchartCustomerStore from './store/InsightGroupBarchartCustomerStore'
import languageFile from "../../../../public/languages/Translation";
var moment = require('moment');

class InsightGroupBarchart extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			loadingCustomer: true,
			graphData:[],
			graphDatacustomer:[]
		}
		this.getData = this.getData.bind(this)
		this.onGraphResponse = this.onGraphResponse.bind(this)
		this.onGraphResponseCustomer = this.onGraphResponseCustomer.bind(this)
		this.getDatacustomer = this.getDatacustomer.bind(this)

	}

	componentWillMount() {
		InsightGroupBarchartStore.bind(this.onGraphResponse);
		InsightGroupBarchartCustomerStore.bind(this.onGraphResponseCustomer);
	}
	componentDidMount() {
		this.getData()
		this.getDatacustomer()
	}
	getData() {
		var hashMap = { 'PHOTOS_COUNT_MERCHANT': languageFile[localStorage.getItem('language')]["13141"], 'PHOTOS_VIEWS_MERCHANT': languageFile[localStorage.getItem('language')]["13144"], 'date': 'name' };
		if (InsightGroupBarchartStore.getResponse()) {
			var data = InsightGroupBarchartStore.getResponse().histogram.map(function (item, index) {
				var singleItem = {};
				for (var i in item) {
					var value = item[i];
					if (i === 'date') {
						value = moment(value).format('ll')
					} else {
						if (value === 0) {
							value = null;
						}
					}
					singleItem[hashMap[i]] = value;
				}
				return singleItem;
			})
		}
		this.setState({
			graphData: data,
			loading: false
		})
	}
	getDatacustomer() {
		var hashMap = { 'date': 'name', 'PHOTOS_COUNT_CUSTOMERS': languageFile[localStorage.getItem('language')]["13141"], 'PHOTOS_VIEWS_CUSTOMERS': languageFile[localStorage.getItem('language')]["13144"] };
		if (InsightGroupBarchartCustomerStore.getResponse()) {
			var data = InsightGroupBarchartCustomerStore.getResponse().histogram.map(function (item, index) {
				var singleItem = {};
				for (var i in item) {
					var value = item[i];
					if (i === 'date') {
						value = moment(value).format('ll')
					} else {
						if (value === 0) {
							value = null;
						}
					}
					singleItem[hashMap[i]] = value;
				}
				return singleItem;
			})
		}
		this.setState({
			graphDatacustomer: data,
			loadingCustomer: false
		})
	}
	componentWillUnmount() {
		InsightGroupBarchartStore.unbind(this.onGraphResponse);
		InsightGroupBarchartCustomerStore.unbind(this.onGraphResponseCustomer);
		// InsightGroupBarchartAction.clearFilters();
	}

	onGraphResponse() {
		this.getData();
	}
	onGraphResponseCustomer() {
		this.getDatacustomer();
	}

	formatterFunction(val) {
		// console.log("val is", val)
	}

	render() {
		if (this.state.loading && this.state.loadingCustomer) {
			return (
				<div className="loader-main">
					<div className="loader"></div>
				</div>
			)
		}
		if (this.state.graphData && this.state.graphData.length === 0) {
			return <div className="loader-main nightmare-check-stacked-line">{languageFile[localStorage.getItem('language')]["23191"]}</div>
		}
		if (this.props.merchantChart === 'merchant') {
			return (
				<span className='nightmare-check-stacked-line'>
					<ResponsiveContainer width="100%" height="100%">
						{/* <BarChart width={600} height={300} data={this.state.graphData}
					margin={{top: 20, right: 30, left: 20, bottom: 5}}> */}
						<ComposedChart width={600} height={400} data={this.state.graphData}
							margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
							<XAxis dataKey="name" />
							<YAxis yAxisId="right" orientation="right" stroke="#8884d8" />
							<YAxis yAxisId="left" orientation="left" stroke="#82ca9d" />
							{/* <CartesianGrid strokeDasharray="3 3"/> */}
							<Tooltip />
							<Legend />
							{/* <Bar dataKey="Photo Count"  fill="#8884d8" />
					<Bar dataKey="Photo Views"  fill="#82ca9d" /> */}
							<Bar yAxisId="left" dataKey={languageFile[localStorage.getItem('language')]["13141"]} fill="#82ca9d" />
							<Line isAnimationActive={false} yAxisId="right" type="monotone" dataKey={languageFile[localStorage.getItem('language')]["13144"]} stroke="#8884d8" strokeWidth={2} />
							{/* <Bar yAxisId="right" dataKey="Photo Views"  fill="#82ca9d" /> */}
						</ComposedChart>
					</ResponsiveContainer>
				</span>
			)
		}
		if (this.state.graphDatacustomer && this.state.graphDatacustomer.length === 0) {
			return <div className="loader-main nightmare-check-stacked-line">{languageFile[localStorage.getItem('language')]["23191"]}</div>
		}
		if (this.props.customerChart === 'customer') {
			return (
				<span className='nightmare-check-stacked-line'>
					<ResponsiveContainer width="100%" height="100%">
						{/* <BarChart width={600} height={300} data={this.state.graphData}
					margin={{top: 20, right: 30, left: 20, bottom: 5}}> */}
						<ComposedChart width={600} height={400} data={this.state.graphDatacustomer}
							margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
							<XAxis dataKey="name" />
							<YAxis yAxisId="right" orientation="right" stroke="#8884d8" />
							<YAxis yAxisId="left" orientation="left" stroke="#82ca9d" />
							{/* <CartesianGrid strokeDasharray="3 3"/> */}
							<Tooltip />
							<Legend />
							{/* <Bar dataKey="Photo Count"  fill="#8884d8" />
					<Bar dataKey="Photo Views"  fill="#82ca9d" /> */}
							<Bar yAxisId="left" dataKey={languageFile[localStorage.getItem('language')]["13141"]} fill="#82ca9d" />
							<Line isAnimationActive={false} yAxisId="right" type="monotone" dataKey={languageFile[localStorage.getItem('language')]["13144"]} stroke="#8884d8" strokeWidth={2} />
							{/* <Bar yAxisId="right" dataKey="Photo Views"  fill="#82ca9d" /> */}
						</ComposedChart>
					</ResponsiveContainer>
				</span>
			)
		}
	}
}

export default InsightGroupBarchart;