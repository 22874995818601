import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themesAnimated from "@amcharts/amcharts4/themes/animated";
import { Amchartmenu } from '../../routers/Dashboard/children/ChartDownload';
import _ from 'lodash';
import languageFile from '../../../public/languages/Translation';
import {reviewTranslationMap} from '../../../src/utils/reviewStarTranslation';
am4core.useTheme(am4themesAnimated);

class CommentRatioChart extends Component {
    formatData = (graphData) => {
        let formattedData = [];
        _.map(graphData, function (item, key) {
            formattedData.push({
                "starRating": item.starRating,
                "key": item.starRating == "total" ? 0 : item.starRating,
                "starRatingCount": item.starRating == "total" ? reviewTranslationMap["Total"] : reviewTranslationMap[item.starRating + " star"],
                "withComment": _.round((item.withComment / item.total) * 100, 1) || 0,
                "withoutComments": _.round((item.withoutComment / item.total) * 100, 1) || 0,
                "withoutComment": -(item.withoutComment / item.total) * 100 || 0
            })
        })
        let orderedData = formattedData.sort((a, b) => b.key - a.key)
        return orderedData
    }
    componentDidMount() {

        var commentRationChart = am4core.create("commentratiodiv", am4charts.XYChart);
        commentRationChart.data = this.formatData(this.props.chartData);

        // Use only absolute numbers
        commentRationChart.numberFormatter.numberFormat = "#.s";

        // Create axes
        var categoryAxis = commentRationChart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "starRatingCount";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.inversed = true;
        categoryAxis.tooltip.disabled = true;

        //   hide grid and show axis line
        categoryAxis.renderer.grid.template.disabled = true;
        categoryAxis.renderer.line.strokeOpacity = 1;
        categoryAxis.renderer.line.strokeWidth = 1;
        categoryAxis.renderer.line.stroke = "#8c8c8c";
        categoryAxis.renderer.ticks.template.disabled = false;
        categoryAxis.renderer.ticks.template.strokeOpacity = 1;
        categoryAxis.renderer.ticks.template.stroke = am4core.color("#495C43");
        categoryAxis.renderer.ticks.template.strokeWidth = 2;
        categoryAxis.renderer.ticks.template.length = 7;
        categoryAxis.renderer.minGridDistance = 20;
        // categoryAxis.renderer.labels.template.adapter.add("text", function (text) {
        //     return text == "total" ? text : text + "-Star";
        // })

        var valueAxis = commentRationChart.xAxes.push(new am4charts.ValueAxis());

        valueAxis.renderer.grid.template.disabled = true;
        valueAxis.tooltip.disabled = true;
        valueAxis.renderer.line.strokeOpacity = 1;
        valueAxis.renderer.line.strokeWidth = 1;
        valueAxis.renderer.line.stroke = "#8c8c8c";
        valueAxis.renderer.ticks.template.disabled = false;
        valueAxis.renderer.ticks.template.strokeOpacity = 1;
        valueAxis.renderer.ticks.template.stroke = am4core.color("#495C43");
        valueAxis.renderer.ticks.template.strokeWidth = 2;
        valueAxis.renderer.ticks.template.length = 7;

        valueAxis.extraMin = 0.1;
        valueAxis.extraMax = 0.1;
        valueAxis.renderer.minGridDistance = 40;
        valueAxis.renderer.ticks.template.length = 5;
        valueAxis.renderer.ticks.template.disabled = false;
        valueAxis.renderer.ticks.template.strokeOpacity = 0.4;
        // valueAxis.max=100;
        // valueAxis.min= -100;
        // valueAxis.strictMinMax = true;
        valueAxis.renderer.labels.template.adapter.add("text", function (text) {
            return text == "With comments" || text == "Without comments" ? text : text + "%";
        })

        // Create series
        var withComment = commentRationChart.series.push(new am4charts.ColumnSeries());
        withComment.dataFields.valueX = "withComment";
        withComment.dataFields.categoryY = "starRatingCount";
        withComment.tooltipText = '{categoryY}: {valueX.formatNumber("#.#s")}%[/]';
        withComment.clustered = false;

        var withCommentLabel = withComment.bullets.push(new am4charts.LabelBullet());
        withCommentLabel.label.text = "{valueX}%";
        withCommentLabel.label.hideOversized = false;
        withCommentLabel.label.truncate = false;
        // withCommentLabel.label.horizontalCenter = "right";
        withCommentLabel.label.dx = 20;

        var withoutComment = commentRationChart.series.push(new am4charts.ColumnSeries());
        withoutComment.dataFields.valueX = "withoutComment";
        withoutComment.dataFields.categoryY = "starRatingCount";
        withoutComment.tooltipText = '{categoryY}: {valueX.formatNumber("#.#s")}%[/]';
        withoutComment.clustered = false;

        var withoutCommentLabel = withoutComment.bullets.push(new am4charts.LabelBullet());
        withoutCommentLabel.label.text = "{valueX}%";
        withoutCommentLabel.label.hideOversized = false;
        withoutCommentLabel.label.truncate = false;
        // withoutCommentLabel.label.horizontalCenter = "left";
        withoutCommentLabel.label.dx = -15;

        var withCommentRange = valueAxis.axisRanges.create();
        withCommentRange.value = 0;
        withCommentRange.endValue = 100;
        withCommentRange.label.text = languageFile[localStorage.getItem('language')]["22671"];
        withCommentRange.label.fill = commentRationChart.colors.list[0];
        withCommentRange.label.dy = 20;
        withCommentRange.label.dx = 5;
        withCommentRange.label.fontWeight = '600';
        withCommentRange.grid.strokeOpacity = 0;
        withCommentRange.grid.stroke = withComment.stroke;

        var withoutCommentRange = valueAxis.axisRanges.create();
        withoutCommentRange.value = -100;
        withoutCommentRange.endValue = 0;
        withoutCommentRange.label.text = languageFile[localStorage.getItem('language')]["22661"];
        withoutCommentRange.label.fill = commentRationChart.colors.list[1];
        withoutCommentRange.label.dy = 20;
        withoutCommentRange.label.dx = -5;
        withoutCommentRange.label.fontWeight = '600';
        withoutCommentRange.grid.strokeOpacity = 0;
        withoutCommentRange.grid.stroke = withoutComment.stroke;

        if (this.props.showDownLoad) {
            commentRationChart.exporting.menu = new am4core.ExportMenu();
            commentRationChart.exporting.menu.items = _.cloneDeep(Amchartmenu);
            commentRationChart.exporting.timeoutDelay = 8000;
            commentRationChart.exporting.dataFields = { "starRating": "star Rating", "withComment": "with Comment", "withoutComments": "without Comments" };
            commentRationChart.exporting.filePrefix = "commentRatio";
        }


        commentRationChart.cursor = new am4charts.XYCursor();
        // commentRationChart.logo.disabled = true;
        this.commentRationChart = commentRationChart
    }
    componentDidUpdate(oldProps) {
        if (oldProps.chartData !== this.props.chartData) {
            this.commentRationChart.data = this.formatData(this.props.chartData);
        }
    }
    render() {
        return (
            <div id="commentratiodiv" style={{ width: "100%", height: "100%" }}></div>
        )
    }
}
export default CommentRatioChart