import AppDispatcher from '../../../flux/dispatcher/dispatcher';
import Constant from '../constant/AuthoriseAccountConstant';
import LogoutConstant from '../../MainFrame/constants/Constants';
import restClient from '../../MainFrame/action/APIClient';
import { message } from 'antd';
// var cookie = require("public/js/cookie")

var AddProfileAction = function () {

}

AddProfileAction.prototype = {

	addProfile: function (data) {
		restClient.addProfile(data, function (error, response) {
			if (error) {
				if (error.status === 401) {
					AppDispatcher.dispatch({
						actionType: LogoutConstant.UNAUTHORIZED_EXCEPTION,
						data: error
					});
				} else {
					message.error(error.data.message)
					AppDispatcher.dispatch({
						actionType: Constant.ADD_PROFILE_ERROR,
						data: error
					});
				}
			} else if(response !=null ) {
				response['status'] = 200;
				AppDispatcher.dispatch({
					actionType: Constant.ADD_PROFILE_RESPONSE,
					data: response
				});
			}
		})
	}
}

export default new AddProfileAction();