import Dispatcher from '../../../flux/dispatcher/dispatcher';
// var Dispatcher = require('dispatcher/dispatcher');
import Constants from '../constant/ResetPasswordRequestConstant';
var EventEmitter = require('events').EventEmitter;
// var Constants = require('../constant/ResetPasswordRequestConstant');
var assign = require('object-assign');
var RESPONSE_CHANGE_EVENT = 'globalResponse';

var response = {};
function parseResponse(resp) {
    if (resp && resp.message && resp.message === "Verification email sent") {
        response = true;
    } else {
        response = false;
    }
}

var ResetPasswordRequestStore = assign({}, EventEmitter.prototype, {
    emitChangeEvent: function (event) {
        this.emit(event);
    },
    bind: function (callback) {
        this.on(RESPONSE_CHANGE_EVENT, callback);
    },
    unbind: function (callback) {
        this.removeListener(RESPONSE_CHANGE_EVENT, callback);
    },
    getResetResponse: function () {
        return response
    }
});

Dispatcher.register(function (action) {
    switch (action.actionType) {
        case Constants.PASSWORD_RESET_REQUEST:
            var resp = action.data;
            parseResponse(resp)
            // console.log("resetpassword store......", resp)
            ResetPasswordRequestStore.emitChangeEvent(RESPONSE_CHANGE_EVENT);
            break;
            
        default:
    }
});

export default ResetPasswordRequestStore;