import React from 'react';
import StackedBarChart from '../../components/sharedComponets/StackedBarChart';
import StackedAreaChart from '../../components/sharedComponets/StackedAreaChart';
import EmptyStates from '../../components/sharedComponets/EmptyStates';
import StackedAreaChartAction from '../../components/sharedComponets/StackedAreaChart/action/StackedAreaChartAction';
import StackedAreaChartCopyAction from '../../components/sharedComponets/StackedAreaChartCopy/action/StackedAreaChartAction';
import LineChartComponent from '../../components/sharedComponets/LineChartComponent';
import LineChartAction from '../../components/sharedComponets/LineChartComponent/action/LineChartAction';
// import DoughnutChart from 'components/DoughnutChart';
import ActionsTypeCountAction from '../Dashboard/action/ActionsTypeCountAction';
import ActionsTypeCountStore from '../Dashboard/store/ActionsTypeCountStore';
import QueriesTypeCountAction from '../Dashboard/action/QueriesTypeCountAction';
import QueriesTypeCountStore from '../Dashboard/store/QueriesTypeCountStore';
import ViewsTypeCountAction from '../Dashboard/action/ViewsTypeCountAction';
import ViewsTypeCountStore from '../Dashboard/store/ViewsTypeCountStore';
import GetHistogramAction from '../Dashboard/action/GetReviewHistogramAction';
import GetHistogramStore from '../Dashboard/store/GetHistogramStore';
import GetOrganisationsAction from '../ReviewDesk/action/GetOrganisationsAction';
import GetOrganisationsStore from '../ReviewDesk/store/GetOrganisationsStore';
import {
	ResponsiveContainer,
	ComposedChart,
	Line,
	Bar,
	XAxis,
	YAxis,
	Tooltip,
	Legend,
} from 'recharts';
import { LocaleProvider, DatePicker } from 'antd';
import FunnelChartAction from '../Dashboard/action/PieChartAction';
import PieChartStore from '../Dashboard/store/PieChartStore';
import GetSuggestionsAction from '../Dashboard/action/GetSuggestionAction';
import GetSuggestionsStore from '../Dashboard/store/GetSuggestionsStore';
import RatingBar from '../../components/sharedComponets/RatingBar';
import TreemapAction from '../Dashboard/action/TreemapAction';
import TreemapStore from '../Dashboard/store/TreemapStore';
import GetLocationsStatesAction from '../Dashboard/action/GetLocationStatesAction';
import GetLocationsStatesStore from '../Dashboard/store/GetLocationStateStore';
import GetLocationInsightAction from '../Dashboard/action/GetLocationInsightAction';
import GetLocationInsightStore from '../Dashboard/store/GetLocationInsightStore';
import StackedBarChartAction from '../../components/sharedComponets/StackedBarChart/action/StackedBarChartAction';
import LoadingStatusStore from '../Dashboard/store/LoadingStatusStore';
import GetProfilesAction from '../AuthorisePage/action/GetProfilesAction';
import GetProfilesStore from '../AuthorisePage/store/GetProfilesStore';
import ReactStars from 'react-stars';
import ChangeProfileStore from '../Dashboard/store/ChangeProfileStore';
import GetDataStatusAction from '../Dashboard/action/GetDataStatusAction';
import GetDataStatusStore from '../Dashboard/store/GetDataStatusStore';
// import CustomLegend from 'components/CustomLegend';
import GetDataFetchStatusAction from '../Dashboard/action/GetDataFetchStatusAction';
import GetDataFetchStatusStore from '../Dashboard/store/GetDataFetchStatusStore';
import GetReviewsCountAction from '../Dashboard/action/GetReviewsCountAction';
import GetReviewsCountStore from '../Dashboard/store/GetReviewsCountStore';
import TopLocationsAction from '../Dashboard/action/TopLocationsAction';
import TopLocationsStore from '../Dashboard/store/TopLocationsStore';
import TopLocationsWidget from '../../components/sharedComponets/TopLocationsWidget';
import InsightsGroupBarChart from '../../components/sharedComponets/InsightsGroupBarChart/';
import InsightGroupBarchartAction from '../../components/sharedComponets/InsightsGroupBarChart/action/BarchartAction';
import PhotosTypeCountAction from '../Dashboard/action/PhotosTypeCountAction';
import PhotosTypeCountStore from '../Dashboard/store/PhotosTypeCountStore';
import PhotosTypeCountCustomerStore from '../Dashboard/store/PhotosTypeCountCustomerStore';
import GetResponsesByRatingAction from '../Dashboard/action/GetRepliesByRatingAction';
import GetResponsesByRatingStore from '../Dashboard/store/GetRepliesByRatingStore';
import ReviewSourceChart from '../../components/sharedComponets/ReviewSourceChart';
import HappinessRatingWidget from '../../components/sharedComponets/HappinessRatingWidget';
import LocalHistogramComponent from '../../components/sharedComponets/LocalHistogramComponent';
import LocalHistogramAction from '../../components/sharedComponets/LocalHistogramComponent/action/LocalHistogramAction';
import LocalHistogramStore from '../../components/sharedComponets/LocalHistogramComponent/store/LocalHistogramStore';
import DateWiseReviewHistoAction from '../Dashboard/action/DateWiseReviewHistoAction';
import DateWiseHistogramStore from '../Dashboard/store/DateWiseHistogramStore';
import CommentsCountAction from '../Dashboard/action/CommentsCountAction';
import CommentsCountStore from '../Dashboard/store/CommentsCountStore';
import CommentWordCountAction from '../Dashboard/action/CommentWordCountAction';
import CommentWordCountStore from '../Dashboard/store/CommentWordCountStore';
import DatewiseInsightHistoAction from '../Dashboard/action/DatewiseInsightHistoAction';
import DatewiseInsightHistoStore from '../Dashboard/store/DatewiseInsightHistoStore';
import '../../../public/css/print.css';
import './style.scss';
import PieChartAm from '../../components/sharedComponets/PieChartAm';
import CommentRatioChart from '../../components/CommentRatioChart';
import CommentWordCountChart from '../../components/CommentWordCountChart';
import CommentLengthChart from '../../components/CommentLengthChart';
import QuadrantAnalysicsChart1 from '../DownloadAllLocationChart';

import QuadrantAnalysicsChart2 from '../DownloadRegionChart';
import QuadrantAnalysicsChart3 from '../DownloadCityChart';
import GetLocationChartAction from '../QuadrantAnalysis/action/GetLocationChartAction';
import GetLocationChartStore from '../QuadrantAnalysis/store/GetLocationChartStore';
import GetRegionChartAction from '../QuadrantAnalysis/action/GetRegionChartAction';
import GetRegionChartStore from '../QuadrantAnalysis/store/GetRegionChartStore';
import GetCityChartAction from '../QuadrantAnalysis/action/GetCityChartAction';
import GetCityChartStore from '../QuadrantAnalysis/store/GetCityChartStore';
import StackedAreaChartCopy from '../../components/sharedComponets/StackedAreaChartCopy';

var config = require('../../../globalconfig');
var cookie = require('../../utils/cookie');
var languageFile = require('../../../public/languages/Translation');
var _ = require('lodash');
var moment = require('moment');
const { MonthPicker, RangePicker } = DatePicker;
const COLORS = [
	{ starRating: 1, color: '#f89673' },
	{ starRating: 2, color: '#fdbd62' },
	{ starRating: 3, color: '#dadfe6' },
	{ starRating: 4, color: '#7ad7a3' },
	{ starRating: 5, color: '#6bcbee' },
];
var intervalFunction;
const fieldsMap = {
	profileName: 'Profile',
	country: 'Country',
	area: 'State',
	locality: 'Administrative area',
	granularity: 'Granularity',
	fromDate: 'from',
	toDate: 'to',
	starType: 'Star rating',
	starValue: '',
	organizationName: 'Account name',
	gt: 'Greater than',
	lt: 'Less than',
	equals: 'Equals',
	GT: 'Greater than',
	LT: 'Less than',
	EQUALS: 'Equals',
	reviewCategory: 'Review Category',
	locationFilterName: 'Group',
	jobType: 'Report',
};

class Dashboard extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			//locationChart: true,
			loading: true,
			pieChartLoading: true,
			publishedLocations: 0,
			verifiedLocations: 0,
			openInfo: {},
			queriesPiedata: {},
			actionsPieData: {},
			viewsPiedata: {},
			viewsLoading: true,
			queriesLoading: true,
			actionsLoading: true,
			searchesCount: 0,
			viewsCount: 0,
			chart: [],
			locationId: [],
			country: '',
			administrativeArea: '',
			city: '',
			locality: '',
			locationName: '',
			master: false,
			currentGranularity: { value: 'weekly', label: 'Weekly' },
			actionsCount: 0,
			dateRange: {
				fromDate: moment().subtract(16, 'week').format('YYYY-MM-DD'),
				toDate: moment().format('YYYY-MM-DD'),
			},
			date: {
				from: moment().subtract(16, 'week').format('YYYY-MM-DD'),
				to: moment().format('YYYY-MM-DD'),
			},
		};
		this.parseUrl = this.parseUrl.bind(this);
		this.onReviewsCountResponse = this.onReviewsCountResponse.bind(this);
		this.topLocationsResponse = this.topLocationsResponse.bind(this);
		this.LocalHistogram = this.LocalHistogram.bind(this);
		this.onDateWiseResponse = this.onDateWiseResponse.bind(this);
		this.onDatewiseActionCount = this.onDatewiseActionCount.bind(this);
		this.onFetchStatus = this.onFetchStatus.bind(this);
		this.onStatusResponse = this.onStatusResponse.bind(this);
		this.onProfileChange = this.onProfileChange.bind(this);
		this.onLoadChange = this.onLoadChange.bind(this);
		this.onPhotosCountResponse = this.onPhotosCountResponse.bind(this);
		this.onPhotosCountResponseCustomer =
			this.onPhotosCountResponseCustomer.bind(this);
		this.onActionsCountResponse = this.onActionsCountResponse.bind(this);
		this.onViewsCountResponse = this.onViewsCountResponse.bind(this);
		this.onQueriesCountResponse = this.onQueriesCountResponse.bind(this);
		this.onInsightsResponse = this.onInsightsResponse.bind(this);
		this.onLocationStates = this.onLocationStates.bind(this);
		this.onRepliesByStarRating = this.onRepliesByStarRating.bind(this);
		this.pieChartResponse = this.pieChartResponse.bind(this);
		this.onGraphResponse = this.onGraphResponse.bind(this);
		this.generateReportFilters = this.generateReportFilters.bind(this);
		this.generateReportHeader = this.generateReportHeader.bind(this);
		this.getData = this.getData.bind(this);
		this.ChangeFormateDate = this.ChangeFormateDate.bind(this);
		this.ChangeDivider = this.ChangeDivider.bind(this);
		//this.getLocationChartStore = this.getLocationChartStore.bind(this);
	}

	componentWillMount() {
		//GetLocationChartStore.bind(this.getLocationChartStore);

		GetDataStatusStore.bind(this.onStatusResponse);
		GetHistogramStore.bind(this.onGraphResponse);
		PieChartStore.bind(this.pieChartResponse);
		GetResponsesByRatingStore.bind(this.onRepliesByStarRating);
		GetLocationsStatesStore.bind(this.onLocationStates);
		GetLocationInsightStore.bind(this.onInsightsResponse);
		ActionsTypeCountStore.bind(this.onActionsCountResponse);
		ViewsTypeCountStore.bind(this.onViewsCountResponse);
		PhotosTypeCountStore.bind(this.onPhotosCountResponse);
		PhotosTypeCountCustomerStore.bind(this.onPhotosCountResponseCustomer);
		QueriesTypeCountStore.bind(this.onQueriesCountResponse);
		LoadingStatusStore.bind(this.onLoadChange);
		GetProfilesStore.bind(this.onProfilesResponse);
		ChangeProfileStore.bind(this.onProfileChange);
		GetDataFetchStatusStore.bind(this.onFetchStatus);
		GetReviewsCountStore.bind(this.onReviewsCountResponse);
		TopLocationsStore.bind(this.topLocationsResponse);
		LocalHistogramStore.bind(this.LocalHistogram);
		DateWiseHistogramStore.bind(this.onDateWiseResponse);
		CommentsCountStore.bind(this.onWithAndWithoutCommentsCount);
		CommentWordCountStore.bind(this.onCommentLengthResponse);
		DatewiseInsightHistoStore.bind(this.onDatewiseActionCount);
		this.jobType = null;
	}
	parseUrl(queryParams) {
		// console.log(" query is ", queryParams)
		var filtersObject = {};
		var fields = queryParams.split('&');
		// console.log("query2", fields)
		for (var i in fields) {
			if (fields[i].split('=')[1]) {
				if (fields[i].split('=')[0] == 'jobType') {
					this.jobType = decodeURIComponent(fields[i].split('=')[1]);
				}
				if (
					fields[i].split('=')[0] == 'fromDate' ||
					fields[i].split('=')[0] == 'toDate'
				) {
					if (!filtersObject.hasOwnProperty('reviewFilters')) {
						filtersObject.reviewFilters = {};
						filtersObject.reviewFilters.dateRange = {};
						filtersObject.reviewFilters.date = {};
					}
					if (fields[i].split('=')[0] == 'fromDate') {
						filtersObject.reviewFilters.dateRange.from = decodeURIComponent(
							fields[i].split('=')[1]
						);
						filtersObject.reviewFilters.date.from = decodeURIComponent(
							fields[i].split('=')[1]
						);
					}
					if (fields[i].split('=')[0] == 'toDate') {
						filtersObject.reviewFilters.dateRange.to = decodeURIComponent(
							fields[i].split('=')[1]
						);
						filtersObject.reviewFilters.date.to = decodeURIComponent(
							fields[i].split('=')[1]
						);
					}
				}
				if (
					fields[i].split('=')[0] == 'starType' ||
					fields[i].split('=')[0] == 'starValue'
				) {
					if (!filtersObject.hasOwnProperty('reviewFilters')) {
						filtersObject.reviewFilters = {};
					} else if (
						!filtersObject.reviewFilters.hasOwnProperty('starRatingFilter')
					) {
						filtersObject.reviewFilters.starRatingFilter = {};
					}
					if (fields[i].split('=')[0] == 'starType') {
						filtersObject.reviewFilters.starRatingFilter['type'] =
							decodeURIComponent(fields[i].split('=')[1]);
					}
					if (fields[i].split('=')[0] == 'starValue') {
						filtersObject.reviewFilters.starRatingFilter['value'] =
							decodeURIComponent(fields[i].split('=')[1]);
					}
				}
				//adding reviewCategory to the request body
				//by Liju Kuriakose
				if (fields[i].split('=')[0] == 'reviewCategory') {
					if (!filtersObject.hasOwnProperty('reviewFilters')) {
						filtersObject.reviewFilters = {};
					}
					if (fields[i].split('=')[0] == 'reviewCategory') {
						filtersObject.reviewFilters.reviewCategory = decodeURIComponent(
							fields[i].split('=')[1]
						);
					}
				}
				if (fields[i].split('=')[0] == 'locationFilterId') {
					filtersObject['locationFilterId'] = decodeURIComponent(
						fields[i].split('=')[1]
					);
				}
				//
				if (
					fields[i].split('=')[0] == 'country' ||
					fields[i].split('=')[0] == 'area' ||
					fields[i].split('=')[0] == 'locality' ||
					fields[i].split('=')[0] == 'locationName' ||
					fields[i].split('=')[0] == 'administrativeArea' ||
					fields[i].split('=')[0] == 'city'
				) {
					if (!filtersObject.hasOwnProperty('locationFilters')) {
						filtersObject.locationFilters = {};
					}
				}
				if (fields[i].split('=')[0] == 'country') {
					filtersObject.locationFilters.country = decodeURIComponent(
						fields[i].split('=')[1]
					);
					this.setState({
						country: filtersObject.locationFilters.country,
						locationId: [],

						//master:filtersObject.masterLocationFilter
					});
				}
				if (fields[i].split('=')[0] == 'administrativeArea') {
					filtersObject.locationFilters.administrativeArea = decodeURIComponent(
						fields[i].split('=')[1]
					);
					this.setState({
						administrativeArea:
							filtersObject.locationFilters.administrativeArea,
						locationId: [],

						//master:filtersObject.masterLocationFilter
					});
				}
				if (fields[i].split('=')[0] == 'city') {
					filtersObject.locationFilters.city = decodeURIComponent(
						fields[i].split('=')[1]
					);
					this.setState({
						city: filtersObject.locationFilters.city,
						locationId: [],

						//master:filtersObject.masterLocationFilter
					});
				}
				if (fields[i].split('=')[0] == 'area') {
					filtersObject.locationFilters.area = decodeURIComponent(
						fields[i].split('=')[1]
					);
					this.setState({
						administrativeArea: filtersObject.locationFilters.area,
						locationId: [],

						//master:filtersObject.masterLocationFilter
					});
				}
				if (fields[i].split('=')[0] == 'locality') {
					filtersObject.locationFilters.locality = decodeURIComponent(
						fields[i].split('=')[1]
					);
					this.setState({
						locality: filtersObject.locationFilters.locality,
						city: filtersObject.locationFilters.locality,
						locationId: [],
						//master:filtersObject.masterLocationFilter
					});
				}
				if (fields[i].split('=')[0] == 'locationName') {
					filtersObject.locationFilters.locationName = decodeURIComponent(
						fields[i].split('=')[1]
					);
					this.setState({
						locationId: [],
						locationName: filtersObject.locationFilters.locationName,
						//master:filtersObject.masterLocationFilter
					});
				}
				if (fields[i].split('=')[0] == 'masterLocationFilter') {
					filtersObject.masterLocationFilter = decodeURIComponent(
						fields[i].split('=')[1]
					);
					this.setState({
						master: filtersObject.masterLocationFilter,
						//master:filtersObject.masterLocationFilter
					});
				}
				// if (fields[i].split('=')[0] == 'locationId') {
				// 	filtersObject.locationFilters.locationId = decodeURIComponent(fields[i].split('=')[1])
				// 	this.setState({
				// 		locationId: [filtersObject.locationFilters.locationId],
				// 		country:"",
				// 		locality:""
				// 		//master:filtersObject.masterLocationFilter
				// 	})
				// }
				if (fields[i].split('=')[0] == 'searchText') {
					filtersObject.reviewFilters.searchText = decodeURIComponent(
						fields[i].split('=')[1]
					);
				}

				filtersObject[fields[i].split('=')[0]] = decodeURIComponent(
					fields[i].split('=')[1]
				);
				//by Liju Kuriakose
				if (fields[i].split('=')[0] == 'organizations') {
					filtersObject.organizations = [
						decodeURIComponent(fields[i].split('=')[1]),
					];
				}
				if (fields[i].split('=')[0] == 'sources') {
					let sorceList = decodeURIComponent(fields[i].split('=')[1]);
					filtersObject.sources = sorceList.split(',').map((item) => {
						return item.replace('[', '').replace(']', '');
					});
				}
			}
		}
		filtersObject.dateRange = {
			from: filtersObject.fromDate,
			to: filtersObject.toDate,
		};
		this.setState({
			date: { from: filtersObject.fromDate, to: filtersObject.toDate },
		});
		cookie.createCookie('lctoken', filtersObject.token, 2);
		localStorage.setItem('profileId', filtersObject.profileId);
		localStorage.setItem(
			'masterLocationFilter',
			filtersObject.masterLocationFilter
		);
		localStorage.setItem('filters', filtersObject);
		localStorage.setItem('language', filtersObject.language);
		delete filtersObject['token'];
		if (filtersObject.language == 'portuguese') {
			moment.locale('pt');
		} else if (filtersObject.language == 'english') {
			moment.locale('en');
		} else if (filtersObject.language == 'german') {
			moment.locale('de');
		} else if (filtersObject.language == 'spanish') {
			moment.locale('es');
		} else if (filtersObject.language == 'french') {
			moment.locale('fr');
		} else if (filtersObject.language == '日本語') {
			moment.locale('ja');
		}
		return filtersObject;
	}
	componentDidMount() {
		//GetOrganisationsAction.getOrgs();
		var filters = this.parseUrl(this.props.match.params.query);

		StackedAreaChartAction.addDownloadFilters(filters);
		StackedAreaChartCopyAction.addDownloadFilters(filters);
		InsightGroupBarchartAction.addDownloadFilters(filters);
		LineChartAction.addDownloadFilters(filters);
		LocalHistogramAction.addDownloadFilters(filters);
		ActionsTypeCountAction.addDownloadFilters(filters);
		QueriesTypeCountAction.addDownloadFilters(filters);
		ViewsTypeCountAction.addDownloadFilters(filters);
		PhotosTypeCountAction.addDownloadFilters(filters);
		FunnelChartAction.addDownloadFilters(filters);
		GetResponsesByRatingAction.addDownloadFilters(filters);
		GetLocationsStatesAction.addDownloadFilters(filters);
		StackedBarChartAction.addDownloadFilters(filters);
		GetReviewsCountAction.addDownloadFilters(filters);
		GetHistogramAction.addDownloadFilters(filters);
		DateWiseReviewHistoAction.addDownloadFilters(filters);
		CommentsCountAction.addDownloadFilters(filters);
		CommentWordCountAction.addDownloadFilters(filters);
		DatewiseInsightHistoAction.addDownloadFilters(filters);
		GetLocationInsightAction.addDownloadFilters(filters);
		TopLocationsAction.addDownloadFilters(filters);
	}
	componentWillUnmount() {
		//	GetLocationChartStore.unbind(this.getLocationChartStore);
		GetDataStatusStore.unbind(this.onStatusResponse);
		GetHistogramStore.unbind(this.onGraphResponse);
		PieChartStore.unbind(this.pieChartResponse);
		GetResponsesByRatingStore.unbind(this.onRepliesByStarRating);
		GetLocationsStatesStore.unbind(this.onLocationStates);
		GetLocationInsightStore.unbind(this.onInsightsResponse);
		ActionsTypeCountStore.unbind(this.onActionsCountResponse);
		ViewsTypeCountStore.unbind(this.onViewsCountResponse);
		QueriesTypeCountStore.unbind(this.onQueriesCountResponse);
		LoadingStatusStore.unbind(this.onLoadChange);
		PhotosTypeCountStore.unbind(this.onPhotosCountResponse);
		PhotosTypeCountCustomerStore.unbind(this.onPhotosCountResponseCustomer);
		GetProfilesStore.unbind(this.onProfilesResponse);
		ChangeProfileStore.unbind(this.onProfileChange);
		GetHistogramAction.clearFilters();
		DateWiseReviewHistoAction.clearFilters();
		CommentsCountAction.clearFilters();
		CommentWordCountAction.clearFilters();
		DatewiseInsightHistoAction.clearFilters();
		GetDataFetchStatusStore.unbind(this.onFetchStatus);
		GetReviewsCountStore.unbind(this.onReviewsCountResponse);
		TopLocationsStore.unbind(this.topLocationsResponse);
		LocalHistogramStore.unbind(this.LocalHistogram);
		DateWiseHistogramStore.unbind(this.onDateWiseResponse);
		CommentsCountStore.unbind(this.onWithAndWithoutCommentsCount);
		CommentWordCountStore.unbind(this.onCommentLengthResponse);
		DatewiseInsightHistoStore.unbind(this.onDatewiseActionCount);
		InsightGroupBarchartAction.clearFilters();
		clearInterval(intervalFunction);
	}
	// getLocationChartStore = () => {
	// 	if(GetLocationChartStore.getResponse())
	// 	this.setState({
	// 		locationChart:false,
	// 		chart:GetLocationChartStore.getResponse()
	// 	})
	// }
	onWithAndWithoutCommentsCount = () => {
		this.setState({
			commentRatioData: CommentsCountStore.getResponse(),
		});
	};
	onCommentLengthResponse = () => {
		this.setState({
			commentLengthData: CommentWordCountStore.getResponse(),
		});
	};
	onReviewsCountResponse() {
		// console.log("count resp", GetReviewsCountStore.getResponse());
		let reviewsAggregateData = {};
		if (!_.isEmpty(GetReviewsCountStore.getResponse())) {
			for (var item in GetReviewsCountStore.getResponse()) {
				if (item !== 'totalReviews' && item !== 'avgReviews') {
					reviewsAggregateData[item] = GetReviewsCountStore.getResponse()[item];
				}
			}
		}
		this.setState({
			totalReviewsCount: GetReviewsCountStore.getResponse().totalReviews,
			avgReviewsCount:
				Math.round(GetReviewsCountStore.getResponse().avgReviews * 100) / 100,
			reviewsAggregate: reviewsAggregateData,
		});
	}
	topLocationsResponse() {
		// console.log("top locations data", TopLocationsStore.getResponse())
		this.setState({
			topCountries: TopLocationsStore.getResponse().topCountries,
			topAreas: TopLocationsStore.getResponse().topAreas,
			topLocalities: TopLocationsStore.getResponse().topLocalities,
		});
	}
	LocalHistogram() {
		var localhistogram = LocalHistogramStore.getResponse();
		this.setState({
			LocalHistoStackLabel: localhistogram.metricCount,
		});
	}
	onDateWiseResponse() {
		var weekdaysMap = {
			Sun: languageFile[localStorage.getItem('language')]['20071'],
			Mon: languageFile[localStorage.getItem('language')]['20011'],
			Tue: languageFile[localStorage.getItem('language')]['20021'],
			Wed: languageFile[localStorage.getItem('language')]['20031'],
			Thu: languageFile[localStorage.getItem('language')]['20041'],
			Fri: languageFile[localStorage.getItem('language')]['20051'],
			Sat: languageFile[localStorage.getItem('language')]['20061'],
		};
		var monthNameMap = {
			Jan: languageFile[localStorage.getItem('language')]['20111'],
			Feb: languageFile[localStorage.getItem('language')]['20121'],
			Mar: languageFile[localStorage.getItem('language')]['20131'],
			Apr: languageFile[localStorage.getItem('language')]['20141'],
			May: languageFile[localStorage.getItem('language')]['20151'],
			Jun: languageFile[localStorage.getItem('language')]['20161'],
			Jul: languageFile[localStorage.getItem('language')]['20171'],
			Aug: languageFile[localStorage.getItem('language')]['20181'],
			Sep: languageFile[localStorage.getItem('language')]['20191'],
			Oct: languageFile[localStorage.getItem('language')]['20201'],
			Nov: languageFile[localStorage.getItem('language')]['20211'],
			Dec: languageFile[localStorage.getItem('language')]['20221'],
		};
		var weekdata =
			DateWiseHistogramStore.getResponse().histogram &&
			DateWiseHistogramStore.getResponse()
				.histogram.dayOfWeekHistogram.sort((a, b) => a.value - b.value)
				.map(function (item, index) {
					var weekdayList = {};
					for (var i in item) {
						var value = item[i];
						if (i === 'dayOfWeek') {
							value = weekdaysMap[value];
						}
						weekdayList[i] = value;
					}
					return weekdayList;
				});
		var yeardata =
			DateWiseHistogramStore.getResponse().histogram &&
			DateWiseHistogramStore.getResponse()
				.histogram.monthOfYearHistogram.sort((a, b) => a.value - b.value)
				.map(function (item, index) {
					var yearList = {};
					for (var i in item) {
						var value = item[i];
						if (i === 'monthOfYear') {
							value = monthNameMap[value];
						}
						yearList[i] = value;
					}
					return yearList;
				});
		this.setState({
			monthHisto:
				DateWiseHistogramStore.getResponse().histogram &&
				DateWiseHistogramStore.getResponse().histogram.dayOfMonthHistogram.sort(
					(a, b) => a.dayOfMonth - b.dayOfMonth
				),
			weekHisto: weekdata,
			yearHisto: yeardata,
		});
	}
	onDatewiseActionCount() {
		var hashMap = {
			ACTIONS_DRIVING_DIRECTIONS:
				languageFile[localStorage.getItem('language')]['10792'],
			ACTIONS_PHONE: languageFile[localStorage.getItem('language')]['13132'],
			ACTIONS_WEBSITE: languageFile[localStorage.getItem('language')]['14523'],
			dayOfWeek: 'dayOfWeek',
			value: 'value',
		};
		var weekdaysMap = {
			Sun: languageFile[localStorage.getItem('language')]['20071'],
			Mon: languageFile[localStorage.getItem('language')]['20011'],
			Tue: languageFile[localStorage.getItem('language')]['20021'],
			Wed: languageFile[localStorage.getItem('language')]['20031'],
			Thu: languageFile[localStorage.getItem('language')]['20041'],
			Fri: languageFile[localStorage.getItem('language')]['20051'],
			Sat: languageFile[localStorage.getItem('language')]['20061'],
		};
		if (DatewiseInsightHistoStore.getResponse().histogram) {
			var data = DatewiseInsightHistoStore.getResponse()
				.histogram.dayOfWeekHistogram.sort((a, b) => a.value - b.value)
				.map(function (item, index) {
					var singleItem = {};
					for (var i in item) {
						var value = item[i];
						if (i === 'dayOfWeek') {
							value = weekdaysMap[value];
						}
						singleItem[hashMap[i]] = value;
					}
					return singleItem;
				});
			this.setState({
				DatewiseInsightData: data,
			});
		}
	}
	onFetchStatus() {
		// console.log("GetDataFetchStatusStore", GetDataFetchStatusStore.getResponse())
		var statusData = GetDataFetchStatusStore.getResponse();
		var profileFetching = false;
		for (var account in statusData) {
			if (
				statusData[account].status !== 'COMPLETED' &&
				statusData.status !== 404
			) {
				profileFetching = true;
			}
		}
		if (profileFetching) {
			var fetchingStatusData = [];
			if (
				GetDataFetchStatusStore.getResponse()[0].status === 'FETCHING_LOCATIONS'
			) {
				fetchingStatusData = GetDataFetchStatusStore.getResponse()[0];
				this.setState({
					showInitalizingFetchers: false,
					fetchingStatusData: fetchingStatusData,
					showFetchingLocations: true,
					showFetchingStatus: false,
					loading: false,
				});
			} else if (
				GetDataFetchStatusStore.getResponse()[0].status === 'FETCHING'
			) {
				fetchingStatusData = GetDataFetchStatusStore.getResponse()[0];
				this.setState({
					showInitalizingFetchers: true,
					fetchingStatusData: fetchingStatusData,
					showFetchingLocations: true,
					showFetchingStatus: false,
					loading: false,
				});
			} else {
				fetchingStatusData = GetDataFetchStatusStore.getResponse().map(
					function (item, index) {
						return {
							accountName: item.accountName,
							status: item.status,
							percentageFetched: Math.round(
								this.findFetchStatus(
									item.locationsProcessed,
									item.locationsFetched
								)
							),
						};
					},
					this
				);
				// console.log("fetchingStatusData", fetchingStatusData)
				this.setState({
					showInitalizingFetchers: false,
					fetchingStatusData: fetchingStatusData,
					dataRetreivalStatus: 'FETCHING_REVIEWS_INSIGHTS',
					showFetchingLocations: false,
					showFetchingStatus: true,
				});
			}
			// GetDataFetchStatusAction.getStatus( localStorage.getItem('profileId') );
		} else {
			this.setState({
				showFetchingStatus: false,
			});
		}
	}
	findFetchStatus(locationsProcessed, totalLocations) {
		return (locationsProcessed / totalLocations) * 100;
	}
	onStatusResponse() {
		// console.log("data status resp", GetDataStatusStore.getResponse())
		var statusData = GetDataStatusStore.getResponse().accountFetchStatuses;
		if (
			GetDataStatusStore.getResponse().status === 'NO_ACCOUNTS_AUTHORIZED' &&
			GetDataStatusStore.getResponse().status === 'NO_ACCOUNTS_ACTIVATED'
		) {
			this.setState({
				dataRetreivalStatus: 'NO_ACCOUNTS_AUTHORIZED',
				loading: false,
			});
		} else if (GetDataStatusStore.getResponse().dataAvailability === false) {
			var profileLocationFetching;
			for (var account in statusData) {
				if (statusData[account].status === 'NO_ACCOUNTS_AUTHORIZED') {
					this.setState({
						dataRetreivalStatus: 'NO_ACCOUNTS_AUTHORIZED',
						loading: false,
					});
					break;
				} else if (statusData[account].status === 'NO_ACCOUNTS_ACTIVATED') {
					this.setState({
						dataRetreivalStatus: 'NO_ACCOUNTS_ACTIVATED',
						loading: false,
					});
					break;
				}
				if (statusData[account].status === 'FETCHING_LOCATIONS') {
					profileLocationFetching = 'LocationFetching';
					break;
				} else if (statusData[account].status === 'FETCHING') {
					profileLocationFetching = 'Fetching';
				} else if (statusData[account] === 'QUEUE') {
					profileLocationFetching = 'Queue';
				}
			}
			if (profileLocationFetching === 'LocationFetching') {
				// GetDataFetchStatusAction.getStatus( localStorage.getItem('profileId') );
				this.setState({
					dataRetreivalStatus: 'FETCHING_LOCATIONS_NO_DATA',
					loading: false,
				});
			} else if (profileLocationFetching === 'Fetching') {
				this.setState({
					dataRetreivalStatus: 'FETCHING_NO_DATA',
					loading: false,
				});
			} else {
				this.setState({
					dataRetreivalStatus: 'QUEUE',
					loading: false,
				});
			}
		} else {
			var accountsFetching = false;
			if (
				GetDataStatusStore.getResponse().status === 'NO_ACCOUNTS_AUTHORIZED' ||
				GetDataStatusStore.getResponse().status === 'NO_ACCOUNTS_ACTIVATED'
			) {
				this.props.history.push('/settings/addAccount');
			}
			for (var account in statusData) {
				if (statusData[account].status !== 'COMPLETED') {
					accountsFetching = true;
				}
			}
			if (accountsFetching) {
				// GetDataFetchStatusAction.getStatus( localStorage.getItem('profileId') );
			} else {
				this.setState({
					showFetchingStatus: false,
				});
			}
			// console.log("account status", statusData);

			var profileId = localStorage.getItem('profileId');
			GetHistogramAction.changeProfile(profileId);
			DateWiseReviewHistoAction.changeProfile(profileId);
			CommentsCountAction.changeProfile(profileId);
			CommentWordCountAction.changeProfile(profileId);
			DatewiseInsightHistoAction.changeProfile(profileId);
			FunnelChartAction.changeProfile(profileId);
			GetResponsesByRatingAction.changeProfile(profileId);
			GetSuggestionsAction.getSuggestions({}, profileId);
			GetLocationsStatesAction.changeProfile(profileId);
			GetLocationInsightAction.changeProfile(profileId);
			StackedBarChartAction.changeProfile(profileId);
			StackedAreaChartAction.changeProfile(profileId);
			StackedAreaChartCopyAction.changeProfile(profileId);
			LineChartAction.changeProfile(profileId);
			LocalHistogramAction.changeProfile(profileId);
			InsightGroupBarchartAction.loadData();
			InsightGroupBarchartAction.loadDataCustomer();
			ActionsTypeCountAction.changeProfile(profileId);
			ViewsTypeCountAction.changeProfile(profileId);
			QueriesTypeCountAction.changeProfile(profileId);
			GetReviewsCountAction.changeProfile(profileId);
			this.setState({
				dataRetreivalStatus: GetDataStatusStore.getResponse().status,
			});
		}
	}
	onProfileChange() {
		this.setState({
			loading: true,
		});
		GetDataStatusAction.getStatus(localStorage.getItem('profileId'));
	}
	onProfilesResponse() {
		intervalFunction = setInterval(function () {
			var callDataStatus = false;
			for (var i in GetDataStatusStore.getResponse().accountFetchStatuses) {
				if (
					GetDataStatusStore.getResponse().accountFetchStatuses[i].status !==
					'COMPLETED'
				) {
					callDataStatus = true;
					break;
				}
			}
			if (callDataStatus) {
				GetDataStatusAction.getStatus(localStorage.getItem('profileId'));
			}
		}, 15000);
		GetDataStatusAction.getStatus(localStorage.getItem('profileId'));
		// console.log("pros store", GetProfilesStore.getResponse());
	}
	onLoadChange() {
		this.setState({
			graphLoading: true,
		});
	}
	modifyPieChartData(data, hashMap) {
		var pieChartData = [];
		for (var key in data) {
			pieChartData.push({ name: hashMap[key], value: data[key] });
		}
		return pieChartData;
	}
	onPhotosCountResponse() {
		var hashMap = {
			PHOTOS_COUNT_MERCHANT: 'Photo Count',
			PHOTOS_VIEWS_MERCHANT: 'Photo Views',
			date: 'name',
		};
		var data = this.modifyPieChartData(
			PhotosTypeCountStore.getResponse(),
			hashMap
		);
		this.setState({
			photosPiedata: data,
			photosLoading: false,
		});
	}
	onPhotosCountResponseCustomer() {
		var hashMap = {
			date: 'name',
			PHOTOS_COUNT_CUSTOMERS: 'Photo Count Customer',
			PHOTOS_VIEWS_CUSTOMERS: 'Photo Views Customer',
		};
		var datas = this.modifyPieChartData(
			PhotosTypeCountCustomerStore.getResponse(),
			hashMap
		);
		this.setState({
			photosPiedataCustomer: datas,
			photosLoading: false,
		});
	}
	onActionsCountResponse() {
		var hashMap = {
			ACTIONS_DRIVING_DIRECTIONS: 'Driving Directions',
			ACTIONS_PHONE: 'Phone',
			ACTIONS_WEBSITE: 'Website',
			date: 'date',
		};
		var data = this.modifyPieChartData(
			ActionsTypeCountStore.getResponse(),
			hashMap
		);
		this.setState({
			actionsPieData: data,
			actionsLoading: false,
		});
	}
	onViewsCountResponse() {
		var hashMap = {VIEWS_SEARCH: 'Search',
		VIEWS_MAPS: 'Maps',
		BUSINESS_IMPRESSIONS_DESKTOP_MAPS: 'Maps - Desktop',
		BUSINESS_IMPRESSIONS_MOBILE_MAPS:'Maps - Mobile',
		BUSINESS_IMPRESSIONS_MOBILE_SEARCH:	'Search - Mobile',
		BUSINESS_IMPRESSIONS_DESKTOP_SEARCH: 'Search - Desktop',
		date: 'date',  };
		var data = this.modifyPieChartData(
			ViewsTypeCountStore.getResponse(),
			hashMap
		);
		this.setState({
			viewsPiedata: data,
			viewsLoading: false,
		});
	}
	onQueriesCountResponse() {
		var hashMap = {
			QUERIES_DIRECT: 'Direct Queries',
			QUERIES_INDIRECT: 'Indirect Queries',
			QUERIES_CHAIN: 'Brand',
			date: 'date',
		};
		var data = this.modifyPieChartData(
			QueriesTypeCountStore.getResponse(),
			hashMap
		);
		this.setState({
			queriesPiedata: data,
			queriesLoading: false,
		});
	}
	onInsightsResponse() {
		var insightsData = GetLocationInsightStore.getResponse();
		this.setState({
			searchesCount: insightsData.searchesCount,
			viewsCount: insightsData.viewsCount,
			actionsCount: insightsData.actionsCount,
			searchesToViews: insightsData.searchesToViews,
			viewsToActions: insightsData.viewsToActions,
		});
	}
	onLocationStates() {
		// console.log("locations states response", GetLocationsStatesStore.getResponse())
		this.setState({
			publishedLocations: GetLocationsStatesStore.getResponse().published,
			verifiedLocations: GetLocationsStatesStore.getResponse().unverified,
			openInfo: GetLocationsStatesStore.getResponse().openInfo,
		});
	}
	onRepliesByStarRating() {
		let repliesByRating = [];
		GetResponsesByRatingStore.getResponse().starRatings.forEach(function (
			item
		) {
			item.name = item.starRating + ' Stars';
			item.value = item.count;
			repliesByRating.push(item);
		});
		this.setState({
			repliesByRating: repliesByRating,
			repliesByRatingLoading: false,
		});
	}
	pieChartResponse() {
		var pieData = [];
		PieChartStore.getResponse().starRatings.forEach(function (item) {
			item.name = item.starRating + ' Stars';
			item.value = item.count;
			pieData.push(item);
		});
		this.setState({
			pieData: pieData,
			pieChartLoading: false,
			happinessRating: Math.round(PieChartStore.getResponse().happinessRate),
		});
	}
	dateFormatter(data) {
		var modifiedData = data.map(function (item) {
			for (var i in item) {
				if (i === 'date') {
					item[i] = moment(item[i]).format('ll');
				} else {
					if (item[i] === 0) {
						item[i] = null;
					}
				}
			}
		});
		return modifiedData;
	}
	onGraphResponse() {
		if (GetHistogramStore.getResponse().histogram) {
			var data = this.dateFormatter(GetHistogramStore.getResponse().histogram);
		}
		let avg = GetHistogramStore.getResponse().totalAverage
			? GetHistogramStore.getResponse().totalAverage.toFixed(2)
			: '-';
		this.setState({
			loading: false,
			data: GetHistogramStore.getResponse().histogram,
			avg: avg,
			totalCount: GetHistogramStore.getResponse().totalCount,
		});
	}
	findPercent(label, data) {
		if (data) {
			if (data.length === 0) {
				return 0;
			} else {
				var sum = 0;
				for (var i in data) {
					sum = sum + data[i].value;
				}
				var percent;
				for (var i in data) {
					if (data[i].name === label) {
						percent = (data[i].value / sum) * 100;
					}
				}
				return Math.round(percent * 100) / 100 + '%';
			}
		}
	}
	findNumShortForm(label, data) {
		if (data) {
			var value = _.find(data, function (item) {
				return item.name === label;
			}).value;
			if (value > 999) {
				var suffixes = ['', 'k', 'M', 'B', 'T'];
				var suffixNum = Math.floor(('' + value).length / 3);
				var shortValue = parseFloat(
					(suffixNum != 0
						? value / Math.pow(1000, suffixNum)
						: value
					).toPrecision(2)
				);
				if (shortValue % 1 != 0) {
					var shortNum = shortValue.toFixed(1);
				}
				return shortValue + suffixes[suffixNum];
			} else {
				return value;
			}
		} else {
			return 0;
		}
	}
	findLabelValue(data, label) {
		if (!_.isEmpty(data)) {
			var value =
				_.find(data, function (item) {
					return item.name === label;
				}) &&
				_.find(data, function (item) {
					return item.name === label;
				}).value;
			return value && value.toLocaleString();
		} else {
			return 0;
		}
	}
	getData (filter,userFilters) {
		let spliceData = ''
		let returnData = ''
		spliceData = userFilters.filter(function (item, index) {
			return (item.split('=')[0] === filter);
		});
		returnData = spliceData.length ? spliceData[0].split('=') : ''
		if(returnData && returnData[1]){
			return returnData[1].replace("%26", "&")
		}else{
			return ''
		}
	}
	ChangeFormateDate(oldDate,divider){
	   return oldDate.toString().split("-").reverse().join(divider);
	}
	ChangeDivider(date){
		return date.toString().split("-").join('.');
	 }
	generateReportHeader () {
		let reportHeader = '',headerJoin = '',locationName = '',fromDate = '',toDate = '',domain = '';
		let userFilters = this.props.match.params.query.split('&');
		locationName = this.getData('locationName',userFilters)
		fromDate = this.getData('fromDate',userFilters)
		toDate = this.getData('toDate',userFilters)
		domain = this.getData('domain',userFilters)
		//change date formate
			fromDate = (domain == 'reviews.10thousandfeet.com') ? this.ChangeFormateDate(fromDate,'.') : this.ChangeDivider(fromDate)
			toDate = (domain == 'reviews.10thousandfeet.com') ? this.ChangeFormateDate(toDate,'.') : this.ChangeDivider(toDate)
		//
		if(locationName){
			headerJoin = fromDate + ' - ' + toDate
			reportHeader = <div className='report-header'>
							<span className='header-location'>
					  			{locationName}
							</span>
							<span className='report-filter-capitalize'>
					  			{headerJoin}
							</span>
						</div>
		}
		return reportHeader;
	}
	generateReportFilters() {
		let reportFilters = [],locationName = '',domain = '';
		let userFilters = this.props.match.params.query.split('&');
		locationName = this.getData('locationName',userFilters)
		domain = this.getData('domain',userFilters)
		let dateFilters = userFilters.filter(function (item, index) {
			return (
				item.split('=')[0] === 'fromDate' || item.split('=')[0] === 'toDate'
			);
		});
		let starRatingFilter = userFilters.filter(function (item, index) {
			return (
				item.split('=')[0] === 'starType' || item.split('=')[0] === 'starValue'
			);
		});
		let remainingFilters = userFilters.filter(function (item, index) {
			return (
				item.split('=')[0] !== 'fromDate' &&
				item.split('=')[0] !== 'toDate' &&
				item.split('=')[0] !== 'starType' &&
				item.split('=')[0] !== 'starValue'
			);
		});
		// console.log("dashfilters", dateFilters, remainingFilters)
		function rotate(array, times) {
			while (times--) {
				var temp = array.shift();
				array.push(temp);
			}
		}
		rotate(remainingFilters, 3);
		remainingFilters.map((item, index) => {
			let filter = item.split('=');
			if (fieldsMap.hasOwnProperty(filter[0]) && filter[1] && filter[1]) {
				reportFilters.push(
					<div style={{ display: 'inline-block', marginRight: 5 }}>
						<span className='report-filter-capitalize'>
							{fieldsMap[filter[0]]}:{' '}
						</span>
						<span className='report-filter-capitalize'>
							{
								// (filter[0] == 'starType' ?
								// 	fieldsMap[filter[1]] :
								// 	filter[1]) +
								// (index == this.props.match.params.query.split('&').length - 2 ?
								// 	"" :
								// 	",")
								decodeURIComponent(filter[1]) +
									(index == this.props.match.params.query.split('&').length - 2
										? ''
										: ',')
							}
						</span>
					</div>
				);
			}
		});
		if(!locationName){
			dateFilters.map((item, index) => {
				let filter = item.split('=');
				if (fieldsMap.hasOwnProperty(filter[0]) && filter[1]) {
					reportFilters.push(
						<div style={{ display: 'inline-block', marginRight: 5 }}>
							<span className='report-filter-capitalize'>
								{fieldsMap[filter[0]]}:{' '}
							</span>
							<span className='report-filter-capitalize'>
								{(filter[0] == 'starType' ? fieldsMap[filter[1]] : 
								 (domain == 'reviews.10thousandfeet.com') ? this.ChangeFormateDate(filter[1],'-') : filter[1]) +
									(index == this.props.match.params.query.split('&').length - 2
										? ''
										: ',')}
							</span>
						</div>
					);
				}
			});
			}
		if (starRatingFilter.length == 2) {
			reportFilters.push(
				<div style={{ display: 'inline-block', marginRight: 5 }}>
					<span className='report-filter-capitalize'>
						{fieldsMap[starRatingFilter[0].split('=')[0]]}:{' '}
					</span>
					<span className='report-filter-capitalize'>{`${
						fieldsMap[starRatingFilter[0].split('=')[1]]
					} ${starRatingFilter[1].split('=')[1]}`}</span>
				</div>
			);
		}
		return reportFilters;
	}

	render() {
		// console.log("queriesPiedata", this.state.viewsPiedata)
		var payLod = [
			{
				value: languageFile[localStorage.getItem('language')]['10010'],
				id: '1',
				color: '#f89670',
				type: 'square',
			},
			{
				value: languageFile[localStorage.getItem('language')]['10013'],
				id: '2',
				color: '#fdbd60',
				type: 'square',
			},
			{
				value: languageFile[localStorage.getItem('language')]['10016'],
				id: '3',
				color: '#dadfe4',
				type: 'square',
			},
			{
				value: languageFile[localStorage.getItem('language')]['10019'],
				id: '4',
				color: '#7ad7a1',
				type: 'square',
			},
			{
				value: languageFile[localStorage.getItem('language')]['10022'],
				color: '#6bcbee',
				type: 'square',
				id: '5',
			},
			{
				value: languageFile[localStorage.getItem('language')]['10237'],
				id: 'avg',
				color: '#8884D8',
				type: 'line',
			},
		];
		var url = require('url');
		var adr = window.location.href;
		var parseUrlName = url.parse(adr, true);
		var urlName = parseUrlName.host;
		var qurysum,
		conversionSum,
		dierctCount,
		indirectCount,
		action_phone,
		action_website,
		action_drivingdirection,
		desktopMapsCount = 0,
		desktopSearchCount = 0,
		mobileMapsCount = 0,
		mobileSearchCount = 0,
		viewMapsCount = 0,
		viewSearchCount = 0,
		chainCount;
		_.map(
			this.state.LocalHistoStackLabel && this.state.LocalHistoStackLabel,
			function (item, key) {
				// console.log("calcu", item, key)
				if (item.hasOwnProperty('QUERIES_DIRECT')) {
					dierctCount = item.QUERIES_DIRECT.sum;
				}
				if (item.hasOwnProperty('QUERIES_INDIRECT')) {
					indirectCount = item.QUERIES_INDIRECT.sum;
				}
				if (item.hasOwnProperty('QUERIES_CHAIN')) {
					chainCount = item.QUERIES_CHAIN.sum;
				}
				if (item.hasOwnProperty('ACTIONS_DRIVING_DIRECTIONS')) {
					action_drivingdirection =
						item.ACTIONS_DRIVING_DIRECTIONS.sum;
				}
				if (item.hasOwnProperty('ACTIONS_PHONE')) {
					action_phone = item.ACTIONS_PHONE.sum;
				}
				if (item.hasOwnProperty('ACTIONS_WEBSITE')) {
					action_website = item.ACTIONS_WEBSITE.sum;
				}
				if (item.hasOwnProperty('BUSINESS_IMPRESSIONS_DESKTOP_MAPS')) {
					desktopMapsCount = item.BUSINESS_IMPRESSIONS_DESKTOP_MAPS.sum;
				}
				if (item.hasOwnProperty('BUSINESS_IMPRESSIONS_DESKTOP_SEARCH')) {
					desktopSearchCount = item.BUSINESS_IMPRESSIONS_DESKTOP_SEARCH.sum;
				}
				if (item.hasOwnProperty('BUSINESS_IMPRESSIONS_MOBILE_MAPS')) {
					mobileMapsCount = item.BUSINESS_IMPRESSIONS_MOBILE_MAPS.sum;
				}
				if (item.hasOwnProperty('BUSINESS_IMPRESSIONS_MOBILE_SEARCH')) {
					mobileSearchCount = item.BUSINESS_IMPRESSIONS_MOBILE_SEARCH.sum;
				}
				if (item.hasOwnProperty('VIEWS_MAPS')) {
					viewMapsCount = item.VIEWS_MAPS.sum;
				}
				if (item.hasOwnProperty('VIEWS_SEARCH')) {
					viewSearchCount = item.VIEWS_SEARCH.sum;
				}
			}
		);
		qurysum = dierctCount + indirectCount + chainCount;
		conversionSum = desktopMapsCount + desktopSearchCount + mobileMapsCount+mobileSearchCount+viewMapsCount+viewSearchCount;
		if (this.state.loading) {
			return (
				<div className='loader-main'>
					<div className='loader'></div>
				</div>
			);
		}
		var emptyStates = [
			'QUEUE',
			'NO_ACCOUNTS_AUTHORIZED',
			'NO_ACCOUNTS_ACTIVATED',
			'FETCHING_NO_DATA',
			'FETCHING_LOCATIONS_NO_DATA',
		];

		if (emptyStates.indexOf(this.state.dataRetreivalStatus) != -1) {
			return (
				<EmptyStates
					status={this.state.dataRetreivalStatus}
					fetchingStatusData={this.state.fetchingStatusData}
				/>
			);
		}

		if (this.state.data && this.state.data.length === 0) {
			var noDataFlag = true;
		}

		return (
			<div className='dashboard-outer'>
				<div className='print-header'>
					<div className='print-logo'>
						<img
							src={
								config.logoBaseUrl +
								'/' +
								localStorage.getItem('profileId') +
								'/logo.png?time=' +
								Date.now()
							}
							onError={(e) =>
								(e.target.src = `/images/${urlName}/logo-text.png`)
							}
						/>
					</div>
				</div>
				<div className='dashboard'>
				<div className='report-options'>
					{this.generateReportHeader()}
					{this.generateReportFilters()}
				</div>
					<div className='row'>
						<div className='col-sm-4 col-md-4 col-lg-4 avg-rating-block'>
							<section className='card'>
								<div className='card-header'>
									<h3>
										{languageFile[localStorage.getItem('language')]['13574']}
									</h3>
								</div>
								<article className='card-body reviews-stats'>
									<div className='reviews-values'>
										{this.state.totalReviewsCount || '-'}
									</div>
									<div className='reviews-icon'>
										<span>
											<img src='/images/reviews.png' />
										</span>
									</div>
									<div className='reviews-content'>
										<span>
											{languageFile[localStorage.getItem('language')]['10246']}:
										</span>
										{this.state.avgReviewsCount == 'Infinity' ? (
											<span>0 (No location)</span>
										) : (
											<span>
												{(this.state.avgReviewsCount &&
													this.state.avgReviewsCount.toFixed(1)) ||
													'-'}
											</span>
										)}
									</div>
								</article>
							</section>
						</div>
						<div className='col-sm-4 col-md-4 col-lg-4 avg-rating-block'>
							<section className='card'>
								<div className='card-header'>
									<h3>
										{languageFile[localStorage.getItem('language')]['10243']}
									</h3>
								</div>
								<article className='card-body reviews-stats'>
									<div className='reviews-values'>{this.state.avg}</div>
									<div className='star-rating-block'>
										<ReactStars
											className={!this.state.avg && 'empty_rating'}
											value={this.state.avg}
											count={5}
											edit={false}
											size={30}
											color2={'#6bcbee'}
											color1={'#DEDEDE'}
										/>
									</div>
								</article>
							</section>
						</div>
						<div className='col-sm-4 col-md-4 col-lg-4 avg-rating-block'>
							<section className='card'>
								<div className='card-header'>
									<h3>
										{languageFile[localStorage.getItem('language')]['13577']}
									</h3>
								</div>
								<article className='card-body reviews-stats'>
									<div className='summary-content'>
										{!this.state.pieChartLoading && (
											<RatingBar
												data={this.state.pieData}
												colorMap={COLORS}
												interactive={true}
												dateRange={this.state.dateRange}
												totalReviews={this.state.totalCount}
												flag={false}
											/>
										)}
									</div>
								</article>
							</section>
						</div>
					</div>
					<div className='row'>
						<div className='col-sm-4 col-md-4 col-lg-4'>
							<div className='review_chart__wrapper'>
								{/* <ReviewSourceChart
									data={this.state.reviewsAggregate}
									source="print"
								/> */}
								<PieChartAm
									chartData={this.state.reviewsAggregate}
									id='piechartam'
								/>
							</div>
						</div>
						<div className='col-sm-4 col-md-4 col-lg-4'>
							<HappinessRatingWidget data={this.state.happinessRating} />
						</div>
						<div className='col-sm-4 col-md-4 col-lg-4'>
							<section className='card'>
								<div className='card-header'>
									<h3>
										{languageFile[localStorage.getItem('language')]['13553']}
									</h3>
								</div>
								<article className='card-body reviews-stats'>
									<div className='summary-content'>
										{!this.state.repliesByRatingLoading && (
											<RatingBar
												data={this.state.repliesByRating}
												reviewData={this.state.pieData}
												colorMap={COLORS}
												interactive={false}
												dateRange={this.state.dateRange}
												totalReviews={this.state.totalCount}
												flag={false}
											/>
										)}
									</div>
								</article>
							</section>
						</div>
					</div>
					<div className='row'>
						<div className='col-sm-12 col-md-12 col-lg-12'>
							<section className='card'>
								<div className='card-header'>
									<h3>
										{languageFile[localStorage.getItem('language')]['13580']}
									</h3>
								</div>
								<article className='card-body'>
									<div className='insights-graph'>
										{noDataFlag && (
											<div className='no-data'>
												{' '}
												{
													languageFile[localStorage.getItem('language')][
														'23191'
													]
												}
											</div>
										)}
										<ResponsiveContainer width='100%' height='100%'>
											<ComposedChart
												data={this.state.data}
												margin={{ top: 20, right: 20, bottom: 20, left: 0 }}>
												<XAxis dataKey='date' />
												<YAxis yAxisId={0} orientation='left' />
												<YAxis
													type='number'
													domain={[0, 5]}
													ticks={[0, 1, 2, 3, 4, 5]}
													yAxisId={1}
													orientation='right'
												/>
												<Tooltip />
												<Legend
													align='left'
													wrapperStyle={{ border: '0px' }}
													payload={payLod}
												/>
												<Bar
													dataKey='1'
													yAxisId={0}
													barSize={400}
													fill='#f89670'
													stackId='a'
												/>
												<Bar
													dataKey='2'
													yAxisId={0}
													barSize={400}
													fill='#fdbd60'
													stackId='a'
												/>
												<Bar
													dataKey='3'
													yAxisId={0}
													barSize={400}
													fill='#dadfe4'
													stackId='a'
												/>
												<Bar
													dataKey='4'
													yAxisId={0}
													barSize={400}
													fill='#7ad7a1'
													stackId='a'
												/>
												<Bar
													dataKey='5'
													yAxisId={0}
													barSize={400}
													fill='#6bcbec'
													stackId='a'
												/>
												<Line
													type='monotone'
													yAxisId={1}
													dataKey='avg'
													stroke='#8884D8'
													connectNulls={true}
													strokeWidth={2}
												/>
											</ComposedChart>
										</ResponsiveContainer>
									</div>
								</article>
							</section>
						</div>
					</div>
					<div className='page-break'></div>
					<div className='row'>
						<div className='col-sm-4 col-md-4 col-lg-4'>
							<section className='card'>
								<div className='card-header'>
									<h3>
										{languageFile[localStorage.getItem('language')]['22641']}
									</h3>
								</div>
								<article className='card-body'>
									<div className='insights-graph'>
										{noDataFlag && (
											<div className='no-data'>
												{' '}
												{
													languageFile[localStorage.getItem('language')][
														'23191'
													]
												}
											</div>
										)}
										<CommentRatioChart
											chartData={this.state.commentRatioData}
											showDownLoad={false}
										/>
									</div>
								</article>
							</section>
						</div>
						<div className='col-sm-4 col-md-4 col-lg-4'>
							<section className='card'>
								<div className='card-header'>
									<h3>
										{languageFile[localStorage.getItem('language')]['22681']}
									</h3>
								</div>
								<article className='card-body'>
									<div className='insights-graph'>
										<CommentLengthChart
											chartData={this.state.commentLengthData}
											showDownLoad={false}
										/>
									</div>
								</article>
							</section>
						</div>
						<div className='col-sm-4 col-md-4 col-lg-4'>
							<section className='card'>
								<div className='card-header'>
									<h3>
										{languageFile[localStorage.getItem('language')]['22711']}
									</h3>
								</div>
								<article className='card-body'>
									<div className='insights-graph'>
										<CommentWordCountChart
											chartData={this.state.commentLengthData}
											showDownLoad={false}
										/>
									</div>
								</article>
							</section>
						</div>
					</div>
					<div className='row'>
						<div className='col-sm-4 col-md-4 col-lg-4'>
							<section className='card'>
								<div className='card-header'>
									<h3>
										{' '}
										{languageFile[localStorage.getItem('language')]['13592']}
									</h3>
								</div>
								<article className='card-body'>
									<div className='insights-graph'>
										{noDataFlag && (
											<div className='no-data'>
												{' '}
												{
													languageFile[localStorage.getItem('language')][
														'23191'
													]
												}
											</div>
										)}
										<ResponsiveContainer width='100%' height='100%'>
											<ComposedChart
												data={this.state.weekHisto}
												margin={{ top: 20, right: -30, bottom: 0, left: -30 }}>
												<XAxis dataKey='dayOfWeek' />
												<YAxis yAxisId={0} orientation='left' />
												<YAxis
													type='number'
													domain={[0, 5]}
													ticks={[0, 1, 2, 3, 4, 5]}
													yAxisId={1}
													orientation='right'
												/>
												<Tooltip />
												{/* <Legend /> */}
												<Bar
													dataKey='count'
													yAxisId={0}
													barSize={400}
													fill='#A6E0F5'
												/>
												<Line
													type='monotone'
													yAxisId={1}
													dataKey='avg'
													stroke='#8884D8'
													connectNulls={true}
													strokeWidth={2}
												/>
											</ComposedChart>
										</ResponsiveContainer>
									</div>
								</article>
							</section>
						</div>

						<div className='col-sm-4 col-md-4 col-lg-4'>
							<section className='card'>
								<div className='card-header'>
									<h3>
										{' '}
										{languageFile[localStorage.getItem('language')]['13589']}
									</h3>
								</div>
								<article className='card-body'>
									<div className='insights-graph'>
										{noDataFlag && (
											<div className='no-data'>
												{' '}
												{
													languageFile[localStorage.getItem('language')][
														'23191'
													]
												}
											</div>
										)}
										<ResponsiveContainer width='100%' height='100%'>
											<ComposedChart
												data={this.state.monthHisto}
												margin={{ top: 20, right: -30, bottom: 0, left: -30 }}>
												<XAxis dataKey='dayOfMonth' />
												<YAxis yAxisId={0} orientation='left' />
												<YAxis
													type='number'
													domain={[0, 5]}
													ticks={[0, 1, 2, 3, 4, 5]}
													yAxisId={1}
													orientation='right'
												/>
												<Tooltip />
												{/* <Legend /> */}
												<Bar
													dataKey='count'
													yAxisId={0}
													barSize={400}
													fill='#A6E0F5'
												/>
												<Line
													type='monotone'
													yAxisId={1}
													dataKey='avg'
													stroke='#8884D8'
													connectNulls={true}
													strokeWidth={2}
												/>
											</ComposedChart>
										</ResponsiveContainer>
									</div>
								</article>
							</section>
						</div>

						<div className='col-sm-4 col-md-4 col-lg-4'>
							<section className='card'>
								<div className='card-header'>
									<h3>
										{' '}
										{languageFile[localStorage.getItem('language')]['13595']}
									</h3>
								</div>
								<article className='card-body'>
									<div className='insights-graph'>
										{noDataFlag && (
											<div className='no-data'>
												{' '}
												{
													languageFile[localStorage.getItem('language')][
														'23191'
													]
												}
											</div>
										)}
										<ResponsiveContainer width='100%' height='100%'>
											<ComposedChart
												data={this.state.yearHisto}
												margin={{ top: 20, right: -30, bottom: 0, left: -30 }}>
												<XAxis dataKey='monthOfYear' />
												<YAxis yAxisId={0} orientation='left' />
												<YAxis
													type='number'
													domain={[0, 5]}
													ticks={[0, 1, 2, 3, 4, 5]}
													yAxisId={1}
													orientation='right'
												/>
												<Tooltip />
												{/* <Legend /> */}
												<Bar
													dataKey='count'
													yAxisId={0}
													barSize={400}
													fill='#A6E0F5'
												/>
												<Line
													type='monotone'
													yAxisId={1}
													dataKey='avg'
													stroke='#8884D8'
													connectNulls={true}
													strokeWidth={2}
												/>
											</ComposedChart>
										</ResponsiveContainer>
									</div>
								</article>
							</section>
						</div>
					</div>
					<div className='row'>
						<div className='col-sm-4 col-md-4 col-lg-4'>
							<TopLocationsWidget
								title={languageFile[localStorage.getItem('language')]['10562']}
								type='Country'
								data={this.state.topCountries}
								typeLabel={
									languageFile[localStorage.getItem('language')]['10565']
								}
							/>
						</div>
						<div className='col-sm-4 col-md-4 col-lg-4'>
							<TopLocationsWidget
								title={languageFile[localStorage.getItem('language')]['10228']}
								type='Area'
								data={this.state.topAreas}
								typeLabel={
									languageFile[localStorage.getItem('language')]['10225']
								}
							/>
						</div>
						<div className='col-sm-4 col-md-4 col-lg-4'>
							<TopLocationsWidget
								title={languageFile[localStorage.getItem('language')]['12359']}
								type='Locality'
								data={this.state.topLocalities}
								typeLabel={
									languageFile[localStorage.getItem('language')]['10538']
								}
							/>
						</div>
					</div>
					<div className='page-break'></div>
					{this.jobType === 'DAILY' && (
						<div className='nightmare-check-stacked-bar nightmare-check-stacked-area nightmare-check-stacked-line'></div>
					)}
					{this.jobType !== 'DAILY' && (
						<div>
							<div className='row'>
								<div className='col-sm-12 col-md-12 col-lg-12'>
									<section className='card'>
										<div className='card-header'>
											<h3>
												{
													languageFile[localStorage.getItem('language')][
														'21671'
													]
													// 'Performance'
												}
											</h3>
										</div>
										<article className='card-body'>
											<div className='row'>
												<div className='col-sm-4 col-md-4 col-lg-4'>
													<div className='insight-values'>
														<div className='row'>
															<div className='col-sm-9'>
																<span>
																	{this.state.viewsCount &&
																		this.state.viewsCount.toLocaleString()}
																</span>
																<span>
																	{
																		languageFile[
																			localStorage.getItem('language')
																		]['2078']
																		// 'Map Views'
																	}
																</span>
															</div>
															<div className='col-sm-3 text-center'>
																<img src='/images/mapBlue.png'/>
															</div>
														</div>
													</div>
												</div>
												<div className='col-sm-4 col-md-4 col-lg-4'>
													<div className='insight-values'>
														<div className='row'>
															<div className='col-sm-9'>
																<span>
																	{this.state.searchesCount &&
																		this.state.searchesCount.toLocaleString()}
																</span>
																<span>
																	{
																		languageFile[
																			localStorage.getItem('language')
																		]['2077']
																		// 'Search Views'
																	}
																</span>
															</div>
															<div className='col-sm-3 text-center'>
																<img src="/images/search.png" />
															</div>
														</div>
													</div>
												</div>
												<div className='col-sm-4 col-md-4 col-lg-4'>
													<div className='insight-values'>
														<div className='row'>
															<div className='col-sm-9'>
																<span>
																	{this.state.actionsCount &&
																		this.state.actionsCount.toLocaleString()}
																</span>
																<span>
																	{
																		languageFile[
																			localStorage.getItem('language')
																		]['10120']
																	}
																</span>
															</div>
															<div className='col-sm-3 text-center'>
																<img src='/images/action.png' />
															</div>
														</div>
													</div>
												</div>
											</div>
										</article>
									</section>
								</div>
							</div>
							<div className='row'>
								<div className='col-sm-12 col-md-12 col-lg-12'>
								<section className='card'>
									<div className='card-header'>
										<h3>
											{
												languageFile[
													localStorage.getItem(
														'language'
													)
												]['14544']
											}
										</h3>
									</div>
									
									<article className='card-body'>
										<div className='row'>
											<div className='col-md-6 insight-labels' style={{width:'20%'}}>
												<span>
													{this.findLabelValue(
														this.state.viewsPiedata,
														'Maps - Mobile'
													)}
												</span>
												<span>
													{this.findPercent(
														'Maps - Mobile',
														this.state.viewsPiedata
													)}
												</span>
												<span>
													{
														languageFile[
															localStorage.getItem(
																'language'
															)
														]['2073']
														// 'Maps - Mobile'
													}
												</span>
											</div>
											<div className='col-md-6 insight-labels' style={{width:'25%'}}>
												<span>
													{this.findLabelValue(
														this.state.viewsPiedata,
														'Maps - Desktop'
													)}
												</span>
												<span>
													{this.findPercent(
														'Maps - Desktop',
														this.state.viewsPiedata
													)}
												</span>
												<span>
													{
														languageFile[
															localStorage.getItem(
																'language'
															)
														]['2074']
														// 'Maps - Desktop'
													}
												</span>
											</div>
											<div className='col-md-6 insight-labels' style={{width:'25%'}}>
												<span>
													{this.findLabelValue(
														this.state.viewsPiedata,
														'Search - Mobile'
													)}
												</span>
												<span>
													{this.findPercent(
														'Search - Mobile',
														this.state.viewsPiedata
													)}
												</span>
												<span>
													{
														languageFile[
															localStorage.getItem(
																'language'
															)
														]['2075']
														// 'Search - Mobile'
													}
												</span>
											</div>
											<div className='col-md-6 insight-labels' style={{width:'25%'}}>
												<span>
													{this.findLabelValue(
														this.state.viewsPiedata,
														'Search - Desktop'
													)}
												</span>
												<span>
													{this.findPercent(
														'Search - Desktop',
														this.state.viewsPiedata
													)}
												</span>
												<span>
													{
														languageFile[
															localStorage.getItem(
																'language'
															)
														]['2076']
														// 'Search - Desktop'
													}
												</span>
											</div>
										</div>
										<div className='insights-graph'>
											<StackedAreaChart />
											</div>
										</article>
									</section>
								</div>
							</div>
							<div className='page-break'></div>
							<div className='row'>
								<div className='col-sm-12 col-md-12 col-lg-12'>
									<section className='card'>
										<div className='card-header'>
											<h3>
												{
													languageFile[localStorage.getItem('language')][
														'11545'
													]
												}
											</h3>
										</div>
										<article className='card-body'>
											<div className='row'>
												<div className='col-sm-4 col-md-4 col-lg-4 insight-labels'>
													<span>
														{this.findLabelValue(
															this.state.queriesPiedata,
															'Direct Queries'
														)}
													</span>
													<span>
														{this.findPercent(
															'Direct Queries',
															this.state.queriesPiedata
														)}
													</span>
													<span>
														{
															languageFile[localStorage.getItem('language')][
																'10762'
															]
														}
													</span>
												</div>
												<div className='col-sm-4 col-md-4 col-lg-4 insight-labels'>
													<span>
														{this.findLabelValue(
															this.state.queriesPiedata,
															'Indirect Queries'
														)}
													</span>
													<span>
														{this.findPercent(
															'Indirect Queries',
															this.state.queriesPiedata
														)}
													</span>
													<span>
														{
															languageFile[localStorage.getItem('language')][
																'10771'
															]
														}
													</span>
												</div>
												<div className='col-sm-4 col-md-4 col-lg-4 insight-labels'>
													<span>
														{this.findLabelValue(
															this.state.queriesPiedata,
															'Brand'
														)}
													</span>
													<span>
														{this.findPercent(
															'Brand',
															this.state.queriesPiedata
														)}
													</span>
													<span>Brand</span>
												</div>
											</div>
											<div className='insights-graph'>
												<StackedBarChart />
											</div>
										</article>
									</section>
								</div>
							</div>
							{/* <div className='page-break'></div>
							<div className='row'>
								<div className='col-sm-12 col-md-12 col-lg-12'>
									<section className='card'>
										<div className='card-header'>
											<h3>
												{
													// languageFile[localStorage.getItem('language')][
													// 	'14544'
													// ]
													"Legacy customer views"
												}
											</h3>
										</div>
										<article className='card-body'>
											<div className='row'>
												<div className='col-sm-6 col-md-6 col-lg-6 insight-labels'>
													<span>
														{this.findLabelValue(
															this.state.viewsPiedata,
															'Maps'
														)}
													</span>
													<span>
														{this.findPercent('Maps', this.state.viewsPiedata)}
													</span>
													<span>
														{
															languageFile[localStorage.getItem('language')][
																'12544'
															]
														}
													</span>
												</div>
												<div className='col-sm-6 col-md-6 col-lg-6 insight-labels'>
													<span>
														{this.findLabelValue(
															this.state.viewsPiedata,
															'Search'
														)}
													</span>
													<span>
														{this.findPercent(
															'Search',
															this.state.viewsPiedata
														)}
													</span>
													<span>
														{
															languageFile[localStorage.getItem('language')][
																'13750'
															]
														}
													</span>
												</div>
											</div>
											<div className='insights-graph'>
												<StackedAreaChartCopy />
											</div>
										</article>
									</section>
								</div>
							</div> */}
							<div className='page-break'></div>
							<div className='row'>
								<div className='col-sm-12 col-md-12 col-lg-12'>
									<section className='card'>
										<div className='card-header'>
											<h3>
												{
													languageFile[localStorage.getItem('language')][
														'10598'
													]
												}
											</h3>
										</div>
										<article className='card-body'>
											<div className='row'>
												<div className='col-sm-4 col-md-4 col-lg-4 insight-labels'>
													<span>
														{this.findLabelValue(
															this.state.actionsPieData,
															'Driving Directions'
														)}
													</span>
													<span>
														{this.findPercent(
															'Driving Directions',
															this.state.actionsPieData
														)}
													</span>
													<span>
														{
															languageFile[localStorage.getItem('language')][
																'10792'
															]
														}
													</span>
												</div>
												<div className='col-sm-4 col-md-4 col-lg-4 insight-labels'>
													<span>
														{this.findLabelValue(
															this.state.actionsPieData,
															'Website'
														)}
													</span>
													<span>
														{this.findPercent(
															'Website',
															this.state.actionsPieData
														)}
													</span>
													<span>
														{
															languageFile[localStorage.getItem('language')][
																'14523'
															]
														}
													</span>
												</div>
												<div className='col-sm-4 col-md-4 col-lg-4 insight-labels'>
													<span>
														{this.findLabelValue(
															this.state.actionsPieData,
															'Phone'
														)}
													</span>
													<span>
														{this.findPercent(
															'Phone',
															this.state.actionsPieData
														)}
													</span>
													<span>
														{
															languageFile[localStorage.getItem('language')][
																'13132'
															]
														}
													</span>
												</div>
											</div>
											<div className='insights-graph'>
												<LineChartComponent />
											</div>
										</article>
									</section>
								</div>
							</div>
							<div className='page-break'></div>
							<div className='row'>
								<div className='col-sm-4 col-md-4 col-lg-4'>
									<section className='card'>
										<div className='card-header'>
											<h3>
												{' '}
												{
													languageFile[localStorage.getItem('language')][
														'10795'
													]
												}
											</h3>
										</div>
										<article className='card-body'>
											<div className='daily-insights'>
												{noDataFlag && (
													<div className='no-data'>
														{' '}
														{
															languageFile[localStorage.getItem('language')][
																'23191'
															]
														}
													</div>
												)}
												<ResponsiveContainer width='100%' height='100%'>
													<ComposedChart
														data={this.state.DatewiseInsightData}
														margin={{
															top: 20,
															right: -30,
															bottom: 20,
															left: -30,
														}}>
														<XAxis dataKey='dayOfWeek' />
														<YAxis yAxisId={0} orientation='left' />
														<Tooltip />
														{/* <Legend /> */}
														<Bar
															dataKey={
																languageFile[localStorage.getItem('language')][
																	'10792'
																]
															}
															yAxisId={0}
															barSize={400}
															fill='#A6E0F5'
														/>
													</ComposedChart>
												</ResponsiveContainer>
											</div>
										</article>
									</section>
								</div>

								<div className='col-sm-4 col-md-4 col-lg-4'>
									<section className='card'>
										<div className='card-header'>
											<h3>
												{' '}
												{
													languageFile[localStorage.getItem('language')][
														'13135'
													]
												}
											</h3>
										</div>
										<article className='card-body'>
											<div className='daily-insights'>
												{noDataFlag && (
													<div className='no-data'>
														{' '}
														{
															languageFile[localStorage.getItem('language')][
																'23191'
															]
														}
													</div>
												)}
												<ResponsiveContainer width='100%' height='100%'>
													<ComposedChart
														data={this.state.DatewiseInsightData}
														margin={{
															top: 20,
															right: -30,
															bottom: 20,
															left: -30,
														}}>
														<XAxis dataKey='dayOfWeek' />
														<YAxis yAxisId={0} orientation='left' />
														<Tooltip />
														{/* <Legend /> */}
														<Bar
															dataKey={
																languageFile[localStorage.getItem('language')][
																	'13132'
																]
															}
															yAxisId={0}
															barSize={400}
															fill='#A6E0F5'
														/>
													</ComposedChart>
												</ResponsiveContainer>
											</div>
										</article>
									</section>
								</div>

								<div className='col-sm-4 col-md-4 col-lg-4'>
									<section className='card'>
										<div className='card-header'>
											<h3>
												{' '}
												{
													languageFile[localStorage.getItem('language')][
														'14526'
													]
												}
											</h3>
										</div>
										<article className='card-body'>
											<div className='daily-insights'>
												{noDataFlag && (
													<div className='no-data'>
														{' '}
														{
															languageFile[localStorage.getItem('language')][
																'23191'
															]
														}
													</div>
												)}
												<ResponsiveContainer width='100%' height='100%'>
													<ComposedChart
														data={this.state.DatewiseInsightData}
														margin={{
															top: 20,
															right: -30,
															bottom: 20,
															left: -30,
														}}>
														<XAxis dataKey='dayOfWeek' />
														<YAxis yAxisId={0} orientation='left' />
														<Tooltip />
														{/* <Legend /> */}
														<Bar
															dataKey={
																languageFile[localStorage.getItem('language')][
																	'14523'
																]
															}
															yAxisId={0}
															barSize={400}
															fill='#A6E0F5'
														/>
													</ComposedChart>
												</ResponsiveContainer>
											</div>
										</article>
									</section>
								</div>
							</div>
							<div className='row'>
								<div className='col-sm-12 col-md-12 col-lg-12'>
									<section className='card'>
										<div className='card-header'>
											<h3>
												{
													languageFile[localStorage.getItem('language')][
														'2072'
													]
												}
												{/* {'Local Impression Conversion'} */}
											</h3>
										</div>
										<article className='card-body'>
											<div className='row'>
												<div className='col-sm-4 col-md-4 col-lg-4 insight-labels'>
													<span>
														{(this.state.LocalHistoStackLabel &&
															_.round(
																(action_drivingdirection * 100) / conversionSum,
																2
															) + '%') ||
															'-'}
													</span>
													<span>
														{
															languageFile[localStorage.getItem('language')][
																'10792'
															]
														}
													</span>
												</div>
												<div className='col-sm-4 col-md-4 col-lg-4 insight-labels'>
													<span>
														{(this.state.LocalHistoStackLabel &&
															_.round((action_phone * 100) / conversionSum, 2) +
																'%') ||
															'-'}
													</span>
													<span>
														{
															languageFile[localStorage.getItem('language')][
																'13132'
															]
														}
													</span>
												</div>
												<div className='col-sm-4 col-md-4 col-lg-4 insight-labels'>
													<span>
														{(this.state.LocalHistoStackLabel &&
															_.round((action_website * 100) / conversionSum, 2) +
																'%') ||
															'-'}
													</span>
													<span>
														{
															languageFile[localStorage.getItem('language')][
																'14523'
															]
														}
													</span>
												</div>
											</div>
											<div className='insights-graph'>
												<LocalHistogramComponent />
											</div>
										</article>
									</section>
								</div>
							</div>
							{/* <div className='page-break'></div>
							<div className='row'>
								<div className='col-sm-12 col-md-12 col-lg-12'>
									<section className='card'>
										<div className='card-header'>
											<h3>
												{
													languageFile[localStorage.getItem('language')][
														'12553'
													]
												}
											</h3>
										</div>
										<article className='card-body'>
											<div className='row'>
												<div className='col-sm-6 col-md-6 col-lg-6 insight-labels'>
													<span>
														{this.findLabelValue(
															this.state.photosPiedata,
															'Photo Count'
														)}
													</span>
													<span>
														{
															languageFile[localStorage.getItem('language')][
																'10240'
															]
														}
													</span>
												</div>
												<div className='col-sm-6 col-md-6 col-lg-6 insight-labels'>
													<span>
														{this.findLabelValue(
															this.state.photosPiedata,
															'Photo Views'
														)}
													</span>
													<span>
														{
															languageFile[localStorage.getItem('language')][
																'13144'
															]
														}
													</span>
												</div>
											</div>
											<div className='insights-graph'>
												<InsightsGroupBarChart merchantChart='merchant' />
											</div>
										</article>
									</section>
								</div>
							</div> */}
							{/* <div className='row'>
								<div className='col-sm-12 col-md-12 col-lg-12'>
									<section className='card'>
										<div className='card-header'>
											<h3>
												{
													languageFile[localStorage.getItem('language')][
														'13201'
													]
												}
											</h3>
										</div>
										<article className='card-body'>
											<div className='row'>
												<div className='col-sm-6 col-md-6 col-lg-6 insight-labels'>
													<span>
														{this.findLabelValue(
															this.state.photosPiedataCustomer,
															'Photo Count Customer'
														)}
													</span>
													<span>
														{
															languageFile[localStorage.getItem('language')][
																'10240'
															]
														}
													</span>
												</div>
												<div className='col-sm-6 col-md-6 col-lg-6 insight-labels'>
													<span>
														{this.findLabelValue(
															this.state.photosPiedataCustomer,
															'Photo Views Customer'
														)}
													</span>
													<span>
														{
															languageFile[localStorage.getItem('language')][
																'13144'
															]
														}
													</span>
												</div>
											</div>
											<div className='insights-graph'>
												<InsightsGroupBarChart customerChart='customer' />
											</div>
										</article>
									</section>
								</div>
							</div> */}
						</div>
					)}
					<div className='page-break'></div>

					{/* {!this.state.locationChart && <div className="location-chart"></div>}
					{!this.state.locationChart &&  */}
					<div className='row-quadrant'>
						<div className='col-sm-12 col-md-12 col-lg-12'>
							<section className='card'>
								<div className='card-header'>
									<h3>
										{languageFile[localStorage.getItem('language')]['27631']}
									</h3>
								</div>
								<article className='card-body'>
									<QuadrantAnalysicsChart1
										date={this.state.date}
										locationId={this.state.locationId}
										master={this.state.master}
										administrativeArea={this.state.administrativeArea}
										locality={this.state.city}
										locationName={this.state.locationName}
									/>
								</article>
							</section>
						</div>
					</div>
					{/* } */}
					<div className='page-break'></div>
					<div className='row-quadrant'>
						<div className='col-sm-12 col-md-12 col-lg-12'>
							<section className='card'>
								<div className='card-header'>
									<h3>
										{languageFile[localStorage.getItem('language')]['27691']}
									</h3>
								</div>
								<article className='card-body'>
									<QuadrantAnalysicsChart3
										date={this.state.date}
										master={this.state.master}
									/>
								</article>
							</section>
						</div>
					</div>

					<div className='page-break'></div>
					<div className='row-quadrant'>
						<div className='col-sm-12 col-md-12 col-lg-12'>
							<section className='card'>
								<div className='card-header'>
									<h3>
										{languageFile[localStorage.getItem('language')]['27681']}
									</h3>
								</div>
								<article className='card-body'>
									<QuadrantAnalysicsChart2
										date={this.state.date}
										master={this.state.master}
									/>
								</article>
							</section>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Dashboard;
