import React from 'react';
import { RenderEmailInputField } from './renderEmailInputfield';
import { RenderInviteEmailError } from './renderInviteEmailError';
import { RenderInviteUSerHint } from './RenderInviteUserHint';
import { RenderProfileListWrapper } from './renderProfileListWrapper';

export const RenderModalWindowFields = ({
	rolesMenu,
	userEmail,
	addEmail,
	emailValidationError,
	drodpownLoading,
	searchForRole,
	selectedRole,
	roleChange,
	profileOptions,
	selectedProfile,
	profileChange,
	tableLoading,
	tableData,
	onPageChange,
	showTotal,
	changeSize,
	getPageSizeOptions,
	//assignProfileChange,
	ischecked,
	allChecked,
	ischeckedAll,
	emailVisible,
	roleChanges,
}) => {
	return (
		<div className='invite-user'>
			<RenderEmailInputField
				userEmail={userEmail}
				addEmail={addEmail}
				emailVisible={emailVisible}
			/>
			<RenderInviteEmailError
				emailValidationError={emailValidationError}
			/>
			<RenderProfileListWrapper
				//editUser={editUser}
				//DeleteInvitedUser={DeleteInvitedUser}
				//reinviteUser={reinviteUser}
				rolesMenu={rolesMenu}
				tableLoading={tableLoading}
				tableData={tableData}
				onPageChange={onPageChange}
				showTotal={showTotal}
				profileOptions={profileOptions}
				changeSize={changeSize}
				roleChange={roleChange}
				roleChanges={roleChanges}
				//assignProfileChange={assignProfileChange}
				getPageSizeOptions={getPageSizeOptions}
				ischecked={ischecked}
				allChecked={allChecked}
				ischeckedAll={ischeckedAll}
			/>
			<RenderInviteUSerHint />
		</div>
	);
};
