
import React, { useState } from 'react'
// import style from '../../../../../components/ProgressiveModalWindow/style.module.scss';
// import { PROFILE_SET_UP } from '../../../../../components/ProgressiveModalWindow/Constants';
import { CREATE_PROFILE_CONTENT_HEADERS, CREATE_PROFILE_CONTENT_SUB, CREATE_PROFILE_LEFT_DESCRIPTIONS, PROFILE_SET_UP } from '../Constants';
import { ProfileCoreDetails } from './ProfileCoreDetails';
import { Button } from 'antd';
import { Localization } from './Localization';
import { GenerativeAI } from './GenerativeAI';
import { InviteUsers } from './InviteUsers';
import { ConfirmDetails } from './ConfirmDetails';
import lcICon from '../../../../../../public/images/lcicon.png';
import socialMedia from '../../../../../../public/images/socialMedia.png'
import CustomScrollbar from '../../../../../components/CustomScrollbar';
import { CREATE_PROFILE_HEADERS } from '../Constants';
import './style.scss';
import { ReportNotices } from './Reports&Notices';
import getSVG from '../../../../../utils/commonSVG';

export const RenderModalForm = (props) => {
    return (
      <div className='add-profile-modal-Wrapper'>
        <div className='left-side-section'>
            <div className='head-section'>
              <img src={props.domainLogo}></img>
              <h2> {props.header}</h2>
            </div>
            <div className='body-section'>
              <div className={`social-media-icon-${props.nextStep}`}>
                
              </div>
            </div>
            <div className='left-Footer'>
              <div className='footer-section'>
                <span className='step-label'>{props.nextStep !==6 ?  `Step ${props.nextStep}`:''}</span>
                <div className='step-content-section' >
                <span className='header'> 
                  {CREATE_PROFILE_HEADERS[props.nextStep]}
                </span>
              <p className='content'>
                {CREATE_PROFILE_LEFT_DESCRIPTIONS[props.nextStep]}
              </p>
              <LineRenderer numberOfLines={props.nextStep} />
              </div>
            </div>
             
  
          </div>
        </div>
      <div className='right-side-section'>
      {/* <CustomScrollbar className='authorize-admin-page-scroller'> */}
      <div className='header-section'>
        <div className='header'>
      <h2>{CREATE_PROFILE_CONTENT_HEADERS[props.nextStep]} </h2>
      <p className='sub-header'>{CREATE_PROFILE_CONTENT_SUB[props.nextStep]} {props.nextStep == 4 && <span>Available with Professional and Enterprise plans. </span>} </p>

      </div>
      <div className='modal-close' onClick={()=>props.onCancel()}>{getSVG('close-icon')}</div>
      {/* <p className='sub-header'>{CREATE_PROFILE_CONTENT_SUB[props.nextStep]} {props.nextStep == 4 && <span> Available with Professional and Enterprise plans </span>} </p> */}
      </div>
      <div className='body-content'>         
      { props.nextStep===1 ? <ProfileCoreDetails props={props.state} onChangeCreteProfileDetails={props.onChangeCreteProfileDetails} handleProfileIconChange={props.handleProfileIconChange}/>
           :props.nextStep===2 ? <Localization props={props.state} onChangeCreteProfileDetails={props.onChangeCreteProfileDetails}/>
           :props.nextStep===4? <GenerativeAI props={props.state} onChangeCreteProfileDetails={props.onChangeCreteProfileDetails}/>
           :props.nextStep===3?<ReportNotices props={props.state} onChangeCreteProfileDetails={props.onChangeCreteProfileDetails}/>
           :props.nextStep===5? <InviteUsers props={props.state} onChangeCreteProfileDetails={props.onChangeCreteProfileDetails} changeInvities={props.changeInvities}/>
           :props.nextStep===6 ? <ConfirmDetails props={props.state}/>:''}
           </div>

        <div className='footer-content'>
        <div>{ props.nextStep!==1 ?
        <Button
          onClick={() =>props.previousStepButtonHandler()}
          type="default"
          shape="round"
          className='rightFooter__buttonBorder btn-default'
        >
          <svg height='20' viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
         <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
          </svg>

          Previous Step
        </Button>:''}</div>
         { props.nextStep!==6 ? 
       <Button
          onClick={() =>props.nextStepButtonHandler()}
          type="default"
          shape="round"
          className='rightFooter__buttonBorder btn-primary'
        >
          Next Step
          <svg  height='20' viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
         <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
          </svg>

        </Button> :<Button
          onClick={()=>props.addNewProfile()}
          type="default"
          shape="round"
         loading={props.state.newProfileLoading}
          className='.rightFooter__buttonBorder btn-primary'
        >
          Build Profile
        </Button> 
        }</div>
      </div>
      </div>
    )
  }

  function LineRenderer({ numberOfLines }) {
    let linesNo= 6;
    const lines = Array.from({ length: linesNo }, (_, index) => {
      return(
      <span key={index} className={`line ${index<numberOfLines && 'line-active'}` }></span>
    )});
  
    return <div className="step-count-line">{lines}</div>;
  }