import AppDispatcher from '../../../flux/dispatcher/dispatcher';
import Constant from '../constant/Constant';
import LogoutConstant from '../../MainFrame/constants/Constants';
import restClient from '../../MainFrame/action/APIClient';

var AddIntegrationAction = function () {

}


AddIntegrationAction.prototype = {

	getintegration: function (domain) {

		restClient.integrationsread(domain, function (error, response) {
			if (error) {
				if (error.status === 401) {
					AppDispatcher.dispatch({
						actionType: LogoutConstant.UNAUTHORIZED_EXCEPTION,
						data: error
					});
				}
				// AppDispatcher.dispatch({
				// 	actionType: Constant.GET_INTEGRATION_CONTROL,
				// 	data: error
				// });
			} else {
				// console.log('AddIntegrationAction : ', response)
				AppDispatcher.dispatch({
					actionType: Constant.GET_INTEGRATION_CONTROL,
					data: response
				});
			}
		})
	}
}

export default new AddIntegrationAction();