import AppDispatcher from '../../../flux/dispatcher/dispatcher';
import Constant from '../constants/constants';
import restClient from '../../MainFrame/action/APIClient';
import LogoutConstant from '../../MainFrame/constants/Constants';
var moment = require('moment');

var SearchWorkspaceNameAction = function () {

}

SearchWorkspaceNameAction.prototype = {

	searchWorkspaceName: function (accountName) {
		restClient.searchWorkspaceName(accountName, function (error, response) {
			if (error) {
				if (error.status === 401) {
					AppDispatcher.dispatch({
						actionType: LogoutConstant.UNAUTHORIZED_EXCEPTION,
						data: error
					});
				}
				else{
					AppDispatcher.dispatch({
						actionType: Constant.SEARCH_WORKSPACE_NAME,
						data: error
					});
				}
			} else {
				if(response!=null){response['status'] = 200};
				AppDispatcher.dispatch({
					actionType: Constant.SEARCH_WORKSPACE_NAME,
					data: response
				});
			}
		})
	}
}

export default new SearchWorkspaceNameAction();