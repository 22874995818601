import React from 'react';
import { Icon, Tooltip } from 'antd';
var languageFile = require("../../../../public/languages/Translation");
class RatingBar extends React.Component {

	render() {
		var dataPresent = true;
		if (this.props.data && this.props.data.length > 0) {
			dataPresent = this.props.data.some(function (item) { return item.count > 0 })
		}
		if (!dataPresent) {
			return <div className="no-data"> {this.props.interactive ? <span>{languageFile[localStorage.getItem('language')]["28711"]}</span> : <span>{languageFile[localStorage.getItem('language')]["12738"]}</span>}</div>
		}
		var ratingData = this.props.data;
		var totalCount = this.props.totalReviews;
		var color = this.props.colorMap;
		var highestRatingCount = Math.max.apply(Math, ratingData && ratingData.map(function (o) { return o.count; }))
		var percentageMap = ratingData && ratingData.map(function (item) {
			var percentage = Math.round((item.count * 100) / highestRatingCount);
			return { name: item.name, percentage: percentage, starRating: item.starRating, count: item.count, replyPercentage: item.replyPercentage }
		})
		let ratingsArray = [];
		if (this.props.interactive) {				// for reviews by star rating 
			ratingsArray = percentageMap && percentageMap.map(function (item, index) {
				var percentage = isNaN(item.percentage) ? 0 : item.percentage;
				var count = isNaN(item.percentage) ? '' : item.count;
				var width = percentage;
				return <div key={index} className="bar-item">
					<span className="bar-label"><i className="fa fa-star" aria-hidden="true"></i><span>{item.starRating}</span></span>
					<Tooltip placement="left" title={width ? languageFile[localStorage.getItem('language')]["20411"] : ""}>
						{this.props.flag === true ?
							(
								<a className={"ratingbar-" + item.starRating + "-star"}
									href={"/reviews/inbox?from=" +
										this.props.dateRange.from +
										"&to=" + this.props.dateRange.to +
										"&rating=" + item.starRating}>
									<span className="bar" style={{ width: width + "%" }}></span>
									<span className="bar-value">{count}</span>
								</a>
							) : (
								<a className={"ratingbar-" + item.starRating + "-star"}>
									<span className="bar" style={{ width: width + "%" }}></span>
									<span className="bar-value">{count}</span>
								</a>
							)}
					</Tooltip>
				</div>
			}, this)
		} else {									// for replies by star rating
			ratingsArray = percentageMap && percentageMap.map(function (item, index) {
				var repliesPercentage = 0;
				if (this.props.reviewData && this.props.reviewData.length > 0 && this.props.reviewData[index].count && this.props.reviewData[index].count !== 0) {
					repliesPercentage = Math.round((item.count / this.props.reviewData[index].count) * 100);
				}
				return <div key={index} className="bar-item">
					<span className="bar-label"><i className="fa fa-star" aria-hidden="true"></i>{item.starRating}</span>
					<div className={"ratingbar-" + item.starRating + "-star-replies"}>
						<span className="bar" style={{ width: repliesPercentage + "%" }}></span>
						<span className="bar-value">{repliesPercentage + "%"}</span>
					</div>
				</div>
			}, this)
		}

		return (
			<div className="rating-bar">
				{ratingsArray}
			</div>
		)
	}
}
export default RatingBar;