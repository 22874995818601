import { Modal, message } from 'antd';
const info = Modal.info; 

export const deepClone = (Item) => {
    return JSON.parse(JSON.stringify(Item))
}

export const csvDownloadHandler = (dataStore, type) => {
    if (checkIfPossibleToDownloadFile(dataStore)) {
        window.location.assign(dataStore.url)
    } else if (CheckDownloadLinkSend(dataStore, type)) {
        mailingInfo(dataStore.email,dataStore.totalLimit,type)
    } else if (checkIsAnyErrorMessage(dataStore)) {
        message.error(dataStore.data.message)
    } else {
        message.error("Unable to download")
    }
    
}

export const searchKeywordDownloadHandler = (dataStore) =>{
    if (CheckDownloadLinkSend(dataStore)) {
        keywordMainingInfo(dataStore.email)
    }
    else{
        message.error(dataStore.data.message)
    }
}

const keywordMainingInfo = (email) =>{
    info({
        title: 'Keyword Data Download',
        content : `Local keyword data files can be quite large. It may take several hours to extract and compile the data. We will send you an email (to ${email}) with a link to download the keyword csv`
    })
}

const mailingInfo = (mail,limit, type) => {
    info({
        title: `You have more than ${limit ? limit : '100'} ${type ? type : 'reviews'}`,
        content: `Your request contains more than  ${limit ? limit : '100'} ${type ? type : 'reviews'}. For such requests,  we will send you an email (to ${mail}) with a link to download the ${type==='posts' ? 'post' : 'review'} csv.`,
    });
}
const checkIfPossibleToDownloadFile = (dataStore) => {
    if (
        dataStore.status == 200 &&
        dataStore.hasOwnProperty("url")
    ) {
        return true;
    }
    return false;
}
const CheckDownloadLinkSend = (dataStore) => {
    if (
        dataStore.status == 200 &&
        dataStore.hasOwnProperty("message")
    ) {
        return true;
    }
    return false;
}
const checkIsAnyErrorMessage = (errorStatus) => {
    if (errorStatus.hasOwnProperty("message")) {
        return true;
    }
    return false;
}