import React from 'react';
import style from './style.module.scss';
import { Button, Input } from 'antd';
import getSVG from '../../../../../utils/commonSVG';

export default function WorkSpaceSearch(props) {
 
    return (
        <div className={style.searchWorkSpaceWrapper}>
            <div className={style.createSection}>
                <div className={style.backLink} onClick={props.onCreateWorkSpace}>
                     Create a new workspace instead 
                </div>
                <div className={style.headerSection}>
                    <h1>
                    {!props.searchResponse ? "Request access to your teams's workspace" : "Search for your team's Workspace"}
                    </h1>
                    <div className={style.label}>
                        Enter the admin's email address to request access.
                    </div>

                </div>
                {props.searchResponseData==500 && <div className={style.emailSearchBlock}>
                    <div className={style.emailLabel}>
                        Email
                    </div>
                    <div className={style.searchBlock}>
                        <span className={style.input}>
                            <Input placeholder="Admin's email address" onChange={(e) => props.onChangeEmail(e.target.value.trim())} value={props.searchEmail} />
                        </span>
                        <span >
                            <Button loading={props.searchLoader} className={style.button} onClick={props.searchWorkSpace}>Send Request</Button>
                        </span>
                    </div>

                </div>}

            </div>
            {props.searchResponse && <>
                <div className={style.divider}>
                    <div className={style.horizontalLine}>
                    </div>
                    <div className={style.text}>
                        Workspaces
                    </div>
                    <div className={style.horizontalLine}>
                    </div>
                </div>
                <div className={style.reponseBlock}>
                    {props.searchResponseData==200 ?
                        <div className={style.successBlock}>
                            <div className={style.hintIcon}>
                                {getSVG('hint')}
                            </div>
                            <div className={style.information}><p>{`We have sent your request to ${props.searchEmail || ''}`}</p>
                                <p>We will inform you through email once the admin of the LocalClarity workspace accepts your request.</p>
                                <p>In the meantime, you can visit our <div onClick={()=>window.open('https://support.localclarity.com/portal/en/kb', '_blank')} className={style.link}> Knowledge Base.</div></p></div>

                        </div> :
                        props.searchResponseData==500 ?
                        <div className={style.errorBlock}>
                            <div className={style.hintIcon}>
                                {getSVG('hint')}
                            </div>
                            <div className={style.information}><p>We were unable to find that Administrator account</p>
                                <p>The email address provided is not associated with an Administrator account. Please ask the admin to log into the platform and invite you directly.</p></div>

                        </div>:<></>
                    }
                </div></>}
        </div>
    )
}
