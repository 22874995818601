import axios from 'axios'
import AppDispatcher from '../../../flux/dispatcher/dispatcher';
import Constant from '../constants/LoginConstant';
import config from '../../../../globalconfig';
import urlConfig from '../../../config/index';

var MicrosoftLinkAction = function () {

}

MicrosoftLinkAction.prototype = {
        getLink: function () {

                var urlName = urlConfig.urlName
                // $.ajax({
                //     url: config.server + "/getMicrosoftSignInUrl?domainName=" + urlName,
                //     type: 'GET',
                //     dataType: 'JSON',
                //     contentType: "application/json; charset=utf-8",
                //     success: function(resp) {
                //         console.log("action......", resp);
                //         AppDispatcher.dispatch({
                //             actionType: Constant.MICROSOFT_LINK_RECEIVED,
                //             data: resp
                //         });
                //     },
                //     error: function(err) {
                //         AppDispatcher.dispatch({
                //             actionType: Constant.MICROSOFT_LINK_FAILURE,
                //             data: err
                //         });

                //     }
                // });
                axios.get(config.server + "/getMicrosoftSignInUrl?domainName=" + urlName)
                        .then((resp) => {
                                AppDispatcher.dispatch({
                                        actionType: Constant.MICROSOFT_LINK_RECEIVED,
                                        data: resp.data
                                });
                        })
                        .catch((err) => {
                                AppDispatcher.dispatch({
                                        actionType: Constant.MICROSOFT_LINK_FAILURE,
                                        data: err
                                });
                        })
        }
}

export default new MicrosoftLinkAction();
