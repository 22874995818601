var keyMirror = require('keymirror');

module.exports = keyMirror({
	AUTHORISE_ACCOUNT_RESPONSE: null,
	ADD_PROFILE_RESPONSE: null,
	ADD_PROFILE_ERROR: null,
	GET_PROFILES_RESPONSE: null,
	ENABLE_ACCOUNT: null,
	GET_PROFILE_DETAILS: null,
	FOURSQUARE_AUTH: null,
	PROFILE_DELETED: null,
	FACEBOOK_AUTH: null,
	DISABLE_ACCOUNT: null,
	SYNC_ACCOUNT_RESPONSE: null,
	GET_USER_IMAGE_DATA: null,
	HIDE_GOOGLE_ACCOUNT: null,
	CREATE_PORTFOLIO_ERROR: null,
	CREATE_PORTFOLIO_RESPONSE: null,
	GET_PORTFOLIO_DETAILS: null,
	PORTFOLIO_DELETED: null,
	NOT_ADMIN_PAYMENT: null,
	ADMIN_CHECK_PAYMENT: null,
	ACCEPT_INVITE: null,
	GET_PROFILE: null,
	GET_PROFILES_DETAILS_RESPONSE:null,
	EDIT_PROFILE_NAME: null,
	UPLOAD_PROFILE_ICON:null,
	GET_USER_SETTINGS:null,
	SAVE_USER_SETTINGS:null
});