import React from 'react';
import { Tooltip } from 'antd';
// var states = require("../../../../statesJson.json");
import iso3166 from 'iso-3166-2';
var languageFile = require("../../../../public/languages/Translation");

class TopLocationsWidget extends React.Component {

	render() {
		var tableData = [];
		var noData = false;
		if (!this.props.data) {
			return <section className="card">
				<div className="card-header">
					<h3>{this.props.title}</h3>
				</div>
				<article className="card-body" style={{ minHeight: 192 }}>
					<div className="no-data">{languageFile[localStorage.getItem('language')]["23191"]}</div>
				</article>
			</section>
		}


		if (this.props.data && this.props.data.length === 0) {
			noData = true
		} else {
			tableData = this.props.data.slice(0, 5).map(function (item, index) {
				if (this.props.type === 'Country') {
					item.locationName = iso3166.country(item.locationName) && iso3166.country(item.locationName).name || item.locationName;
				}
				if (this.props.type === 'Area') {
					// if(states.states.hasOwnProperty(item.locationName)){
					// 	item.locationName = states.states[item.locationName];
					// }
					item.locationName = item.country && iso3166.subdivision(item.country, item.locationName) && iso3166.subdivision(item.country, item.locationName).name || item.locationName
				}
				var tableRow = [];
				tableRow.push(<td key={index}>{item.locationName && item.locationName.length > 16 && <Tooltip title={item.locationName}>{item.locationName.slice(0, 14) + ".."}</Tooltip> || item.locationName}</td>);
				tableRow.push(<td key={index + 1}>{item.reviewCount.toLocaleString()}</td>);
				tableRow.push(<td key={index + 2}>{Math.round(item.avgStarRating * 100) / 100}</td>);
				return <tr key={index}>{tableRow}</tr>
			}, this)
		}

		return (
			<section className="card">
				<div className="card-header">
					<h3>{this.props.title}</h3>
				</div>
				<article className="card-body" style={{ minHeight: 192 }}>
					{noData && <div className="no-data">{languageFile[localStorage.getItem('language')]["23191"]}</div>}
					{!noData && <table className="table locations-table">
						<tbody>
							<tr>
								<th>{this.props.typeLabel}</th>
								<th>{languageFile[localStorage.getItem('language')]["13574"]}</th>
								<th>{languageFile[localStorage.getItem('language')]["13520"]}</th>
							</tr>
						</tbody>
						<tbody>
							{tableData}
						</tbody>
					</table>}
				</article>
			</section>
		)
	}
}

export default TopLocationsWidget;