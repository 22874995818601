import React from "react";
import moment from 'moment'

export const CREATE_PROFILE_HEADERS = {
    '1' : 'Set Up Profile',
    '2' : 'Localization',
    '3' : 'Set Up Reporting Preferences',
    '4' : 'Generative AI',
    '5' : 'Invite Users',
    '6' : 'Confirm All Details'
}
export const CREATE_PROFILE_CONTENT_HEADERS = {
    '1' : 'Profile Core Details',
    '2' : 'Language,Date & Time Settings',
    '3' : 'Reports & Notices',
    '4' : 'Generative AI Capabilities',
    '5' : 'Invite Users',
    '6' : 'Confirm All Details'
}
export const CREATE_PROFILE_CONTENT_SUB = {
    '1' : 'Complete the items below for a tailored experience (*required)',
    '2' : 'Complete the items below for a tailored experience (*required)',
    '3' : "All reports can be refined and filtered in the profile to meet your team's exact needs." ,
    '4' : 'Gain deep insights & cutting edge functionality, ',
    '5' : 'All plans included unlimited users',
    '6' : 'Review details and press "Build Profile" or return to previous steps to edit',
}

export const CREATE_PROFILE_LEFT_DESCRIPTIONS = {
    '1' : 'Profile are the core organizations tool for your local listings and reviews',
    '2' : 'Set preferences to align with your business operations',
    '3' :'Activate an initial set of reports for enhanced visibility and control',
    '4' : 'Turn on powerful AI-powered toos to augment your capabilities. ',
    '5' : 'Share the power with your team, other departments and all stakeholders. ',
    '6' : 'Create the profile set to your custom details and preferences. '
}
export const PROFILE_SET_UP={
    'STEP_1':'Step ',
    'SET_UP_PROFILE':'Set Up Profile',
    SET_UP_PROFILE_CONTENT:'Profile are the core organizations tool for your local listings and reviews',
    PROFILE_CORE_DETAILS:'Profile Core Details',
    PROFILE_CORE_SUB:'Complete the items below for a tailored experience (*required)',
    PROFILE_ICON:'Profile icon',
    UPLOAD_Image:'Upload image',
    PROFILE_NAME:'Profile name*',
    BUSINESS_INDUSTRY:'Business industry',
    LOCATION_VOLUME:'Location volume',
    ESTIMATE:'(estimate)'
}

export const LOCALIZATION={
    LAN_DATE_TIME:'Language,Date & Time Settings',
    LAN_DATE_TIME_SUB:'Complete the items below for a tailored experience (*required)',
    DEFAULT_LAN:'Default Language*',
    DEFAULT_LAN_SUB:'Sets the login page default. Users will be able to select preference in platform',
    DATE_FORMAT:'Date Format*',
    DATE_FORMAT_SUB:'Sets the default date format for most platform reporting',
    LISTING_SCHEDULED:'Listing Scheduled Sync',
    LISTING_SCHEDULED_SUB:'After initial integrations,profile re-syncs listing details early on the day selected',
    SET_REVIEW_TIMESTAMP:'Set Review Timestamp',
    SET_REVIEW_TIMESTAMP_SUB:'When a review is received,set the timestamp to'
}

export const GENERATIVE_AI={
    AI_CAPABILITIES:'Generative AI Capabilities',
    AI_CAPABILITIES_SUB:'Gain deep insights, Available with Professional and Enterprise plans',
    IDENTIFY_REVIEW_LAN:'Identify review language',
    IDENTIFY_REVIEW_LAN_SUB:'Tag all new reviews with the primary comment language',
    SENTIMENT_ANALYSIS:'Sentiment Analysis',
    SENTIMENT_ANALYSIS_SUB:'Identify review comment topics,sentiment & emotions',
    SENTIMENT_ANALYSIS_LAN:'Sentiment Analysis Language',
    SENTIMENT_ANALYSIS_LAN_SUB:'"Open" allows for sentiment topic in the original comment language. "Set" sets topic to a selected language',
    DEFAULT_LAN:'Default Language',
    DEFAULT_LAN_SUB:'Topics identified will be  translated into selected language ',
    REVIEW_GENDER_TAGG:'Review Gender Tagging',
    REVIEW_GENDER_TAGG_SUB:'Identify reviewer gender via AI (Coming Soon)',
    TRACK_COMPETITOR_REVIEW:'Track Competitor Reviews',
    TRACK_COMPETITOR_REVIEW_SUB:'Track 10x competitor reviews (Competitor sentiment scoring and comparisons coming soon)'
}

export const INVITE_USERS={
    INVITE_USERS:'Invite Users',
    INVITE_USERS_SUB:'All plans included unlimited users',
    EMAIL:'Email',
    ROLE:'Role',
    MULTIPLE_EMAIL:'Enter multiple emails separated for commas for each role',
    PERSONAL_MESSAGE:'Personal Message'
}

export const CONFIRM={
    CONFIRM_ALL_DETAILS:'Confirm All Details',
    CONFIRM_ALL_DETAILS_SUB:'Review details and press "Build Profile" or return to previous steps to edit',
    BUSINESS_NAME_ABC:'BusinessName ABC 123 (2-10 locations)',
    BUSINESS_NAME_ABC_SUB:'Banking/Financial Services',
    SETTINGS:'Settings',
    LANGUAGE:'Language',
    SYNC_DAY:'Sync Day',
    DATE_FORMAT:'Date Format',
    REVIEW_TIMESTAMP:'Review Time stamp',
    GENERATIVE_AI:'Generative AI',
    DETECT_LAN:'Detect Language',
    SENTIMENT_LAN:'Sentiment Language',
    REVIEWER_GENDER:'Review Gender',
    COMP_REVIEWS:'Competitor Reviews',
    INVITED_USERS:'Invited Users',
    TSSUMMY:'tsummy@loaclclarity. com'
}

export const BUSINESS_DROPDOWN_OPTIONS=[
    {
    label:'Activities/Sports',value:'Activities/Sports'
}, {
    label:'Arts/Theatre',value:'Arts/Theatre'
}, {
    label:'Automotive',value:'Automotive'
}, {
    label:'Beauty',value:'Beauty'
}, {
    label:'Business Services',value:'Business/Services'
}, {
    label:'Education/Training',value:'Education/Training'
}, {
    label:'Events/Event Rental',value:'Events/Event Rental'
}, {
    label:'Banking/Financial Services',value:'Banking/Financial Services'
}, {
    label:'Food/Grocery',value:'Food/Grocery'
}, {
    label:'Healthcare/Medical',value:'Healthcare/Medical'
}, {
    label:'Home Services/Trades',value:'Home Services/Trades'
}, {
    label:'Hospitality/Tourism',value:'Hospitality/Tourism'
}, {
    label:'Industrial/Manufacturing',value:'Industrial/Manufacturing'
}, {
    label:'Local Services',value:'Local Services'
}, {
    label:'Legal',value:'Legal'
}, {
    label:'Public Services/Government',value:'Public Services/Government'
}, {
    label:'Real Estate',value:'Real Estate'
}, {
    label:'Religious/Non-Profit',value:'Religious/Non-Profit'
}, {
    label:'Restaurants',value:'Restaurants'
}, {
    label:'Retail/Shopping',value:'Retail/Shopping'
}, {
    label:'Other',value:'other'
}
]

export const  AVAILABLE_LANGUAGES= [{
    value:'english', label: "ENGLISH",},
    {value:'portuguese',label: "PORTUGUÊS"},
    {value:'german',label: "DEUTSCH"},
    {value:'spanish',label: "ESPAÑOL"},
    {value:'french',label: "FRANÇAIS"},
    {value:'日本語',label: "日本語"},
    {value:'arabic',label: "عربى"},
    {value:'italian',label: "ITALIANO"},
    {value:'dutch',label: "NEDERLANDS"},
    {value:'mandarin',label: "繁體中文"},
    {value:'hindi',label:"हिंदी"},
    {value:'vietnamese',label:"Tiếng Việt"},
    {value:'thai',label:"ไทย"},
    {value:'filipino',label:"Filipino"},
]
export const selectedLanguage={
    
      'english' : "English",
        'portuguese': "Portugese",
        'german': "Deutsch",
        'spanish': "Espanol",
        'french': "Français",
        '日本語': "日本語",
        'arabic': "عربى",
        'italian': "Italiano",
        'dutch': "Nederlands",
        'mandarin': "繁體中文",
        'hindi':"हिंदी",
        'vietnamese':"Tiếng Việt",
        'thai':"ไทย",
        'filipino':"Filipino",
}
export const TIME_FORMAT_OPTIONS = [
    {
        label:'h:mm (12-hour)     8:08 AM', value:'h:mm'
    },
    {
        label:'hh:mm (12-hour)     08:08 AM', value:'hh:mm'
    },
    {
        label:'H:MM (24-hour)     8:08', value:'H:MM'
    },
    {
        label:'HH:MM (12-hour)     08:08', value:'HH:MM'
    }
]
export const TIME_FORMAT_OPTIONS_DROPDOWN = [
  {
      label:'h:mm (12-hour)', value:'h:mm'
  },
  {
      label:'hh:mm (12-hour)', value:'hh:mm'
  },
  {
      label:'H:MM (24-hour)', value:'H:MM'
  },
  {
      label:'HH:MM (12-hour)', value:'HH:MM'
  }
]


export const selectedDateFormat = {
    'h:mm':'8:08 AM',
    'hh:mm':'08:08 AM',
    'H:MM':'8:08',
    'HH:MM':'08:08'
}

export const  DATE_FORMAT_OPTIONS= [
    { label: `MM/DD/YYYY (${moment().format('MM/DD/YYYY')})`, value: "MM/DD/YYYY" },
    { label: `MMM D,YYYY (${moment().format('MMM D,YYYY')})`, value: "ll" },
    { label: `MMMM D,YYYY (${moment().format('MMMM D,YYYY')})`, value: "LL" },
    { label: `DD/MM/YYYY (${moment().format('DD/MM/YYYY')})`, value: "DD/MM/YYYY" },
    { label: `DD MMM YYYY (${moment().format('DD MMM YYYY')})`, value: "DD MMM YYYY" },
    { label: `DD MMMM YYYY (${moment().format('DD MMMM YYYY ')})`, value: "DD MMMM YYYY" },
    { label: `YYYY-MM-DD (${moment().format('YYYY-MM-DD')})`, value: "YYYY-MM-DD" },
]
export const  DATE_FORMAT_OPTIONS_DROPDOWN= [
  { label: `MM/DD/YYYY`, value: "MM/DD/YYYY" },
  { label: `MMM D,YYYY `, value: "ll" },
  { label: `MMMM D,YYYY`, value: "LL" },
  { label: `DD/MM/YYYY`, value: "DD/MM/YYYY" },
  { label: `DD MMM YYYY`, value: "DD MMM YYYY" },
  { label: `DD MMMM YYYY`, value: "DD MMMM YYYY" },
  { label: `YYYY-MM-DD`, value: "YYYY-MM-DD" },
]

export const selected_date_format = {
    'MM/DD/YYYY': moment().format('MM/DD/YYYY'),
    'll': moment().format('MMM D,YYYY '),
    'LL': moment().format('MMMM D,YYYY'),
    'DD/MM/YYYY': moment().format('DD/MM/YYYY'),
    'DD MMM YYYY': moment().format('DD MMM YYYY'),
    'DD MMMM YYYY': moment().format('DD MMMM YYYY'),
    'YYYY-MM-DD': moment().format('YYYY-MM-DD'),


}


export const TIMEZ_ZONE_LIST= [
    { label: "GMT-12:00", value: "-12:00" },
    { label: "GMT-11:00", value: "-11:00" },
    { label: "GMT-10:00", value: "-10:00" },
    { label: "GMT-09:30", value: "-09:30" },
    { label: "GMT-09:00", value: "-09:00" },
    { label: "GMT-08:00", value: "-08:00" },
    { label: "GMT-07:00", value: "-07:00" },
    { label: "GMT-06:00", value: "-06:00" },
    { label: "GMT-05:00", value: "-05:00" },
    { label: "GMT-04:00", value: "-04:00" },
    { label: "GMT-03:30", value: "-03:30" },
    { label: "GMT-03:00", value: "-03:00" },
    { label: "GMT-02:00", value: "-02:00" },
    { label: "GMT-01:00", value: "-01:00" },
    { label: "GMT+00:00", value: "+00:00" },
    { label: "GMT+01:00", value: "+01:00" },
    { label: "GMT+02:00", value: "+02:00" },
    { label: "GMT+03:00", value: "+03:00" },
    { label: "GMT+03:30", value: "+03:30" },
    { label: "GMT+04:00", value: "+04:00" },
    { label: "GMT+04:30", value: "+04:30" },
    { label: "GMT+05:00", value: "+05:00" },
    { label: "GMT+05:30", value: "+05:30" },
    { label: "GMT+05:45", value: "+05:45" },
    { label: "GMT+06:00", value: "+06:00" },
    { label: "GMT+06:30", value: "+06:30" },
    { label: "GMT+07:00", value: "+07:00" },
    { label: "GMT+08:00", value: "+08:00" },
    { label: "GMT+08:45", value: "+08:45" },
    { label: "GMT+09:00", value: "+09:00" },
    { label: "GMT+09:30", value: "+09:30" },
    { label: "GMT+10:00", value: "+10:00" },
    { label: "GMT+10:30", value: "+10:30" },
    { label: "GMT+11:00", value: "+11:00" },
    { label: "GMT+12:00", value: "+12:00" },
    { label: "GMT+12:45", value: "+12:45" },
    { label: "GMT+13:00", value: "+13:00" },
    { label: "GMT+14:00", value: "+14:00" },
]

export const weekDaysMap = [{
	label: 'Monday' , value:"MON"},
	{label: 'Tuesday', value:'TUE'},
	{label: 'Wednesday',value:'WED'},
	{label: 'Thursday',value:'THU'},
	{label: 'Friday',value:'FRI'},
	{label: 'Saturday', value:'SAT'},
	{label: 'Sunday',value:'SUN'}
]
;


export const  availableSentimentLanguages = [{
      "label": "Bulgarian",
      "value": "Bulgarian"
    }, {
      "label": "Chinese",
      "value": "Chinese"
    }, {
      "label": "Czech",
      "value": "Czech"
    }, {
      "label": "Danish",
      "value": "Danish"
    },{
      "label": "Dutch",
      "value": "Dutch"
    },{
      "label": "English",
      "value": "English"
    }, {
      "label": "Estonian",
      "value": "Estonian"
    }, {
      "label": "Finnish",
      "value": "Finnish"
    },{
      "label": "French",
      "value": "French"
    },{
      "label": "German",
      "value": "German"
    },{
      "label": "Greek",
      "value": "Greek"
    },{
      "label": "Hungarian",
      "value": "Hungarian"
    },{
      "label": "Indonesian",
      "value": "Indonesian"
    },{
      "label": "Italian",
      "value": "Italian"
    },{
      "label": "Japanese",
      "value": "Japanese"
    },{
      "label": "Korean",
      "value": "Korean"
    }, {
      "label": "Latvian",
      "value": "Latvian"
    },{
      "label": "Lithuanian",
      "value": "Lithuanian"
    },{
      "label": "Norwegian",
      "value": "Norwegian"
    }, {
      "label": "Polish",
      "value": "Polish"
    },{
      "label": "Portuguese",
      "value": "Portuguese"
    }, {
      "label": "Romanian",
      "value": "Romanian"
    }, {
      "label": "Russian",
      "value": "Russian"
    }, {
      "label": "Slovak",
      "value": "Slovak"
    },{
      "label": "Slovenian",
      "value": "Slovenian"
    },{
      "label": "Spanish",
      "value": "Spanish"
    },{
      "label": "Swedish",
      "value": "Swedish"
    },{
      "label": "Turkish",
      "value": "Turkish"
    },{
      "label": "Ukrainian",
      "value": "Ukrainian"
  }]



export const Invite_Role = [ {
    value: 'ADMIN',
    label: 'Admin',
},
{
    value: 'EDITOR',
    label: 'Editor',
},
{
    value: 'READ_ONLY',
    label: 'Read Only',
},]


export const Selected_Invitee_Role = {
    'READ_ONLY': 'Read Only',
    'ADMIN':'Admin',
    'EDITOR':'Editor'

}