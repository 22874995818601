import React from 'react';
var config = require("./../../../globalconfig");
const ChargeBee = window.ChargeBee;
const redirectCall = window.redirectCall;

class PaymentPortal extends React.Component {
    constructor(props) {
        super(props)
    }
    componentDidMount() {
        this.subscribeResponseEmbedHandler();
    }

    subscribeResponseEmbedHandler() {
        var hostedPageUrl = this.props.hostPageLink;
        var hostedPageId = config.uiServer + '/settings/payment';
        var iframeContainer = this.checkoutInfo;
        // need to uncomment
        
        ChargeBee.embed(hostedPageUrl, 'Local Clarity').load({   
            /*
             * This function will be called when iframe is created.
             * addIframe callback will recieve iframe as parameter.
             * you can use this iframe to add iframe to your page.
             * Loading image in container can also be showed in this callback.
             * Note: visiblity will be none for the iframe at this moment
             */
            addIframe(iframe) {
                iframeContainer.append(iframe);
            },

            /*
             * This function will be called once when iframe is loaded.
             * Since checkout pages are responsive you need to handle only height.
             */
            onLoad(iframe, width, height) {
                // hideProcessing();
                var style = 'border:none;overflow:visible;width:100%;';
                style = style + 'height:' + height + 'px;';
                // style = style + 'display:none;';//This is for slide down effect
                iframe.setAttribute('style', style);
                // $(iframe).slideDown(1000);
            },

            /*
             * This will be triggered when any content of iframe is resized.
             */
            onResize(iframe, width, height) {
                var style = 'border:none;overflow:visible;width:100%;';
                style = style + 'height:' + height + 'px;';
                iframe.setAttribute('style', style);
            },

            /*
             * This will be triggered when checkout is complete.
             */
            onSuccess(iframe) {
                redirectCall(hostedPageId);
            },

            /*
             * This will be triggered when user clicks on cancel button. 
             */
            onCancel(iframe) {
                console.log("cancel")
                iframeContainer.empty();
                this.props.closeModal();
                alert("Payment aborted!");
            }
        });
    }

    render() {
        return (
            <div className="payment-portal-container" ref={(input) => { this.checkoutInfo = input; }}>
            </div>
        )
    }
}

export default PaymentPortal;