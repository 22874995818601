import React from 'react';
import GetPortfolioUsersStore from '../store/GetPortfolioUsersStore';
export const generateTableData=()=> {
    return GetPortfolioUsersStore
        .getResponse().usersList
        .map(function(item, index) {
            return {
                key: index,
                userId: item.userId,
                name:item.name,
                //role: item.roleName,
                //roleType: item.role,
                inviter: item.inviter,
                portfolioUserStatus: item.portfolioUserStatus,
               // currentUser: item.currentUser,
               // signupDate: item.signupDate,
                //lastLogin: item.lastLogin,
                inviteDate: item.inviteDate,
                reInviteCount: item.reInviteCount,
                // isPaymentOwner:
                //     item.userId === item.profile.paymentOwner
            };
        });
}