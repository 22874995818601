export function colorSpectrumGenerator(color1, color2, steps) {
    var stepFactor = 1 / (steps - 1),
        colorArray = [];
    color1 = color1.match(/\d+/g).map(Number);
    color2 = color2.match(/\d+/g).map(Number);
    for (var i = 0; i < steps; i++) {
        colorArray.push(interpolateColor(color1, color2, stepFactor * i));
    }
    return colorArray;
}
const interpolateColor = (color1, color2, factor) => {
    var result = color1.slice();
    for (var i = 0; i < 3; i++) {
        result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
    }
    return result;
}