import AppDispatcher from '../../../flux/dispatcher/dispatcher';
import Constant from '../constants/GetHistogramConstant';

var ChangeProfileAction = function () {

}

ChangeProfileAction.prototype = {

	changeProfile: function (data) {
		AppDispatcher.dispatch({
			actionType: Constant.PROFILE_CHANGED,
			data: data
		});
	}
}

export default new ChangeProfileAction();