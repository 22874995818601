module.exports = {
    availableLanguages: {
        english: "ENGLISH",
        portuguese: "PORTUGUÊS",
        german: "DEUTSCH",
        spanish: "ESPAÑOL",
        french: "FRANÇAIS",
        日本語: "日本語",
        arabic: "عربى",
        italian: "ITALIANO",
        dutch: "NEDERLANDS",
        mandarin: "繁體中文",
        hindi:"हिंदी",
        vietnamese:"Tiếng Việt",
        thai:"ไทย",
        filipino:"Filipino"
    },
    languageDropDownDomains: ['app.localclarity.com','app.ormanagement.co','dev.localclarity.com','localhost:3000','app.bizzrep.com','app.localclarity.com.br','stage.localclarity.com'],
    languageCode: {
        english: "en",
        portuguese: "pt",
        german: "de",
        spanish: "es",
        french: "fr",
        日本語: "en",
        arabic: "en",
        italian: "it",
        dutch: "en",
        mandarin: "en",
        hindi:"en",
        vietnamese:"en",
        thai:"en",
        filipino:"en"
    },
    languageDropDown:[
        {label: 'EN : English', value: 'english'},
        {label: 'PT : Portugues', value: 'portuguese'},
        {label: 'DE : Deutsch', value: 'german'},
        {label: 'ES : Espanol', value: 'spanish'},
        {label: 'FR : Francais', value: 'french'},
        {label: 'JA : 日本語', value: '日本語'},
        {label: 'AR : عربى', value: 'arabic'},
        {label: 'IT : Italiano', value: 'italian'},
        {label: 'NL : Nederlands', value: 'dutch'},
        {label: 'ZH : 繁體中文', value: 'mandarin'},
        {label: 'HI : हिंदी', value: 'hindi'},
        {label: 'VI : Tiếng Việt', value: 'vietnamese'},
        {label: 'TH : ไทย', value: 'thai'},
        {label: 'PH : Filipino', value: 'filipino'},

    ],
    language2letterCode:{
        english: "EN",
        portuguese: "PT",
        german: "DE",
        spanish: "ES",
        french: "FR",
        日本語: "JA",
        arabic: "AR",
        italian: "IT",
        dutch: "NL",
        mandarin: "ZH",
        hindi:"HI",
        vietnamese:"VI",
        thai:"TH",
        filipino:"PH"
    }
}