import React from 'react';
import { LCTooltip } from '../../../frameWork/LCTooltip';
const SUBSCRIPTION_DETAILS='Subscription Details';
const moment = require('moment');

export const PlanDetailsHeader=({
    paymentDetails
})=>{
    return (
        <h4 className='header remark-text'>
					{SUBSCRIPTION_DETAILS}
					{paymentDetails.status === 'NON_RENEWING' && (
						<LCTooltip
						placement='left'
						title={
							'This profile will expire on ' +
							moment(
								paymentDetails.endOfTerm
							).format('ll')
						}
						children={	<span className='expiry-warning-label'>
						<i
							className='fa fa-exclamation-triangle'
							aria-hidden='true'></i>
					</span>}/>
					)}
				</h4>
    )
}