import React from 'react';
import { UnsubscribedPaymentHeader } from './UnsubscribedPaymentHeader';
import { UnsubscribedPaymentPlan } from './UnsubscribedPaymentPlan';
export const UnSubscribedUserPaymentWrapper=({openPaymentPortal})=>{
    return (
        <div>
       <UnsubscribedPaymentHeader/>
       <UnsubscribedPaymentPlan
       openPaymentPortal={openPaymentPortal}
       />
    </div>
    )
}