import Dispatcher from '../../../../flux/dispatcher/dispatcher';
import Constants from '../constants/constant';
var EventEmitter = require('events').EventEmitter;
var RESPONSE_CHANGE_EVENT = 'globalResponse';

var assign = require('object-assign');

var response = {};

function parseResponse(resp) {
    response = resp;
}

var PushNotificationStore = assign({}, EventEmitter.prototype, {
    emitChangeEvent: function (event) {
        this.emit(event);
    },
    bind: function (callback) {
        this.on(Constants.RESPONSE_CHANGE_EVENT, callback);
    },
    unbind: function (callback) {
        this.removeListener(Constants.RESPONSE_CHANGE_EVENT, callback);
    },
    getResponse: function () {
        return response;
    }
});

Dispatcher.register(function (action) {
    // console.log(action.actionType);
    switch (action.actionType) {
        case Constants.PUSH_NOTIFICATION:
            var resp = action.data;
            // console.log("PUSH NOTIFICATION ===>",resp);
            parseResponse(resp)
            PushNotificationStore.emitChangeEvent(Constants.RESPONSE_CHANGE_EVENT);
            break;

        default:
    }
});

export default PushNotificationStore;