import AppDispatcher from '../../../flux/dispatcher/dispatcher';
import Constant from '../constants/GetHistogramConstant';
import restClient from '../../../routers/MainFrame/action/APIClient';

var moment = require('moment');
var organization, country, locality, area;
var dateRange = {};

var TreemapAction = function () {

}


TreemapAction.prototype = {

	changeOrganisation: function (value) {
		organization = value;
		this.loadData();
	},

	addDates: function (fromDate, toDate) {
		dateRange.from = fromDate;
		dateRange.to = toDate;
		this.loadData();
	},

	addCountry: function (value) {
		country = value;
		locality = undefined;
		area = undefined;
		this.loadData()
	},

	addArea: function (value) {
		area = value;
		locality = undefined;
		this.loadData();
	},

	addLocality: function (value) {
		locality = value;
		this.loadData();
	},

	loadData: function () {
		if (!dateRange.from || !dateRange.to) {
			dateRange.from = moment().subtract(6, 'week');
			dateRange.to = moment();
		}
		var data = {
			organization: organization,
			filters: {
				dateRange: dateRange,
				country: country,
				locality: locality,
				area: area
			}
		};
		restClient.getReviewsTreemap(data, function (error, response) {
			if (error) {
				console.log("error", error);
			} else {
				AppDispatcher.dispatch({
					actionType: Constant.TREEMAP_RESPONSE,
					data: response
				});
			}
		})
	}
}

export default new TreemapAction();