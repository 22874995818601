import AppDispatcher from '../../../flux/dispatcher/dispatcher';
import Constant from '../constants/LoginConstant';
import restClient from '../../MainFrame/action/APIClient';
import logoutConstants from '../../MainFrame/constants/logoutConstants';

var GetWorkSpaceAction = function () {

}

GetWorkSpaceAction.prototype = {
    getWorkspace: function () {
        restClient.getWorkplace(function (error, response) {
			if (error) {
				if (error.status === 401) {
					AppDispatcher.dispatch({
						actionType: logoutConstants.UNAUTHORIZED_EXCEPTION,
						data: error
					});
				} 
				else{
				AppDispatcher.dispatch({
					actionType: Constant.GET_WORKSPACE_ERROR,
					data: error
				});
			}
			} else {
				AppDispatcher.dispatch({
					actionType: Constant.GET_WORKSPACE_RESPONSE,
					data: response
				});
			}
		});
	}
}

export default new GetWorkSpaceAction();

