import AppDispatcher from '../../../flux/dispatcher/dispatcher';
import Constant from '../constant/PaymentsConstant';
import restClient from '../../MainFrame/action/APIClient';
import LogoutConstant from '../../MainFrame/constants/Constants';
var HostPagePaymentUrlAction = function () {

}

HostPagePaymentUrlAction.prototype = {

	getUrl: function () {
		restClient.getHostPage(localStorage.getItem('profileId'), function (error, response) {
			if (error) {
				// console.log("error", error);
				if (error.status === 401) {
					AppDispatcher.dispatch({
						actionType: LogoutConstant.UNAUTHORIZED_EXCEPTION,
						data: error
					});
				}
			} else {
				AppDispatcher.dispatch({
					actionType: Constant.HOST_PAGE_PAYMENT_URL,
					data: response
				});
			}
		})
	}
}

export default new HostPagePaymentUrlAction();