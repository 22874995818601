import AppDispatcher from '../../../flux/dispatcher/dispatcher';
import Constant from '../constant/AuthoriseAccountConstant';
import LogoutConstant from '../../MainFrame/constants/Constants';
import restClient from '../../MainFrame/action/APIClient';


var GetProfileDetailsAction = function () {

}

GetProfileDetailsAction.prototype = {

	getProfileDetails: function (profileId,domainName) {
		restClient.getProfileDetails(profileId,domainName, function (error, response) {
			if (error) {
				if (error.status === 401) {
					AppDispatcher.dispatch({
						actionType: LogoutConstant.UNAUTHORIZED_EXCEPTION,
						data: error
					});
				} else if (error.status === 404) {
					AppDispatcher.dispatch({
						actionType: Constant.GET_PROFILE_DETAILS,
						data: error
					});
				}

			} else if(response != null){
				response['status'] = 200;
				AppDispatcher.dispatch({
					actionType: Constant.GET_PROFILE_DETAILS,
					data: response
				});
			}
		})
	}
}

export default new GetProfileDetailsAction();