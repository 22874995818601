import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from 'antd';
import languageFile from '../../../../public/languages/Translation';

const ACTIVE_CLASSNAME = "active";
const EMPTY_STRING = "";
const LANGUAGE_IDENTIFIER = "language";

export const SideBarRouteItem = ({ config, routePath, index, onLinkClick }) => {
    return <li onClick={onLinkClick.bind(this, index)}>
    {renderChildrenTypes(config, routePath)}
</li>
}


const renderChildrenTypes = (config, routePath) => {
 
    return renderLink(config, routePath);
}


const renderLink = (config, routePath, childrens = []) => {
    return [
        <Link
            key={0}
            className={checkIsActive(routePath, config.to)}
            to={config.to}>
            {determineWhichIconToRender(config)}
            {languageFile[localStorage.getItem(LANGUAGE_IDENTIFIER)][config.label] || config.label}
        </Link>,
        childrens]
}


const determineWhichIconToRender = ({ iconName, iconType, altText }) => {
    let iconMap = {
        icon: (name) => <Icon type={name} />,
        image: (name, altText, src) => <img alt={altText} src={name} />,
        i: (name) => <i className={name} aria-hidden="true"></i>,
    }
    return iconMap[iconType](iconName, altText)
}

const checkIsActive = (routePath, to) => {
    return (routePath === to|| routePath.includes(to)) ? ACTIVE_CLASSNAME : EMPTY_STRING;
}