import AppDispatcher from '../../../flux/dispatcher/dispatcher';
import Constant from '../constants/GetHistogramConstant';
import restClient from '../../MainFrame/action/APIClient';
import LogoutConstant from '../../MainFrame/constants/Constants';
var moment = require('moment');

var GetSuggestionsAction = function () {

}

GetSuggestionsAction.prototype = {

	getSuggestions: function (data, profileId, masterLocationFilter) {
		var dataObject = data;
		dataObject.profileId = profileId;
		dataObject.masterLocationFilter = localStorage.getItem('masterLocationFilter');
		restClient.getReviewSuggestions(dataObject, function (error, response) {
			if (error) {
				// console.log("error", error);
				if (error.status === 401) {
					AppDispatcher.dispatch({
						actionType: LogoutConstant.UNAUTHORIZED_EXCEPTION,
						data: error
					});
				}
			} else  {
				if(response != null){ response["status"] = 200}
				AppDispatcher.dispatch({
					actionType: Constant.GET_SUGGESTIONS,
					data: response
				});
			}
		})
	}
}

export default new GetSuggestionsAction();