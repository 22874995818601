import Dispatcher from '../../../flux/dispatcher/dispatcher';
import Constants from '../constants/GetHistogramConstant';

var EventEmitter = require('events').EventEmitter;
var RESPONSE_CHANGE_EVENT = 'globalResponse';
var assign = require('object-assign');

var response = {};

function parseResponse(resp) {
    response.actionsCount = resp.ACTIONS_DRIVING_DIRECTIONS + resp.ACTIONS_PHONE + resp.ACTIONS_WEBSITE;
    let viewSearch = resp.VIEWS_SEARCH || 0;
    let viewMaps = resp.VIEWS_MAPS ||0;
    let desktopMaps = resp.BUSINESS_IMPRESSIONS_DESKTOP_MAPS ||0;
    let desktopSearch = resp.BUSINESS_IMPRESSIONS_DESKTOP_SEARCH||0;
    let mobileMaps = resp.BUSINESS_IMPRESSIONS_MOBILE_MAPS || 0;
    let mobileSearch = resp.BUSINESS_IMPRESSIONS_MOBILE_SEARCH||0;
    response.viewsCount =mobileMaps +viewMaps+desktopMaps
    response.searchesCount =desktopSearch+mobileSearch+viewSearch;
    // response.searchesCount = resp.QUERIES_DIRECT + resp.QUERIES_INDIRECT + resp.QUERIES_CHAIN;
    response.total = resp.total;
    // response.searchesToViews = Math.floor((resp.VIEWS_SEARCH/searchesCount)*100);
    // response.searchesToViews = Math.floor((resp.VIEWS_SEARCH/searchesCount)*100);
    // response.viewsToActions = Math.floor((actionsCount/resp.VIEWS_SEARCH)*100);
    // response.viewsToActions = Math.floor((actionsCount/resp.VIEWS_SEARCH)*100);
}

var GetLocationInsightStore = assign({}, EventEmitter.prototype, {
    emitChangeEvent: function (event) {
        this.emit(event);
    },
    bind: function (callback) {
        this.on(RESPONSE_CHANGE_EVENT, callback);
    },
    unbind: function (callback) {
        this.removeListener(RESPONSE_CHANGE_EVENT, callback);
    },
    getResponse: function () {
        return response;
    }
});

Dispatcher.register(function (action) {

    switch (action.actionType) {
        case Constants.LOCATION_INSIGHTS:
            var resp = action.data;
            // console.log("locations insights", resp)
            parseResponse(resp);
            GetLocationInsightStore.emitChangeEvent(RESPONSE_CHANGE_EVENT);
            break;

        default:
    }
});

export default GetLocationInsightStore;