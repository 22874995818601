import React from 'react';
import { Button } from 'antd';

export const LCButton = props => (
    <Button {...props}>{props.buttonlabel}</Button>
);


// code for reconstructing LCButton  & RenderButton

// export const LCButton = props => (
//     <Button {...props}>{props.children}</Button>
// );

// export const RenderButton = ({
//     disabled,
//     icon,
//     type,
//     onClick,
//     buttonlabel
// }) => {
//     return (
//         <LCButton
//             disabled={disabled}
//             icon={icon}
//             type={type}
//             onClick={onClick}
//         >
//         {
//             [
//                 <i style={{ color: "#7A98BF" }} className="fa fa-user-plus" aria-hidden="true"></i>,
//                 buttonlabel
//             ]
//         }
//         </LCButton>
//     );
// };
