var keyMirror = require('keymirror');

module.exports = keyMirror({
    GET_REVIEW_RESPONSE_HISTOGRAM:null,
    GET_HISTOGRAM: null,
    GET_PIE_CHART: null,
    GET_SUGGESTIONS: null,
    TREEMAP_RESPONSE: null,
    LOCATION_STATES: null,
    LOCATION_INSIGHTS: null,
    ACTIONS_TYPE_RESPOSNE: null,
    VIEWS_TYPE_RESPONSE: null,
    QUERIES_TYPE_RESPONSE: null,
    LOADING_CHANGE: null,
    PROFILE_CHANGED: null,
    DATA_STATUS: null,
    DATA_STATUS_ERROR: null,
    DATA_FETCHING_STATUS: null,
    REVIEWS_COUNT_RESPONSE: null,
    TOP_LOCATIONS_RESPONSE: null,
    PHOTOS_TYPE_RESPOSNE: null,
    GET_LOCATION_TAGS_RESPONSE: null,
    REPLIES_BY_STAR_RATING: null,
    DOWNLOAD_REVIEW_LOCATION: null,
    PHOTOS_TYPE_RESPOSNE_CUSTOMER: null,
    GET_DATEWISE_HISTOGRAM: null,
    DATEWISE_INSIGHT_HISTO_PHONE: null,
    FILTER_SAVING_ACROSS_TABS: null,
    GET_RESPONSE_HISTOGRAM:null,
    GET_COMMENTS_WORD_COUNT:null,
    GET_COMMENT_COUNT:null,
    REVIEW_RESPONSE_TIME: null,
    WORD_HISTOGRAM: null,
    COMMENT_HISTOGRAM: null,
});