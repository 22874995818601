import React from 'react';
const INVOICE_ID='Invoice Id';
const AMOUNT='Amount';
const DATE='Date';
const PAYMENT_STATUS='Payment status';
export const Invoices = ({
    getInvoicesList,
    invoicesData
}) => {
	return (
			<div className='payment-invoices'>
				<h4 className='header'>Invoices</h4>
				<div className='payment-ledger table-responsive'>
					<table className='table table-bordered'>
						<tbody>
							<tr className='payment-group-header'>
								<th>{INVOICE_ID}</th>
								<th>{AMOUNT}</th>
								<th>{DATE}</th>
								<th colSpan='2'>{PAYMENT_STATUS}</th>
							</tr>
							{getInvoicesList(invoicesData)}
						</tbody>
					</table>
				</div>
			</div>
	);
};
