import React from 'react';
import { Modal } from 'antd';
import PaymentPortal from '../../../components/PaymentPortal';
import HostPagePaymentUrlStore from '../store/HostPagePaymentUrlStore';
import { LCModal } from '../../../frameWork';

export const UpdateCardModalWindow = ({
    openPaymentModal,
    handleCancel,
    handleOk,
	paymentUrl,
	authorizePage
}) => {
	return (
		<LCModal
			key={Math.random()}
			title='Update payment card'
			width={600}
			wrapClassName='payment-card'
			style={{top: 20}}
			visible={openPaymentModal}
			onOk={handleOk}
			onCancel={handleCancel}
			footer={null}
			modalContent={<PaymentPortal
				closeModal={handleCancel}
				authorizePage={authorizePage}
				hostPageLink={
				paymentUrl || HostPagePaymentUrlStore.getResponse().URL
				}
			/>}
		/>
	);
};
