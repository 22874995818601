import AppDispatcher from '../../../flux/dispatcher/dispatcher';
import Constant from '../constant/AuthoriseAccountConstant';
import LogoutConstant from '../../MainFrame/constants/Constants';
import restClient from '../../MainFrame/action/APIClient';
import urlConfig from '../../../config/index';
var cookie = require("../../../utils/cookie")

var GetUserAction = function () {

}

GetUserAction.prototype = {

	get: function () {
		var userId = cookie.readCookie("userId");
		restClient.getUser(userId, function (error, response) {
			if (error) {
				if (error.status === 401) {
					AppDispatcher.dispatch({
						actionType: LogoutConstant.UNAUTHORIZED_EXCEPTION,
						data: error
					});
				}
			} else {
				AppDispatcher.dispatch({
					actionType: Constant.GET_USER_IMAGE_DATA,
					data: response
				});
			}
		})
	}
}

export default new GetUserAction();