import axios from 'axios';
import AppDispatcher from '../../../flux/dispatcher/dispatcher';
import config from '../../../../globalconfig';
import urlConfig from '../../../config/index';
import Constant from '../constants/LoginConstant';

var GoogleLoginAction = function () {

}

GoogleLoginAction.prototype = {
    login: function (idToken) {
        let data ={
            domainName:urlConfig.urlName,
            idToken:idToken
        }

        // axios({
        //     url: config.server + "/googleSignin?idToken=" + idToken + "&domainName=" + urlName,
        //     type: 'GET',
        //     dataType: 'JSON',
        //     contentType: "application/json; charset=utf-8",
        //     data: JSON.stringify(idToken),

        //     success: function(resp) {
        //         console.log("action......", resp);
        //         AppDispatcher.dispatch({
        //             actionType: Constant.SIGN_IN_RESPONSE_RECIEVED,
        //             data: resp
        //         });
        //     },
        //     error: function(err) {
        //         if(err.status===401){
        //             AppDispatcher.dispatch({
        //                 actionType: LogoutConstant.LOGOUT_RESPONSE,
        //                 data: err
        //             });
        //         }
        //         AppDispatcher.dispatch({
        //             actionType: Constant.SIGN_IN_ERROR,
        //             data: err
        //         });

        //     }
        // });
        axios.post(config.server + "/googleSignin",data)
            .then((resp) => {
                AppDispatcher.dispatch({
                    actionType: Constant.SIGN_IN_RESPONSE_RECIEVED,
                    data: resp.data
                });
            })
            .catch((err) => {
                // if(err.status===401){
                //     AppDispatcher.dispatch({
                //         actionType: LogoutConstant.LOGOUT_RESPONSE,
                //         data: err
                //     });
                // }
                AppDispatcher.dispatch({
                    actionType: Constant.SIGN_IN_ERROR,
                    data: err
                });
            })
    }
}

export default new GoogleLoginAction();