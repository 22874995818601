import React from 'react'
import { Button } from 'antd'
import { Modal } from 'antd'
import { PropTypes } from 'prop-types';
import style from './style.module.scss';
import { PROFILE_SET_UP } from './Constants';
import { ProfileCoreDetails } from '../../routers/AuthorizePage/children/ProfilePage/children/ProfileCoreDetails';
import { RenderModalForm } from '../../routers/AuthorizePage/children/ProfilePage/children/RenderModalForm';

var languageFile = require('../../../public/languages/Translation')
let _ = require('lodash')

export const ProgressiveModalWindow=(props)=>{
    return (
        <Modal
          wrapClassName='model-window'
          onCancel={props.onCancel}
          width={props.width}
          footer={null}
          visible={props.visible}
        >
          {props.children}
        </Modal>
      )
}