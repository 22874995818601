import React from 'react';
import {LCInput} from '../../../frameWork';
var languageFile = require('../../../../public/languages/Translation.js');

export const RenderEmailInputField = ({userEmail, addEmail,emailVisible}) => {
    return (
        <div className='email-input'>
            <span>{languageFile[localStorage.getItem('language')]['28161']}</span>
            <LCInput
                placeholder={languageFile[localStorage.getItem('language')]['28161']}
                size='small'
                disabled={emailVisible}
                value={userEmail}
                onChange={addEmail}
            />
        </div>
    );
};
