import AppDispatcher from '../../../flux/dispatcher/dispatcher';
import Constant from '../constant/Constant';
import restClient from '../../../routers/MainFrame/action/APIClient';

var GetNotificationEditedFieldsAction = function () {

}

GetNotificationEditedFieldsAction.prototype = {

	getNotificationEditedFields: function (data) {
		restClient.getNotificationEditedFields(data, function (error, response) {
			if (error) {
				// console.log("error", error);
				AppDispatcher.dispatch({
					actionType: Constant.GET_EDIT_NOTIFICATIONS_FILTER,
					data: error
				});
			} else if(response != null) {
				response['status'] = 200;
				AppDispatcher.dispatch({
					actionType: Constant.GET_EDIT_NOTIFICATIONS_FILTER,
					data: response
				});
			}
		})
	}
}

export default new GetNotificationEditedFieldsAction();