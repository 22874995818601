import React from 'react';
const REMARK_TEXT='Above details pertain to the next scheduled '+
' payment only. Charges incurred while '+
'activating locations in the middle of the '+
'subscription term will not be shown here.';
export const RenderPlanTableRemarkText=()=>{
    return (
        <div className='remark-text'>
						{REMARK_TEXT}
					</div>
    )
}