import { Route, Redirect } from 'react-router-dom';
import React from 'react';
var cookie = require('../../utils/cookie');

var analytics = window.analytics;

function recordPageView(page) {
	if (localStorage.getItem('language') === null) {
		localStorage.setItem('language', 'english');
	}
	analytics.page(page);
}

const ProtectedRoute = ({ component: Component, ...rest }) => {
	recordPageView(rest.path);
	return (
		<Route
			{...rest}
			render={(props) =>
				cookie.readCookie('lctoken') ? (
					<Component {...{ ...props, ...rest }} />
				) : (
					<Redirect
						to={{
							pathname: '/login',
							state: { from: props.location },
						}}
					/>
				)
			}
		/>
	);
};

export default ProtectedRoute;
