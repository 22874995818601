import React from 'react';
var cookie = require('../../utils/cookie');

class AcceptAuth extends React.Component {

  render() {
    cookie.createCookie("AcceptAuth", true, 1)
    // console.log("Cookie data is ", cookie.readCookie("AcceptAuth"));
    window.close();
    return null
  }
}

export default AcceptAuth;