import React from 'react';
import HostPagePaymentUrlAction from './action/HostPagePaymentUrlAction';
import HostPagePaymentUrlStore from './store/HostPagePaymentUrlStore';
import PaymentUrlAction from './action/PaymentUrlAction';
import PaymentUrlStore from './store/PaymentUrlStore';
import GetSubscriptionDetailsAction from './action/GetSubscriptionDetailsAction';
import UpdatePayerAction from './action/UpdatePayerAction';
import UpdatePayerStore from './store/UpdatePayerStore';
import GetSubscriptionDetailsStore from './store/GetSubscriptionDetailsStore';
import InvoiceListAction from './action/InvoiceListAction'
import InvoiceListStore from './store/InvoiceListStore';
import CancelSubscriptionAction from './action/CancelSubscriptionAction';
import CancelSubscriptionStore from './store/CancelSubscriptionStore';
import DownloadInvoiceAction from './action/DownloadInvoicePdfAction';
import DownloadInvoiceStore from './store/DownloadInvoiceStore';
import { PaymentPageWrapper } from './childrens/PaymentPageWrapper';
import { LCMessage } from '../../frameWork';
import ReactiveSubscriptionAction from './action/ReactiveSubscriptionAction';
import ReactiveSubscriptionStore from './store/ReactiveSubscriptionStore';
import { Modal } from 'antd';
import { languageCode } from '../../utils/languageConstants';
var moment = require('moment');
const PROFILE_ID='profileId';
const PAYMENT_EXPIRED='paymentExpired';
const NO_PERMISSION='You do not have sufficient permission to perform this action.';
const confirm = Modal.confirm;
class Payment extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			openPaymentModal: false,
			loading: true,
			isSubscriptionCancelled:false
		}
	}
	componentWillMount() {
		PaymentUrlStore.bind(this.onReceivingPaymentUrl);
		HostPagePaymentUrlStore.bind(this.onHostPageUrl);
		GetSubscriptionDetailsStore.bind(this.onPaymentDetails);
		UpdatePayerStore.bind(this.onUpdatingOwner);
		CancelSubscriptionStore.bind(this.onCancellingSubscription);
		InvoiceListStore.bind(this.onInvoicesResponse);
		DownloadInvoiceStore.bind(this.onDownloadingInvoice);
		ReactiveSubscriptionStore.bind(this.onResubscriptionActive);
	}

	componentWillUnmount() {
		PaymentUrlStore.unbind(this.onReceivingPaymentUrl);
		HostPagePaymentUrlStore.unbind(this.onHostPageUrl);
		GetSubscriptionDetailsStore.unbind(this.onPaymentDetails);
		UpdatePayerStore.unbind(this.onUpdatingOwner);
		CancelSubscriptionStore.unbind(this.onCancellingSubscription);
		InvoiceListStore.unbind(this.onInvoicesResponse);
		DownloadInvoiceStore.unbind(this.onDownloadingInvoice);
		ReactiveSubscriptionStore.unbind(this.onResubscriptionActive);
	}

	componentDidMount() {
		GetSubscriptionDetailsAction.getDetails();
		InvoiceListAction.getList();
	}

	onDownloadingInvoice=()=> {
		window.open(DownloadInvoiceStore.getResponse())
	}
	onResubscriptionActive = () => {
		if(ReactiveSubscriptionStore.getResponse().status==200){
			GetSubscriptionDetailsAction.getDetails();
			LCMessage.success(ReactiveSubscriptionStore.getResponse().message);
		}else if(ReactiveSubscriptionStore.getResponse().data && ReactiveSubscriptionStore.getResponse().data.message) {
			LCMessage.error(ReactiveSubscriptionStore.getResponse().data.message);
		}else {
			LCMessage.error("Something went wrong");
		}
	}

	onCancellingSubscription=()=> {
		let data=CancelSubscriptionStore.getResponse();
		this.setState({
			cancelSubscriptionResponseMessage:data.message,
			isSubscriptionCancelled:true
		})
	}

	onInvoicesResponse=()=> {
		this.setState({
			invoicesData: InvoiceListStore.getResponse()
		})
	}

	onUpdatingOwner=()=> {
		GetSubscriptionDetailsAction.getDetails();
		let data=UpdatePayerStore.getResponse();
		this.setState({
			ownerUpdateResponseMessage:data.data.message,
			changePayerDetailsModal: false,
			ownerUpdatedMessage: true
		})
	}

	onPaymentDetails=() =>{
		if (GetSubscriptionDetailsStore.getResponse().status === 404) {
			this.setState({
				notSubscribed: true,
				loading: false
			})
		} else {
			this.setState({
				notSubscribed: false,
				paymentDetails: GetSubscriptionDetailsStore.getResponse(),
				loading: false,
			})
		}
	}

	onReceivingPaymentUrl=()=> {
		if (PaymentUrlStore.getResponse().status == 403) {
			LCMessage.warning(NO_PERMISSION)
		} else {
			window.location = PaymentUrlStore.getResponse().url+`&customer[locale]=${languageCode[localStorage.getItem('language')]}`;
		}
	}

	onHostPageUrl=()=> {
		this.setState({
			openPaymentModal: true
		})
	}

	getHostPage=()=> {
		if (!this.state.openPaymentPortal) {
			HostPagePaymentUrlAction.getUrl();
		}
	}

	openPaymentPortal=()=> {
		var profileId = localStorage.getItem(PROFILE_ID);
		if (this.props.history.location.pathname.indexOf(PAYMENT_EXPIRED) > -1) {
			profileId = this.props.profileId;
		}
		PaymentUrlAction.getUrl(profileId);
	}

	handleCancel=()=> {
		this.setState({
			openPaymentModal: false,
			changePayerDetailsModal: false,
			ownerUpdatedMessage: false,
			cancelConfirmationModal: false,
			isSubscriptionCancelled:false,
			cancelSubscriptionResponseMessage:false,
			payerEmail:''

		})
	}

	changeEmailModal=()=> {
		this.setState({
			changePayerDetailsModal: true
		})
	}

	changeOwner=()=> {
		if (!this.validateEmail(this.state.payerEmail)) {
			this.setState({
				invalidEmail: true
			})
		} else {
			this.setState({
				invalidEmail: false
			})
			UpdatePayerAction.update(this.state.payerEmail);
		}

	}

	getAddonDetails=(data,isTotalPrice)=> {
		var totalLocationCharge = 0;
		var addonCharges = data.map(function (item, index) {
			totalLocationCharge += item.quantity * (item.unit_price / 100);
			return <tr key={item.Id}>
				<td>
					Charge for {item.quantity} locations ( ${item.unit_price / 100} per location )
						</td>
				<td>
					$ {item.quantity * (item.unit_price / 100)}
				</td>
			</tr>
		})
		addonCharges.push(<tr key={0}>
			<td>
				Total location charges
			</td>
			<td>
				$ {totalLocationCharge}
			</td>
		</tr>)
		if(isTotalPrice){
			return totalLocationCharge
		}else {
			return addonCharges
		}
	}

	cancelConfirmation=()=> {
		this.setState({
			cancelConfirmationModal: true
		})
	}

	reActiveConfirmation = () => {
		confirm({
			title: 'Are you sure?',
			content: 'Would you like to re-activate the subscription',

			onCancel() {
			},
			onOk() {
				ReactiveSubscriptionAction.reactive()
			}
		});
	}
	cancelSubscription=()=> {
		this.setState({
			cancelConfirmationModal: false
		})
		CancelSubscriptionAction.deactivate();
	}

	downloadInvoice=(id)=> {
		DownloadInvoiceAction.downloadInvoice(id)
	}

	validateEmail=(email)=> {
		var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email);
	}

	getInvoicesList=(data)=> {
		if (data) {
			var invoicesTable = data.map(function (item, index) {
				return <tr key={index}>
					<td>
						{item.invoice.id}
					</td>
					<td>
						$ {item.invoice.amount_paid / 100}
					</td>
					<td>
						{moment(item.invoice.date * 1000).local().format('ll')}
					</td>
					<td style={{ textTransform: 'Uppercase' }}>
						{item.invoice.status}
					</td>
					<td>
						<a onClick={this.downloadInvoice.bind(this, item.invoice.id)} style={{color:'#06a7e1'}}>Download Invoice</a>
					</td>
				</tr>
			}, this)
			return invoicesTable;
		} else {
			return null
		}
	}

	updatePayerEmail=(e)=> {
		this.setState({
			payerEmail: e.target.value
		})
	}

	render() {
		return <PaymentPageWrapper
		history={this.props.history}
         role={this.props.role}
         loading={this.state.loading}
         notSubscribed={this.state.notSubscribed}
         paymentDetails={this.state.paymentDetails}
         getHostPage={this.getHostPage}
         cancelSubscription={this.cancelSubscription}
         handleCancel={this.handleCancel}
         handleOk={this.handleOk}
         cancelConfirmationModal={this.state.cancelConfirmationModal}
         cancelConfirmation={this.cancelConfirmation}
         getAddonDetails={this.getAddonDetails}
         invoicesData={this.state.invoicesData}
         getInvoicesList={this.getInvoicesList}
         openPaymentModal={this.state.openPaymentModal}
         invalidEmail={this.state.invalidEmail}
         changeOwner={this.changeOwner}
         changePayerDetailsModal={this.state.changePayerDetailsModal}
         changeEmailModal={this.changeEmailModal}
         updatePayerEmail={this.updatePayerEmail}
		 payerEmail={this.state.payerEmail}
		 isSubscriptionCancelled={this.state.isSubscriptionCancelled}
		 cancelSubscriptionResponseMessage={this.state.cancelSubscriptionResponseMessage}
		 ownerUpdatedMessage={this.state.ownerUpdatedMessage}
		 ownerUpdateResponseMessage={this.state.ownerUpdateResponseMessage}
		 openPaymentPortal={this.openPaymentPortal}
		 reActiveConfirmation={this.reActiveConfirmation}
		 
		/>
	}
}

export default Payment;
