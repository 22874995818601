import AppDispatcher from '../../../flux/dispatcher/dispatcher';
import LogoutConstant from '../../MainFrame/constants/logoutConstants';
import restClient from '../../MainFrame/action/APIClient';

var LogoutAction = function () {

}

LogoutAction.prototype = {

	log: function (token) {
		restClient.logout(token, function (error, response) {
			if (error) {
				// console.log("error", error);
				if (error.status === 401) {
					AppDispatcher.dispatch({
						actionType: LogoutConstant.UNAUTHORIZED_EXCEPTION,
						data: error
					});
				}
			} else {
				// console.log("logged out")
			}
		})
	}
}

export default new LogoutAction();