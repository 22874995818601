import React from 'react';

class TermsAndPolicy extends React.Component {
    render() {
        return (
            <div className="terms-policy" style={{ backgroundColor: 'white' }}>
                <h2>Terms and Service</h2>
                <p>This Terms of Service Agreement (“Agreement”) constitutes a legally binding agreement made between you, whether personally or on behalf of an entity (“Customer” or “you”) and Actonia,
                    Inc. and its affiliated companies (collectively, “Company” or “we” or “us” or “our”), concerning your access to and use of the Subscription Service (defined below). Supplemental terms and conditions
                    or documents that may be posted on our Website (defined below) from time to time, are hereby expressly incorporated into this Agreement by reference.</p>
                <p>YOU ACCEPT AND AGREE TO BE BOUND BY THIS AGREEMENT BY ACKNOWLEDGING SUCH ACCEPTANCE DURING THE ORDERING PROCESS AND ALSO BY CONTINUING TO USE THE SUBSCRIPTION SERVICE.
                    IF YOU DO NOT AGREE TO ABIDE BY THIS AGREEMENT, OR TO MODIFICATIONS THAT COMPANY MAY MAKE TO THIS AGREEMENT IN THE FUTURE, DO NOT USE OR ACCESS OR CONTINUE TO USE OR ACCESS THE SUBSCRIPTION SERVICE.</p>
                <h4></h4>
                <p>“Agreement” means these Terms of Service and all materials referred or linked to in this document.</p>
                <p>“Company Content” means all information, data, text, messages, software, sound, music, video, photographs, graphics, images, and tags that we incorporate into the Subscription Service or Consulting Service.</p>
                <p>“Confidential Information” means all information provided by you or us (“Discloser”) to the other (“Receiver”), whether orally or in writing that is designated as confidential. Confidential Information will include Customer Data and information about the Discloser’s business plans, technical data, and the terms of the Order. Confidential Information does not include any information that (i) is or becomes generally known to the public without breach of any obligation owed to the Discloser or (ii) was known to the Receiver before receipt from the Discloser.</p>
                <p>“Consulting Service” means the professional services provided to you by us, which may include training services, installation, integration or other Consulting Service.</p>
                <p>“Customer Data” means all information that you submit or collect via the Subscription Service.</p>
                <p>“Customer Materials” means all information, data, text, messages, sound, music, video, photographs, graphics, images, and tags that you provide or post, upload, input or submit for public display through the Subscription Service.</p>
                <p>“Information Services” means third-party services, online communities, review sites and/or other communication facilities (such as Google My Business, Yelp and Facebook) linked to the Subscription Service that contain information and/or reviews about your business.</p>
                <p>“Order” or “Order Form” means the Company-approved form or online subscription process by which you agree to subscribe to the Subscription Service and purchase the Consulting Service. Most Orders are completed through our online payment process or via in-app purchases.</p>
                <p>“Pricing Page” means the page on our Website that contains pricing for the Subscription Service.</p>
                <p>“Review Responses” means your responses and interactions relating to consumer reviews on the Information Services.</p>
                <p>“Sensitive Information” means (a) credit or debit card numbers; personal financial account information; Social Security numbers or local equivalents; passport numbers; driver’s license numbers or similar identifiers; passwords; racial or ethnic origin; physical or mental health condition or information; or other employment, financial or health information, including any information subject to the Health Insurance Portability and Accountability Act, the Payment Card Industry Data Security Standards, and other regulations, laws or industry standards designed to protect similar information; and (b) any information defined under EU data protection laws as ‘Sensitive Personal Data’.</p>
                <p>“Subscription Fee” means the amount you pay for the Subscription Service.</p>
                <p>“Subscription Service” means all of our analytics, review response, landing page, content management applications, tools and platforms that you have subscribed to by an Order Form or that we otherwise make available to you, and are developed, operated, and maintained by us, accessible via our Website or another designated URL, and any ancillary products and services, including website hosting, that we provide to you.</p>
                <p>“Subscription Term” means the initial term of your subscription to the applicable Subscription Service, as specified on your Order Form(s), and each subsequent renewal term (if any).</p>
                <p>“Third-Party Products” means non-embedded products and professional services that are provided by third parties which interoperate with or are used in connection with the Subscription Service.</p>
                <p>“Third-Party Sites” means third-party websites linked to from within the Subscription Service, including Information Services.</p>
                <p>“Users” means your employees, representatives, consultants, contractors or agents who are authorized to use the Subscription Service for your benefit and have unique user identifications and passwords for the Subscription Service.</p>
                <p>“Website” means our website located at www.reputationmanager.com.</p>
                <h4>B. GENERAL COMMERCIAL TERMS</h4>
                <p>1.<strong>Access.</strong>During the Subscription Term, we will provide you access to use the Subscription Service as described in this Agreement and the applicable Order. We might provide some or all elements of the Subscription Service through third party service providers.</p>
                <p>2.<strong>Additional Features.</strong>You may subscribe to additional features of the Subscription Service by placing an additional Order or activating the additional features from within your Company portal (if this option is made available by us.). This Agreement will apply to all additional Order(s) and all additional features that you activate from within your Company portal.</p>
                <p>3.<strong>Availability.</strong>We try to make the Subscription Service available 24 hours a day, 7 days a week, except for planned down-time for maintenance.</p>
                <p>4.<strong>Consulting Service.</strong>You may purchase the Consulting Service by placing an Order with us. Fees for the Consulting Service are in addition to your Subscription Fee. If you purchase a Consulting Service that recurs, it will be considered part of your subscription and will renew in accordance with your subscription. The Consulting Service is performed remotely, unless you and we otherwise agree. For Consulting Service performed on-site, you will reimburse us our reasonable costs for all expenses incurred in connection with the Consulting Service. Any invoices or other requests for reimbursements will be due and payable within thirty (30) days of the date of the invoice. We might provide some or all elements of the Consulting Service through third party service providers.
                The Consulting Service is non-cancellable and all fees for the Consulting Service are non-refundable.</p>
                <p>5.<strong>Fees and Payment.</strong></p>
                <p>a.<u>Subscription Fees.</u> The Subscription Fee will remain fixed during the Subscription Term unless you: (i) increase
                the number of locations utilizing the Subscription Service, (ii) upgrade products or base packages, or (iii) subscribe to additional features or products. Once increased, your Subscription Fee will not decrease, even if there is a subsequent reduction in the number of locations until the end of the then-current Subscription Term.</p>
                <p>b.<u>Fee Adjustments at Renewal.</u> Prior to renewal of your Subscription Term, you will be notified of any increase in Subscription Fees.</p>
                <p>c.<u>Payment by credit card.</u> If you are paying by credit card, you authorize us to charge your credit card or bank account for all fees payable during the Subscription Term. You further authorize us to use a third party to process payments, and consent to the disclosure of your payment information to such third party.</p>
                <p>d.<u>Payment against invoice.</u> If you are paying by invoice, we will invoice you during the Subscription Term when fees are payable. All amounts invoiced are due and payable within thirty (30) days from the date of the invoice, unless otherwise specified in the Order Form.</p>
                <p>e.<u>Payment Information.</u> You will keep your contact information, billing information and credit card information (where applicable) up to date. Changes may be made on your billing page within your Company portal. All payment obligations are non-cancelable and all amounts paid are
                 non-refundable, except as specifically provided for in this Agreement. All Subscription Fees are due and payable in advance throughout the Subscription Term. If you are a purchasing as an agency on behalf of a client, you agree to be responsible for the Order Form and to guarantee payment of all fees.</p>
                <p>f.<u>Sales Tax. </u> All fees are exclusive of taxes, which we will charge as applicable. You agree to pay any taxes applicable to your use of the Subscription Service and performance of a Consulting Service. You shall have no liability for any taxes based upon our gross revenues or net income. At our request, you will provide us with the VAT registration number under which you are registered in your member state. If you are subject to GST, all fees are exclusive of GST. If you are required to deduct or withhold any tax, you must pay the amount deducted or withheld as required by law and pay us an additional amount so that we receive payment in full as if there were no deduction or withholding.</p>
                <p>g.<u>Late Payments.</u> We may charge a late fee equal to the lesser of 1½% per month and the highest rate allowable under applicable law. Additionally, you will reimburse us for any costs, including attorneys’ fees, that we incur collecting unpaid fees.</p>
                <p>6.<strong>Use and Limitations of Use.</strong></p>
                <p>a.<u>Review Responses.</u> The Subscription Service notifies you of consumer reviews appearing on the Information Services and facilitates Review Responses to those reviews. It is your obligation to ensure that all Review Responses are consistent with applicable laws, regulations and proper industry conduct.</p>
                <p>b.<u>Landing Pages. </u> The Subscription Service provides functionality for the creation and updating of landing pages for each of your locations. You are solely responsible to the accuracy of data and information as well as any other content that appears on the landing pages. The landing pages are hosted on our subdomains.</p>
                <p>c.<u>Use of Information Services.</u> You agree to use and interact with Information Services only in compliance with any terms of use specified by each Information Service. We do not control the content, messages or information found in the Information Services. We will not have any liability with regards to the Information Services and any actions resulting from your use of or interactions with the Information Services.</p>
                <p>d.<u>Prohibited and Unauthorized Use.</u> You will not (i) use or launch any automated system, including, “robots,” “spiders,” or “offline readers,” that sends more request messages to our servers in a given period of time than a human can reasonably produce in the same period by using a conventional browser; (ii) use the Subscription Service in any manner that damages, disables, overburdens, or impairs any of our websites or interferes with any other party’s use of the Subscription Service; (iii) attempt to gain unauthorized access to the Subscription Service; (iv) access the Subscription Service other than through our interface; or (v) use the Subscription Service for any purpose or in any manner that is unlawful or prohibited by this Agreement.</p>
                <p>You may not use the Subscription Service if you are legally prohibited from receiving or using the Subscription Service under the laws of the country in which you are resident or from which you access or use the Subscription Service.</p>
                <p>The Subscription Service is not designed to comply with industry-specific regulations such as the Health Insurance Portability and Accountability Act (HIPAA), the Gramm-Leach-Bliley Act (GLBA), or the Federal Information Security Management Act (FISMA), so you may not use the Subscription Service where your communications would be subject to such laws.</p>
                <p>You will notify us right away of any unauthorized use of your Users’ identifications and passwords or your account.</p>
                <p>e.<u>No Sensitive Information.</u>YOU AGREE NOT TO USE THE SUBSCRIPTION SERVICE TO COLLECT, MANAGE OR PROCESS SENSITIVE INFORMATION. WE WILL NOT HAVE ANY LIABILITY THAT MAY RESULT FROM YOUR USE OF THE SUBSCRIPTION SERVICE TO COLLECT OR MANAGE SENSITIVE INFORMATION.</p>
                <p>e.<u>Third-Party Sites and Products.</u>Third-Party Sites and Products are not under our control. Third-Party Sites and Products are provided to you only as a convenience, and the availability of any Third-Party Site or Product does not mean we endorse, support or warrant the Third-Party Site or Product.</p>
                <p>7.<strong>Subscription Term, Termination, Suspension.</strong></p>
                <p>a.<u>Term and Renewal.</u>Your initial subscription period will be specified in your Order, and your subscription will automatically renew for the shorter of the subscription period, or one year. To prevent renewal of the subscription, notice must be provided to us prior to renewal. If you add products during the Subscription Term, the fees for these additional products will be pro-rated and they will renew along with your subscription, unless otherwise indicated in your Order. The renewal pricing set forth in your Order will apply, subject to adjustment as specified in the ‘Fees and Payments’ section above. If renewal pricing is not included in your Order, then our standard pricing available on our Pricing Page on the date of renewal will apply. Upon termination or expiration of this Agreement, you will stop all use of the affected Subscription Service.</p>
                <p>b.<u>No Early Termination; No Refunds.</u>The Subscription Term will end on the expiration date and the subscription cannot be cancelled early. We do not provide refunds if you decide to stop using the Company subscription during your Subscription Term.</p>
                <p>c.<u>Termination for Cause.</u>Either party may terminate this Agreement for cause, as to any or all Subscription Service: (i) upon thirty (30) days’ notice to the other party of a material breach if such breach remains uncured at the expiration of such period, or (ii) immediately, if the other party becomes the subject of a petition in bankruptcy or any other proceeding relating to insolvency, liquidation or assignment for the benefit of creditors. We may also terminate this Agreement for cause on thirty (30) days’ notice if we determine that you are acting, or have acted, in a way that has or may negatively reflect on or affect us, our prospects, or our customers. This Agreement may not otherwise be terminated prior to the end of the Subscription Term.</p>
                <p>d.<u>Suspension for Prohibited Acts.</u> We may suspend any Customer’s access to any or all Subscription Service without notice for: (i) use of the Subscription Service in a way that violates applicable local, state, federal, or foreign laws or regulations or the terms of this Agreement or (ii) repeated instances of posting or uploading material that infringes or is alleged to infringe on the copyright or trademark rights of any person or entity. We may, without notice, review, edit and delete any Customer Data or Customer Materials that we determine in good faith violate these terms, provided that, we have no duty to prescreen, control, monitor or edit your Customer Data or Customer Materials.</p>
                <p>e.<u>Suspension for Non-Payment.</u>We will provide you with notice of non-payment of any amount due. Unless the full amount has been paid, we may suspend your access to any or all of the Subscription Service ten (10) days after such notice. We will not suspend the Subscription Service while you are disputing the applicable charges reasonably and in good faith and are cooperating diligently to resolve the dispute. If a Subscription Service is suspended for non-payment, we may charge a re-activation fee to reinstate the Subscription Service.</p>
                <p>f.<u> Suspension for Present Harm.</u> If your use of the Subscription Service: (i) is being subjected to denial of service attacks or other disruptive activity, (ii) is being used to engage in denial of service attacks or other disruptive activity, (iii) is creating a security vulnerability for the Subscription Service or others, (iv) is consuming excessive bandwidth, or (v) is causing harm to us or others, then we may, with electronic or telephonic notice to you, suspend all or any access to the Subscription Service. We will try to limit the suspension to the affected portion of the Subscription Service and promptly resolve the issues causing the suspension of the Subscription Service. Nothing in this clause limits our right to terminate for cause as outlined above, if we determine that you are acting, or have acted, in a way that has or may negatively reflect on or affect us, our prospects, or our customers.</p>
                <p>g.<u>Fees upon Termination.</u>If you terminate this Agreement for cause, we will promptly refund any prepaid but unused fees covering use of the Subscription Service after termination. If we terminate this Agreement for cause, you will promptly pay all unpaid fees due through the end of the Subscription Term. Fees are otherwise non-refundable.</p>
                <p>8.<strong>Customer Support.</strong></p>
                <p>Phone, email and in-app support is included at no additional cost. Phone support for subscriptions is available from __am to __pm Central Time, Monday through Friday, excluding holidays. We accept email and in-app support questions 24 hours per day x 7 days per week. Email and in-app responses are provided during phone support hours only. We attempt to respond to email and in-app support questions within one business day; in practice, our responses are generally even faster. We do not promise or guarantee any specific response time.</p>
                <p>9.<strong>Retrieval of Customer Data.</strong> It is your obligation to retrieve all Customer Data prior to termination or expiration of this Agreement. After termination or expiration of this Agreement, we will have no obligation to maintain or provide you the Customer Data and may, unless legally prohibited, delete all Customer Data in our systems or otherwise in our control.</p>
                <p>10.<strong>Alpha/Beta Services.</strong>If we make alpha or beta access to some or all of the Subscription Service (the “Alpha/Beta Services”) available to you (i) the Alpha/Beta Services are provided “as is” and without warranty of any kind, (ii) we may suspend, limit, or terminate the Alpha/Beta Services for any reason at any time without notice, and (iii) we will not be liable to you for damages of any kind related to your use of the Alpha/Beta Services. If we inform you of additional terms and conditions that apply to your use of the Alpha/Beta Services, those will apply as well. We might require your participation to be confidential, and we might also require you to provide feedback to us about your use of the Alpha/Beta Services. You agree that we own all rights to use and incorporate your feedback into our services and products, without payment or attribution to you.</p>
                <p>11<strong>Free Trial.</strong>If you register for a free trial, we will make the applicable Subscription Service available to you on a trial basis free of charge until the earlier of (a) the end of the free trial period (if not terminated earlier) or (b) the start date of your paid subscription. Unless you purchase a subscription to the applicable Subscription Service before the end of the free trial, all of your data in the Subscription Service may be permanently deleted at the end of the trial, and we will not recover it. If we include additional terms and conditions on the trial registration web page, those will apply as well.</p>
                <h4>C. GENERAL LEGAL TERMS</h4>
                <p><strong>1. Customer Data</strong></p>
                <p>a.<u> Limits on Company.</u>We will not use, or allow anyone else to use, customer Data to contact any individual or company except as you direct or otherwise permit. We will use Customer Data only in order to provide the Subscription Service and Consulting Service to you and only as permitted by applicable law, this Agreement, and our Privacy Policy, located on the Website.</p>
                <p>b.<u>Aggregate Data.</u>We may monitor use of the Subscription Service by all of our customers and use the data gathered in an aggregate and anonymous manner. You agree that we may use and publish such information, provided that such information does not incorporate any Customer Data and/or identify you.</p>
                <p>c.<u>Safeguards.</u>We will maintain commercially appropriate administrative, physical, and technical safeguards to protect Customer Data. You consent to the processing of Customer Data in the United States.</p>

                <p>2.<strong>Company’s Proprietary Rights.</strong>This is an Agreement for access to and use of the Subscription Service, and you are not granted a license to any software by this Agreement. The Subscription Service and Consulting Service are protected by intellectual property laws, they belong to and are the property of us or our licensors (if any), and we retain all ownership rights to them. You agree not to copy, rent, lease, sell, distribute, or create derivative works based on the Company Content, the Subscription Service, or the Consulting Service in whole or in part, by any means, except as expressly authorized in writing by us.</p>
                <p>We encourage all customers to comment on the Subscription Service or Consulting Service, provide suggestions for improving it, and vote on suggestions they like. You agree that all such comments and suggestions will be non-confidential and that we own all rights to use and incorporate them into the Subscription Service or Consulting Service, without payment or attribution to you.</p>
                <p>3.<strong>Customer’s Proprietary Rights.</strong>As between the parties, you own and retain all rights to the Customer Materials and Customer Data. This Agreement does not grant us any ownership rights to Customer Materials or Customer Data. You grant permission to us and our licensors to use the Customer Materials and Customer Data only as necessary to provide the Subscription Service and Consulting Service to you and as permitted by this Agreement. If you are using the Subscription Service or receiving Consulting Service on behalf of another party, then you represent and warrant that you have all sufficient and necessary rights and permissions to do so.</p>
                <p>4.<strong>Customer Representations. </strong>By using the Subscription Services, you represent and warrant that: (i) all registration information you submit is truthful and accurate; (ii) you will maintain the accuracy of such information; (iii) you will keep your password confidential and will be responsible for all use of your password and account; (iv) your use of the Company Services does not violate any applicable law or regulation; and (v) the creation, distribution, transmission, public display and performance, accessing, downloading and copying of your Customer Content does not and will not infringe the proprietary rights, including but not limited to the copyright, patent, trademark, trade secret or moral rights, of any third party.</p>
                <p>5.<strong>Confidentiality</strong>The Receiver will: (i) protect the confidentiality of the Confidential Information using the same degree of care that it uses with its own confidential information of similar nature, but with no less than reasonable care, (ii) not use any Confidential Information for any purpose outside the scope of this Agreement, (iii) not disclose Confidential Information to any third party (except our third party service providers), and (iv) limit access to Confidential Information to its employees, contractors, advisors and agents. Upon notice to the Discloser, the Receiver may disclose Confidential Information if required
to do so under any federal, state, or local law, statute, rule or regulation, subpoena or legal process.</p>
                <p>6.<strong>Publicity</strong>You grant us the right to add your name andcompany logo to our customer list, Website and marketing materials.</p>
                <p>7.<strong>Indemnification</strong> You will indemnify, defend and hold us harmless, at your expense, against any third-party claim, suit, action, or proceeding (each, an “Action”) brought against us (and our officers, directors, employees, agents, service providers, licensors, and affiliates) by a third party not affiliated with us to the extent that such Action is based upon or arises out of (a) unauthorized or illegal use of the Subscription Service by you, (b) your noncompliance with or breach of this Agreement, (c) your Review Responses, (d) your use of Third-Party Products, or (e) the unauthorized use of the Subscription Service by any other person using your User information. We will: notify you in writing within thirty (30) days of our becoming aware of any such claim; give you sole control of the defense or settlement of such a claim; and provide you (at your expense) with any and all information and assistance reasonably requested by you to handle the defense or settlement of the claim. You shall not accept any settlement that (i) imposes an obligation on us; (ii) requires us to make an admission; or (iii) imposes liability not covered by these indemnifications or places restrictions on us without our prior written consent.</p>
                <p>8.<strong>Disclaimers; Limitations of Liability</strong></p>
                <p>a<u>Disclaimer of Warranties</u>WE AND OUR AFFILIATES AND AGENTS MAKE NO REPRESENTATIONS OR WARRANTIES ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, SECURITY OR ACCURACY OF THE SUBSCRIPTION SERVICE, DATA MADE AVAILABLE FROM THE SUBSCRIPTION SERVICE, COMPANY CONTENT, OR THE CONSULTING SERVICE FOR ANY PURPOSE. APPLICATION PROGRAMMING INTERFACES (APIs) MAY NOT BE AVAILABLE AT ALL TIMES. TO THE EXTENT PERMITTED BY LAW, THE SUBSCRIPTION SERVICE, COMPANY CONTENT AND CONSULTING SERVICE ARE PROVIDED “AS IS” WITHOUT WARRANTY OR CONDITION OF ANY KIND. WE DISCLAIM ALL WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS, IMPLIED OR STATUTORY, WITH REGARD TO THE SUBSCRIPTION SERVICE AND THE CONSULTING SERVICE, INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.</p>
                <p>b.<u>No Indirect Damages</u>TO THE EXTENT PERMITTED BY LAW, IN NO EVENT SHALL WE BE LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES, OR LOSS OF PROFITS, REVENUE, DATA OR BUSINESS OPPORTUNITIES.</p>
                <p>c.<u>Limitation of Liability</u> OUR AGGREGATE LIABILITY TO YOU UNDER THIS AGREEMENT WILL BE LIMITED TO THE TOTAL OF FEES PAYABLE FOR THE SUBSCRIPTION SERVICE IN THE THREE MONTH PERIOD PRECEDING THE EVENT GIVING RISE TO A CLAIM.</p>
                <p>d.<u>Third Party Products.</u>WE DISCLAIM ALL LIABILITY WITH RESPECT TO THIRD-PARTY PRODUCTS THAT YOU USE. OUR LICENSORS SHALL HAVE NO LIABILITY OF ANY KIND UNDER THIS AGREEMENT.</p>
                <p>e.<u>Agreement to Liability Limit</u>YOU UNDERSTAND AND AGREE THAT ABSENT YOUR AGREEMENT TO THIS LIMITATION OF LIABILITY, WE WOULD NOT PROVIDE THE SUBSCRIPTION SERVICE TO YOU.</p>
                <p>9.<strong>Miscellaneous</strong></p>
                <p>a.<u>Amendment; No Waiver</u>We may update and change any part or all of these Terms of Service, including the fees and charges associated with the use of the Subscription Service (but, your fees and charges will not change during the Subscription Term except as we explain in the ‘Fees and Payments’ section above.) If we update or change these Terms of Service, the updated Terms of Service will be posted on our Website, and we may in our discretion also notify you via email or through in-app notifications. The updated Terms of Service will become effective and binding on the next business day after the are posted. When we change these Terms of Service, the “Version Date” date above will be updated to reflect the date of the most recent version. We encourage you to review these Terms of Service periodically.</p>
                <p>If you do not agree with a modification to the Terms of Service, you must notify us in writing within thirty (30) days after receiving notice of modification. If you give us this notice, your subscription will continue to be governed by the terms and conditions of the Terms of Service prior to modification for the remainder of your current term. Upon renewal, the Terms of Service published by us on our Website will apply. No delay in exercising any right or remedy or failure to object will be a waiver of such right or remedy or any other right or remedy. A waiver on one occasion will not be a waiver of any right or remedy on any future occasion.</p>
                <p>b.<u> Force Majeure</u>Neither party will be responsible for failure or delay of performance if caused by: an act of war, hostility, or sabotage; act of god; electrical, internet, or telecommunication outage that is not caused by the obligated party; government restrictions; or other event outside the reasonable control of the obligated party. Each party will use reasonable efforts to mitigate the effect of a force majeure event.</p>
                <p>c.<u>Actions Permitted.</u>Except for actions for nonpayment or breach of a party’s proprietary rights, no action, regardless of form, arising out of or relating to this Agreement may be brought by either party more than one (1) year after the cause of action has accrued.</p>
                <p>d.<u>Relationship of the Parties.</u>You and we agree that no joint venture, partnership, employment, or agency relationship exists between us.</p>
                <p>e.<u>Compliance with Laws</u>We will comply with all U.S. state and federal laws (where applicable) in our provision of the Subscription Service, the Consulting Service and our processing of Customer Data. We reserve the right at all times to disclose any information as necessary to satisfy any law, regulation, legal process or governmental request. You will comply with all laws in your use of the Subscription Service and the Consulting Service, including any applicable export laws. You will comply with the sanctions programs administered by the Office of Foreign Assets Control (OFAC) of the U.S. Department of the Treasury. You will not directly or indirectly export, re-export, or transfer the Subscription Service or the Consulting Service to prohibited countries or individuals or permit use of the Subscription Service or the Consulting Service by prohibited countries or individuals.</p>
                <p>f.<u>Severability.</u>If any part of this Agreement or an Order Form is determined to be invalid or unenforceable by applicable law, then the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of this Agreement will continue in effect.</p>
                <p>g.<u>Notices.</u>Notices will be sent to the contact address set forth herein, and will be deemed delivered as of the date of actual receipt. If to Company, to the address of our main offices as indicated in the contact page of our Website. If to you, to your address as provided in our Company subscription account information for you. We may give electronic notices by general notice via the Subscription Service and may give electronic notices specific to you by email to your e-mail address(es) on record in our account information for you or through the notifications center of the Subscription Service. We may give notice to you by telephone calls to the telephone numbers on record in our account information for you. You must keep all of your account information current.</p>
                <p>h.<u> Entire Agreement.</u>This Agreement (including each Order), along with our Privacy Policy, is the entire agreement between us for the Subscription Service and the Consulting Service and supersedes all other proposals and agreements, whether electronic, oral or written, between us. We object to and reject any additional or different terms proposed by you, including those contained in your purchase order, acceptance or website. Our obligations are not contingent on the delivery of any future functionality or features of the Subscription Service or dependent on any oral or written public comments made by us regarding future functionality or features of the Subscription Service. We might make versions of this Agreement available in languages other than English. If we do, the English version of this Agreement will govern our relationship and the translated version is provided for convenience only and will not be interpreted to modify the English version of this Agreement.</p>
                <p>i.<u>Assignment.</u>You will not assign or transfer this Agreement, including any assignment or transfer by reason of merger, reorganization, sale of all or substantially all of your assets, change of control or operation of law, without our prior written consent, which will not be unreasonably withheld. We may assign this Agreement to any affiliate or in the event of merger, reorganization, sale of all or substantially all of our assets, change of control or operation of law.</p>
                <p>j.<u>No Third Party Beneficiaries.</u> Nothing in this Agreement, express or implied, is intended to or shall confer upon any third party person or entity any right, benefit or remedy of any nature whatsoever under or by reason of this Agreement.</p>
                <p>k.<u>Contract for Services.</u> This Agreement is a contract for the provision of services and not a contract for the sale of goods. The provisions of the Uniform Computer Information Transaction Act (UCITA), or any substantially similar legislation as may be enacted, shall not apply to this Agreement. If you are located outside of the territory of the United States, the parties agree that the United Nations Convention on Contracts for the International Sale of Goods shall not govern this Agreement or the rights and obligations of the parties under this Agreement.</p>
                <p>l.<u>Authority.</u>Each party represents and warrants to the other that it has full power and authority to enter into this Agreement and that it is binding upon such party and enforceable in accordance with its terms.</p>
                <p>m.<u>Survival.</u>Any provisions of this Agreement that, in order to fulfill the purposes of such provisions, need to survive the termination or expiration of this Agreement, shall be deemed to survive for as long as necessary to fulfill such purposes.</p>
                <p>n.<u>Precedence.</u>In the event of a conflict between the terms of this Agreement and an Order, the terms of the Order shall control, but only as to that Order.</p>
                <p>o.<u>Applicable law and Jurisdiction.</u> This Agreement is governed by the laws of the State of Illinois, U.S.A. without reference to conflicts of law principles. Both parties consent to the exclusive jurisdiction and venue of the courts in Cook County, Illinois, U.S.A. for all disputes arising out of or relating to the use of the Subscription Service or the Consulting Service.</p>
            </div>
        )
    }
}

export default TermsAndPolicy;