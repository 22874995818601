import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ResetPasswordRequest from '../ResetPassword/action/ResetPasswordRequest';
import ResetPasswordRequestStore from '../ResetPassword/store/ResetPasswordRequestStore';
import '../../../public/css/login.css';
import urlConfig from '../../config/index';
// var cookie = require('../../utils/cookie');

class ResetPassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			passwordReset: 'sent',
			validationObject: {},
		};
		this.resetLinkSent = this.resetLinkSent.bind(this);
		this.emailValue = this.emailValue.bind(this);
		this.resetPassword = this.resetPassword.bind(this);
	}

	componentWillMount() {
		ResetPasswordRequestStore.bind(this.resetLinkSent);
	}

	componentWillUnmount() {
		ResetPasswordRequestStore.unbind(this.resetLinkSent);
	}

	resetLinkSent() {
		if (ResetPasswordRequestStore.getResetResponse()) {
			this.setState({
				passwordResetSuccess: 'success',
			});
		} else {
			this.setState({
				passwordResetSuccess: 'failure',
			});
		}
	}

	emailValue(event) {
		this.setState({
			email: event.target.value,
		});
	}

	resetPassword() {
		ResetPasswordRequest.resetPassword(this.state.email);
	}

	render() {
		// const user = {};
		var urlName = urlConfig.urlName;
		return (
			<div className='forget-password'>
				<div className='password-wrap'>
					<div className='password-wrap__inner'>
						<div className='logo'>
							<span>
								<img src={`/images/${urlName}/logo-icon.png`} />
							</span>
						</div>
						<h3>Forgot your Password?</h3>
						<ul>
							{(window.location.href.indexOf(
								'reputationmanager'
							) === -1 && (
								<li>
									Enter the email you used to register and we
									will send you a link to reset your password.
								</li>
							)) || (
								<li>
									Enter the email you used to register into
									Reputation Manager and we will send you a
									link to reset your password.
								</li>
							)}
							<li>
								<input
									required
									type='email'
									placeholder='Email'
									onChange={this.emailValue}
									value={this.state.email}
								/>
								<span className='highlight'></span>
								<span className='bar'></span>
								{this.state.validationObject.password && (
									<span className='error-message'>
										Please enter a valid email
									</span>
								)}
							</li>
							<li>
								<button onClick={this.resetPassword}>
									Send Mail
								</button>
							</li>
							{this.state.passwordResetSuccess === 'success' && (
								<li>
									<span className='success-message'>
										Password reset confirmation email has
										been sent to your email id. Please check
										your email.
									</span>
								</li>
							)}
							{this.state.passwordResetSuccess === 'failure' && (
								<li>
									<span className='error-message'>
										Please check the email id you have
										provided.
									</span>
								</li>
							)}
							<li>
								<span className='signup-text'>
									<Link to='/login'>Back to Login</Link>
								</span>
							</li>
						</ul>
					</div>
				</div>
			</div>
		);
	}
}

export default ResetPassword;
