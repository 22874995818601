import React from 'react';
var languageFile = require('../../../../public/languages/Translation');
const PRICE_MONTH = languageFile[localStorage.getItem('language')]["24171"];
const FREE_LIMIT = languageFile[localStorage.getItem('language')]["24181"];
const FULL_REVIEW_INBOX = languageFile[localStorage.getItem('language')]["24201"];
const FULL_ROLES_ACCESS = languageFile[localStorage.getItem('language')]["24211"];
const ALL_INTEGRAIONS_INCLUDED = languageFile[localStorage.getItem('language')]["24191"];
const FULL_REPORTING_SUITE = languageFile[localStorage.getItem('language')]["24221"];
const UNLIMITED_USERS = languageFile[localStorage.getItem('language')]["24231"];
const REVIEW_RESPONSE = languageFile[localStorage.getItem('language')]["24241"];
const BASIC_SUPPORT = languageFile[localStorage.getItem('language')]["24251"];
export const PlusPlanDetails = () => {
	return (
		<ul className='plan-details'>
			{/* <li className='plan-detail-item'>{PRICE_MONTH}</li> */}
			<li className='plan-detail-item'>{FREE_LIMIT}</li>
			<li className='plan-detail-item'>
			All Major Review Sources
				{/* {ALL_INTEGRAIONS_INCLUDED} */}
			</li>
			<li className='plan-detail-item'>
			Industry-Leading ReviewsInbox™
				{/* {FULL_REVIEW_INBOX} */}
				</li>
			<li className='plan-detail-item'>
			Real-Time Review Notices
				{/* {FULL_ROLES_ACCESS} */}
				</li>
			<li className='plan-detail-item'>
			Google Q&A Management
				{/* {FULL_REPORTING_SUITE} */}
				</li>
			<li className='plan-detail-item'>
			Full Reputation Reporting Suite
				{/* {UNLIMITED_USERS} */}
				</li>
			<li className='plan-detail-item'>
			GMB Listing Management
				{/* {REVIEW_RESPONSE} */}
			</li>
			<li className='plan-detail-item'>
			Facebook Local Listings
				{/* {BASIC_SUPPORT} */}
				</li>
				<li className='plan-detail-item'>
				"Spreadsheet UI" Bulk Management
				</li>
				<li className='plan-detail-item'>
				Bulk & Schedule Google Posts
				</li>
				<li className='plan-detail-item'>
				Unlimited Users & Reports
				</li>
				<li className='plan-detail-item'>
				Multiple Platform Languages
				</li>
				<li className='plan-detail-item'>
				Volume Pricing Available
				</li>
				<li className='plan-detail-item'>
				14-Day Free Trail
				</li>
		</ul>
	);
};
