import AppDispatcher from '../../../flux/dispatcher/dispatcher';
import Constant from '../constant/Constant';
import restClient from '../../../routers/MainFrame/action/APIClient';

var UpdateNotificationAction = function () {

}

UpdateNotificationAction.prototype = {

	updateNotification: function (data) {
		restClient.updateNotification(data, function (error, response) {
			if (error) {
				// console.log("error", error);
				AppDispatcher.dispatch({
					actionType: Constant.UPDATE_NOTIFICATIONS,
					data: error
				});
			} else if(response != null) {
				response['status'] = 200;
				AppDispatcher.dispatch({
					actionType: Constant.UPDATE_NOTIFICATIONS,
					data: response
				});
			}
		})
	}
}

export default new UpdateNotificationAction();