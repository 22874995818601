import React from 'react';
var moment = require('moment');
const periodMap = {
	'week':'Weekly',
	'month':'Monthly',
	'year':'Yearly',
}
export const SubscriberPaymentPlanTable = ({
	paymentDetails,
	getAddonDetails
}) => {
	return (
		<table className='table table-bordered'>
			<tbody>
				<tr className='payment-group-header'>
					<th colSpan='2'>{`${periodMap[paymentDetails.billingPeriodUnit]} Charges`}</th>
				</tr>
				<tr>
					<td style={{position: 'relative'}}>
						<span className='payment-remark'>
							{`${periodMap[paymentDetails.billingPeriodUnit]} charge`}
						</span>
					</td>
					<td>$ {paymentDetails.planAmount / (paymentDetails.billingPeriod * 100)}</td>
				</tr>
				<tr className='payment-group-header'>
					<th colSpan='2'>Location Charges</th>
				</tr>
				{getAddonDetails(paymentDetails.addons,false)}

				<tr className='payment-group-header'>
					<th colSpan='2'>Total Charges</th>
				</tr>
				<tr>
					<td>Total amount</td>
					<td>$ {(paymentDetails.planAmount / 100) + getAddonDetails(paymentDetails.addons,true)}</td>
				</tr>
				<tr>
					<td>Next billing date</td>
					<td>
						{paymentDetails.NextBilling
							? moment(
									paymentDetails.NextBilling.split(
										' '
									)[0]
							  ).format('ll')
							: 'Cancelled'}
					</td>
				</tr>
			</tbody>
		</table>
	);
};


