import React from 'react';
import languageFile from '../../../../public/languages/Translation';

export const UnsubscribedPaymentHeader = () => {
	return (
		<div className='page-header '>
			<h1>{languageFile[localStorage.getItem('language')]["24101"]}</h1>
		</div>
	);
};
