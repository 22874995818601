const iso3166 = require('iso-3166-2');

export const knowledgePanelAddress = (item,extentions) => {
    let address = item.address
    let location = item.locationName
    var Knowledgeloc = location ? location.split(',')[0] : ''
    if (address && address.addressLines && address.addressLines[0]) {
        Knowledgeloc = Knowledgeloc + "," + address.addressLines[0]
    }
    if (address &&  address.locality) {
        Knowledgeloc = Knowledgeloc + "," + address.locality
    }
    if (address &&  address.administrativeArea) {
        Knowledgeloc = Knowledgeloc + "," + address.administrativeArea
    }
    if (address && address.regionCode) {
        Knowledgeloc = Knowledgeloc + "," + address.regionCode
    }
    var generatedurl = "https://www.google.com/search?q=" + Knowledgeloc + extentions;
    return generatedurl;
}
export const locationAddress = (item) => { 
    let address 
    
    if (item &&  item.city) {
        address = item.city
    }
    if (item && item.region) {
        address = address + ", " + item.region
    }
    if (item &&  item.country) {
        address = address + ", " + (iso3166.country(item.country) && iso3166.country(item.country).name || item.country)
    }
    if (item && item.postal_code) {
        address = address + ", " + item.postal_code
    }
    return address;
}