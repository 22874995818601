import React from 'react';

class CustomLegend extends React.Component {
	constructor(props) {
		super(props)
	}

	legendClick(legendItem) {
		this.props.onLegendClick(legendItem);
	}

	getLegend() {
		var legendArray = this.props.data.map(function (item, index) {
			return <li key={index} onClick={this.legendClick.bind(this, item.value)}>
				<div className="custom-legend"
					style={{ backgroundColor: this.props.active.indexOf(item.value) > -1 ? [item.color] : "#fff" }}></div>
				<span>{item.label}</span>
			</li>;
		}, this)
		return legendArray;
	}

	render() {
		return (
			<ul className="legend-block">
				{this.getLegend()}
			</ul>
		)
	}
}

export default CustomLegend;