import Constants from '../constants/constant';
import Dispatcher from '../../../flux/dispatcher/dispatcher'
var EventEmitter = require('events').EventEmitter;
var RESPONSE_CHANGE_EVENT = 'globalResponse';

var assign = require('object-assign');

var response = {};

function parseResponse(resp) {
    response = resp;
}

var EditPortfolioStore = assign({}, EventEmitter.prototype, {
    emitChangeEvent: function (event) {
        this.emit(event);
    },
    bind: function (callback) {
        this.on(Constants.RESPONSE_CHANGE_EVENT, callback);
    },
    unbind: function (callback) {
        this.removeListener(Constants.RESPONSE_CHANGE_EVENT, callback);
    },
    getResponse: function () {
        return response;
    }
});

Dispatcher.register(function (action) {

    switch (action.actionType) {
        case Constants.EDIT_PORTFOLIO:
            var resp = action.data;
            parseResponse(resp)
            EditPortfolioStore.emitChangeEvent(Constants.RESPONSE_CHANGE_EVENT);
            break;

        default:
    }
});

export default EditPortfolioStore;