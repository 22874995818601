import Dispatcher from '../../../flux/dispatcher/dispatcher';
import Constants from '../../AuthorisePage/constant/AuthoriseAccountConstant';
// var Dispatcher = require('dispatcher/dispatcher');
var EventEmitter = require('events').EventEmitter;
var RESPONSE_CHANGE_EVENT = 'globalResponse';
// var Constants = require('components/AuthorisePage/constant/AuthoriseAccountConstant');
var assign = require('object-assign');

var response = {};

function parseResponse(resp) {
    response.status = 200;
    response = resp;
}

function parseError(err) {
    response = {};
    response.status = err.status;
    // console.log("ERROR ==> ",err)
    response.message = err.data.message;
}

var AddProfileStore = assign({}, EventEmitter.prototype, {
    emitChangeEvent: function (event) {
        this.emit(event);
    },
    bind: function (callback) {
        this.on(RESPONSE_CHANGE_EVENT, callback);
    },
    unbind: function (callback) {
        this.removeListener(RESPONSE_CHANGE_EVENT, callback);
    },
    getResponse: function () {
        return response;
    }
});

Dispatcher.register(function (action) {

    switch (action.actionType) {
        case Constants.ADD_PROFILE_RESPONSE:
            var resp = action.data;
            // console.log("add profile store resp", resp);
            parseResponse(resp)
            AddProfileStore.emitChangeEvent(RESPONSE_CHANGE_EVENT);
            break;

        case Constants.ADD_PROFILE_ERROR:
            var error = action.data;
            // console.log("add profile error resp", error);
            parseError(error)
            AddProfileStore.emitChangeEvent(RESPONSE_CHANGE_EVENT);
            break;

        default:
    }
});

export default AddProfileStore;