export const leftSectionData = {
    2:{
        reviewerImage:'https://uploads-ssl.webflow.com/60bfe5e646f0e7104a56b4b4/64778bac4ef640a24446f02c_unnamed%20(33)%20(1).png',
        reviewerTitle:'Search Marketing Director',
        reviewerName:'Sam G',
        review:'Love this reputation management tool. The reporting is clear and easy to understand. The reviews inbox makes it very easy to respond to reviews in a single location. The most outstanding feature of LocalClarity is the support. Their response time to any questions or requests is extremely quick!',
        footerLabel:'Search Workspace',
        child:{
            label1:'Create a home four your Profiles or search & join an existing Workspace.',
            label2:'Find your teams existing workspace.',
        }

    },
    3:{
        reviewerImage:'https://uploads-ssl.webflow.com/60bfe5e646f0e7104a56b4b4/6477b87f07fd9adebe8d71f9_3e0d4a62d0410f61921b4314d7ff80da%20(1).jpeg',
        reviewerTitle:'National Digital Marketing Manager',
        reviewerName:'Marianne M.',
        review:'Automated reporting on location review & listing performance with great visualizations. Love the ease of visualizing traditionally clunky Google Business insights data and the ability to tag locations into categories of our choosing. This has made my reporting process so much easier!',
        footerLabel:'Create New  Workspace',
        child:{
            label1:'Build the home for your Profiles to connect users and integrations.',
        }

    },
    4:{
        reviewerImage:'https://uploads-ssl.webflow.com/60bfe5e646f0e7104a56b4b4/647766e3dade8470c00da883_unnamed%20(2)%20(1).jpg',
        reviewerTitle:'Founder President',
        reviewerName:'Nick S',
        review:'After many months of searching for the right solution...we chose Local Clarity! Love this reputation management tool. The reporting is clear and easy to understand. The reviews inbox makes it very easy to respond to reviews in a single location.',
        footerLabel:'Plans for LocalClarity',
        child:{
            label1:'Help us provide you with the best possible support by sharing your initial goals.',
        }

    },
    5:{
        reviewerImage:'https://uploads-ssl.webflow.com/60bfe5e646f0e7104a56b4b4/64711006cff90af9f02b956a_Ashutosh-Anil-Parian.png',
        reviewerTitle:'Director of Customer Experience',
        reviewerName:'Ashutosh A',
        review:'We are excited about the progress you have made with ChatGPT and the new capabilities you have launched. We look forward to exploring how we can leverage them to drive better results for our business.'
    },

}