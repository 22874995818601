import AppDispatcher from '../../../flux/dispatcher/dispatcher';
import Constant from '../constants/constant';
import LogoutConstant from '../../MainFrame/constants/Constants';
import restClient from '../../MainFrame/action/APIClient';

var RemovePortfolioUserAction = function () {

}


RemovePortfolioUserAction.prototype = {

	removePortfolioUser: function (profileId,UserId) {

		restClient.removePortfolioUser(profileId,UserId, function (error, response) {
			if (error) {
				if (error.status === 401) {
					AppDispatcher.dispatch({
						actionType: LogoutConstant.UNAUTHORIZED_EXCEPTION,
						data: error
					});
				} else{
					AppDispatcher.dispatch({
						actionType: Constant.REMOVE_PORTFOLIO_USER,
						data: error
					});
				}
			} else {
				response['status']= 200;
				AppDispatcher.dispatch({
					actionType: Constant.REMOVE_PORTFOLIO_USER,
					data: response
				});
			}
		})
	}
}

export default new RemovePortfolioUserAction();