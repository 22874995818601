import Dispatcher from '../../../flux/dispatcher/dispatcher';
import Constants from '../constants/GetHistogramConstant';

// var Dispatcher = require('dispatcher/dispatcher');
var EventEmitter = require('events').EventEmitter;
var RESPONSE_CHANGE_EVENT = 'globalResponse';
// var Constants = require('components/Dashboard/constants/GetHistogramConstant');
var assign = require('object-assign');

var response = {};

function parseResponse(resp) {
    if( resp != null){
    response = resp;
    }
}

function parseError(resp) {
    // console.log("RESP ==> ", resp)
    response.status = resp.data && resp.data.message;
}

var GetDataStatusStore = assign({}, EventEmitter.prototype, {
    emitChangeEvent: function (event) {
        this.emit(event);
    },
    bind: function (callback) {
        this.on(RESPONSE_CHANGE_EVENT, callback);
    },
    unbind: function (callback) {
        this.removeListener(RESPONSE_CHANGE_EVENT, callback);
    },
    getResponse: function () {
        return response;
    }
});

Dispatcher.register(function (action) {

    switch (action.actionType) {
        case Constants.DATA_STATUS:
            var resp = action.data;
            // console.log("data status action resp", resp);
            parseResponse(resp)
            GetDataStatusStore.emitChangeEvent(RESPONSE_CHANGE_EVENT);
            break;
        case Constants.DATA_STATUS_ERROR:
            var res = action.data;
            // console.log("data status store resp", res);
            parseError(res)
            GetDataStatusStore.emitChangeEvent(RESPONSE_CHANGE_EVENT)
            break;
        default:
    }
});

export default GetDataStatusStore;