import React from 'react';
import laguageFile from "../../../../public/languages/Translation";
class HappinessRatingWidget extends React.Component{

	render (){
		// console.log("qqqqqqqq", this.props.data)
		if (!this.props.data) {
			return <section className="card">
				<div className="card-header"><h3>{laguageFile[localStorage.getItem('language')]["11520"]}</h3></div>
				<article className="card-body reviews-stats">
					<div className="reviews-values" style={{ margin: 20 }}>
						-
							</div>
				</article>
			</section>
		}

		let color = '#F15922';
		var happiness_print_class = 'below30'
		if (this.props.data > 30 && this.props.data < 50) {
			happiness_print_class = 'below50'
			color = '#F99D1B';
		} else if (this.props.data == 50 || this.props.data > 50) {
			happiness_print_class = 'greaterthan50'
			color = '#2FB66A';
		}
		return (
			<section className="card">
				<div className="card-header"><h3>{laguageFile[localStorage.getItem('language')]["11520"]}</h3></div>
				<article className="card-body reviews-stats">
					<div className={`reviews-values ${happiness_print_class}`} style={{ margin: 20, color: color }}>
						{this.props.data}
					</div>
				</article>
			</section>
		)
	}
}

export default  HappinessRatingWidget;