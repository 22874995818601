import AppDispatcher from '../../../flux/dispatcher/dispatcher';
import Constant from '../constants/GetHistogramConstant';
// var _ = require("lodash");
var  country, locality, area,  granularity = { value: 'weekly', label: 'Weekly' };
var dateRange = {}, filters = {};

var FilterSaveAction = function () {

}

FilterSaveAction.prototype = {
	//granularity
	granularityFilter() {
		granularity = { value: 'weekly', label: 'Weekly' }
	},

	storeFilterWithGranularity(data, granular) {
		filters = data;
		filters['granularity'] = granular;
		granularity = granular;
		AppDispatcher.dispatch({
			actionType: Constant.FILTER_SAVING_ACROSS_TABS,
			filter: filters
		})
	},
	storeFilters(data) {
		filters = data;
		if (granularity) {
			filters['granularity'] = granularity
		}
		AppDispatcher.dispatch({
			actionType: Constant.FILTER_SAVING_ACROSS_TABS,
			filter: filters
		})
	},
	storeReviewFilters(data, date) {
		filters = data;
		filters['dateRange'] = date
		if (granularity) {
			filters['granularity'] = granularity
		}
		AppDispatcher.dispatch({
			actionType: Constant.FILTER_SAVING_ACROSS_TABS,
			filter: filters
		})
	},

	clearFilter() {
		granularity = { value: 'weekly', label: 'Weekly' }
		setTimeout(function(){
            AppDispatcher.dispatch({
                actionType: Constant.FILTER_SAVING_ACROSS_TABS,
                filter: {}
            })
       }, 0)
	},
	// lpo
	dateFilter(date) {
		filters['dateRange'] = date
		AppDispatcher.dispatch({
			actionType: Constant.FILTER_SAVING_ACROSS_TABS,
			filter: filters
		})
	},
	orgFilter(org) {
		filters['organizationName'] = (org && org.label) || null;
		filters['organizations'] = (org && [org.value]) || null;
		AppDispatcher.dispatch({
			actionType: Constant.FILTER_SAVING_ACROSS_TABS,
			filter: filters
		})
	},

	addCountry(value) {
		country = value;
		locality = undefined;
		area = undefined;
		this.loadData()
	},

	addArea(value) {
		area = value;
		locality = undefined;
		this.loadData();
	},

	addLocality(value) {
		locality = value;
	},

	addDates(fromDate, toDate) {
		dateRange.from = fromDate;
		dateRange.to = toDate;
	},
}

export default new FilterSaveAction();