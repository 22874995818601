import axios from 'axios';
import AppDispatcher from '../../../flux/dispatcher/dispatcher';
import Constant from '../constants/LoginConstant';
import urlConfig from '../../../config/index';
import config from '../../../../globalconfig'

var MicrosoftLoginAction = function () {

}

MicrosoftLoginAction.prototype = {
        login: function (idToken) {

                var urlName = urlConfig.urlName
                // 	$.ajax({
                //         url: config.server + "/microsoftSignIn?domainName=" + urlName,
                //     type: 'POST',
                //     dataType: 'JSON',
                //     contentType: "application/json; charset=utf-8",
                //     data: JSON.stringify(),

                //     success: function(resp) {
                //         console.log("action......", resp);
                //         AppDispatcher.dispatch({
                //             actionType: Constant.SIGN_IN_RESPONSE_RECIEVED,
                //             data: resp
                //         });
                //     },
                //     error: function(err) {
                //         if(err.status===401){
                //             AppDispatcher.dispatch({
                //                 actionType: LogoutConstant.LOGOUT_RESPONSE,
                //                 data: err
                //             });
                //         }
                //         AppDispatcher.dispatch({
                //             actionType: Constant.SIGN_IN_ERROR,
                //             data: err
                //         });

                //     }
                // });

                let bodyToSend = {id_token: idToken,domainName:urlName}
                axios.post(config.server + "/microsoftSignIn",bodyToSend)
                        .then((resp) => {
                                // console.log("action......", resp);
                                AppDispatcher.dispatch({
                                        actionType: Constant.SIGN_IN_RESPONSE_RECIEVED,
                                        data: resp.data
                                });
                        })
                        .catch((err) => {
                                // if (err.status === 401) {
                                //         AppDispatcher.dispatch({
                                //                 actionType: LogoutConstant.LOGOUT_RESPONSE,
                                //                 data: err
                                //         });
                                // }
                                AppDispatcher.dispatch({
                                        actionType: Constant.SIGN_IN_ERROR,
                                        data: err
                                });
                        })
        }
}

export default new MicrosoftLoginAction();