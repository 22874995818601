var url = require('url');

var adr, q, urlName;

if (window && window.location) {
  adr = window.location.href;
  q = url.parse(adr, true);
  urlName = q.host;
} else {
  urlName = 'app.localclarity.com';
}

if (!['localhost:3000', 'dev.localclarity.com', 'stage.localclarity.com'].includes(urlName)) {
  for (var item in console) {
    if (typeof console[item] === 'function') {
      console[item] = function () {};
    }
  }
}

module.exports = {
  port: '8000',
  server: "https://".concat(urlName, "/api"),
  uiServer: 'https://app.localclarity.com',
  logoBaseUrl: 'https://app.localclarity.com/api/assets/logos'
};