import React from 'react'
import { Button, Input, Spin, Upload } from 'antd';
import style from './style.module.scss'
import getSVG from '../../../../../utils/commonSVG';
export default function WorkSpaceCreate(props) {
    return (
        <div className={style.createWorkSpaceWrapper}>
            <div className={style.createSection}>
                <div className={style.backLink} onClick={props.onJoinWorkspace}>
                    Search your team's Workspace instead
                </div>
                <div className={style.headerSection}>
                    <h1>
                        What would you like to name your Workspace?
                    </h1>

                </div>
                <div className={style.uploadSection}>
                    <div className={style.label}>
                        Workspace icon
                    </div>
                    <div className={style.imageUpload}>
                        <div className={style.image + ' ' + !props.backgroundImage && style.noImage} style={{ backgroundImage: `url(${props.workeSpaceUrl})` }}>
                        </div>
                        <div className={style.upload}>
                            <div className={style.uploadButton}>
                                <Upload action="//jsonplaceholder.typicode.com/posts/"
                                accept='.png,.jpeg,.gif'
                                beforeUpload={props.beforeUpload}
                                    fileList={props.fileList && props.fileList || []}
                                    onChange={props.handleChange}
                                    customRequest={(e) => props.imageFile(e, props.type)}
                                    showUploadList={false}><Button className='secondary-btn'>Upload Image</Button></Upload>
                                <span>{props.imageUploadLoader && <Spin size='small'></Spin>}</span>
                            </div>
                            <div className={style.uploadHint}>.png, .jpeg, .gif files upto 2MB. Min: 128*128, Rec:1024*1024</div>

                        </div>

                    </div>
                    <div className={style.nameSection}>
                        <div className={style.label}>
                            Workspace name*

                        </div>
                        <div className={style.input}>
                            <Input value={props.newWorkspaceName} onChange={(e) => props.onChangeWorkspaceName(e.target.value)} />
                        </div>
                    </div>
                    <div className={style.hintSection}>
                        {props.newWorkspaceName.length < 6 && <div className={style.guideline}>
                            {getSVG('info')}
                            <div className={style.content}>
                                Each Workspace name must be unique and be between 6 and 16 characters long.
                            </div>

                        </div>}
                        {(!props.validateWorkspaceName && props.newWorkspaceName.length >= 6) && <div className={style.notAvailable}>
                            {getSVG('info')}
                            <div className={style.content}>
                                This workspace name already exists. Please try another
                            </div>

                        </div>}
                        {(props.validateWorkspaceName && props.newWorkspaceName.length >= 6) && <div className={style.available}>
                            {getSVG('info')}
                            <div className={style.content}>
                                Available
                            </div>

                        </div>}
                    </div>
                    <div className={style.buttonSection}>
                        <Button onClick={props.onStepFour} className='primary-btn btn-padding-lg larger-button'>Continue</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
