var keyMirror = require('keymirror');

module.exports = keyMirror({
    USER_DETAILS: null,
    UPLOAD_USER_IMAGE: null,
    UPDATE_PROFILE_LANDING_PAGE: null,
    PASSWORD_RESET: null,
    UPDATE_USER_DETAILS: null,
    TEAM_MEMBER_ACCESS: null,
    SAVE_USER_FILTER: null 
});