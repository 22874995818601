import AppDispatcher from '../../../flux/dispatcher/dispatcher';
import Constant from '../constant/PaymentsConstant';
import restClient from '../../MainFrame/action/APIClient';
import LogoutConstant from '../../MainFrame/constants/Constants';

var InvoiceListAction = function () {

}

InvoiceListAction.prototype = {

	getList: function (limit) {
		restClient.listInvoices(localStorage.getItem('profileId'), limit, function (error, response) {
			if (error) {
				// console.log("error", error);
				if (error.status === 401) {
					AppDispatcher.dispatch({
						actionType: LogoutConstant.UNAUTHORIZED_EXCEPTION,
						data: error
					});
				}
			} else {
				AppDispatcher.dispatch({
					actionType: Constant.INVOICES_LIST,
					data: response
				});
			}
		})
	}
}

export default new InvoiceListAction();