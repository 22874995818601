import React, { useEffect, useState } from "react";
import { Button, Radio, Select } from "antd";
import style from '../../../../../components/ProgressiveModalWindow/style.module.scss';
import { LOCALIZATION, PROFILE_SET_UP } from "../../../../../components/ProgressiveModalWindow/Constants";
import { AVAILABLE_LANGUAGES, weekDaysMap, TIMEZ_ZONE_LIST, TIME_FORMAT_OPTIONS, DATE_FORMAT_OPTIONS } from "../Constants";
import './style.scss'
import { useRef } from "react";

const { Option, OptGroup } = Select;

export const Localization = (props) => {
    const [visible, setVisible] = useState(false);
    const selectRef = useRef(null);
    useEffect(() => {
        const handleDocumentClick = (e) => {
            if (selectRef.current && !selectRef.current.contains(e.target)) {
                setVisible(false); 
            }
        };
        document.addEventListener('click', handleDocumentClick);
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, []);

    return (
        <div className='body-section'>
            <div className='profile-details'>
                <ul>
                    <li className={`language-content ${props.props.validationSelectClass}`} >
                        <span>
                            <h3>{LOCALIZATION.DEFAULT_LAN}</h3>
                            <p>{LOCALIZATION.DEFAULT_LAN_SUB}</p>
                        </span>
                        <Select placeholder="Select" value={props.props.selectedLanguage}
                            onSelect={(e) => props.onChangeCreteProfileDetails('selectedLanguage', e)}

                        >
                            {AVAILABLE_LANGUAGES.map((items) => {
                                return <Option value={items.value}>{items.label.charAt(0).toUpperCase() + items.label.slice(1).toLowerCase()}</Option>
                            })}
                        </Select>
                    </li>
                    <li className="language-content" >
                        <span>
                            <h3>{LOCALIZATION.DATE_FORMAT}</h3>
                            <p>{LOCALIZATION.DATE_FORMAT_SUB}</p>
                        </span>
                        <span className="content-select">
                            <Select placeholder="MMM D,     (Dec8,2023)" value={props.props.selectedDateFormat}
                                onSelect={(e) => props.onChangeCreteProfileDetails('selectedDateFormat', e)}>
                                {DATE_FORMAT_OPTIONS.map((items => {
                                    return <Option value={items.value}>{items.label}</Option>
                                }))}
                            </Select>
                            <Select placeholder="h:mm (12-hour)    8:08 AM" value={props.props.selectedTimeFormat}
                                onSelect={(e) => props.onChangeCreteProfileDetails('selectedTimeFormat', e)}>

                                {TIME_FORMAT_OPTIONS.map((items) => {
                                    return <Option value={items.value}>{items.label}</Option>

                                })}
                            </Select>
                        </span>

                    </li>
                    <li className="language-content">
                        <span>
                            <h3>
                                Time Zone
                            </h3>
                            <p>
                                Sets the default time zone.
                            </p>
                        </span>
                        <Select value={props.props.selectedTimeZone}
                            onSelect={(e) => props.onChangeCreteProfileDetails('selectedTimeZone', e)}>
                            {TIMEZ_ZONE_LIST.map((items => {
                                return <Option value={items.value}>{items.label}</Option>
                            }))}
                        </Select>
                    </li>

                    <li className="language-content" >
                        <span>
                            <h3>{LOCALIZATION.SET_REVIEW_TIMESTAMP}</h3>
                            <p>{LOCALIZATION.SET_REVIEW_TIMESTAMP_SUB}</p>
                        </span>
                        <Radio.Group onChange={(e) => props.onChangeCreteProfileDetails('selectedTimeStamp', e.target.value)} value={props.props.selectedTimeStamp}>
                            <Radio value={'UTC'} ><span className="label-timezone">UTC (default)</span></Radio>
                            <Radio value={'TIME_ZONE_OF_LOCATION'} className="label-timezone"><span className="label-timezone">Time Zone of Location</span></Radio>
                        </Radio.Group>
                    </li>
                </ul>
            </div>
        </div>
    )
}
