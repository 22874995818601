import Dispatcher from '../../../flux/dispatcher/dispatcher';
import Constants from '../constants/GetHistogramConstant';

var EventEmitter = require('events').EventEmitter;
var RESPONSE_CHANGE_EVENT = 'globalResponse';
var assign = require('object-assign');

var response = {};

function parseResponse(resp){
  response = resp;
}

var QueriesTypeCountStore = assign({},EventEmitter.prototype,{
   emitChangeEvent: function(event) {
       this.emit(event);
   },
   bind: function(callback) {
       this.on(RESPONSE_CHANGE_EVENT, callback);
   },
   unbind: function(callback) {
       this.removeListener(RESPONSE_CHANGE_EVENT, callback);
   },
   getResponse:function(){
        return response;
   }
});

Dispatcher.register(function(action){

   switch (action.actionType) {
       case Constants.QUERIES_TYPE_RESPONSE:
           var resp = action.data;
           // console.log("queries type action resp", resp);
           parseResponse(resp)
           QueriesTypeCountStore.emitChangeEvent(RESPONSE_CHANGE_EVENT);
           break;

       default:
   }
});

export default QueriesTypeCountStore;