import React from 'react';
import { LCModal } from '../../../frameWork';
import { RenderInviteMdoalWindowFooter } from './renderInviteModalWindowFooter';
import { RenderModalWindowFields } from './renderModalWindowFields';
var languageFile = require('../../../../public/languages/Translation');

export const InviteUSerModalWindowWrapper = ({
	rolesMenu,
	inviteModal,
	handleCancel,
	handleOk,
	inviteUser,
	userEmail,
	addEmail,
	emailValidationError,
	drodpownLoading,
	searchForRole,
	selectedRole,
	roleChange,
	profileOptions,
	selectedProfile,
	profileChange,
	tableLoading,
	tableData,
	onPageChange,
	showTotal,
	changeSize,
	getPageSizeOptions,
	//assignProfileChange,
	ischecked,
    allChecked,
	ischeckedAll,
	emailVisible,
	roleChanges
}) => {
	return (
		<LCModal
			visible={inviteModal}
			title={emailVisible ? "Edit User Roles" : languageFile[localStorage.getItem('language')]['11750']}
			width={500}
			onOk={handleOk}
			onCancel={handleCancel}
			footer={
				<RenderInviteMdoalWindowFooter
					handleCancel={handleCancel}
					inviteUser={inviteUser}
					emailVisible={emailVisible}
				/>
			}
			modalContent={
				<RenderModalWindowFields
					rolesMenu={rolesMenu}
					userEmail={userEmail}
					addEmail={addEmail}
					emailValidationError={emailValidationError}
					drodpownLoading={drodpownLoading}
					searchForRole={searchForRole}
					selectedRole={selectedRole}
					roleChange={roleChange}
					roleChanges={roleChanges}
					profileOptions={profileOptions}
					selectedProfile={selectedProfile}
					profileChange={profileChange}
					tableLoading={tableLoading}
					tableData={tableData}
					onPageChange={onPageChange}
					showTotal={showTotal}
					changeSize={changeSize}
					getPageSizeOptions={getPageSizeOptions}
					//assignProfileChange={assignProfileChange}
                    ischecked={ischecked}
                    allChecked={allChecked}
					ischeckedAll={ischeckedAll}
					emailVisible={emailVisible}
				/>
			}
		/>
	);
};
