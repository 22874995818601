import React from 'react';
import { Link } from 'react-router-dom';
var languageFile = require('../../../../public/languages/Translation.js');

class EmptyStates extends React.Component {
	render() {
		if (this.props.status === 'QUEUE') {
			return (
				<div className='empty-state'>
					<div className='empty-state__inner'>
						<div className='add-pro-icon'>
							<div className='queue-icon'>
								<div className='queue-one queue-animation'></div>
								<div className='queue-two queue-animation'></div>
								<div className='queue-three queue-animation'></div>
							</div>
						</div>
						<h3>In queue</h3>
						<p>
							Our fetchers are busy right now. Your profile has been added to a
							queue, and its data will be fetched shortly. Thanks for waiting!
						</p>
					</div>
				</div>
			);
		}

		if (this.props.status === 'NO_ACCOUNTS_AUTHORIZED') {
			return (
				<div className='dashboard-outer'>
					<section className='card'>
						<article className='card-body'>
							<div style={{ height: 700 }}>
								<h3>
									The current profile does not have any accounts authorized
								</h3>
								<p>
									You can authorize new accounts in the{' '}
									<Link to='/settings/addAccount'>settings tab</Link>.
								</p>
							</div>
						</article>
					</section>
				</div>
			);
		}

		if (this.props.status === 'NO_ACCOUNTS_ACTIVATED') {
			return (
				<div className='dashboard-outer'>
					<section className='card'>
						<article className='card-body'>
							<div style={{ height: 700 }}>
								<h3>No accounts activated</h3>
							</div>
						</article>
					</section>
				</div>
			);
		}

		if (this.props.status === 'NO_LOCATIONS') {
			return (
				<div className='empty-state'>
					<div className='empty-state__inner'>
						<div className='add-pro-icon'>
							<img src='/images/no-location.png' />
						</div>
						<h3>No locations found for the accounts authorized</h3>
					</div>
				</div>
			);
		}
		if (this.props.status === 'NO_DATA_IN_ACCOUNT') {
			return (
				<div className='empty-state'>
					<div className='empty-state__inner'>
						<div className='add-pro-icon'>
							<img src='/images/no-data.png' />
						</div>
						<h3>
							We couldn't find any reviews or insights associated with this
							account!
						</h3>
					</div>
				</div>
			);
		}
		if (this.props.totalReviewsCount === 0) {
			return (
				<div className='empty-state'>
					<div className='empty-state__inner'>
						<div className='add-pro-icon'>
							<img src='/images/no-data.png' />
						</div>
						<h3>{languageFile[localStorage.getItem('language')]['20421']}</h3>
					</div>
				</div>
			);
		}
		if (this.props.insightTotal === 0) {
			return (
				<div className='empty-state'>
					<div className='empty-state__inner'>
						<div className='add-pro-icon'>
							<img src='/images/no-data.png' />
						</div>
						<h3>
							{languageFile[localStorage.getItem('language')]['29451']}
						</h3>
					</div>
				</div>
			);
		}
		if (this.props.status === 'FETCHING_NO_DATA') {
			return (
				<div className='empty-state'>
					<div className='empty-state__inner'>
						<div className='add-pro-icon'>
							<img src='/images/server-icon.png' />
						</div>
						<h3>Initializing fetchers, please wait..</h3>
					</div>
				</div>
			);
		}
		if (this.props.status === 'FETCHING_LOCATIONS_NO_DATA') {
			var fetchingStatusData = this.props.fetchingStatusData;
			return (
				<div className='empty-state'>
					<div className='empty-state__inner'>
						<div className='add-pro-icon'>
							<div className='location-featching'>
								<div className='location-map-one animation-hover-one'>
									<img src='/images/location.png' />
								</div>
								<div className='location-map-two animation-hover-two'>
									<img src='/images/location.png' />
								</div>
								<div className='location-map-three animation-hover-three'>
									<img src='/images/location.png' />
								</div>
							</div>
						</div>
						<h3>
							Finding locations for {fetchingStatusData.accountName}, please
							wait..
						</h3>
						Locations fetched:{' '}
						{fetchingStatusData ? fetchingStatusData.locationsFetched : 0}
					</div>
				</div>
			);
		}
		if (this.props.status === 'NO_POST_IN_DATE_RANGE') {
			return (
				<div className='empty-state'>
					<div className='empty-state__inner'>
						<div className='add-pro-icon'>
							<img src='/images/no-data.png' />
						</div>
						<h3>
							There were no local posts found for the selected date range and
							criteria
						</h3>
					</div>
				</div>
			);
		}
		if (this.props.status === 'NO_POST_IN_ACCOUNT') {
			return (
				<div className='empty-state'>
					<div className='empty-state__inner'>
						<div className='add-pro-icon'>
							<img src='/images/no-data.png' />
						</div>
						<h3>No local posts found for this account</h3>
					</div>
				</div>
			);
		}
		if (this.props.status === 'INVITE_USER_MANAGEUSERPAGE') {
			return (
				<div className='empty-state'>
					<div className='empty-state__inner'>
						<div className='add-pro-icon'>
							<img src='/images/not-allowed.png' />
						</div>
						<h3>User details will be available after user joins</h3>
					</div>
				</div>
			);
		}
	}
}

export default EmptyStates;
