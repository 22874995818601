import React from 'react';
import { Tabs } from 'antd';
import { UnsubscribedPlanDetails } from './UnsubscribedPlanDetails';
import { UnsubscribedPaymentButton } from './UnsubscribedPaymentButton';
import languageFile from '../../../../public/languages/Translation';
import {urlName} from '../../../config/index';

const TabPane = Tabs.TabPane;
export const UnsubscribedPaymentPlan = ({openPaymentPortal}) => {
	return (
		<div className='payment-page-outer'>
			<div className='payment-plans'>
				<Tabs defaultActiveKey='1' animated={false}>
					<TabPane tab={<span>{languageFile[localStorage.getItem('language')]["24111"]}</span>} key='1'>
						{ urlName=="app.localclarity.com" &&
							<UnsubscribedPlanDetails /> }
						<UnsubscribedPaymentButton
							openPaymentPortal={openPaymentPortal}
						/>
					</TabPane>
				</Tabs>
			</div>
		</div>
	);
};
