import Dispatcher from '../../../../flux/dispatcher/dispatcher';
import Constants from '../constant/StackedAreaConstant';

var EventEmitter = require('events').EventEmitter;
var RESPONSE_CHANGE_EVENT = 'globalResponse';
var assign = require('object-assign');

var response = null;

function parseResponse(resp){
  response = resp;
}

var StackedAreaStore = assign({},EventEmitter.prototype,{
   emitChangeEvent: function(event) {
       this.emit(event);
   },
   bind: function(callback) {
       this.on(RESPONSE_CHANGE_EVENT, callback);
   },
   unbind: function(callback) {
       this.removeListener(RESPONSE_CHANGE_EVENT, callback);
   },
   getResponse:function(){
        return response;
   }
});

Dispatcher.register(function(action){

   switch (action.actionType) {
       case Constants.STACKED_AREA:
           var resp = action.data;
           parseResponse(resp)
           StackedAreaStore.emitChangeEvent(RESPONSE_CHANGE_EVENT)
           break;

       default:
   }
});

export default StackedAreaStore;