import AppDispatcher from '../../../flux/dispatcher/dispatcher';
import Constant from '../constants/constants';
import restClient from '../../MainFrame/action/APIClient';
import LogoutConstant from '../../MainFrame/constants/Constants';
var moment = require('moment');

var CreateNewWorkSpaceAction = function () {

}

CreateNewWorkSpaceAction.prototype = {

	createWorkspace: function (data) {
		restClient.createWorkSpace(data, function (error, response) {
			if (error) {
				if (error.status === 401) {
					AppDispatcher.dispatch({
						actionType: LogoutConstant.UNAUTHORIZED_EXCEPTION,
						data: error
					});
				}
				else{
					AppDispatcher.dispatch({
						actionType: Constant.CREATE_WORKSPACE,
						data: error
					});
				}
			} else {
				if(response!=null){response['status'] = 200};
				AppDispatcher.dispatch({
					actionType: Constant.CREATE_WORKSPACE,
					data: response
				});
			}
		})
	}
}

export default new CreateNewWorkSpaceAction();