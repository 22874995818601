import React from 'react';
const EXPIRE_MESSAGE='Your subscription has expired. '+
'Please ask the owner of this profile to subscribe to continue using it.';
export const PaymentExpiredPage = ({history, role}) => {
		if (!role) {
			history.push('/authorize');
			return null;
		} else if (role !== 'OWNER') {
			return (
				<div className='review-settings'>
					<section className='card'>
						<article className='card-body'>
							<div>
								<div>
									<i
										className='fa fa-lock'
										aria-hidden='true'></i>
									<h4>
										{EXPIRE_MESSAGE}
									</h4>
								</div>
							</div>
						</article>
					</section>
				</div>
			);
		}
		else return [];
	
};
