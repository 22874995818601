import AppDispatcher from '../../../flux/dispatcher/dispatcher';
import Constant from '../constants/LoginConstant';
import restClient from '../../MainFrame/action/APIClient';

var UpdateEmailAction = function () {

}

UpdateEmailAction.prototype = {
	update: function (userObject) {
		restClient.updateEmail(userObject, function (error, response) {
			if (error) {
				// console.log("error", error)
				AppDispatcher.dispatch({
					actionType: Constant.SIGN_IN_ERROR,
					data: error
				});
			} else {
				// console.log("response of update", response);
				AppDispatcher.dispatch({
					actionType: Constant.UPDATE_EMAIL_RESPONSE,
					data: response
				});
			}
		});
	}
}

export default UpdateEmailAction;