var keyMirror = require('keymirror');

module.exports = keyMirror({
	ADD_INTEGRATION_CONTROL: null,
	EDIT_INTEGRATION_CONTROL: null,
	DELETE_INTEGRATION_CONTROL: null,
	GET_INTEGRATION_CONTROL: null,
	GET_CUSTOM_NAVIGATION: null,
	ADD_CUSTOM_NAV: null,
	UPDATE_CUSTOM_NAV: null,
	DELETE_CUSTOM_NAV: null,
});