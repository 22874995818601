import React, { useState } from "react";
import { Button, Input, Select, Upload } from "antd";
import { PROFILE_SET_UP } from "../../../../../components/ProgressiveModalWindow/Constants";
import './style.scss'
import { BUSINESS_DROPDOWN_OPTIONS } from "../Constants";
import { Spin } from 'antd';
const { Option, OptGroup } = Select;
export const ProfileCoreDetails = (props) => {
  const [upload, setUpload] = useState(false);
  const header = (
    <div style={{ padding: '8px 12px', borderBottom: '2px dotted #ccc' }}>
      <p>Multiple Industries</p>
    </div>
  );

  return (
    <div className='body-section'>
      <div className="profile-image-section">
        <span className="icon-label">Profile Icon</span>
        <div className='profile-upload-block'>
          <div><img src={props.props.profileIconURL}></img></div>
          <div className="upload-button-section">
            <div className="upload-button-block">
              <Upload
                customRequest={props.handleProfileIconChange}
                showUploadList={false}
                action=""
                accept=".jpg,.jpeg,.png,"
              >

                <Button
                  type="file"
                >
                  Upload image
                </Button>
              </Upload>
              <div>
                {props.props.uploadIconLoader && <Spin size="small" />}
              </div>
            </div>
            <p>.png, .jpeg, .gif files up to 2MB. Min:128*128, Rec: 1024*1024 </p>

          </div>

        </div>

      </div>
      <div className='profile-details'>
        <ul>
          <li className={props.props.validationInpuClass}>
            <span>{PROFILE_SET_UP.PROFILE_NAME}</span>
            <Input placeholder="Enter Profile name" value={props.props.profileName} onChange={(e) => props.onChangeCreteProfileDetails('profileName', e.target.value)} />
          </li>
          <li>
            <span>{PROFILE_SET_UP.BUSINESS_INDUSTRY}</span>
            <Select className="ant-select-props" placeholder="Select" value={props.props.selectedBusiness}
              onSelect={(e) => props.onChangeCreteProfileDetails('selectedBusiness', e)}
              dropdownRender={(menu) => (
                <div>
                  {header}
                  {menu}

                </div>
              )}
            >
              {BUSINESS_DROPDOWN_OPTIONS.map((items) => {
                return <Option value={items.value}>{items.label}</Option>
              })}
            </Select>           </li>
          <li>
            <span>{PROFILE_SET_UP.LOCATION_VOLUME}</span>
            <Select className="ant-select-props" placeholder="Select" value={props.props.selectedLocationVolume} onSelect={(e) => props.onChangeCreteProfileDetails('selectedLocationVolume', e)}>
              {props.props.locationVolume.map((item) => {
                return (
                  <Select.Option key={item.value} value={item.value} >
                    {item.label}
                  </Select.Option>
                );
              })}
            </Select>
          </li>
        </ul>
      </div>
    </div>
  )
}
