import React from 'react';
var languageFile = require('../../../../public/languages/Translation');

const ALL_FEATURES = languageFile[localStorage.getItem('language')]["24271"];
const FULL_GB_MANAGEMNET = languageFile[localStorage.getItem('language')]["24281"];
const BULK_SCHEDULE_GOOGLE_POST = languageFile[localStorage.getItem('language')]["24291"];
const REALTIME_NOTIFICATIONS = languageFile[localStorage.getItem('language')]["24301"];;
const LOCAL_SEARCH_OPTIMIZATION = languageFile[localStorage.getItem('language')]["24321"];
const VIDEO_ONBOARDING = languageFile[localStorage.getItem('language')]["24331"];
const FRAUDULENT_REVIEW_AUDIT = languageFile[localStorage.getItem('language')]["24341"];
const TRAINING_MATERIALS = 'Training Materials';
export const ProPlanDetails = () => {
	return (
		<ul className='plan-details'>
			<li className='plan-detail-item'>
			All Features of PLUS Plan
				{/* {ALL_FEATURES} */}
			</li>
			<li className='plan-detail-item'>+</li>
			<li className='plan-detail-item'>
			10X Competitor Review Tracking
				{/* {FULL_GB_MANAGEMNET} */}
				</li>
			<li className='plan-detail-item'>
			Google Data Studio Connection
				{/* {BULK_SCHEDULE_GOOGLE_POST} */}
			</li>
			<li className='plan-detail-item'>
			Unique Google Audit Log
				{/* {REALTIME_NOTIFICATIONS} */}
			</li>
			<li className='plan-detail-item'>
			Intelligent Auto Response™
				</li>
			<li className='plan-detail-item'>
			Dedicated Account Management
				{/* {LOCAL_SEARCH_OPTIMIZATION} */}
			</li>
			<li className='plan-detail-item'>
			API Access*
				{/* {VIDEO_ONBOARDING} */}
				</li>
			<li className='plan-detail-item'>
			1st Party Review Integration*
				{/* {FRAUDULENT_REVIEW_AUDIT} */}
			</li>
			<li className='plan-detail-item'>
			Custom Data Overlays*
			</li>
			<li className='plan-detail-item'>
			Direct Access to Dev Roadmap*
			</li>
			<li className='plan-detail-item'>
			Volume Pricing Available
			</li>
			{/* <li className='plan-detail-item'>{TRAINING_MATERIALS}</li> */}
		</ul>
	);
};
