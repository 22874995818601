import AppDispatcher from '../../../flux/dispatcher/dispatcher';
import Constant from '../constant/ResetPasswordRequestConstant';
import restClient from '../../MainFrame/action/APIClient';
import urlConfig from '../../../config/index';

var ResetPasswordRequestAction = function () {

}

ResetPasswordRequestAction.prototype = {

	resetPassword: function (userId) {
		var urlName = urlConfig.urlName
		restClient.resetPasswordRequest(userId, urlName, function (error, response) {
			if (error) {
				// console.log("error", error)
				AppDispatcher.dispatch({
					actionType: Constant.PASSWORD_RESET_REQUEST,
					data: response
				});
			} else {
				// console.log("response of reset request", response);
				AppDispatcher.dispatch({
					actionType: Constant.PASSWORD_RESET_REQUEST,
					data: response
				});
			}
		});
	}
}

export default new ResetPasswordRequestAction();