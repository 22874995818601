import React from 'react';
import {Invoices} from './Invoices';
import {PaymentPreference} from './PaymentPreference';
import {PlanDetails} from './PlanDetails';
import {SubscribedUserPayamentTitle} from './SubscribedUserPaymentTitle';
import { LCTabs, LCTabPane } from '../../../frameWork';
import languageFile from '../../../../public/languages/Translation';

export const SubscribedUserPaymentWrapper = ({
	paymentDetails,
	getHostPage,
	cancelSubscription,
	handleCancel,
	handleOk,
	cancelConfirmationModal,
	cancelConfirmation,
	getAddonDetails,
	invoicesData,
	getInvoicesList,
	ownerUpdatedMessage,
	openPaymentModal,
	ownerUpdateResponseMessage,
	invalidEmail,
	changeOwner,
	changePayerDetailsModal,
	changeEmailModal,
	updatePayerEmail,
	payerEmail,
	cancelSubscriptionResponseMessage,
	isSubscriptionCancelled,
	reActiveConfirmation
}) => {
	return (
		<div>
			<SubscribedUserPayamentTitle />
			<div className='payment-page-outer'>
				<div className='payment-plans'>
					<LCTabs defaultActiveKey='1' animated={false}>
						<LCTabPane
							tab={<span>{languageFile[localStorage.getItem('language')]["24111"]}</span>}
							key='1'>
							<PlanDetails
								paymentDetails={paymentDetails}
								getAddonDetails={getAddonDetails}
								cancelConfirmationModal={
									cancelConfirmationModal
								}
								cancelConfirmation={
									cancelConfirmation
								}
								reActiveConfirmation={
									reActiveConfirmation
								}
								cancelSubscription={
									cancelSubscription
								}
								handleCancel={handleCancel}
								isSubscriptionCancelled={
									isSubscriptionCancelled
								}
								cancelSubscriptionResponseMessage={
									cancelSubscriptionResponseMessage
								}
								handleOk={handleOk}
							/>
						</LCTabPane>
						<LCTabPane
							tab={<span>Payment preferences</span>}
							key='2'>
							<PaymentPreference
								paymentDetails={paymentDetails}
								getHostPage={getHostPage}
								changeEmailModal={changeEmailModal}
								openPaymentModal={openPaymentModal}
								updatePayerEmail={updatePayerEmail}
								payerEmail={payerEmail}
								invalidEmail={invalidEmail}
								handleCancel={handleCancel}
								changeOwner={changeOwner}
								handleOk={handleOk}
								changePayerDetailsModal={
									changePayerDetailsModal
								}
								ownerUpdatedMessage={
									ownerUpdatedMessage
								}
								ownerUpdateResponseMessage={
									ownerUpdateResponseMessage
								}
							/>
						</LCTabPane>

						<LCTabPane tab={<span>Invoices</span>} key='3'>
							<Invoices
								getInvoicesList={getInvoicesList}
								invoicesData={invoicesData}
							/>
						</LCTabPane>
					</LCTabs>
				</div>
			</div>
		</div>
	);
};
