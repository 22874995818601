import React from 'react';
import { Switch } from 'react-router-dom';
import ProtectedRoute from '../../../components/ProtectedRoute';

export const RouterListWrapper = ({ routeList }) => {
    return <Switch>
        {
            routeList
                .map((item, key) =>
                    <ProtectedRoute
                        key={key}
                        {...item}
                    />
                )
        }
    </Switch>
}