import keyMirror from 'keymirror';

// module.exports = keyMirror({
//   SIGN_IN_RESPONSE_RECIEVED: null,
//   SIGN_IN_ERROR: null,
//   GOOGLE_SIGNIN_RESPONSE : null,
//   MICROSOFT_SIGNIN_RESPONSE : null,
//   UPDATE_EMAIL_RESPONSE : null,
//   MICROSOFT_LINK_RECEIVED : null
// });




export default keyMirror({
  SIGN_IN_RESPONSE_RECIEVED: null,
  SIGN_IN_ERROR: null,
  GOOGLE_SIGNIN_RESPONSE: null,
  MICROSOFT_SIGNIN_RESPONSE: null,
  UPDATE_EMAIL_RESPONSE: null,
  MICROSOFT_LINK_RECEIVED: null,
  GET_WORKSPACE_RESPONSE: null,
  GET_WORKSPACE_ERROR: null
});