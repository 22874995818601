import React from 'react';
import style from './style.module.scss';
import { leftSectionData } from './helper';
import { domainNames } from '../../../../utils/domainNameList';
import { urlName } from '../../../../config';

export default function LeftSection(props) {
    return (
        <section className={style.leftSection}>
            <div className={style.header}>
                <div className={style.headerImage}>
                    <img src='https://lc-whitelabel-images-prod-dev.s3-us-west-2.amazonaws.com/images/localhost%3A3000/domainIcon'></img>
                </div>
                <h1 className={style.headerText}>
                    {domainNames[urlName]}
                </h1>

            </div>
            <div className={style.backgroundImageBlock + ' ' + style[`background${props.step}`]}>
                <div className={style.headSection}>
                    <img src={leftSectionData[props.step].reviewerImage}></img>
                    <div className={style.reviewDetails}>
                        <span className={style.reviewer}>
                        {leftSectionData[props.step].reviewerName}
                        </span>
                        <span className={style.reviewerDetails}>
                        {leftSectionData[props.step].reviewerTitle}
                        </span>
                    </div>
                </div>
                <div className={style.review}>
                {`"${leftSectionData[props.step].review}"`}

                </div>
            </div>
            <div className={style.footerSection}>
                <span className={style.stepCount}>{`Step ${props.step}`}</span>
                <div className={style.footerDescriptionBlock}>
                    <h2 className={style.descriptionLabel}>
                    {leftSectionData[props.step].footerLabel}

                    </h2>
                    <span className={style.desciptionContent}>
                    {leftSectionData[props.step].child[`label${props.subPage}`]}
                    </span>
                </div>
                <span className={style.pageIndicatior}>
                    <span className={style.line + ' ' +  style.active}></span>
                    <span class={style.line + ' ' + (props.step >= 2 ? style.active: '')}></span>
                    <span class={style.line + ' ' + (props.step >= 3 ? style.active: '')}></span>
                    <span class={style.line + ' ' + (props.step == 4 ? style.active: '')}></span>
                </span>

            </div>
        </section>
    )
}
