var languageFile = require("../../../../public/languages/Translation");

module.exports = {
  Amchartmenu: [{
    "label": '',
    "menu": [
      {
        "label": languageFile[localStorage.getItem('language')]["11720"],
        "menu": [
          { "type": "png", "label": "PNG" },
          { "type": "jpg", "label": "JPG" },
          { "type": "pdf", "label": "PDF" }
        ]
      }, {
        "label": "Data",
        "menu": [
          { "type": "json", "label": "JSON" },
          { "type": "csv", "label": "CSV" },
          { "type": "xlsx", "label": "XLSX" }
        ]
      }
    ]
  }]
}