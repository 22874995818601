import React from 'react';
import '../../../public/css/login.css'; // try to uncomment
import ResetPasswordAction from './action/ResetPasswordAction';
import ResetPasswordStore from './store/ResetPasswordStore';
import { Icon, Tooltip, message } from 'antd';
import urlConfig from '../../config/index';
import ResetPasswordExpired from '../ResetPasswordExpired';

var cookie = require('../../utils/cookie');
var passwordValidator = require('password-validator');
var languageFile = require('../../../public/languages/Translation');

const passwordInvalidMessage =
	'Passwords must be atleast 8 characters long and maximum 20 characters, not contain spaces and contain characters from three of the following four categories: uppercase letters, lowercase letters, numeric (0-9), and special characters(!@#$%^&*).';
const schema = new passwordValidator();

schema
	.is()
	.min(8) // Minimum length 8
	.is()
	.max(20) // Maximum length 100
	.has()
	.uppercase() // Must have uppercase letters
	.has()
	.lowercase() // Must have lowercase letters
	.has()
	.digits() // Must have digits
	.has()
	.not()
	.spaces() // Should not have spaces
	.has()
	.symbols();

class ForgotPassword extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			validationObject: {},
		};
		this.onPasswordReset = this.onPasswordReset.bind(this);
		this.userDetailsChange = this.userDetailsChange.bind(this);
		this.passwordChange = this.passwordChange.bind(this);
		this.confirmPasswordChange = this.confirmPasswordChange.bind(this);
		this.resetPassword = this.resetPassword.bind(this);
		this.checkPasswordMatch = this.checkPasswordMatch.bind(this);
		this.checkPasswordStrength = this.checkPasswordStrength.bind(this);
	}

	componentWillMount() {
		ResetPasswordStore.bind(this.onPasswordReset);
	}
	componentDidMount(){
		let data = this.props.match.params.query.split('&');
		let isExpired = this.getData('isExpired',data)
		this.setState({
			isExpired: isExpired
		})
	}
	componentWillUnmount() {
		ResetPasswordStore.unbind(this.onPasswordReset);
	}
	getData (filter,data) {
		let spliceData = ''
		let returnData = ''
		spliceData = data.filter(function (item, index) {
			return (item.split('=')[0] === filter);
		});
		returnData = spliceData.length ? spliceData[0].split('=') : ''
		if(returnData && returnData[1]){
			return returnData[1].replace("%26", "&")
		}else{
			return false
		}
	}
	onPasswordReset() {
		// console.log("password reset response", ResetPasswordStore.getResponse())
		if (ResetPasswordStore.getResponse().status == 200) {
			this.setState({
				passwordResetSuccess: 'success',
			});
			setTimeout(function () {
				this.props.history.push('login');
			}, 3000);
		} else if (
			ResetPasswordStore.getResponse().status === 400 &&
			ResetPasswordStore.getResponse().subCode === 2
		) {
			this.setState({
				passwordResetSuccess:
					'Please use a new password, that has not been previously used.',
			});
		} else if (
			ResetPasswordStore.getResponse().status === 400 &&
			ResetPasswordStore.getResponse().subCode === 1
		) {
			this.setState({
				passwordResetSuccess:
					'Your password, although valid, is very common and easy to figure out. Please use a stronger password!',
			});
		} else if (ResetPasswordStore.getResponse().status === 409) {
			if (window.location.href.indexOf('reputationmanager') == -1) {
				this.setState({
					passwordResetSuccess:
						'This email id already has an account in Local Clarity. Please use another email.',
				});
			} else {
				this.setState({
					passwordResetSuccess:
						'This email id already has an account . Please use another email.',
				});
			}
		} else {
			this.setState({
				passwordResetSuccess: ResetPasswordStore.getResponse().message,
			});
		}
	}
	userDetailsChange(field, event) {
		this.setState({
			[field]: event.target.value,
		});
	}
	passwordChange(event) {
		this.setState(
			{
				password: event.target.value,
			},
			function () {
				this.checkPasswordStrength();
			}
		);
	}
	confirmPasswordChange(event) {
		this.setState(
			{
				confirmPassword: event.target.value,
			},
			function () {
				this.checkPasswordMatch();
			}
		);
	}
	resetPassword() {
		var params = this.props.match.params.query;
		var userDetails = {
			[params.split('=')[0]]: params.split('=')[1],
		};
		this.setState({
			passwordResetSuccess: '',
		});
		if (this.state.password && this.state.password.length < 8) {
			message.error('Password should be more than 8 Chararcters');
		} else if (!this.state.passwordValid) {
			message.error('invalid password');
		} else if (this.state.password === this.state.confirmPassword) {
			ResetPasswordAction.resetPassword({
				token: userDetails['token'],
				password: this.state.password,
			});
		}
	}
	checkPasswordMatch() {
		if (this.state.password !== this.state.confirmPassword) {
			this.setState({
				validationError: true,
				errorMessage: 'Passwords do not match!',
			});
		} else {
			this.setState({
				validationError: false,
				errorMessage: '',
			});
		}
	}
	checkPasswordStrength() {
		// console.log("validation", schema.validate(this.state.password, { list: true }));
		var tests = schema.validate(this.state.password, { list: true });
		this.setState({
			passwordValid:
				tests.length < 2
					? tests.indexOf('min') < 0 && tests.indexOf('spaces') < 0
					: false,
		});
	}
	
	getInvalid(){
		window.location.href = '/resetPasswordLinkExpired';
	}


	render() {
		var urlName = urlConfig.urlName;
		return (
			<div className='forget-password'>
				{this.state.isExpired ? 
					this.getInvalid():
				<div className='password-wrap'>
					<div className='password-wrap__inner'>
						<div className='logo'>
							<span>
								<img src={`/images/${urlName}/logo-icon.png`} />
							</span>
						</div>
						<h3>
							{
								languageFile[localStorage.getItem('language')][
									'13550'
								]
							}
						</h3>
						{/* {this.state.isExpired && <span className='error-message'>
							{'This link has been expired'}
						</span>} */}
						
						<ul>
							<li style={{ position: 'relative' }}>
								<input
									required
									type='password'
									placeholder='Password'
									onChange={this.passwordChange}
									value={this.state.password}
									onBlur={this.checkPasswordStrength}
								/>
								<span className='highlight'></span>
								{this.state.passwordValid !== undefined &&
									(this.state.passwordValid ? (
										<Tooltip title='Password is valid'>
											<span className='password-strength-indicator'>
												<Icon
													style={{ color: '#00AF00' }}
													type='check-square'
												/>
											</span>
										</Tooltip>
									) : (
										<Tooltip
											visible={true}
											title={passwordInvalidMessage}>
											<span className='password-strength-indicator'>
												<Icon
													style={{ color: '#ef4343' }}
													type='close-square'
												/>
											</span>
										</Tooltip>
									))}
								<span className='bar'></span>
							</li>
							<li>
								<input
									required
									type='password'
									placeholder={
										languageFile[
											localStorage.getItem('language')
										]['10548']
									}
									onChange={this.confirmPasswordChange}
									onBlur={this.checkPasswordMatch}
									value={this.state.confirmPassword}
								/>
								<span className='highlight'></span>
								<span className='bar'></span>
								{this.state.validationError && (
									<span className='error-message'>
										Passwords do not match!
									</span>
								)}
							</li>
							{this.state.passwordResetSuccess === 'success' ? (
								<li>
									<span className='success-message'>
										Password reset successfully! Login using
										your new password to continue.
									</span>
								</li>
							) : (
								this.state.passwordResetSuccess && (
									<li>
										<span className='error-message'>
											{this.state.passwordResetSuccess}
										</span>
									</li>
								)
							)}
							<li>
								<button 
									onClick={this.resetPassword} 
									disabled={this.state.isExpired ? true : false} 
									style={{cursor: this.state.isExpired ? 'not-allowed' : 'pointer'}}>
									Reset Password
								</button>
							</li>
						</ul>
					</div>
				</div>}
			</div>
		);
	}
}

export default ForgotPassword;
