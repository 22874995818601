import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import _ from 'lodash';
import { integrationSources, SOURCE_COLOR_MAP } from "./Children/souceData";
import {sourceList} from "../../../routers/ReviewDownload/childrens/ReviewSourceList";

var laguageFile = require("../../../../public/languages/Translation");

am4core.useTheme(am4themes_animated);

class PieChartAm extends Component {
    componentDidMount() {
        this.piechartRender()
    }
    formatData(data) {
        let formattedData = [];
        _.map(data, function (item, key) {
            if (integrationSources.indexOf(key) > -1) {
                formattedData.push({ source: sourceList[key], value: item, color: am4core.color(SOURCE_COLOR_MAP[key]) });
            }
        })
        return formattedData
    }

    piechartRender() {
        var chart = am4core.create(this.props.id, am4charts.PieChart);
        chart.data = this.formatData(this.props.chartData);
        chart.hiddenState.properties.opacity = 0;


        chart.radius = am4core.percent(91);
        chart.innerRadius = am4core.percent(55);
        chart.startAngle = 180;
        chart.endAngle = 360;

        var series = chart.series.push(new am4charts.PieSeries());
        series.dataFields.value = "value";
        series.dataFields.category = "source";
        series.labels.template.disabled = true;
        series.ticks.template.disabled = true;

        series.slices.template.propertyFields.fill = "color";
        series.slices.template.cornerRadius = 5;
        series.slices.template.innerCornerRadius = 2;
        series.slices.template.draggable = false;
        series.slices.template.inert = true;
        series.alignLabels = false;
        series.legendSettings.valueText = "{valueY.close}";
        // series.legendSettings.valueText=[];

        series.hiddenState.properties.startAngle = 90;
        series.hiddenState.properties.endAngle = 90;

        chart.legend = new am4charts.Legend();
        // chart.legend.useDefaultMarker = true;
        var markerTemplate = chart.legend.markers.template;
        markerTemplate.width = 10;
        markerTemplate.height = 10;
        chart.legend.labels.template.text = "[bold {color}]{name} ({value})[/]";
        chart.legend.valueLabels.template.disabled = true;
        chart.legend.itemContainers.template.paddingBottom = 4;
        chart.legend.itemContainers.template.paddingTop = 0;
        // chart.logo.disabled = true;
        this.chart = chart;
    }
    componentDidUpdate(oldProps) {
        if (oldProps.chartData !== this.props.chartData) {
            this.chart.data = this.formatData(this.props.chartData);
        }
    }
    componentWillUnmount() {
        if (this.chart) {
            this.chart.dispose();
        }
    }
    render() {
        return (
            <section className="card">
                <div className="card-header"><h3>{laguageFile[localStorage.getItem('language')]["20291"]}</h3></div>
                <article className="card-body reviews-stats" style={{ padding: 0 }}>
                    <div id={this.props.id} style={{ width: "100%", height: "100%" }}></div>
                </article>
            </section>
        )
    }
}
export default PieChartAm;