import React from "react";
import { Button, Input, Select, Switch } from "antd";

import style from '../../../../../components/ProgressiveModalWindow/style.module.scss';
import { CONFIRM, INVITE_USERS } from "../../../../../components/ProgressiveModalWindow/Constants";
import TextArea from "antd/lib/input/TextArea";
import lcICon from '../../../../../../public/images/lcicon.png';
import './style.scss'
import { selectedLanguage, selectedDateFormat, selected_date_format, Selected_Invitee_Role } from "../Constants";


export const ConfirmDetails = (props) => {
    const selectedValue = props.props.selectedLocationVolume; // Replace with your selected string value
    const filteredItem = props.props.locationVolume.find((item) => item.value === selectedValue) || '';
    const label = filteredItem ? filteredItem.label : '';
    return (

        <div className='body-section'>
            <div className='profile-details'>
                <div className="confirm-image-section">
                    <div className="head"><img src={props.props.profileIconURL}></img>
                        <div className="head-sub">
                            <div className="sub-section">
                                <h3>{props.props.profileName}</h3>
                                {filteredItem && <p>({filteredItem.label})</p>}
                            </div>

                            <p>{props.props.selectedBusiness}</p>
                        </div>
                    </div>
                </div>
                <div className="confirm-reporting">
                    <div className="head">
                        <h3>{CONFIRM.SETTINGS}</h3>
                    </div>
                    <div className="content-wrapper">
                        <div className="content-section">
                            <div className="content">
                                <label className="label">{CONFIRM.LANGUAGE}:</label><p>{selectedLanguage[props.props.selectedLanguage]}</p>
                            </div>
                            <div className="content">
                                <label className="item">{CONFIRM.REVIEW_TIMESTAMP}:</label><p>{props.props.selectedTimeStamp == 'UTC' ? 'UTC' : 'Location Time Zone'}</p>
                            </div>
                        </div>
                        <div className="content-section">
                            <div className="content">
                                <label className="label">{CONFIRM.DATE_FORMAT}:</label><p>{`(${selected_date_format[props.props.selectedDateFormat]})  ${selectedDateFormat[props.props.selectedTimeFormat]}`}</p>
                            </div>


                        </div>
                    </div>
                </div>
                <div className="confirm-reporting">
                    <div className="head">
                        <h3>Reporting</h3>
                    </div>
                    <div className="content-wrapper">
                        <div className="content-section">
                            <div className="content">
                                <label>
                                    Review Summary, Daily:
                                </label>
                                <p>
                                    {props.props.dailyReviewSummary ? 'On' : 'Off'}
                                </p>
                            </div>
                            <div className="content">
                                <label>
                                    Review Summary, Weekly:
                                </label>
                                <p>
                                    {props.props.weeklyReviewSummary ? 'On' : 'Off'}
                                </p>
                            </div>
                        </div>
                        <div className="content-section">
                            <div className="content">
                                <label>
                                    Real-Time Reviews:
                                </label>
                                <p>
                                    {props.props.realTimeGoogleReviewNotification ? 'On' : 'Off'}
                                </p>
                            </div>
                            <div className="content">
                                <label>
                                    Real-Time Images:
                                </label>
                                <p>
                                    {props.props.realTimeGoogleMediaNotification ? 'On' : 'Off'}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='confirm-reporting'>
                    <div className="head">
                        <h3>{CONFIRM.GENERATIVE_AI}</h3>
                    </div>
                    <div className="content-wrapper">
                        <div className="content-section">
                            <div className="content">
                                <label>
                                    {CONFIRM.DETECT_LAN}:
                                </label>
                                <p>
                                    {props.props.identifyLanguage ? 'On' : 'Off'}
                                </p>
                            </div>
                            <div className="content">
                                <label>
                                    {CONFIRM.REVIEWER_GENDER}:
                                </label>
                                <p>
                                    {props.props.reviewGenderTagging ? 'On' : 'Off'}
                                </p>
                            </div>
                        </div>
                        <div className="content-section">
                            <div className="content">
                                <label>
                                    {CONFIRM.SENTIMENT_LAN}:
                                </label>
                                <p>
                                    {props.props.sentimentAnalysisEnable ? 'On' : 'Off'}
                                </p>
                            </div>
                            <div className="content">
                                <label>
                                    {CONFIRM.COMP_REVIEWS}:
                                </label>
                                <p>
                                    {props.props.trackCompetitorReviews ? 'On' : 'Off'}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='confirm-reporting'>
                    <div className="head">
                        <h3>{CONFIRM.INVITED_USERS}</h3>
                    </div>
                    <div className="content-wrapper">
                        <div className="content-profile-block">

                            {props.props.inviterUser.map((items) => {
                                if (items.invitees.length > 0) {
                                    return <div className="content-item">
                                        <span>{items.invitees} </span>
                                        <span className="divider">:</span>
                                        <p>{Selected_Invitee_Role[items.role]}</p>
                                    </div>
                                }
                            })}

                        </div>
                        <TextArea value={props.props.inviteMessage} rows={4} />
                    </div>
                    <div>
                    </div>
                </div>
            </div>
        </div>
    )
}