import AppDispatcher from '../../../flux/dispatcher/dispatcher';
import Constant from '../constant/PaymentsConstant';
import restClient from '../../MainFrame/action/APIClient';
import LogoutConstant from '../../MainFrame/constants/Constants';


var PaymentUrlAction = function () {

}


PaymentUrlAction.prototype = {

	getUrl: function (profileId) {
		restClient.getPaymentUrl(profileId, function (error, response) {
			if (error) {
				// console.log("error", error);
				if (error.status === 401) {
					AppDispatcher.dispatch({
						actionType: LogoutConstant.UNAUTHORIZED_EXCEPTION,
						data: error
					});
				} else if (error.status === 403) {
					AppDispatcher.dispatch({
						actionType: Constant.PAYMENT_URL,
						data: error
					});
				}
			} else {
				AppDispatcher.dispatch({
					actionType: Constant.PAYMENT_URL,
					data: response
				});
			}
		})
	}
}

export default new PaymentUrlAction();