import React, { Component } from 'react'
import style from './style.module.scss';
import LeftSection from './children/LeftSection/LeftSection';
import RightSection from './children/RightSection/RightSection';
import SearchWorkSpaceAction from './action/SearchWorkSpaceAction';
import SearchWorkspaceStore from './store/SearchWorkSpaceStore';
import { message } from 'antd';
import SearchWorkSpaceNameAction from './action/SearchWorkSpaceNameAction';
import SearchWorkspaceNameStore from './store/SearchWorkSpaceNameStore';
import UploadProfileLogoAction from '../ImageUpload/action/UploadProfileLogoAction';
import { urlName } from '../../config';
import UploadProfileLogoStore from '../ImageUpload/store/UploadProfileLogoStore';
import CreateNewWorkSpaceAction from './action/CreateNewWorkSpaceAction';
import CreateNewWorkSpaceStore from './store/CreateNewWorkSpaceStore';
import cookie from '../../utils/cookie';
export default class CreateWorkFlow
  extends Component {
  constructor() {
    super();
    this.state = {
      currentStep: 2,
      subPage: 1,
      searchEmail: '',
      searchResponse: false,
      searchLoader: false,
      searchResponseData: 500,
      newWorkspaceName: '',
      validateWorkspaceName: false,
      workeSpaceUrl: 'https://lc-whitelabel-images-prod-dev.s3-us-west-2.amazonaws.com/images/localhost%3A3000/domainIcon',
      fileList: [],
      imageUploadLoader: false,
      localListings: false,
      reputationManagement: false,
      localSocial: false,
      localKeywords: false,
      analyticReporting: false,
      createWorkspaceLoader: false
    }
  }
  componentDidMount() {
    SearchWorkspaceStore.bind(this.searchWorkspaceResponse);
    SearchWorkspaceNameStore.bind(this.validateWorkSpaceName);
    UploadProfileLogoStore.bind(this.getUploadResponse);
    CreateNewWorkSpaceStore.bind(this.createWorkspaceResponse);
  }
  componentWillUnmount() {
    SearchWorkspaceStore.unbind(this.searchWorkspaceResponse);
    SearchWorkspaceNameStore.bind(this.validateWorkSpaceName);
    UploadProfileLogoStore.bind(this.getUploadResponse);
    CreateNewWorkSpaceStore.bind(this.createWorkspaceResponse);
  }

  createWorkspaceResponse = () =>{
    let response = CreateNewWorkSpaceStore.getResponse();
    if(response.status == 200){
      // window.location.href = '/build-profile';
      cookie.createCookie('workspaceData', JSON.stringify(response.accountDetails));
      cookie.createCookie('currentAccountId', response.accountId);
      this.props.history.push('/build-profile')
    }
  }
  getUploadResponse = () => {
    let response = UploadProfileLogoStore.getResponse();
    if (response.status == 200) {
      var imageList = this.state.fileList;
      imageList[0].status = 'done';
      this.setState({
        fileList: imageList,
        workeSpaceUrl: response.url
      });
    } else {
      message.error("failed to upload");
      this.setState({
        fileList: []
      });
    }
    this.setState({ imageUploadLoader: false })
  }
  validateWorkSpaceName = () => {
    let response = SearchWorkspaceNameStore.getResponse();
    if (response.status == 200) {
      this.setState({ validateWorkspaceName: true })
    }
    else if (response.status == 400) {
      this.setState({ validateWorkspaceName: false })
    }
  }
  searchWorkspaceResponse = () => {
    let response = SearchWorkspaceStore.getResponse();
    if (response.status == 200) {
      this.setState({ searchResponse: true, searchResponseData: 200 })
    }
    else {
      this.setState({ searchResponse: true, searchResponseData: 500})
    }
    this.setState({ searchLoader: false })
  }
  onCreateWorkSpace = () => {
    this.setState({ currentStep: 3, subPage: 1, newWorkspaceName: '', validateWorkSpaceName: false })
  }
  onJoinWorkspace = () => {
    this.setState({ currentStep: 2, subPage: 2, searchEmail: '', searchResponse: false, searchResponseData: 500 })
  }

  searchWorkSpace = () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    let isValidEmail = emailRegex.test(this.state.searchEmail);
    if (!isValidEmail) {
      message.error('Enter a valid emailId');
    } else {
      this.setState({ searchLoader: true })
      SearchWorkSpaceAction.searchWorkspace(urlName,this.state.searchEmail.trim())
    }
  }
  onStepFour = () => {
    !this.state.newWorkspaceName ? message.error('Add a Workspace name') : (this.state.validateWorkspaceName && this.state.newWorkspaceName.length >= 6) && this.setState({ currentStep: 4 })
  }
  imageFile = (e) => {
    this.setState({ imageUploadLoader: true })
    var fd = new FormData();
    fd.append('image', e.file);
    fd.append('imageType', 'accountIcon');
    fd.append('domainName', urlName);
    UploadProfileLogoAction.upload(fd)
  }

  handleChange = (fileList) => {
    this.setState({
      fileList: fileList.fileList
    })
  }
  beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG/GIF file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  }
  onChangeWorkspaceName = (value) => {
    if (value.length <= 16) {
      this.setState({ newWorkspaceName: value })
      if (value.length >= 6) {
        SearchWorkSpaceNameAction.searchWorkspaceName(value)
      }
      else {
        this.setState({ validateWorkSpaceName: false })
      }
    }
    else {
      message.error('Maximum character limit is 16')
    }
  }
  searchEmail = (value) => {
    this.setState({ searchEmail: value })
  }

  changePlan = (field) => {
    this.setState({ [field]: !this.state[field] })
  }
  createWorkspace = () => {
    let { localListings, reputationManagement, localSocial, localKeywords, analyticReporting } = this.state;
    if(!localListings && !reputationManagement &&!localSocial&&!localKeywords&&!analyticReporting ){
      message.error('Please enable any of the available options.')
    }
    else{
      let data = {
        "accountName": this.state.newWorkspaceName,
        "accountIcon": this.state.workeSpaceUrl,
        "accountSettings": {
            "localListings": localListings,
            "reputationManagement": reputationManagement,
            "localSocial": localSocial,
            "localKeywords": localKeywords,
            "analyticReporting": analyticReporting,
            "domainName":urlName
        }
      }
      this.setState({createWorkspaceLoader:true})
      CreateNewWorkSpaceAction.createWorkspace(data)
    }
  }
  render() {
    return (
      <div className={style.verifyPageWrapper}>
        <LeftSection step={this.state.currentStep} subPage={this.state.subPage} />
        {/* <section className={style.rightSection}> */}
          <RightSection {...this.state}
            searchWorkSpace={this.searchWorkSpace}
            onChangeEmail={this.searchEmail}
            step={this.state.currentStep}
            subPage={this.state.subPage}
            onJoinWorkspace={this.onJoinWorkspace}
            onCreateWorkSpace={this.onCreateWorkSpace}
            onStepFour={this.onStepFour}
            onChangeWorkspaceName={this.onChangeWorkspaceName}
            imageFile={this.imageFile}
            handleChange={this.handleChange}
            beforeUpload={this.beforeUpload}
            changePlan={this.changePlan}
            onCreateWorkspace={this.createWorkspace} />
        {/* </section> */}
      </div>
    )
  }
}
