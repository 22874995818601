export const PROFILE_SET_UP={
    STEP_1:'Step ',
    SET_UP_PROFILE:'Set Up Profile',
    SET_UP_PROFILE_CONTENT:'Profile are the core organizations tool for your local listings and reviews',
    PROFILE_CORE_DETAILS:'Profile Core Details',
    PROFILE_CORE_SUB:'Complete the items below for a tailored experience (*required)',
    PROFILE_ICON:'Profile icon',
    UPLOAD_Image:'Upload image',
    PROFILE_NAME:'Profile name*',
    BUSINESS_INDUSTRY:'Business industry',
    LOCATION_VOLUME:'Location volume',
    ESTIMATE:'(estimate)'
}

export const REPORTS_NOTICES={
    FIRST_HEADER:' Daily Review Summary',
    FIRST_HEADER_SUB: 'Recap of all reviews for all integration locations and sources, delivered daily each morning.',
    SECOND_HEADER:"Weekly Review Summary",
    SECOND_HEADER_SUB:"Full recap of the prior week's reviews delivered each Monday morning at 9.00 for your selected time zone.",
    THIRD_HEADER: "Real-Time Review Notices, Google",
    THIRD_HEADER_SUB:"Instant email notification of new or updated Google Business Profile reviews.",
    FOURTH_HEADER:"Real-Time Image Notices, Google",
    FOURTH_HEADER_SUB:'Instant email notification of any Images uploaded to your Google Business Profile listings.'

}

export const LOCALIZATION={
    LAN_DATE_TIME:'Language, Date & Time Settings.',
    LAN_DATE_TIME_SUB:'Complete the items below for a tailored experience (*required).',
    DEFAULT_LAN:'Default Language*',
    DEFAULT_LAN_SUB:'Set the language users will see after logging into the platform. Users will be able to select their preference in platform.',
    DATE_FORMAT:'Date & Language Format*',
    DATE_FORMAT_SUB:'Sets the default date and time format for localized presentation and report timing.',
    LISTING_SCHEDULED:'Listing Scheduled Sync',
    LISTING_SCHEDULED_SUB:'After initial integrations,profile re-syncs listing details early on the day selected',
    SET_REVIEW_TIMESTAMP:'Set Review Timestamp',
    SET_REVIEW_TIMESTAMP_SUB:'When a review is received, set the timestamp to'
}

export const GENERATIVE_AI={
    AI_CAPABILITIES:'Generative AI Capabilities.',
    AI_CAPABILITIES_SUB:'Gain deep insights, Available with Professional and Enterprise plans.',
    IDENTIFY_REVIEW_LAN:'Identify review language.',
    IDENTIFY_REVIEW_LAN_SUB:'Tag all new reviews with the primary comment language.',
    SENTIMENT_ANALYSIS:'Sentiment Analysis.',
    SENTIMENT_ANALYSIS_SUB:'Identify review comment topics, sentiment & emotions.',
    SENTIMENT_ANALYSIS_LAN:'Sentiment Analysis Language.',
    SENTIMENT_ANALYSIS_LAN_SUB:'"Open" allows for sentiment topic tagging in the original comment language. "Set" sets topic to a selected language.',
    DEFAULT_LAN:'Default Language.',
    DEFAULT_LAN_SUB:'Topics identified will be  translated into selected language.',
    REVIEW_GENDER_TAGG:'Review Gender Tagging.',
    REVIEW_GENDER_TAGG_SUB:'Identify reviewer gender via AI (Coming Soon).',
    TRACK_COMPETITOR_REVIEW:'Track Competitor Reviews.',
    TRACK_COMPETITOR_REVIEW_SUB:'Track 10x competitor reviews (Competitor sentiment scoring and comparisons coming soon).'
}

export const INVITE_USERS={
    INVITE_USERS:'Invite Users',
    INVITE_USERS_SUB:'All plans included unlimited users',
    EMAIL:'Email',
    ROLE:'Role',
    MULTIPLE_EMAIL:'Enter multiple emails separated for commas for each role',
    PERSONAL_MESSAGE:'Personal Message'
}

export const CONFIRM={
    CONFIRM_ALL_DETAILS:'Confirm All Details',
    CONFIRM_ALL_DETAILS_SUB:'Review details and press "Build Profile" or return to previous steps to edit',
    BUSINESS_NAME_ABC:'BusinessName ABC 123 ',
    BUSINESS_NAME_ABC_SUB:'Banking/Financial Services',
    SETTINGS:'Settings',
    LANGUAGE:'Language',
    SYNC_DAY:'Sync Day',
    DATE_FORMAT:'Date Format',
    REVIEW_TIMESTAMP:'Review Time Stamp',
    GENERATIVE_AI:'Generative AI',
    DETECT_LAN:'Detect Language',
    SENTIMENT_LAN:'Sentiment Language',
    REVIEWER_GENDER:'Review Gender',
    COMP_REVIEWS:'Competitor Reviews',
    INVITED_USERS:'Invited Users',
    TSSUMMY:'tsummy@loaclclarity.com'
}