import AppDispatcher from '../../../flux/dispatcher/dispatcher';
import Constant from '../constant/AuthoriseAccountConstant';
import LogoutConstant from '../../MainFrame/constants/Constants';
import restClient from '../../MainFrame/action/APIClient';
// var cookie = require("public/js/cookie")
import urlConfig from '../../../config/index';


var CheckAdminAction = function () {

}

CheckAdminAction.prototype = {

	check: function (urlName) {
		restClient.checkAdmin(urlName,function (error, response) {
			if (error) {
				// console.log("error", error);
				if (error.status === 401) {
					AppDispatcher.dispatch({
						actionType: LogoutConstant.UNAUTHORIZED_EXCEPTION,
						data: error
					});
				} else if (error.status === 403) {
					AppDispatcher.dispatch({
						actionType: Constant.NOT_ADMIN_PAYMENT,
						data: error
					});
				}
			} else {
				response['status'] = 200;
				AppDispatcher.dispatch({
					actionType: Constant.ADMIN_CHECK_PAYMENT,
					data: response
				});
			}
		})
	}
}

export default new CheckAdminAction();