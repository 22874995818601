import AppDispatcher from '../../../flux/dispatcher/dispatcher';
// import AppDispatcher from 'dispatcher/dispatcher';
import Constant from '../constants/GetHistogramConstant';
import restClient from '../../MainFrame/action/APIClient';
import LogoutConstant from '../../MainFrame/constants/Constants';
import urlConfig from '../../../config/index';
import {urlName} from '../../../config'
var moment = require('moment');
var _ = require("lodash");
var organization, country, locality, area, profile, granularity = 'weekly';
var dateRange = {}, filters = {};

var GetReviewHistogramAction = function () {

}

GetReviewHistogramAction.prototype = {

	addFilters: function (data, newGranularity) {
		filters = data;
		granularity = newGranularity;
		this.loadData();
	},

	clearFilters: function () {
		filters = {};
		granularity = 'weekly';
	},

	changeProfile: function (value) {
		profile = value;
		this.loadData();
	},

	changeOrganisation: function (value) {
		organization = value;
		this.loadData();
	},

	addDownloadFilters: function (data) {
		filters = _.cloneDeep(data);
		if (filters.profileId) {
			// profileId = filters.profileId;
			delete filters.profileId
		}

		if (data.granularity) {
			granularity = data.granularity;
			// delete data.granularity;
		}
		this.loadData();
	},

	addCountry: function (value) {
		country = value;
		locality = undefined;
		area = undefined;
		this.loadData()
	},

	addArea: function (value) {
		area = value;
		locality = undefined;
		this.loadData();
	},

	addLocality: function (value) {
		locality = value;
		this.loadData();
	},

	addDates: function (fromDate, toDate) {
		dateRange.from = fromDate;
		dateRange.to = toDate;
		this.loadData();
	},

	changeGranularity: function (value) {
		granularity = value;
		this.loadData();
	},

	loadData: function () {

		// if(filters.hasOwnProperty('reviewFilters')){
		// 	if(!(filters.reviewFilters.hasOwnProperty('dateRange'))){
		// 		filters['reviewFilters']['dateRange'] = {}
		// 		var dateRange = {};
		// 		dateRange.from = moment().subtract(8, 'week').format('YYYY-MM-DD');
		// 		dateRange.to = moment().format('YYYY-MM-DD');
		// 		filters['reviewFilters']['dateRange'] = dateRange;
		// 	}

		// }else{
		// 	filters['reviewFilters'] = {};
		// 	filters['reviewFilters']['dateRange'] = {}
		// 	var dateRange = {};
		// 	dateRange.from = moment().subtract(8, 'week').format('YYYY-MM-DD');
		// 	dateRange.to = moment().format('YYYY-MM-DD');
		// 	filters['reviewFilters']['dateRange'] = dateRange;
		// }

		if (!(filters.hasOwnProperty('dateRange'))) {
			dateRange.from = moment().subtract(16, 'week').format('YYYY-MM-DD');
			dateRange.to = moment().format('YYYY-MM-DD');
			filters['dateRange'] = dateRange;
		}

		var data = {
			profileId: localStorage.getItem('profileId'),
			granularity: granularity,
			sources: filters.sources != null ? filters.sources: [],
			filters: filters,
			domainName:urlName
		};


		restClient.getReviewHistogram(data, function (error, response) {
			if (error) {
				// console.log("error", error);
				if (error.status === 401) {
					AppDispatcher.dispatch({
						actionType: LogoutConstant.UNAUTHORIZED_EXCEPTION,
						data: error
					});
				}
			} else {
				AppDispatcher.dispatch({
					actionType: Constant.GET_HISTOGRAM,
					data: response
				});
			}
		})
	},
	downloadReviewLocation() {
		var urlName = urlConfig.urlName;
		
		var data = {
			profileId: localStorage.getItem('profileId'),
			granularity: granularity,
			domainName: urlName,
			sources: filters.sources != null ? filters.sources: [],
			filters: filters
		};
		restClient.downloadReviewLocation(data, function (error, response) {
			if (error) {
				// console.log("error", error);
				if (error.status === 401) {
					AppDispatcher.dispatch({
						actionType: LogoutConstant.UNAUTHORIZED_EXCEPTION,
						data: error
					});
				}
			} else {
				AppDispatcher.dispatch({
					actionType: Constant.DOWNLOAD_REVIEW_LOCATION,
					data: response
				});
			}
		})
	}
}

export default new GetReviewHistogramAction();