import AppDispatcher from '../../../flux/dispatcher/dispatcher';
import Constant from '../constants/constant';
import restClient from '../../MainFrame/action/APIClient';
import LogoutConstant from '../../MainFrame/constants/Constants';
import { urlName } from '../../../config';

var GetNavigationDataAction = function () {

}

GetNavigationDataAction.prototype = {
	getData: function (profileId) {
		let domainName = urlName;
		restClient.getLeftNavigationLinks(profileId,domainName, function (error, response) {
			if (error) {
				AppDispatcher.dispatch({
					actionType: Constant.TAB_NAVIGATION_DATA,
					data: error
				});
			} else {
				response.status = 200;
				AppDispatcher.dispatch({
					actionType: Constant.TAB_NAVIGATION_DATA,
					data: response
				});
			}
		})
	}
}


export default new GetNavigationDataAction();