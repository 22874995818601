import urlConfig from '../../config/index';
var React = require('react');

class Feedback extends React.Component {

	componentWillMount() {
		var urlName = urlConfig.urlName;
		window.location = "https://" + urlName + "/pdf/feedback?domainName=" + urlName;
	}

	render() {
		return null
	}
}

export default Feedback;