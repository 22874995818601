import AppDispatcher from '../../../flux/dispatcher/dispatcher';
import Constant from '../constants/GetOrganisationsConstant';
import restClient from '../../MainFrame/action/APIClient';
import LogoutConstant from '../../MainFrame/constants/Constants';

var GetOrganisationsAction = function () {

}

GetOrganisationsAction.prototype = {

	getOrgs: function (listAllAccounts, fromPage, size) {
		var proId = localStorage.getItem('profileId');
		restClient.getOrganisations(proId, listAllAccounts, fromPage, size, function (error, response) {
			if (error) {
				// console.log("error", error);
				// if (error.status === 401) {
				// 	AppDispatcher.dispatch({
				// 		actionType: LogoutConstant.UNAUTHORIZED_EXCEPTION,
				// 		data: error
				// 	});
				// }
			} else {
				// console.log("response of orgs action", response)
				if (listAllAccounts) {
					AppDispatcher.dispatch({
						actionType: Constant.GET_ORGANISATIONS_RESPONSE,
						data: response
					});
				} else {
					AppDispatcher.dispatch({
						actionType: Constant.GET_ACTIVE_ORGANISATIONS_RESPONSE,
						data: response
					});
				}
			}

		})
	},

	getProfileOrgs: function (listAllAccounts, profileId) {
		restClient.getOrganisations(profileId, listAllAccounts, 0, 100, function (error, response) {
			if (error) {
				// console.log("error", error);
				if (error.status === 401) {
					AppDispatcher.dispatch({
						actionType: LogoutConstant.UNAUTHORIZED_EXCEPTION,
						data: error
					});
				}
			} else {
				// console.log("response of orgs action", response)
				AppDispatcher.dispatch({
					actionType: Constant.GET_PROFILE_ORGANISATIONS_RESPONSE,
					data: response
				});
			}
		})
	}
}

export default new GetOrganisationsAction();