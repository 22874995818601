import AppDispatcher from '../../../flux/dispatcher/dispatcher';
import Constant from '../constants/GetHistogramConstant';
import restClient from '../../MainFrame/action/APIClient';
import LogoutConstant from '../../MainFrame/constants/Constants';

var moment = require('moment');
var organization, country, locality, area, profileId;
var dateRange = {};

var GetDataFetchStatusAction = function () {

}

GetDataFetchStatusAction.prototype = {

	getStatus: function (profileId) {

		restClient.getDataFetchStatus(profileId, function (error, response) {
			if (error) {
				// console.log("error", error);
				if (error.status === 401) {
					AppDispatcher.dispatch({
						actionType: LogoutConstant.UNAUTHORIZED_EXCEPTION,
						data: error
					});
				} else {
					AppDispatcher.dispatch({
						actionType: Constant.DATA_FETCHING_STATUS,
						data: response
					});
				}
			} else {
				AppDispatcher.dispatch({
					actionType: Constant.DATA_FETCHING_STATUS,
					data: response
				});
			}
		})
	}
}

export default  new GetDataFetchStatusAction();