import React from 'react';
import { Route, Switch } from 'react-router-dom';
import HeaderBar from '../../components/Header'
import PortfolioSideBar from '../../components/PortfolioSideBar'
import { Layout } from 'antd';
import LogoutStore from '../MainFrame/store/LogoutStore';
import ProtectedRoute from '../../components/ProtectedRoute'
import ReactAlert from '../../components/sharedComponets/ReactAlert'
import cookie from '../../utils/cookie';
import GetIntegrationAction from '../IntegrationControl/action/GetIntegrationAction';
import GetIntegrationStore from '../IntegrationControl/store/GetIntegrationStore';
import urlConfig from '../../config/index';
import PortfolioManageUsers from '../PortfolioManageUsers/index';
import PortfolioManageProfiles from '../PortfolioManageProfiles';
import PortfolioSettings from '../PortfolioSettings';




const { Content, Sider } = Layout;
var analytics = window.analytics;

class PortfolioFrame extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            collapsed: true,
            isSideBarVisible: true,
            managePortfolio: true
        }
        this.toggleSideBar = this.toggleSideBar.bind(this)
    }

    componentWillMount() {
        LogoutStore.bind(this.logout);
        GetIntegrationStore.bind(this.onGetIntegrations);
        GetIntegrationAction.getintegration(urlConfig.urlName);
    }

    componentWillUnmount() {
        LogoutStore.unbind(this.logout);
        GetIntegrationStore.unbind(this.onGetIntegrations);
    }

    componentDidMount() {
        analytics.identify(cookie.readCookie('userId'),
            {
                username: cookie.readCookie('userName'),
                name: cookie.readCookie('userName'),
                email: cookie.readCookie('userId')
            })
    }
    onGetIntegrations = () => {
        if (GetIntegrationStore.getResponse().domainColors) {
            localStorage.setItem('sidebarBackground', GetIntegrationStore.getResponse().domainColors.sidebarBackground);
            localStorage.setItem('sidebarBorder', GetIntegrationStore.getResponse().domainColors.sidebarBorder);
            localStorage.setItem('sidebarTransparency', GetIntegrationStore.getResponse().domainColors.sidebarTransparency);
            localStorage.setItem('activeTabList', GetIntegrationStore.getResponse().domainColors.activeTabList);
            localStorage.setItem('tabIcon', GetIntegrationStore.getResponse().domainColors.tabIcon);
            localStorage.setItem('activeIcon', GetIntegrationStore.getResponse().domainColors.activeIcon);
            localStorage.setItem('logoBackground', GetIntegrationStore.getResponse().domainColors.logoBackground);
            localStorage.setItem('headerBackground', GetIntegrationStore.getResponse().domainColors.headerBackground);
            localStorage.setItem('headerIcon', GetIntegrationStore.getResponse().domainColors.headerIcon);
        }
    }
    logout() {
        localStorage.removeItem("profileId");
        localStorage.removeItem("profileName");
        localStorage.removeItem("masterLocationFilter");
        localStorage.removeItem("platformRequestId");
        localStorage.removeItem("googleRequestId");
        localStorage.removeItem("attributesRequestId");
        localStorage.removeItem('facebookRequestId');
        localStorage.removeItem('googlelocationRequestId');
        localStorage.removeItem('facebooklocationRequestId');
        cookie.eraseCookie("lctoken");
        cookie.eraseCookie("userName");
        cookie.eraseCookie("userId");
        cookie.eraseCookie("workspaceData");
        cookie.eraseCookie("currentAccountId");

        // this.props.history.push("/login");
        window.location.href = "/login"
    }

    toggleSideBar() {
        this.setState({
            collapsed: !this.state.collapsed
        })
    }

    checkIfHashed(str) {
        if (str) {
            return str.match("[a-fA-F0-9]{32}")
        } else return str;
    }
    onChangeLanguage = (key) => {
        window.location.reload(false);
        this.setState({
            language: key
        })
    }
    changeSideBarVisibility = () => {
        this.setState({
            isSideBarVisible: !this.state.isSideBarVisible
        })
    }
    renderSideBarClass = () => {
        if (!this.state.isSideBarVisible) {
            return "main-wrapper close-side";
        } else if (this.state.collapsed) {
            return "main-wrapper";
        } else {
            return "collapsed main-wrapper";
        }
    }

    render() {

        // if (localStorage.getItem('profileId') === 'expired' || !(this.checkIfHashed(localStorage.getItem('profileId')))) {
        //     this.props.history.push('/authorize/paymentExpired');
        // }

        // const user = {
        //     user_id: cookie.readCookie('userId'),
        //     email: cookie.readCookie('userId'),
        //     name: cookie.readCookie('userName')
        // };
        // var minHeight = screen.height - 202;
        return (
            <div className={this.renderSideBarClass()}>
                <ReactAlert />
                <aside className={this.state.isSideBarVisible && "main-wrapper__sidebar" || "main-wrapper__sidebar"} style={{ backgroundColor: localStorage.getItem("sidebarBackground") }}>
                    <PortfolioSideBar />
                </aside>
                <article className="main-wrapper__content">
                    <HeaderBar toggleSideBar={this.toggleSideBar} collapsed={this.state.collapsed} onChangeLanguage={this.onChangeLanguage}
                        onChangeVisibility={this.changeSideBarVisibility} isSideBarVisible={this.state.isSideBarVisible} managePortfolio={this.state.managePortfolio} />
                    <div className="ant-layout-divider">
                        <span className="divider-red"></span>
                        <span className="divider-blue"></span>
                        <span className="divider-yellow"></span>
                        <span className="divider-green"></span>
                    </div>
                    <Switch>
                        <ProtectedRoute path="/manageprofiles" component={PortfolioManageProfiles} />
                        <ProtectedRoute path="/manageusers" component={PortfolioManageUsers} />
                        <ProtectedRoute path="/portfoliosettings" component={PortfolioSettings} />
                        <ProtectedRoute component={PortfolioManageProfiles} />
                    </Switch>
                </article>
            </div>
        );
    }
}

export default PortfolioFrame;
