import React from 'react';
import { PlusPlanTitle } from './PlusPlanTitle';
import { PlusPlanDetails } from './PlusPlanDetails';
import languageFile from '../../../../public/languages/Translation';

export const UnsubscribedPlusPlan = () => {
	return (
		<div className='payment-wrap'>
			<div className='payment-details'>
				<div className='payment-header'>{languageFile[localStorage.getItem('language')]["24121"]}</div>
				<PlusPlanTitle/>
				<PlusPlanDetails/>
			</div>
		
		</div>
	);
};
