import React from 'react';
import {PaymentExpiredPage} from './PaymentExpiredPage';
import {PaymentLoader} from './paymentLoader';
import {SelectPaymentPage} from './SelectPaymentPage';
var moment = require('moment');
const PAYMENT_EXPIRED = 'paymentExpired';
export const PaymentPageWrapper = ({
	history,
	role,
	loading,
	notSubscribed,
	paymentDetails,
	getHostPage,
	cancelSubscription,
	handleCancel,
	handleOk,
	cancelConfirmationModal,
	cancelConfirmation,
	getAddonDetails,
	invoicesData,
	getInvoicesList,
	ownerUpdatedMessage,
	openPaymentModal,
	invalidEmail,
	changeOwner,
	ownerUpdateResponseMessage,
	changePayerDetailsModal,
	changeEmailModal,
	updatePayerEmail,
	payerEmail,
	openPaymentPortal,
	cancelSubscriptionResponseMessage,
	isSubscriptionCancelled,
	reActiveConfirmation
}) => {
	if (history.location.pathname.indexOf(PAYMENT_EXPIRED) > -1 &&(!role || role !="OWNER")) {
		return <PaymentExpiredPage history={history} role={role} />;
	}

	if (loading) {
		return <PaymentLoader loading={loading} />;
	}
	if (
		!notSubscribed &&
		history.location.pathname.indexOf(PAYMENT_EXPIRED) > -1
	) {
		return null;
	}
	return (
		<SelectPaymentPage
			isSubscriptionCancelled={isSubscriptionCancelled}
			cancelSubscriptionResponseMessage={
				cancelSubscriptionResponseMessage
			}
			notSubscribed={notSubscribed}
			paymentDetails={paymentDetails}
			getHostPage={getHostPage}
			cancelSubscription={cancelSubscription}
			handleCancel={handleCancel}
			handleOk={handleOk}
			cancelConfirmationModal={cancelConfirmationModal}
			cancelConfirmation={cancelConfirmation}
			getAddonDetails={getAddonDetails}
			invoicesData={invoicesData}
			getInvoicesList={getInvoicesList}
			ownerUpdatedMessage={ownerUpdatedMessage}
			ownerUpdateResponseMessage={ownerUpdateResponseMessage}
			openPaymentModal={openPaymentModal}
			invalidEmail={invalidEmail}
			changeOwner={changeOwner}
			changePayerDetailsModal={changePayerDetailsModal}
			changeEmailModal={changeEmailModal}
			updatePayerEmail={updatePayerEmail}
			payerEmail={payerEmail}
			openPaymentPortal={openPaymentPortal}
			reActiveConfirmation={reActiveConfirmation}
		/>
	);
};
