import React, { Component } from 'react';
import EditPortfolioAction from './action/EditPortfolioAction';
import EditPortfolioStore from './store/EditPortfolioStore';
import GetPortfolioDetailsAction from '../AuthorisePage/action/GetPortfolioDetailsAction';
import GetPortfolioDetailsStore from '../AuthorisePage/store/GetPortfoliosDetailStore';
import UploadPortfolioImageAction from './action/UploadPortfolioImageAction';
import UploadPortfolioImageStore from './store/UploadPortfolioImageStore';
import moment from 'moment';

import { Row, Col } from 'react-bootstrap';
import {
	LocaleProvider,
	Table,
	notification,
	Dropdown,
	Menu,
	message,
	Button,
	Modal,
	Input,
	Icon,
	Tooltip,
	Upload
} from 'antd';
import { urlName } from '../../config';

import enUS from 'antd/lib/locale-provider/en_US';
import Select from 'react-select';
import _ from 'lodash';
import './style.scss';
var languageFile = require('../../../public/languages/Translation.js');
var analytics = window.analytics;
var cookie = require('../../utils/cookie');

class PortfolioEdit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			roleValue: 'READ_ONLY',
			loading: true,
			UsersForProfile: [],
			selectedRowKeys: [],
			newProfile: '',
			profiles: [],
			selectedOption: null,
			showRemoveProfileConfirm: false,
			showModel: false,
			fileList: [],
			portfolioName: localStorage.getItem('portfolioName'),
			portfolioLogo: localStorage.getItem('portfolioLogo')
		};
		this.handleCancel = this.handleCancel.bind(this);
		this.changeNameConfirm = this.changeNameConfirm.bind(this);
		this.uploadPortfolioImageResponse = this.uploadPortfolioImageResponse.bind(this);
		this.portfolioResponse = this.portfolioResponse.bind(this);

	}

	componentWillMount() {
		EditPortfolioStore.bind(this.editPortfolioStoreResponse);
		UploadPortfolioImageStore.bind(this.uploadPortfolioImageResponse);
		GetPortfolioDetailsStore.bind(this.portfolioResponse);

	}

	componentWillUnmount() {
		EditPortfolioStore.unbind(this.editPortfolioStoreResponse);
		UploadPortfolioImageStore.unbind(this.uploadPortfolioImageResponse);
		GetPortfolioDetailsStore.unbind(this.portfolioResponse);

	}

	componentDidMount() {}
	portfolioResponse() {
		var portfolioLogo =  _.find(GetPortfolioDetailsStore.getResponse(), function (item) { return item.portfolioName === localStorage.getItem('portfolioName') }).portfolioLogo;
		localStorage.setItem('portfolioLogo',portfolioLogo)
		this.setState({
			portfolioLogo: portfolioLogo,
		});
	}
	changePortfolioName = (e) => {
		this.setState({
			portfolioName: e.target.value,
		});
	};
	handleCancel() {
		this.setState({
			showModel: false,
		});
	}
	editPortfolioStoreResponse = () => {
		if (EditPortfolioStore.getResponse().status === 200) {
			message.success('Successfully edited the portfolio name');
			localStorage.setItem('portfolioName', this.state.portfolioName);
			GetPortfolioDetailsAction.getPortfolios(cookie.readCookie('lctoken'));
			this.setState({
				portfolioName: localStorage.getItem('portfolioName'),
			});
		} else {
			message.warning(EditPortfolioStore.getResponse().data.message);
		}
	};
	changeNameConfirm() {
		EditPortfolioAction.editPortfolio({
			portfolioName: this.state.portfolioName,
			portfolioId: localStorage.getItem('portfolioId'),
		});
		this.setState({
			showModel: false,
		});
	}
	changeName = () => {
		this.setState({
			showModel: true,
			portfolioName: localStorage.getItem('portfolioName'),
		});
	};
	uploadPortfolioImageResponse = () =>{
		if (UploadPortfolioImageStore.getResponse() && UploadPortfolioImageStore.getResponse().status == 200) {
			message.success("Successfully uploaded the image");
			var imageList = this.state.fileList;
			GetPortfolioDetailsAction.getPortfolios(cookie.readCookie('lctoken'));
			imageList[0].status = 'done';
			this.setState({
				fileList: imageList,
				flag: !this.state.flag,
			});
		} else {
			message.error("failed to upload");
		}

	}
	handleChange = (fileList) => {
		this.setState({
			fileList: fileList.fileList
		})
	}
	handlePreview = (file) => {
		this.setState({
			previewImage: file.url || file.thumbUrl,
			previewVisible: true,
		});
	}
	handlePreviewCancel = () => {
		this.setState({
			previewVisible: false
		})
	}
	imageFile = (e, a) => {
		var fd = new FormData();
		fd.append('portfolioId', localStorage.getItem('portfolioId'));
		//fd.append('domainName', urlName);
		fd.append('image', e.file);
		//fd.append('imageType', this.state.selectedUploadType.value);
		//fd.append('domainName', urlName);
		//UploadProfileLogoAction.upload(fd)
		UploadPortfolioImageAction.uploadPortfolioImage(fd)
	}
	removeImage = () => {
		this.setState({
			fileList: [],
		});
	};
	render() {
		return (
			<>
				<div className="page-header">
					<h3>{languageFile[localStorage.getItem('language')]["27921"]}</h3>
				</div>
				<div className="portfolio-name">
					<h3>{languageFile[localStorage.getItem('language')]["27931"]}</h3>
					<div className="upload-inner">
						<h4>
							{localStorage.getItem('portfolioName')}
							<Icon type="edit" onClick={this.changeName} />
						</h4>
					</div>
				</div>
				<div className="current-image">
					<h3><span>{languageFile[localStorage.getItem('language')]["27941"]}</span>
					<Tooltip title={languageFile[localStorage.getItem('language')]["27951"]}>
						<Icon type="info-circle" />
					</Tooltip>
					</h3>
					
					<div className="image-list">
						<div className="image-list__item">
							{/* <h4>
								<Tooltip title="image">
									<Icon type="info-circle" />
								</Tooltip>
							</h4> */}
							<span className="item-image">
								{localStorage.getItem('portfolioLogo').substring(0, 9) != 'undefined' ?
								<img src={localStorage.getItem('portfolioLogo')+ "?time=" + moment().format('LTS')} alt="image" /> 
							 :
							 <div className="ant-upload-text" style={{ marginTop: 10 }}>Upload a Image</div>}
							</span>
						</div>
					</div>
				</div>
				<div className="upload-portfolio-image">
                    <Upload
                        action="//jsonplaceholder.typicode.com/posts/"
                        listType="picture-card"
                        fileList={this.state.fileList}
                        onPreview={this.handlePreview}
                        onChange={this.handleChange}
                        customRequest={this.imageFile}
                        onRemove={this.removeImage}
                        className="upload-portfolio-wrap"
                    >
                        {this.state.fileList.length >= 1 ? null :
                            <div>
                                <span ><img src="/images/add-image.png" /></span>
                                <div className="ant-upload-text" style={{ marginTop: 10 }} >Upload </div>
                            </div>}
                    </Upload>
                    <Modal visible={this.state.previewVisible} footer={null} onCancel={this.handlePreviewCancel}>
                        <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
                    </Modal>
                </div>
				<Modal
					visible={this.state.showModel}
					title={languageFile[localStorage.getItem('language')]["27961"]}
					onOk={this.changeNameConfirm}
					onCancel={this.handleCancel}
					// footer={null}
				>
					<div className="text-center">
						<div className="email-input">
							<span>{languageFile[localStorage.getItem('language')]["27931"]}</span>
							<Input
								placeholder="Enter Portfolio Name"
								size="small"
								value={this.state.portfolioName}
								onChange={this.changePortfolioName}
							/>
						</div>
					</div>
				</Modal>
			</>
		);
	}
}

export default PortfolioEdit;
