import React from 'react';
import { Button } from 'antd/lib/radio';
import {urlName} from '../../../config/index';

export const UnsubscribedPaymentButton = ({
    openPaymentPortal
}) => {
	return (
		<div className='price-block' style={urlLevelStyle()}>
			<Button
				style={{
					background: '#067ae1',
					color: '#fff'
				}}
				onClick={openPaymentPortal}>
				Subscribe
			</Button>
		</div>
	);
};
const urlLevelStyle = () => {
	if(urlName=='app.localclarity.com'){
		return {margin: '10px 0px'}
	}else {
		return {margin: '150px 0px'}
	}
}