import Dispatcher from '../../../flux/dispatcher/dispatcher';
import Constants from "../constants/LoginConstant"
var EventEmitter = require('events').EventEmitter;
var assign = require('object-assign');
var RESPONSE_CHANGE_EVENT = 'globalResponse';

var confirmationSignUp = {}
function parseResponse(resp) {
    // console.log("Login Response", resp);
    confirmationSignUp = resp
}


var LoginStore = assign({}, EventEmitter.prototype, {
    emitChangeEvent: function (event) {
        this.emit(event);
    },
    bind: function (callback) {
        this.on(RESPONSE_CHANGE_EVENT, callback);
    },
    unbind: function (callback) {
        this.removeListener(RESPONSE_CHANGE_EVENT, callback);
    },
    getSignInResponse: function () {
        return confirmationSignUp
    }
});

Dispatcher.register(function (action) {
    switch (action.actionType) {
        case Constants.SIGN_IN_RESPONSE_RECIEVED:
            var resp = action.data;
            parseResponse(resp)
            // console.log("SignInStore......", resp)
            LoginStore.emitChangeEvent(RESPONSE_CHANGE_EVENT);
            break;

        case Constants.SIGN_IN_ERROR:
            var res = action.data;
            parseResponse(res)
            // console.log("SignInStore......", res)
            LoginStore.emitChangeEvent(RESPONSE_CHANGE_EVENT)
            break;
            
        default:
    }
});

export default LoginStore;