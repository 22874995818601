import React from 'react';
import {Icon} from 'antd';

export const RenderPaymentPreferenceTable = ({
	paymentDetails,
	getHostPage,
	changeEmailModal
}) => {
	let body = [];
	body = generateTableData({
		getHostPage,
		paymentDetails,
		changeEmailModal
	});
	return (
		<table className='table table-bordered'>
        <tbody>
			{body.map(item => {
				return <tr>
						<td>
							{item.rowData}
							{item.text}
						</td>
						<td>{item.link}</td>
					</tr>
            })}
        </tbody>

		</table>
	);
};

const generateTableData = ({
	getHostPage,
	paymentDetails,
	changeEmailModal
}) => {
	return [
		{
			rowData: (
				<i
					className='fa fa-credit-card'
					aria-hidden='true'
					style={{marginRight: 5}}></i>
			),
			text: 'Card ending in ' + paymentDetails.cardLastDigit,
			link: (
				<a className='payment-action' onClick={getHostPage}>
					Update card <Icon type='edit' />
				</a>
			)
		},
		{
			rowData: (
				<i
					className='fa fa-credit-card-alt'
					aria-hidden='true'
					style={{marginRight: 5}}></i>
			),
			text: 'Card type',
			link: <span>{paymentDetails.cardType}</span>
		},
		{
			rowData: <Icon type='mail' />,
			text: 'Billing Email',
			link: (
				<a
					onClick={changeEmailModal}
					className='payment-action'>
					<span>
						{paymentDetails.userId} <Icon type='edit' />
					</span>
				</a>
			)
		},
		{
			rowData: <Icon type='user' />,
			text: 'Billing Name',
			link: (
				<span>
					{paymentDetails.firstName +
						' ' +
						paymentDetails.lastName}
				</span>
			)
		},
		{
			rowData: <Icon type='team' />,
			text: 'Organization',
			link: <span>{paymentDetails.organisation || '-'}</span>
		}
	];
};

