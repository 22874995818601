import React from 'react';

export const RenderInviteEmailError = ({emailValidationError}) => {
	if (emailValidationError)
		return (
			<div className='error-message'>
				Please enter a valid email id
			</div>
        );
	else return [];
};
