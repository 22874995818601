import React from 'react';
import { message, Modal, LocaleProvider } from 'antd';
import GetProfileDetailsAction from '../AuthorisePage/action/GetProfileDetailsAction';
import GetProfileDetailStore from '../AuthorisePage/store/GetProfileDetailStore';
import enUS from 'antd/lib/locale-provider/en_US';
import { urlName } from '../../config';
var cookie = require("../../utils/cookie");
const confirm = Modal.confirm
const info = Modal.info;

class LinkRedirection extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			loading: false
		}
		this.onProfileDetails = this.onProfileDetails.bind(this);
	}

	componentDidMount() {
		if (cookie.readCookie("lctoken")) {
			if (this.props.location.search.includes('?reviewurl')) {
				// if (Object.prototype.hasOwnProperty.call(this.props.location.search, 'reviewurl')) {
				localStorage.setItem('profileId', this.props.location.search.split("&profileId=")[1]);
				// console.log("profileId ==>", this.props.location.search.split("&profileId=")[1])
				GetProfileDetailsAction.getProfileDetails(this.props.location.search.split("&profileId=")[1],urlName);
			}
		} else {
			var newurl = '/login?reviewurl=' + this.props.location.search.split("?reviewurl=")[1];
			// var newurl = '/login?reviewurl=' + this.props.location.search.reviewurl + '&profileId=' + this.props.location.search.profileId;
			this.props.history.push(newurl)
		}
	}
	componentWillMount() {
		GetProfileDetailStore.bind(this.onProfileDetails);
	}
	componentWillUnmount() {
		GetProfileDetailStore.unbind(this.onProfileDetails);
	}
	onProfileDetails() {
		if (GetProfileDetailStore.getResponse().status == 200) {
			localStorage.setItem('masterLocationFilter', GetProfileDetailStore.getResponse().profile.masterLocationFilter)
			localStorage.setItem('profileName', GetProfileDetailStore.getResponse().profile.profileName)
			if (this.props.location.search.includes('?reviewurl')) {
				// if (Object.prototype.hasOwnProperty.call(this.props.location.search, 'reviewurl')) {
				var url = this.props.location.search.split("reviewurl=")[1]
				var newurl = decodeURIComponent(url)
				this.props.history.push(newurl)
				// console.log("url   newurl ===> ", url | newurl)
			}
		} else if (GetProfileDetailStore.getResponse().data && GetProfileDetailStore.getResponse().data.status == 404) {
			this.unAuthorisedInfo()
			this.props.history.push('/login')
		} else if (GetProfileDetailStore.getResponse().data && GetProfileDetailStore.getResponse().data.message) {
			message.error(GetProfileDetailStore.getResponse().data.message)
		}
	}
	unAuthorisedInfo() {
		info({
			title: "You don't have permission to view this page!",
			content: "You don't have access to this profile.Please contact the profile admin to get access."
		});
	}
	render() {
		return (
			<LocaleProvider locale={enUS}>
				<div></div>
			</LocaleProvider>
		)
	}
}

export default LinkRedirection;
