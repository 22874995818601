import React from "react";
import { Button, Input, Select, Switch } from "antd";

import style from '../../../../../components/ProgressiveModalWindow/style.module.scss';
import { GENERATIVE_AI } from "../../../../../components/ProgressiveModalWindow/Constants";
import './style.scss'
import { availableSentimentLanguages } from "../Constants";

const DEFAULT_LANGUAGE = [{

}]

export const GenerativeAI = (props) => {
    return (

        <div className='body-section'>
            <div className='profile-details'>
                <ul>
                    <li className="ai-content ai-block" >
                        <div className="label-section">
                            <h3>{GENERATIVE_AI.IDENTIFY_REVIEW_LAN}</h3>
                            <p>{GENERATIVE_AI.IDENTIFY_REVIEW_LAN_SUB}</p>
                        </div>
                        <div className="switch-element">
                            <Switch onChange={(e) => props.onChangeCreteProfileDetails('identifyLanguage', e)} checked={props.props.identifyLanguage} checkedChildren="On" unCheckedChildren="Off" />
                        </div>
                    </li>
                    <li className="ai-content">
                        <div className="label-section">
                            <h3>{GENERATIVE_AI.SENTIMENT_ANALYSIS}</h3>
                            <p>{GENERATIVE_AI.SENTIMENT_ANALYSIS_SUB}</p>
                        </div>
                        <div className="switch-element">
                            <Switch onChange={(e) => props.onChangeCreteProfileDetails('sentimentAnalysisEnable', e)} checked={props.props.sentimentAnalysisEnable} checkedChildren="On" unCheckedChildren="Off" />
                        </div>
                    </li>
                    {props.props.sentimentAnalysisEnable &&
                        <li className="ai-content">
                            <div className="label-section">
                                <h3>{GENERATIVE_AI.SENTIMENT_ANALYSIS_LAN}</h3>
                                <p>{GENERATIVE_AI.SENTIMENT_ANALYSIS_LAN_SUB}</p>
                            </div>
                            <div className="switch-element">
                                <Switch onChange={(e) => props.onChangeCreteProfileDetails('setSentimentLanguage', e)} checked={props.props.setSentimentLanguage} checkedChildren="Set" unCheckedChildren="Off" />
                            </div>
                        </li>}
                    {props.props.setSentimentLanguage && <li className="ai-content">
                        <div className="label-section">
                            <div className="sub-label">
                                <h3>{GENERATIVE_AI.DEFAULT_LAN}</h3>
                                <p>{GENERATIVE_AI.DEFAULT_LAN_SUB}</p>
                            </div>
                        </div>
                        <div className="switch-element">
                            <Select placeholder='French'
                                value={props.props.defaultSentimentLanguage}
                                onSelect={(e) => props.onChangeCreteProfileDetails('defaultSentimentLanguage', e)}
                                style={{
                                    width: 120,
                                }}
                            >
                                {availableSentimentLanguages.map((items) => {
                                    return <Select.Option value={items.value}>{items.label}</Select.Option>
                                })}
                            </Select>
                        </div>
                    </li>}
                    <li className="ai-content">
                        <div className="label-section">
                            <h3>{'Custom Generative AI Tone of Voice'}</h3>
                            <p>{'Activate ability to personalize the generative AI tone by rating.'}</p>
                        </div>
                        <div className="switch-element">
                            <Switch onChange={(e) => props.onChangeCreteProfileDetails('customGenerativeAIToneVoice', e)} checked={props.props.customGenerativeAIToneVoice} checkedChildren="On" unCheckedChildren="Off" />
                        </div>
                    </li>
                    <li className="ai-content">
                        <div className="label-section">
                            <h3>{GENERATIVE_AI.REVIEW_GENDER_TAGG}</h3>
                            <p>{GENERATIVE_AI.REVIEW_GENDER_TAGG_SUB}</p>
                        </div>
                        <div className="switch-element">
                            <Switch onChange={(e) => props.onChangeCreteProfileDetails('reviewGenderTagging', e)} checked={props.props.reviewGenderTagging} checkedChildren="On" unCheckedChildren="Off" />
                        </div>
                    </li>
                    <li className="ai-content">
                        <div className="label-section">
                            <h3>{GENERATIVE_AI.TRACK_COMPETITOR_REVIEW}</h3>
                            <p>{GENERATIVE_AI.TRACK_COMPETITOR_REVIEW_SUB}</p>
                        </div>
                        <div className="switch-element">
                            <Switch onChange={(e) => props.onChangeCreteProfileDetails('trackCompetitorReviews', e)} checked={props.props.trackCompetitorReviews} checkedChildren="On" unCheckedChildren="Off" />
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    )
}