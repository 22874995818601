var keyMirror = require('keymirror');

module.exports = keyMirror({
    PAYMENT_URL: null,
    HOST_PAGE_PAYMENT_URL: null,
    PAYMENT_AMOUNT: null,
    SUBSCRIPTION_DETAILS: null,
    UPDATE_OWNER: null,
    SUBSCRIPTION_DEACTIVATE: null,
    SUBSCRIPTION_DELETED: null,
    SUBSCRIPTION_REACTIVATE: null,
    INVOICES_LIST: null,
    INVOICE_DOWNLOAD: null,
    REACTIVE_SUBSCRIPTION: null
});