import React from 'react';
import { UnsubscribedPlusPlan } from './UnsubcriedPlusPlan';
import { UnsubscribedProPlan } from './UnsubscribedProPlan';

export const UnsubscribedPlanDetails = () => {
	return (
		<div className='payment-wrap' style={{padding: 14}}>
			<UnsubscribedPlusPlan/>
		    <UnsubscribedProPlan/>
		</div>
	);
};
