var languageFile = require("../../../../public/languages/Translation.js")

export const SideBarRoutesList = [
    {
        to: '/portfoliosettings/editPortfolio',
        label: languageFile[localStorage.getItem('language')]["27911"],
        iconName: 'unlock',
        iconType: 'icon',
        permissions: []
    },
];
