import AppDispatcher from '../../../flux/dispatcher/dispatcher';
import Constant from '../constants/constant';
import LogoutConstant from '../../MainFrame/constants/Constants';
import restClient from '../../MainFrame/action/APIClient';

var GetPortfolioUsersAction = function () {

}


GetPortfolioUsersAction.prototype = {

	getPortfolioUsers: function (portfolioId, fromPage, size) {

		restClient.getPortfolioUsers(portfolioId, fromPage, size, function (error, response) {
			if (error) {
				if (error.status === 401) {
					AppDispatcher.dispatch({
						actionType: LogoutConstant.UNAUTHORIZED_EXCEPTION,
						data: error
					});
				} else{
					AppDispatcher.dispatch({
						actionType: Constant.GET_PORTFOLIO_USERS,
						data: error
					});
				}
			} else {
				//response['status']= 200;
				AppDispatcher.dispatch({
					actionType: Constant.GET_PORTFOLIO_USERS,
					data: response
				});
			}
		})
	}
}

export default new GetPortfolioUsersAction();