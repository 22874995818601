import AppDispatcher from '../../../flux/dispatcher/dispatcher';
import Constant from '../constants/constant';
import restClient from '../../MainFrame/action/APIClient';
import LogoutConstant from '../../MainFrame/constants/Constants';

var UploadProfileLogoAction = function () {

}


UploadProfileLogoAction.prototype = {

	upload: function (data) {
		restClient.uploadWhitelabelImage(data, function (error, response) {
			if (error) {
				AppDispatcher.dispatch({
					actionType: Constant.PROFILE_LEVEL_LOGO_UPLOAD,
					data: error
				});
			} else if(response !=null) {
				response.status = 200;
				AppDispatcher.dispatch({
					actionType: Constant.PROFILE_LEVEL_LOGO_UPLOAD,
					data: response
				});
			}
		})
	}
}


export default new UploadProfileLogoAction();