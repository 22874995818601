import React from 'react';
import {LCButton} from '../../../frameWork';
var languageFile = require('../../../../public/languages/Translation.js');
export const RenderInviteMdoalWindowFooter = ({
    inviteUser,
    handleCancel,
    emailVisible
}) => {
    return (
        <div>
            <LCButton
                key='submit'
                type='primary'
                size='large'
                onClick={inviteUser}
                buttonlabel={emailVisible ? 'Edit Roles' : languageFile[localStorage.getItem('language')]['28181']}
            />
            <LCButton
                key='cancel'
                type='default'
                size='large'
                onClick={handleCancel}
                buttonlabel={languageFile[localStorage.getItem('language')]['10523']}
            />
        </div>
    );
};
