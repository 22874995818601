import React from 'react';
var cookie = require("../../utils/cookie");

class MicrosoftLogin extends React.Component {

	componentDidMount() {
		var fields = this.props.match.params.query.split("&");
		var fieldsObject = {};
		for (var i in fields) {
			fieldsObject[fields[i].split('=')[0]] = fields[i].split('=')[1];
		}
		var idToken = fieldsObject['id_token'];
		cookie.createCookie('micToken', idToken)
		// MicrosoftLoginAction.login(idToken);
		window.close();
	}

	render() {
		return (
			<div>Microsoft Login</div>
		)
	}
}

export default MicrosoftLogin;