import React from 'react';
import { Button } from 'antd';
import style from './style.module.scss'
import getSVG from '../../../../../utils/commonSVG';

export default function WorkspaceHome(props) {
    return (
        <div className={style.workSpaceWrapper}>
            <div className={style.createSection}>
                <div className={style.descriptionBlock}>
                    <h1>
                        Let's set up your Workspace

                    </h1>
                    <span className={style.details}>
                        Your Workspace holds all of your Profiles and let's you manage your account.
                    </span>

                </div>
                <div className={style.buttonBlock}>
                    <Button  className='primary-btn btn-padding-lg larger-button' onClick={props.onCreateWorkSpace}>
                        Create New Workspace
                    </Button>
                </div>

            </div>
            <div className={style.divider}>
                <div className={style.horizontalLine}></div>
                <div className={style.text}>OR</div>
                <div className={style.horizontalLine}></div>
            </div>

            <div className={style.joinSection}>
                <div className={style.hintBlock}>
                    <div className={style.hintIcon}>
                        {getSVG('hint')}
                    </div>
                    <div>Does your team already have a Workspace? Provide your team admin's email address to request access.</div>
                </div>
                <div className={style.buttonBlock}>
                    <Button className='secondary-btn btn-padding-lg larger-button' onClick={props.onJoinWorkspace}>
                        Join Existing Workspace
                    </Button>
                </div>

            </div>
        </div>
    )
}
