import React from 'react';
import { notification } from 'antd';
import PushNotificationStore from './store/PushNotificationStore';

class ReactAlert extends React.Component {
        constructor(props) {
                super(props)
                this.showAlert = this.showAlert.bind(this);
        }
        componentWillMount() {
                PushNotificationStore.bind(this.showAlert)
        }
        componentWillUnmount() {
                PushNotificationStore.unbind(this.showAlert);
        }
        showAlert() {
                var data = PushNotificationStore.getResponse();
                switch (data.notificationType) {
                        case 'INFO':
                                notification['info']({
                                        description: data.text,
                                       
                                });
                                break;
                        case 'SUCCESS':
                                notification['success']({
                                        description: data.text,
                                        
                                });
                                break;
                        case 'ERROR':
                                notification['error']({
                                        description: data.text,
                                        
                                });
                                break;
                        case 'EMAIL':
                                notification['info']({
                                        description: data.text,
                                        icon: <i style={{ color: "#ef6262"}} className="fa fa-envelope" aria-hidden="false"></i>
                                });
                                break;
                        default:
                                notification['info']({
                                        description: data.text,
                                });
                                break;
                }
        }
        render() {
                return (
                        <div>  </div>
                )
        }
}

export default ReactAlert;